import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Box, Tab, Button, Select, Card, MenuItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, Container, Grid } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { PeriodService } from "@/service/services/Period.service";
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import moment from 'moment';
import ___PHRASE from '@/service/translate/translateService';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const ReportExpenses: React.FC<any> = (props: any): JSX.Element => {

    const [value, setValue] = useState('1');

    // data localstorage
    const data_user = readLocalStorage(KEY_USER_DATA);
    const id_company = data_user?.user?.id_company;

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>(moment().format('YYYY'));
    const [monthSelected, setMonthSelected] = useState<any>(moment().format('MM'));

    //data api solicitudes de gastos
    const [approvedRequests, setApprovedRequests] = React.useState([]);
    const [pendingRequests, setPendingRequests] = React.useState([]);
    const [rejectedRequests, setRejectedRequests] = React.useState([]);
    const [sinDataPendiente, setSinDataPendiente] = React.useState<any>(false);
    const [sinDataAprobado, setSinDataAprobado] = React.useState<any>(false);
    const [sinDataRechazado, setSinDataRechazado] = React.useState<any>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
  
  const labels = [
    { label: ___PHRASE("solicitudesPendientes"), id: '1' },
    { label: ___PHRASE("solicitudesAprobadas"), id: '2' },
    { label: ___PHRASE("solicitudesRechazadas"), id: '3' }
  ]

    let cabecera = [
        {id: 0, name: ___PHRASE("tipoDeGasto")},
        {id: 1, name: ___PHRASE("gastoDelEmpleado")},
        {id: 2, name: ___PHRASE("gastoDeLaEmpresa")},
        {id: 3, name: ___PHRASE("gastoTotal")},
    ]

    //ONCHANGE TABS
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        getPeriods();
    }, []);

    useEffect(()=> {
        if(periods.length > 0) showExpensReport();
    },[periods]);

    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                ...item,
                alias_name: item.month + '-' + item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if (!found) { arrayYears.push(item.year); }

        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const limpiarData = () => {
        setPendingRequests([]);
        setApprovedRequests([]);
        setRejectedRequests([]);
        setSinDataRechazado(false);
        setSinDataAprobado(false);
        setSinDataPendiente(false);
    }

    const showExpensReport = async () => {
        limpiarData();
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSeleccionarMesyAno")
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year
        }

        await ReportService.getReportExpens(id_company, dataPre.idperiod).then((data) => {


            const countEmployees = data?.data?.employee?.countEmployees || 0;
            const review_status = data?.data?.employee?.review_status || [];

            if ((review_status?.Pendiente).length > 0) {
                let newDataFilter = [];
                let lengthValues = 0;

                for (let index = 0; index < (review_status?.Pendiente).length; index++) {
                    const key: any = Object.keys(review_status?.Pendiente[index]);
                    const values: any = Object.values(review_status?.Pendiente[index]);

                    values.length > 0 && values.map((arrayItem, i) => {
                        let newData = {
                            employee: key[i],
                            concept: arrayItem[0],
                            data: arrayItem.splice(1),
                        }
                        newDataFilter.push(newData)
                    });
                }
                
                newDataFilter.length > 0 && newDataFilter.map((arrayItem, i) => {
                    lengthValues = lengthValues + (arrayItem.data).length;
                });

                setPendingRequests(newDataFilter || []);
                if(lengthValues == 0) setSinDataPendiente(true)
            }

            if ((review_status?.Aprobado).length > 0) {
                let newDataFilter = [];
                let lengthValues = 0;

                for (let index = 0; index < (review_status?.Aprobado).length; index++) {
                    const key: any = Object.keys(review_status?.Aprobado[index]);
                    const values: any = Object.values(review_status?.Aprobado[index]);
                    
                    values.length > 0 && values.map((arrayItem, i) => {
                        let newData = {
                            employee: key[i],
                            concept: arrayItem[0],
                            data: arrayItem.splice(1),
                        }
                        newDataFilter.push(newData)
                    });
                }

                newDataFilter.length > 0 && newDataFilter.map((arrayItem, i) => {
                    lengthValues = lengthValues + (arrayItem.data).length;
                });

                setApprovedRequests(newDataFilter || []);
                if(lengthValues == 0) setSinDataAprobado(true)
            }
            if ((review_status?.Rechazado).length > 0) {
                let newDataFilter = [];
                let lengthValues = 0;

                for (let index = 0; index < (review_status?.Rechazado).length; index++) {
                    const key: any = Object.keys(review_status?.Rechazado[index]);
                    const values: any = Object.values(review_status?.Rechazado[index]);

                    values.length > 0 && values.map((arrayItem, i) => {
                        let newData = {
                            employee: key[i],
                            concept: arrayItem[0],
                            data: arrayItem.splice(1),
                        }
                        newDataFilter.push(newData)
                    });
                }

                newDataFilter.length > 0 && newDataFilter.map((arrayItem, i) => {
                    lengthValues = lengthValues + (arrayItem.data).length;
                });

                setRejectedRequests(newDataFilter || []);
                if(lengthValues == 0) setSinDataRechazado(true)
            }

            setLoading(false);
        })
    }
    
   const  getExcelReport = async() =>{
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSeleccionarMesyAno")
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year}
        
        const respExcel = await ReportService.getReportExpensExcel(id_company,dataPre?.idperiod);
       
        const btn_excel = document.createElement("a")
        btn_excel.href = `${respExcel.data.link}`
        btn_excel.download = 'Reporte.xlsx'

        document.body.appendChild(btn_excel);
        btn_excel.click();
        document.body.removeChild(btn_excel)
        setLoading(false);
   }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    {___PHRASE("rendicionDeGastos")}
                </Typography>

                <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={yearSelected}
                                onChange={(event) => {
                                setYearSelected(event.target.value);
                                }}
                                fullWidth
                            >
                                {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1">{___PHRASE("mes")} </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={monthSelected}
                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                fullWidth
                            >
                                <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={2} display='flex'>
                        <Button onClick={showExpensReport} type={'submit'} size="small" color={"primary"} variant="contained" >
                            {___PHRASE("filtrar")}
                        </Button>
                    </Grid>
                </Grid>

                {
                    (pendingRequests.length == 0 && approvedRequests.length  == 0 && rejectedRequests.length == 0 && !loading) && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }} >
                            <Div>{___PHRASE("noSeEncontraronDatos")}</Div>
                        </Box>
                    )
                }
                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }

                {
                    ((pendingRequests.length > 0 || approvedRequests.length  > 0 || rejectedRequests.length > 0 ) && !loading) && (
                        <>
                        <Grid item xs={12} sx={{textAlign: 'right', marginBottom: '20px'}}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => getExcelReport()}
                            >
                                {___PHRASE("descargarExcel")}
                            </Button>
                        </Grid>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="lab API tabs example">
                                        {labels.map((value, i) => {
                                            return (
                                                <Tab key={i} label={value.label} value={value.id} />
                                            )
                                        })}
                                    </TabList>
                                </Box>
                                <TabPanel value="1" > 
                                    {
                                        pendingRequests.length > 0 && pendingRequests.map((valueData) => {
                                            return <>
                                                {(valueData.data).length > 0 && (
                                                    <Card sx={{ width: "100%", marginBottom: '20px'}}>
                                                    <Grid sx={{ width: "100%", padding: '10px'}}>
                                                        <Grid sx={{ width: "100%", paddingLeft: '5px', marginBottom: '15px'}}>
                                                            <Typography variant="h6" sx={{ fontWeight:'bold', color:'#16556b' }}>
                                                                {(`${valueData.concept}`).toUpperCase()}
                                                            </Typography>
                                                            <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                                                {`${(___PHRASE("empleado")+': ').toUpperCase()} ${valueData.employee}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid sx={{ width: "100%", paddingLeft: '5px'}}>
                                                        <Typography sx={{ marginBottom: '10px', color: '#16556b', fontSize:'16px'}}>
                                                            {(___PHRASE("detalleDeLaSolicitud")).toUpperCase()}
                                                        </Typography>
                                                        <TableContainer>
                                                        <Table>
                                                            <TableHead sx={{ bgcolor: '#EDEFF1'}}>
                                                                <TableRow >
                                                                {
                                                                    cabecera.length > 0 && cabecera.map((item)=>(
                                                                        <TableCell >
                                                                            <Typography>{item.name}</Typography>
                                                                        </TableCell>
                                                                    ))
                                                                }
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    (valueData.data).length > 0 && (valueData.data).map((item)=>(
                                                                        <TableRow >
                                                                            <TableCell size={'small'}>
                                                                                {item.type_report}
                                                                            </TableCell>
                                                                            <TableCell size={'small'}>
                                                                                {moneyFormat(parseFloat(item.employee_expense))}
                                                                            </TableCell>
                                                                            <TableCell size={'small'}>
                                                                                {moneyFormat(parseFloat(item.company_expense))}
                                                                            </TableCell>
                                                                            <TableCell size={'small'}>
                                                                                {moneyFormat(parseFloat(item.total_cost))}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                        </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                    </Card>
                                                )}
                                            </>
                                        })
                                    }
                                    {
                                        sinDataPendiente && (
                                            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }} >
                                                <Div>{___PHRASE("noHaySolicitudesPendientes")}</Div>
                                            </Box>
                                        )
                                    }
                                </TabPanel>
                                <TabPanel value="2">
                                        {
                                            approvedRequests.length > 0 && approvedRequests.map((valueData) => {
                                                return <>
                                                    {(valueData.data).length > 0 && (
                                                        <Card sx={{ width: "100%", marginBottom: '20px'}}>
                                                        <Grid sx={{ width: "100%", padding: '10px'}}>
                                                            <Grid sx={{ width: "100%", paddingLeft: '5px', marginBottom: '15px'}}>
                                                                <Typography variant="h6" sx={{ fontWeight:'bold', color:'#16556b' }}>
                                                                    {(`${valueData.concept}`).toUpperCase()}
                                                                </Typography>
                                                                <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                                                    {`${(___PHRASE("empleado")+': ').toUpperCase()} ${valueData.employee}`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid sx={{ width: "100%", paddingLeft: '5px'}}>
                                                            <Typography sx={{ marginBottom: '10px', color: '#16556b', fontSize:'16px'}}>
                                                                {(___PHRASE("detalleDeLaSolicitud")).toUpperCase()}
                                                            </Typography>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                                        <TableRow >
                                                                        {
                                                                            cabecera.length > 0 && cabecera.map((item)=>(
                                                                                <TableCell >
                                                                                    <Typography>{item.name}</Typography>
                                                                                </TableCell>
                                                                            ))
                                                                        }
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            (valueData.data).length > 0 && (valueData.data).map((item)=>(
                                                                                <TableRow >
                                                                                    <TableCell size={'small'}>
                                                                                        {item.type_report}
                                                                                    </TableCell>
                                                                                    <TableCell size={'small'}>
                                                                                        {moneyFormat(parseFloat(item.employee_expense))}
                                                                                    </TableCell>
                                                                                    <TableCell size={'small'}>
                                                                                        {moneyFormat(parseFloat(item.company_expense))}
                                                                                    </TableCell>
                                                                                    <TableCell size={'small'}>
                                                                                        {moneyFormat(parseFloat(item.total_cost))}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                        </Card>
                                                    )}
                                                </>
                                                
                                            })
                                        }
                                        {
                                            sinDataAprobado && (
                                                <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }} >
                                                    <Div>{___PHRASE("noHaySolicitudesAprobadas")}</Div>
                                                </Box>
                                            )
                                        }
                                </TabPanel>
                                <TabPanel value="3">
                                        {
                                            rejectedRequests.length > 0 && rejectedRequests.map((valueData) => {
                                                return <>
                                                {(valueData.data).length > 0 && (
                                                    <Card sx={{ width: "100%", marginBottom: '20px'}}>
                                                    <Grid sx={{ width: "100%", padding: '10px'}}>
                                                        <Grid sx={{ width: "100%", paddingLeft: '5px', marginBottom: '15px'}}>
                                                            <Typography variant="h6" sx={{ fontWeight:'bold', color:'#16556b' }}>
                                                                {(`${valueData.concept}`).toUpperCase()}
                                                            </Typography>
                                                            <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                                                {`${(___PHRASE("empleado")+': ').toUpperCase()} ${valueData.employee}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid sx={{ width: "100%", paddingLeft: '5px'}}>
                                                        <Typography sx={{ marginBottom: '10px', color: '#16556b', fontSize:'16px'}}>
                                                            {(___PHRASE("detalleDeLaSolicitud")).toUpperCase()}
                                                        </Typography>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                                    <TableRow >
                                                                    {
                                                                        cabecera.length > 0 && cabecera.map((item)=>(
                                                                            <TableCell >
                                                                                <Typography>{item.name}</Typography>
                                                                            </TableCell>
                                                                        ))
                                                                    }
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        (valueData.data).length > 0 && (valueData.data).map((item)=>(
                                                                            <TableRow >
                                                                                <TableCell size={'small'}>
                                                                                    {item.type_report}
                                                                                </TableCell>
                                                                                <TableCell size={'small'}>
                                                                                    {moneyFormat(parseFloat(item.employee_expense))}
                                                                                </TableCell>
                                                                                <TableCell size={'small'}>
                                                                                    {moneyFormat(parseFloat(item.company_expense))}
                                                                                </TableCell>
                                                                                <TableCell size={'small'}>
                                                                                    {moneyFormat(parseFloat(item.total_cost))}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                    </Card>
                                                )}
                                                </>
                                            })
                                        }
                                        {
                                            sinDataRechazado && (
                                                <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }} >
                                                    <Div>{___PHRASE("noHaySolicitudesRechazadas")}</Div>
                                                </Box>
                                            )
                                        }
                                </TabPanel>
                            </TabContext>
                        </Box>
                        </>
                    )
                }
            </Container>
        </Protected >
    )
};