
import { Protected } from "@/components/layout/Protected"
import { Container, Card, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Typography, Snackbar, Alert, Grid, Select, MenuItem, Button, Box, styled } from "@mui/material"
import { Props } from "./Request.type"
import { Loading } from "@/components/common/Loading";
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from "react";
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import { PeriodService } from "@/service/services/Period.service";
import { RequestService } from "@/service/services/Request.service"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const RequestView: React.FC<Props> = (props: any): JSX.Element => {

    const sessionUSer = readLocalStorage(KEY_USER_DATA);
    const idCompany = sessionUSer?.user?.id_company;

    const [loading, setLoading] = useState<boolean>(false);
    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>(moment().format('YYYY'));
    const [monthSelected, setMonthSelected] = useState<any>(moment().format('MM'));

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        if(years.length == 0) {
            getPeriods();
        }
    }, []);

    useEffect(()=> {
        if(periods.length > 0) getRequestReport();
    },[periods]);
   
    const [request, setRequest] = useState<any>();
    const [countEmployees, setCountEmployees] = useState<any>('');
    const [countRequest, setCountRequest] = useState<any>('');
    const [open, setOpen] = useState(false);

    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              id: item.id,
              month: item.month,
              year: item.year,
              alias_name: item.month + '-' + item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if (!found) { arrayYears.push(item.year); }
  
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const getRequestReport = async () => {
        setRequest('');
        setLoading(true);
        if (!monthSelected || !yearSelected) {
           setLoading(false);
           return (
              setSnackBarConfig(prev => ({
                 ...prev, open: true,
                 severity: 'warning',
                 message: `Debe llenar todos los campos`
              }
              ))
           );
        }
  
        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
  
        await RequestService.getRequestReport(idCompany, period.id).then((data)=> {
          const countEmployees = data?.data?.employee?.countEmployees || 0;
          const countRequest = data?.data?.employee?.countRequests || 0;
          const request = data?.data?.employee?.request || [] ;
  
          setCountEmployees(countEmployees);
          setCountRequest(countRequest);
  
          let newData = [];
  
          if(request.length > 0){
              for (let index = 0; index < request.length; index++) {
                  if(!Array.isArray(request[index])){
                      newData.push(request[index])
                  }
              }
          }
          if (newData.length > 0) {
              let newDataFilter = [];
              for (let index = 0; index < (newData).length; index++) {
                  const key: any = Object.keys(newData[index]);
                  const values: any = Object.values(newData[index]);
  
                  values.length > 0 && values.map((arrayItem, i) => {
                      if (arrayItem.length > 0) {
                          const Slope = (arrayItem[0]);
                          const Accepted = (arrayItem[1]);
                          const Rejected = (arrayItem[2]);
  
                          let newData = {
                             requestName: key[i],
                             slope: Slope,
                             accepted: Accepted,
                             rejected: Rejected,
                          }
                          newDataFilter.push(newData)
                      }
                  });
              }
              setRequest(newDataFilter);
          }
  
        })
  
        setLoading(false);
    }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    {___PHRASE("reporteDeSolicitud")}
                </Typography>

                <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={yearSelected}
                                onChange={(event) => {
                                setYearSelected(event.target.value);
                                }}
                                fullWidth
                            >
                                {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1">{___PHRASE("mes")} </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={monthSelected}
                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                fullWidth
                            >
                                <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={2} display='flex'>
                        <Button onClick={getRequestReport} type={'submit'} size="small" color={"primary"} variant="contained" >
                            {___PHRASE("filtrar")}
                        </Button>
                    </Grid>
                </Grid>

                {
                    (request && request.length > 0 && !loading) && (
                        <Grid container xs={12} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                            <Grid item xs={9} sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="subtitle2" className="custom-input" 
                                    sx={{ border: `1px #81a4cb solid`, borderRadius:'8px', padding: '5px', color: '#707ac3', width:'450px', textAlign: 'center', marginRight: '5px'}}>
                                    Se encontró {___PHRASE("seEncontro")+' '+countRequest+' '+___PHRASE("solicitudesEnElPeriodoyMesSeleccionado")}
                                </Typography>

                                <Typography variant="subtitle2" className="custom-input" 
                                    sx={{ border: `1px #81a4cb solid`, borderRadius:'8px', padding: '5px', color: '#707ac3', width:'240px',textAlign: 'center' }}>
                                    {___PHRASE('totalEmpleadosConContrato')+' '+countEmployees}.
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }

                {
                    request && request.length > 0 && request.map((item, index)=> (
                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {item.requestName}
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>{___PHRASE("estadoDeSolicitud")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("cantidadEmpleados")}</Typography>
                                            </TableCell>
                                            <TableCell sx={{width:'500px'}}>
                                                <Typography>{___PHRASE("empleados")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {___PHRASE("pendientes")}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.slope.Slope}
                                            </TableCell>
                                            <TableCell size={'small'} component="th" scope="row">
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => request.indexOf(item) == index && setOpen(!open)}
                                                >
                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {
                                                                (item.slope.detail).map((employee)=>(
                                                                    <ListItemButton>
                                                                        {employee}
                                                                    </ListItemButton>
                                                                ))
                                                            }
                                                        </List>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {___PHRASE("aceptados")}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.accepted.Accepted}
                                            </TableCell>
                                            <TableCell size={'small'} component="th" scope="row">
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => request.indexOf(item) == index && setOpen(!open)}
                                                >
                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {
                                                            (item.accepted.detail).map((employee)=>(
                                                                <ListItemButton>
                                                                    {employee}
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {___PHRASE("rechazados")}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.rejected.Rejected}
                                            </TableCell>
                                            <TableCell size={'small'}  component="th" scope="row">
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => request.indexOf(item) == index && setOpen(!open)}
                                                >
                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {
                                                            (item.rejected.detail).map((employee)=>(
                                                                <ListItemButton>
                                                                    {employee}
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                        </Grid>
                    ))
                }

                {
                    (!request && !loading) && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }} >
                            <Div>{___PHRASE("noSeEncontraronDatos")}</Div>
                        </Box>
                    )
                }
                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }
            </Container>

        </Protected>
    )
}