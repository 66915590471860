import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalVariableGlobal.type";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { ChargeService } from "@/service/services/Charge.service";
import { Loading } from "@/components/common/Loading";
import { BusinessAreaService } from "@/service/services/BusinessArea.service";
import { VariableGlobalsService } from "@/service/services/VariableGlobal.service";
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalVariableGlobal: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, variableGlobal, onListVariableGlobal, clearState, listVariablesGlobals, setVariableGlobal} = props;
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     })
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState({
        date: variableGlobal ? moment(variableGlobal.date).format('YYYY-MM') : '',
        uf: variableGlobal ? variableGlobal.uf : '',
        utm: variableGlobal ? variableGlobal.utm : '',
        tope_cot: variableGlobal ? variableGlobal.tope_cot : '',
        tope_cs: variableGlobal ? variableGlobal.tope_cs : '',
        tcs1_i: variableGlobal ? variableGlobal.tcs1_i : '',
        tcs2_f: variableGlobal ? variableGlobal.tcs2_f : '',
        ecs1_i: variableGlobal ? variableGlobal.ecs1_i : '',
        ecs2_f: variableGlobal ? variableGlobal.ecs2_f : '',
        rmi1: variableGlobal ? variableGlobal.rmi1 : '',
        rmi2: variableGlobal ? variableGlobal.rmi2 : '',
        rmi3: variableGlobal ? variableGlobal.rmi3 : '',
        taf_a: variableGlobal ? variableGlobal.taf_a : '',
        taf_b: variableGlobal ? variableGlobal.taf_b : '',
        taf_c: variableGlobal ? variableGlobal.taf_c : '',
        taf_d: variableGlobal ? variableGlobal.taf_d : '',
    });


    const validateForm = (values) => {
        let errors: any = {};
        if(!values.date) errors.date = "Fecha requerido";
        if(!values.uf) errors.uf = "UF requerido";
        if(!values.utm) errors.utm = "UTM requerido";
        if(!values.tope_cot) errors.tope_cot = "Tope de Cotizacion requerido";
        if(!values.tope_cs) errors.tope_cs = "Tope de Cesantia requerido";
        if(!values.tcs1_i) errors.tcs1_i = "TCS1 requerido";
        if(!values.tcs2_f) errors.tcs2_f = "TCS2 requerido";
        if(!values.ecs1_i) errors.ecs1_i = "ECS1 requerido";
        if(!values.ecs2_f) errors.ecs2_f = "ECS22 requerido";
        if(!values.rmi1) errors.rmi1 = "Remuneracion Mínima 1 requerido";
        if(!values.rmi2) errors.rmi2 = "Remuneracion Mínima 2 requerido";
        if(!values.rmi3) errors.rmi3 = "Remuneracion Mínima 3 requerido";
        if(!values.taf_a) errors.taf_a = "Tramo Familia A requerido";
        if(!values.taf_b) errors.taf_b = "Tramo Familia B requerido";
        if(!values.taf_c) errors.taf_c = "Tramo Familia C requerido";
        // if(!values.taf_d) errors.taf_d = "Tramo Familia D requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            date: moment(values.date).format('YYYY-MM-DD'),
            uf: values.uf,
            utm: values.utm,
            tope_cot: values.tope_cot,
            tope_cs: values.tope_cs,
            tcs1_i: values.tcs1_i,
            tcs2_f: values.tcs2_f,
            ecs1_i: values.ecs1_i,
            ecs2_f: values.ecs2_f,
            rmi1: values.rmi1,
            rmi2: values.rmi2,
            rmi3: values.rmi3,
            taf_a: values.taf_a,
            taf_b: values.taf_b,
            taf_c: values.taf_c,
            taf_d: values.taf_d,
            idcountry: 1
        }
       
        setLoading(true);
        if(variableGlobal && actionSelected=='edit'){
            await VariableGlobalsService.editVariableGlobal(variableGlobal.id,formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);
                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open: true, severity: 'success', message: apimessagge})
                }else{
                    setSnackBarConfig({...snackBarConfig, open: true, severity: 'error', message:apimessagge})
                }
                setTimeout(()=>{
                    onListVariableGlobal && onListVariableGlobal();
                    closeModal && closeModal();
                },2000)
            })
        }else{
            await VariableGlobalsService.createVariableGlobal(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);
                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open: true, severity: 'success', message: apimessagge})
                }else{
                    setSnackBarConfig({...snackBarConfig, open: true, severity: 'error', message:apimessagge})
                }
                setTimeout(()=>{
                    onListVariableGlobal && onListVariableGlobal();
                    closeModal && closeModal();
                },2000)
               
            })
        }
        setLoading(false);
    }

    useEffect(() => {
        if(open && actionSelected == 'save'){
          setVariableGlobal(listVariablesGlobals[0])
          setData({...listVariablesGlobals[0], date:''})
        }
     },[open])

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((variableGlobal && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("variableGlobal")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container  sx={{maxHeight: '65vh', paddingRight:5, overflowY: 'scroll'}}>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fecha")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="date"
                                            variant="standard"
                                            type="month" 
                                            name="date"
                                            fullWidth
                                            value={values.date}
                                            onChange={handleChange}
                                            disabled={actionSelected=='edit'?  true: false}
                                            placeholder={___PHRASE("fechaDeRegistro")}
                                            error={errors.date && touched.date ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>UF:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="uf"
                                            variant="standard"
                                            type="number" 
                                            name="uf"
                                            fullWidth
                                            value={values.uf}
                                            onChange={handleChange}
                                            placeholder="UF"
                                            error={errors.uf && touched.uf ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>UTM:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="utm"
                                            variant="standard"
                                            type="number" 
                                            name="utm"
                                            fullWidth
                                            value={values.utm}
                                            onChange={handleChange}
                                            placeholder="UTM"
                                            error={errors.utm && touched.utm ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("topeCotizacion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="tope_cot"
                                            variant="standard"
                                            type="number" 
                                            name="tope_cot"
                                            fullWidth
                                            value={values.tope_cot}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("topeCotizacion")}
                                            error={errors.tope_cot && touched.tope_cot ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("topeCesantia")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="tope_cs"
                                            variant="standard"
                                            type="number" 
                                            name="tope_cs"
                                            fullWidth
                                            value={values.tope_cs}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("topeCesantia")}
                                            error={errors.tope_cs && touched.tope_cs ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>TCS1:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="tcs1_i"
                                            variant="standard"
                                            type="number" 
                                            name="tcs1_i"
                                            fullWidth
                                            value={values.tcs1_i}
                                            onChange={handleChange}
                                            placeholder="TCS1"
                                            error={errors.tcs1_i && touched.tcs1_i ? true : false}
                                        />
                                        </Grid>
                                    </Grid><Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>TCS2:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="tcs2_f"
                                            variant="standard"
                                            type="number" 
                                            name="tcs2_f"
                                            fullWidth
                                            value={values.tcs2_f}
                                            onChange={handleChange}
                                            placeholder="TCS2"
                                            error={errors.tcs2_f && touched.tcs2_f ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>ECS1:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="ecs1_i"
                                            variant="standard"
                                            type="number" 
                                            name="ecs1_i"
                                            fullWidth
                                            value={values.ecs1_i}
                                            onChange={handleChange}
                                            placeholder="ECS1"
                                            error={errors.ecs1_i && touched.ecs1_i ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>ECS2:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="ecs2_f"
                                            variant="standard"
                                            type="number" 
                                            name="ecs2_f"
                                            fullWidth
                                            value={values.ecs2_f}
                                            onChange={handleChange}
                                            placeholder="ECS2"
                                            error={errors.ecs2_f && touched.ecs2_f ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("remuneracionMensualMinima")} 1:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="rmi1"
                                            variant="standard"
                                            type="number" 
                                            name="rmi1"
                                            fullWidth
                                            value={values.rmi1}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("remuneracionMensualMinima")+' 1'}
                                            error={errors.rmi1 && touched.rmi1 ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("remuneracionMensualMinima")} 2:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="rmi2"
                                            variant="standard"
                                            type="number" 
                                            name="rmi2"
                                            fullWidth
                                            value={values.rmi2}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("remuneracionMensualMinima")+' 2'}
                                            error={errors.rmi2 && touched.rmi2 ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("remuneracionMensualMinima")} 3:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="rmi3"
                                            variant="standard"
                                            type="number" 
                                            name="rmi3"
                                            fullWidth
                                            value={values.rmi3}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("remuneracionMensualMinima")+' 3'}
                                            error={errors.rmi3 && touched.rmi3 ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("tramoFamiliar")} A:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="taf_a"
                                            variant="standard"
                                            type="number" 
                                            name="taf_a"
                                            fullWidth
                                            value={values.taf_a}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("tramoFamiliar")+' A'}
                                            error={errors.taf_a && touched.taf_a ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("tramoFamiliar")} B:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="taf_b"
                                            variant="standard"
                                            type="number" 
                                            name="taf_b"
                                            fullWidth
                                            value={values.taf_b}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("tramoFamiliar")+' B'}
                                            error={errors.taf_b && touched.taf_b ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("tramoFamiliar")} C:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="taf_c"
                                            variant="standard"
                                            type="number" 
                                            name="taf_c"
                                            fullWidth
                                            value={values.taf_c}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("tramoFamiliar")+' C'}
                                            error={errors.taf_c && touched.taf_c ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("tramoFamiliar")} D:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="taf_d"
                                            variant="standard"
                                            type="number" 
                                            name="taf_d"
                                            fullWidth
                                            value={values.taf_d}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("tramoFamiliar")+' D'}
                                            error={errors.taf_d && touched.taf_d ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(variableGlobal && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}