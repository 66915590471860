import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalUser.type";
import '../../../assets/styles/modals.scss';
import { userService } from "@/service/services/User.service";
import { companiesService } from "@/service/services/Companies.service";
import { ProfileSessionService } from "@/service/services/ProfileSession.service";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "@/toolbox/constants/role-type";
import { WorkerService } from "@/service/services/Worker.service";
import { ConfigurationIPService } from "@/service/services/ConfigurationIP.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalUser: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, user, onListUsers, clearState } = props;
    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;

    const [loading, setLoading] = useState<boolean>(false);
    const [roles, setRoles] = useState<any>([]);
    const [companies, setCompanies] = useState<any>([]);
    const [profiles, setProfiles] = useState<any>([]);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [workersSelected, setWorkersSelected] = useState<any>('');
    const [rolesSelected, setrolesSelected] = useState<any>([]);
    const [workers, setWorkers] = useState<any>('');

    const [data, setData] = useState<any>({
        id: '',
        email: '',
        user_name: '',
        password: '',
        confirm_password: '',
        n_document: '',
        role: '2',
        assign_ip: '0',
        idCompany: '',
        idprofile: ''
    });

    useEffect(() => {
        console.log('0')
        getRoles();
        if(role == ROLE_SUPER_ADMIN) getCompaniesAdmin();
    }, []);

    useEffect(() => {
        console.log('1')
        if(role == ROLE_SUPER_ADMIN) getProfileByRole();
    }, [role]);

    useEffect(() => {
        console.log('2')
        if(rolesSelected.length > 0) getProfileByRole();
    }, [rolesSelected]);

    useEffect(() => {
        if(rolesSelected.length > 0 && rolesSelected.find(role=> role.id == 5)) getWorkers();
    }, [rolesSelected]);


    useEffect(() => {
        async function initialData(){
            if(user && roles.length > 0){
                setLoading(true);
                await companiesService.companiesByUser(user.iduser).then((data) => {
                    const companiesByUser = data?.data;
                    setData({
                        id: user ? user.iduser : '',
                        email: user ? user.email : '',
                        user_name: user ? user.user_name : '',
                        password: user ? user.password : '',
                        confirm_password: user ? user.confirm_password : '',
                        n_document: user ? user.n_document : '',
                        role: user && role == ROLE_SUPER_ADMIN? getCustomRoleName(user.roles, roles) : getCustomRoles(user.rolesArray, roles),
                        idCompany: user ? getCustomCompanies(companiesByUser || []) : '',
                        assign_ip: user ? user.assign_ip : '0',
                        idprofile: user ? getCustomProfileName(user.profile, profiles) : '',
                    });
                })
                setLoading(false);
            }
        }
        initialData();
    }, [user, roles]);

    useEffect(()=>{
        setData({
            ...data,
            idprofile: user ? getCustomProfileName(user.profile, profiles) : '',
        })
    },[profiles])

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();

        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData || []);
        if(user){
            let empSelect = newData.find((item)=> item.id == user.idemployee)
            setWorkersSelected(empSelect)
        }
        setLoading(false);
    }

    const getRoles = async() => {
        setLoading(true);
        await userService.getRoles().then((data) => {
            let roles = []
            const respData = data.data || [];
            if(role == ROLE_SUPER_ADMIN) roles = respData && respData.filter(role => role.id == 2);
            if(role == ROLE_ADMIN) roles = respData && respData.filter(role => role.id == 2 || role.id == 5);
            setRoles(roles|| []);
            setLoading(false);
        })
    }

    const getProfileByRole = async() => {
        setLoading(true);
        if(role == ROLE_SUPER_ADMIN){
            await ProfileSessionService.getProfileByRole(JSON.stringify([2])).then((data) => {
                setProfiles(data.data || []);
                setLoading(false);
            })
        }
        if(role == ROLE_ADMIN){
            let arrayIdsRoles = [];
            rolesSelected.length > 0 && rolesSelected.map((role) => {
                arrayIdsRoles.push(role.id);
            });
            await ProfileSessionService.getProfileByRole(JSON.stringify(arrayIdsRoles)).then((data) => {
                setProfiles(data.data || []);
                setLoading(false);
            })
        }

    }

    const getCompaniesAdmin = async() => {
        setLoading(true);
        await companiesService.getCompaniesAdmin().then((data) => {
            setCompanies(data.data || []);
            setLoading(false);
        })
    }

    function getCustomCompanies(originalCompanies): any {
        return originalCompanies ? originalCompanies.map(company => ({id: company.id, business_name: company.business_name})) : [];
    }

    const customCompanies = getCustomCompanies(companies || []);

    const validateForm = (values) => {
        let errors: any = {};
        let nameUser = /^[a-z]+$/;
        let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;
        let regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        if(role == ROLE_SUPER_ADMIN || role == ROLE_ADMIN){
            if(!values.email && !user){errors.email = "correo requerido";} 
            else if(!(regEmail.test(values.email))) errors.email =  ___PHRASE("correo") +' '+"no válido";
            if(!values.user_name && !user ){ errors.user_name = "nombre de usuario requerido";}
            else if(values.user_name.length >= 20) errors.user_name ="nombre de usuario debe tener como máximo 20 caracteres"
            if(values.user_name && !(nameUser.test(values.user_name))) errors.user_name = "solo debe contener letras minúsculas";
            if(!values.n_document && !user) errors.n_document = "numero de documento requerido";
            if(values.n_document && !(regRut.test(values.n_document))) errors.n_document = "numero de documento no válido";
            if(!values.idprofile && !user) errors.idprofile = "perfil requerido";
            return errors;
        }
       
    }

    const onSubmit = async(values, {resetForm})  => {

        let arrayIdsCompanies= [];

        values.idCompany && values.idCompany.map((item) => {
            arrayIdsCompanies.push(item.id);
        });

        let formData = {
            id: values.id,
            email: values.email,
            user_name : values.user_name,
            password : values.password,
            confirm_password : values.confirm_password,
            n_document : (rolesSelected.find(role => role.id == 5) && workersSelected) ? workersSelected.n_document : values.n_document,
            role : role == ROLE_SUPER_ADMIN? values.role : rolesSelected,
            assign_ip : values.assign_ip || '0',
            idprofile : values.idprofile,
            idemployee: workersSelected.id
        }

        let dataEditDetail = {
            iduser: values.id,
            idcompanies: JSON.stringify(arrayIdsCompanies),
            roleuser: values.role,
            idprofile : values.idprofile,
        }

        let dataEditUser = {
            id: values.id,
            email: values.email,
            user_name : values.user_name,
            n_document : (rolesSelected.find(role => role.id == 5) && workersSelected) ? workersSelected.n_document : values.n_document,
            idprofile : values.idprofile,
            idcompanies: JSON.stringify(arrayIdsCompanies),
            iduser: values.id,
        }

        let EditUserForAdmin = {
            id: values.id,
            iduser: values.id,
            email: values.email,
            user_name : values.user_name,
            n_document : (rolesSelected.find(role => role.id == 5) && workersSelected) ? workersSelected.n_document : values.n_document,
            idprofile : values.idprofile,
            idEmployee : (rolesSelected.find(role => role.id == 5) && workersSelected) ? workersSelected.id : null,
            role: rolesSelected.map(role => role.id)
        }


        setLoading(true);
        if(user && actionSelected=='helper'){
            if(arrayIdsCompanies.length == 0){
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: ___PHRASE("asegureseDeSeleccionarAlMenosUnaEmpresa") 
                    }))
                )
            }
            await userService.editDetailUserCompany(dataEditDetail).then((data) => {
                if(data.data.status === true){
                    setLoading(false);
                    closeModal && closeModal();
                    onListUsers && onListUsers();
                }else{
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: `${data.data.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` 
                        }))
                    )
                }
            })
        }else if(user && actionSelected=='edit' && role == ROLE_ADMIN){
            await ConfigurationIPService.assignPermissionIP({iduser: values.id, assign_ip: values.assign_ip}).then(async(data) => {
                if(data.data.status === true){
                    setLoading(false);
                    closeModal && closeModal();
                    onListUsers && onListUsers();
                    await userService.updateForAdmin(EditUserForAdmin).then((data)=>{
                        if(data == false){
                            setLoading(false);
                            return (
                                setSnackBarConfig(prev => ({ ...prev, open: true, 
                                    severity:'error', 
                                    message: `${ ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` 
                                }))
                            )
                        }else{
                            closeModal && closeModal();
                            onListUsers && onListUsers();
                            setLoading(false);
                        }
                    })
                }else{
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: `${data.data.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` 
                        }))
                    )
                }
            })
        }else{
            if(role == ROLE_SUPER_ADMIN && actionSelected == 'save'){
                if(arrayIdsCompanies.length == 0){
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: ___PHRASE("asegureseDeSeleccionarAlMenosUnaEmpresa") 
                        }))
                    )
                }
                if(!values.idprofile){
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: ___PHRASE("debeDeSeleccionarUnPerfil") 
                        }))
                    )
                }
                await userService.createOnlyUser({...formData, 'idCompany': JSON.stringify(arrayIdsCompanies)}).then((data) => {
                    if(data.data.status === false){
                        setLoading(false);
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'error', 
                                message: `${data.data.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` 
                            }))
                        )
                    }else{
                        closeModal && closeModal();
                        onListUsers && onListUsers();
                        setLoading(false);
                    }
                })
            }else if(ROLE_SUPER_ADMIN && actionSelected == 'edit'){
                if(!values.idprofile){
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: ___PHRASE("debeDeSeleccionarUnPerfil") 
                        }))
                    )
                }

                await userService.update(dataEditUser).then((data)=>{
                    if(data == false){
                        setLoading(false);
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'error', 
                                message: `${ ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` 
                            }))
                        )
                    }else{
                        closeModal && closeModal();
                        onListUsers && onListUsers();
                        setLoading(false);
                    }
                })
            }
            else{
                await userService.createUser(formData).then((data) => {
                    if(data === false){
                        setLoading(false);
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'error', 
                                message: `${___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` 
                            }))
                        )
                    }else{
                        closeModal && closeModal();
                        onListUsers && onListUsers();
                        setLoading(false);
                    }
                })
            }
            
        }
        setLoading(false);
    }

    const getCustomRoleName = (oldRolName, roles) => {
        const customRol = roles.find(rol => rol.name == oldRolName);
        setrolesSelected([customRol])
        return  customRol ? customRol.id : ''
    }

    const getCustomRoles = (oldArrayRoles, roles) => {
        let arrayIds = [];
        let newArraySelected = oldArrayRoles.map((item)=>{
            
            let obj =  roles.find(rol => rol.name == item);
            arrayIds.push(obj?.id)
            return obj
        })
        setrolesSelected(newArraySelected)
        return arrayIds
    }

    const getCustomProfileName = (oldProfileName, profiles) => {
        const customProfile = profiles.find(rol => rol.name == oldProfileName);
        return  customProfile ? customProfile.id : ''
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={(user && actionSelected=='edit') ? ___PHRASE("editar")+' '+___PHRASE("usuario") : (user && actionSelected=='helper') ? ___PHRASE("empresaALaQueElUsuarioPertenece") : ___PHRASE("agregar")+' '+___PHRASE("usuario")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    {
                                        actionSelected != 'helper' && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("correo")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="email"
                                                    variant="standard"
                                                    type="email" 
                                                    name="email"
                                                    fullWidth
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("correoDeUsuario")}
                                                    error={errors.email && touched.email ? true : false}
                                                    // disabled={user ? true :  false}
                                                    helperText={errors.email && touched.email ? errors.email : ''}
                                                />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombreDeUsuario")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="user_name"
                                            variant="standard"
                                            type="text" 
                                            name="user_name"
                                            multiline
                                            fullWidth
                                            value={values.user_name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombreDeUsuario")}
                                            error={errors.user_name && touched.user_name ? true : false}
                                            disabled={actionSelected == 'helper' ? true :  false}
                                            helperText={errors.user_name && touched.user_name ? errors.user_name: ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    {
                                        actionSelected != 'helper' && (
                                            <>

                                            {
                                                role == ROLE_SUPER_ADMIN && (
                                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body1"><b>{___PHRASE("rol")}:</b> </Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Select
                                                                id="role"
                                                                variant="standard"
                                                                value={values.role}
                                                                onChange={(event) => { 
                                                                setFieldValue("role", event.target.value);
                                                                }}
                                                                error={ errors.role && touched.role ? true : false }
                                                                fullWidth
                                                                disabled={role ==  ROLE_SUPER_ADMIN ? true : false}
                                                            >
                                                                {
                                                                    roles.map((rol, i) => {
                                                                        return <MenuItem key={i} value={rol.id}>{rol.name}</MenuItem>;
                                                                    })
                                                                }
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }

                                            {
                                                role == ROLE_ADMIN && (
                                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body1"><b>Roles:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Autocomplete
                                                                multiple
                                                                disablePortal
                                                                id="combo-box-demo"
                                                                value={rolesSelected}
                                                                options={roles || []}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                                onChange={(e, newValue) => {
                                                                    setrolesSelected(newValue);
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }

                                            {
                                                rolesSelected.find(role => role.id == 5) && (
                                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body1"><b>{___PHRASE("empleado")}:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Autocomplete
                                                                disablePortal
                                                                id="combo-box-demo"
                                                                value={workersSelected|| []}
                                                                options={workers || []}
                                                                getOptionLabel={(option) => option.name_complete || ""}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                                onChange={(e, newValue) => {
                                                                    setWorkersSelected(newValue);
                                                                    setFieldValue('n_document', newValue.n_document)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }

                                            {/* {
                                                (role == ROLE_SUPER_ADMIN ) && (
                                                    
                                                )
                                            } */}

                                            {
                                                (rolesSelected.find(role => role.id == 5) && workersSelected) ? (
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nDocumento")}:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                        <TextField
                                                            id="n_document"
                                                            variant="standard"
                                                            type="text" 
                                                            name="n_document"
                                                            multiline
                                                            fullWidth
                                                            value={values.n_document}
                                                            onChange={handleChange}
                                                            placeholder={___PHRASE("numeroDeDocumento")}
                                                            error={errors.n_document && touched.n_document ? true : false}
                                                            // disabled={user ? true :  false}
                                                            helperText={errors.n_document && touched.n_document ? errors.n_document : ''}
                                                        />
                                                        </Grid>
                                                    </Grid>
                                                ):(
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nDocumento")}:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                        <TextField
                                                            id="n_document"
                                                            variant="standard"
                                                            type="text" 
                                                            name="n_document"
                                                            multiline
                                                            fullWidth
                                                            value={values.n_document}
                                                            onChange={handleChange}
                                                            placeholder={___PHRASE("numeroDeDocumento")}
                                                            error={errors.n_document && touched.n_document ? true : false}
                                                            helperText={errors.n_document && touched.n_document ? errors.n_document : ''}
                                                        />
                                                        </Grid>
                                                    </Grid> 
                                                )
                                            }

                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>{___PHRASE("perfil")}:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="idprofile"
                                                        variant="standard"
                                                        value={values.idprofile}
                                                        onChange={(event) => { 
                                                        setFieldValue("idprofile", event.target.value);
                                                        }}
                                                        error={ errors.idprofile && touched.idprofile ? true : false }
                                                        fullWidth
                                                    >
                                                        {
                                                            profiles.map((profile, i) => {
                                                                return <MenuItem key={i} value={profile.id}>{profile.name}</MenuItem>;
                                                            })
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            
                                            </>
                                        )
                                    }

                                    {
                                        role == ROLE_SUPER_ADMIN  && (
                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>{___PHRASE("empresas")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        multiple={true}
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        value={values.idCompany || []}
                                                        options={customCompanies || []}
                                                        getOptionLabel={(option) => option.business_name || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                        onChange={(e, newValue) => {
                                                            setFieldValue("idCompany", newValue);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }


                                    {
                                        (role != ROLE_SUPER_ADMIN )&& (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("restriccionPorIP")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="assign_ip"
                                                        variant="standard"
                                                        value={values.assign_ip}
                                                        onChange={(event) => {setFieldValue("assign_ip", event.target.value)}}
                                                        error={ errors.idtypeabsence && touched.idtypeabsence ? true : false }
                                                        fullWidth
                                                    >
                                                        <MenuItem value="0">{___PHRASE("sinRestriccion")}</MenuItem>
                                                        <MenuItem value="1">{___PHRASE("conRestriccion")}</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    }

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(user && actionSelected=='edit') ? ___PHRASE("actualizar") : (user && actionSelected=='helper') ? ___PHRASE("actualizar")+' '+___PHRASE("empresas") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}