import React, { useState, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { Box, Stepper, IconButton, Step, StepLabel, Grid, Container, StepIconProps, Typography, Divider } from '@mui/material'
import { Props } from "./Workers.type"
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FormPersonalData } from "./FormPersonalData";
import { FormLaborData } from "./FormLaborData";
import PaymentIcon from '@mui/icons-material/Payment';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { FormInformation } from './FormInformation';
import { FormBankInformation } from './FormBankInformation';
import { FormFamilies } from './FormFamilies';
import { DataModalFamily } from './ModalFamily/DataModalFamily';
import { DataModalWork } from './ModalAccount/DataModalWork';
import { WorkerService } from '@/service/services/Worker.service';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { dateFormat } from '@/toolbox/helpers/date.helper';
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONTRACT_EMPLOYEE } from '@/toolbox/constants/route-map';
import { saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { FormIdentityDocuments } from './FormIdentityDocuments';
import { ModalHeader } from '@/components/Modal/ModalHeader';
import { Modal } from '@/components/Modal/Modal';
import { ModalBody } from '@/components/Modal/ModalBody';
import "./Workers.css";
import ___PHRASE from '@/service/translate/translateService';
import { ModalWorkerDelete } from '@/components/Modal/ModalWorkerDelete/ModalWorkerDelete';

export const Workers: React.FC<Props> = (props: Props): JSX.Element => {

  const steps = [
   { name: ___PHRASE("datosPersonales"), icon: null },
   { name: ___PHRASE("documentosDeIdentidad"), icon: null },
   { name: ___PHRASE("datosLaborales"), icon: null },
   { name: ___PHRASE("informacionPrevisional"), icon: null },
   { name: ___PHRASE("cargasFamiliares"), icon: null },
   { name: ___PHRASE("informacionAdicional"), icon: null },
  ]

  const header = [
   { name: 'n_document', label: 'Rut', width: '' },
   { name: 'surname', label: ___PHRASE("apellidoPaterno"), width: '' },
   { name: 'second_surname', label: ___PHRASE("apellidoMaterno"), width: '' },
   { name: 'name', label: ___PHRASE("nombre"), width: '' },
  ]

  const dataAction = [
   { name: 'edit', route: null },
   { name: 'add_person', route: null, tooltip: ___PHRASE("cargasFamiliares") },
   { name: 'folder', route: null, tooltip: ___PHRASE("historialLaboral") },
   { name: 'certificate', route: null, tooltip: ___PHRASE("certificadoDeAntiguedad") },
  ]
   
   const history = useHistory();
   const [loading, setLoading] = useState<boolean>(false);
   const [activeStep, setActiveStep] = useState(0);
   const [skipped, setSkipped] = useState(new Set());
   const [error, setError] = useState(null);

   const [openWork, setOpenWork] = useState(false);
   const [openFamily, setOpenFamily] = useState(false);
   const [openNewWorker, setOpenNewWorker] = useState(false);
   const [openModalDeleteEmployee, setOpenModalDeleteEmployee] = useState(false);
   const [isEdit, setIsEdit] = useState(false);
   const [employeeSelect, setEmployeeSelect] = useState<any>({});
   const [idContractSelect, setIdContractSelect] = useState<number>(0);
   const [dataWorker, setDataWorker] = useState<any>([]);
   const [idEmployee, setIdEmployee] = useState<any>('');

   const handleOpenWork = () => setOpenWork(true);
   const handleCloseWork = () => setOpenWork(false);

   const handleOpenFamily = () => setOpenFamily(true);
   const handleCloseFamily = () => setOpenFamily(false);

   const handleOpenNewWorker = () => setOpenNewWorker(true);
   const handleCloseContract = () => {
      setOpenNewWorker(false); setIdEmployee('')
   };

   const getWorkers = async () => {
      setLoading(true);
      const resp = await WorkerService.getWorkers();
      setDataWorker(resp.data);
      setLoading(false);
   }
   
   const [dataInitial, setDataInitial] = useState<any>({
      Bank: [],
      Country: [],
      Military: [],
      Education: [],
      State: [],
      Category: [],
   })
   
   const getDataInitialEmployee = async () => {
      setLoading(true);
      const resp = await WorkerService.getDataInitialEmployee();
      const res = resp.data;
      if (res) {
         setDataInitial(prev => ({
            ...prev,
            Bank: res.Bank, Country: res.Country,
            Military: res.Military, Education: res.Education,
            State: res.State, Category: res.Category,
            AFP: res.AFP, APV: res.APV, AccountType: res.AccountType,
            BusinessArea: res.BusinessArea, CivilStatus: res.CivilStatus,
            ContractType: res.ContractType, DisabledPerson: res.DisabledPerson,
            District: res.District, EmployeeType: res.EmployeeType,
            FamilyStretch: res.FamilyStretch, Position: res.Position,
            Profession: res.Profession, Salud: res.Salud,
            TypeCtoSalud: res.TypeCtoSalud, TypeMoney: res.TypeMoney,
            WayPay: res.WayPay, GratificationType: res.GratificationType,
            TypeDurationContract: res.TypeDurationContract,
            BranchOffice: res.BranchOffice,
            WorkMode: res.WorkMode, CostCenter: res.CostCenter,
            Charge: res.Charge,
            WorkingTime: res.WorkingTime,
            DocumentContract: res.DocumentContract,
            Compensationbox: res.Compensationbox,
         }))
      }
      setLoading(false);
   }

   const getContractForEmployee = async (idSelect: number) => {
      const resp = await WorkerService.getContracts();
      const resId = resp.data.map(res => { res.idemployee == idSelect && getEmployeeContract(res.id) });
   }

   const getEmployeeContract = async (idContract) => {
      setLoading(true);
      const resp = await WorkerService.getEmployeeContract(idContract);
      const res = resp.data[0];
      saveLocalStorage('EMPLOYEE_END', res)
      if (res) {
         setvaluePersonalData(prev => ({
            ...prev,
            n_document: res.n_document, //->1
            name: res.name,  //->1
            surname: res.surname,  //->1 
            second_surname: res.second_surname,  //->1
            idcivilstatus: res.idcivilstatus,  //->1
            idcategoryemployee: res.idcategoryemployee,
            // idprofession: 1,
            idprofession: res.idprofession,
            // idposition: 1,
            idposition: res.idposition,
            sexo: res.sexo,
            birth_date: res.birth_date,
            // pensioner: 1,
            pensioner: res.pensioner,
            idemployeetype: res.idemployeetype,
            state: res.state,
            iddistrictbranchoffice: res.iddistrictbranchoffice,
            idmilitary: res.idmilitary,
            ideducation: res.ideducation,
            direccion: res.direccion,
            nationality: res.nationality,
            iddistrict: res.iddistrict,
            telefono: res.telefono,
            celular: res.celular,
            personal_phonename: res.personal_phonename,
            emergency_phone: res.emergency_phone,
            email: res.email,
         }));

         setvalueDatosLaborales(prev => ({
            ...prev,
            idprofession: res.idprofession,
            idcharge: res.idcharge,
            idbusinessarea: res.idbusinessarea,
            idtype_contract: res.idtype_contract,            
            workerstate: res.workerstate,
            status: res.status, //->workerstate,
            workersubsidy: res.workersubsidy,
            idbranch_office:res.idbranchoffice,
            idcommuneprovisionservices: res.idcommuneprovisionservices,
            iddistrictbranchoffice: res.iddistrictbranchoffice,
            money: res.money,
            idgratificationtype: res.idgratificationtype,
            idcostcenter: res.idcostcenter,
            private_role: res.private_role,
            n_control_card: res.n_control_card,
            years_other_employers: res.years_other_employers,
            apv_libro_rem: res.apv_libro_rem,
            unemployment_insurance: res.unemployment_insurance,
            idworkmode: res.idworkmode,
            idworkingtime: res.idworkingtime,
            idtype_duration_contract:res.idtype_duration_contract,
            iddisableperson: res.iddisableperson,
            time_control: res.time_control,
            iddocumentcontract: res.iddocumentcontract,
         }));
         setvalueInformation(prev => ({
            ...prev,
            idAFP: res.idAFP,
            idsalud: res.idsalud,
            idAPV: res.idAPV,
            reduced_tax: res.reduced_tax,
            apv_afp: res.apv_afp,
            book_rem_electr: res.book_rem_electr,
            n_contrato: res.n_contrato,
            idctosalud: res.idctosalud,
            cot_salud_por: res.cot_salud_por,
            cot_salud_$: res.cot_salud_$,
            cto_salud_uf: res.cto_salud_uf,
            payment_method: res.payment_method,
         }));
         setValueFamilyBurden(prev => ({
            ...prev,
            load_number: res.load_number,
            idfamilystretch: res.idfamilystretch
         }));
         setValueBankInformation(prev => ({
            ...prev,
            idbank: res.idbank,
            idwaypay: res.idwaypay,
            idaccounttype: res.idaccounttype,
            account_number: res.account_number,
            idcompensationbox: res.idcompensationbox,
            last_imponible: res.last_imponible,
         }));
      }
      setLoading(false);
      setIdContractSelect(idContract);
      getDataInitialEmployee();
   }
   const vaciarData = async () => {

      setIdEmployee('');
      setvalueDatosDocuments('');
      setvaluePersonalData({
         ...valuePersonalData,
         n_document: '',
         name: '',
         surname: '',
         second_surname: '',
         idcivilstatus: '',
         idcategoryemployee: '',
         // idprofession: 1,
         // idposition: 1,
         idposition: 0,
         sexo: '',
         birth_date: '',
         // pensioner: 1,
         pensioner: 0,
         state: '',
         // iddistrictbranchoffice: '',
         idmilitary: '',
         idemployeetype: '',
         ideducation: '',
         direccion: '',
         // nationality: '',
         iddistrict: '',
         telefono: '',
         celular: '',
         emergency_phone: '',
         email: ''
      });
      setvalueDatosLaborales({
         ...valueDatosLaborales,
         idcharge: '',
         idprofession: '',
         idbusinessarea: '',
         idtype_contract: '',
         idbranch_office:'',
         // status:'', 
         workersubsidy: '',
         idworkmode:'',
         idcommuneprovisionservices: '',
         iddistrictbranchoffice: '',
         money: '',
         idgratificationtype: '',
         idcostcenter: '',
         private_role: '',
         n_control_card: '',
         years_other_employers: '',
         apv_libro_rem: 0,
         unemployment_insurance: '',
         idtype_duration_contract: '',
         workerstate:'',
         iddisableperson: '',
         time_control: ''
      });
      setvalueInformation({
         ...valueInformation,
         idAFP: '',
         idsalud: '',
         idAPV: '',
         reduced_tax: '',
         apv_afp: '',
         book_rem_electr: '',
         n_contrato: '',
         idctosalud: '',
         cot_salud_por: '',
         cot_salud_$: '',
         cto_salud_uf: '',
         payment_method: '',
      });
      setValueFamilyBurden({
         ...valueFamilyBurden,
         load_number: '',
         idfamilystretch: ''
      });
      setValueBankInformation({
         ...valueBankInformation,
         idbank: '',
         idwaypay: '',
         idaccounttype: '',
         account_number: '',
         idcompensationbox:'',
         last_imponible:'',
      });
   }

   const recuperarData = (data) => {
      setIdEmployee(data.id || '')
      switch (data.action) {
         case 'add_person':
            setEmployeeSelect(data);
            handleOpenFamily();

            break;
         case 'edit':
            setIsEdit(true);
            getContractForEmployee(data.id);
            setOpenNewWorker(true);
            break;
         case 'delete':
            setEmployeeSelect(data);
            setOpenModalDeleteEmployee(true);
            break;
         case 'folder':
            setEmployeeSelect(data);
            handleOpenWork();
            break;
         case 'certificate':
            break;
         case 'contract':
            setLoading(true)
            history.push(ROUTE_CONTRACT_EMPLOYEE,{idEmployee: data?.id, name: data?.name+" "+data?.surname })
            break;
      }
   }

   const searchData = async (value) => {
      const resp = await WorkerService.getWorkers(value);
      setDataWorker(resp.data);
   }

   // valores que van en los inputs de cada pestaña del stepper
   const [valuePersonalData, setvaluePersonalData] = useState({
      n_document: '',
      name: '',
      surname: '',
      second_surname: '',
      idcivilstatus: '',
      idemployeetype: '',
      sexo: '',
      birth_date: '',
      pensioner: 1,
      idposition: 1,
      state: '',
      idmilitary: '',
      ideducation: '',
      direccion: '',
      iddistrict: '',
      ciudad:'',
      telefono: '',
      celular: '',
      emergency_phone: '',
      email: '',
      idcategoryemployee: ''
   });

   const [valueDatosDocuments, setvalueDatosDocuments] = useState<any>('');

   const [valueDatosLaborales, setvalueDatosLaborales] = useState({
      idcharge: '',
      idbusinessarea: '',
      idtype_contract: '',
      workerstate: '',
      workersubsidy: '',
      idprofession: '',
      idbranch_office:'',
      idworkmode:'',
      idworkingtime:'',
      idcommuneprovisionservices:'',
      iddistrictbranchoffice: '',
      money: '',
      idgratificationtype: '',
      idcostcenter: '',
      private_role: '',
      n_control_card: '',
      years_other_employers: '',
      apv_libro_rem: 1,
      unemployment_insurance: "1",
      iddisableperson: "1",
      time_control: "1",
      idtype_duration_contract:'',
      iddocumentcontract: ''
   });

   const [valueInformation, setvalueInformation] = useState({
      idAFP: '',
      idsalud: '',
      idAPV: '',
      reduced_tax: '',
      apv_afp: '',
      book_rem_electr: '',
      n_contrato: '',
      idctosalud: '',
      cot_salud_por: '',
      cot_salud_$: '',
      cto_salud_uf: '',
      payment_method: ''
   });

   const [valueFamilyBurden, setValueFamilyBurden] = useState({
      load_number: '',
      idfamilystretch: ''
   })

   const [valueBankInformation, setValueBankInformation] = useState({
      idbank: '',
      idwaypay: '',
      idaccounttype: '',
      account_number: '',
      idcompensationbox:'',
      last_imponible:'',
   })

   //  Títulos y estilo a columnas de la tabla
   interface Column {
      id: 'Descripcion' | 'Fecha Ingreso' | 'Fecha Compra' | 'Concepto';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
   }

   // Valores de la tabla 
   useEffect(() => {
      getWorkers();
      getDataInitialEmployee();
      if (props && props.entity !== undefined) {
         setvaluePersonalData(props.entity);
         setvalueDatosLaborales(props.entity);
         setvalueInformation(props.entity);
      }
    
   }, []);

   //ACÁ AÑADE TRABAJADOR
   const addNewWorker = () => {
      setActiveStep(0);
      vaciarData();
      setIsEdit(false);
      setOpenNewWorker(true);
   }

   const createWorkersContract = async (data) => {

      const dataCreate = {
         ...valuePersonalData, ...valueDatosLaborales, ...valueInformation,
         ...valueBankInformation, ...valueFamilyBurden, ...data
      }

      //DATOS PERSONALES
      if (!dataCreate.n_document) { setActiveStep(0); return setError('n_document')}
      if (!dataCreate.name) { setActiveStep(0);  return setError('name')}
      if (!dataCreate.surname) { setActiveStep(0);return setError('surname')}
      if (!dataCreate.second_surname) { setActiveStep(0);return setError('second_surname')}
      if (!dataCreate.idcivilstatus) { setActiveStep(0);return setError('idcivilstatus')}
      if (!dataCreate.idemployeetype) { setActiveStep(0); return setError('idemployeetyp')}
      if (!dataCreate.sexo) { setActiveStep(0);return setError('sexo')}
      if (!dataCreate.state) { setActiveStep(0);return setError('state')}
      if (!dateFormat(dataCreate.birth_date)) {setActiveStep(0);return setError('birth_date')}
      if (!dataCreate.ideducation) { setActiveStep(0); return setError('ideducation')}
      if (!dataCreate.direccion) {setActiveStep(0);
         return setError('direccion')
      }
      if (!dataCreate.iddistrict) {setActiveStep(0);
         return setError('iddistrict')
      }
      if (!isEdit && !dataCreate.telefono) {setActiveStep(0);
         return setError('telefono')
      }
      // if (!dataCreate.ciudad) {
      //    console.log('aqui'); setActiveStep(0);
      //    return setError('ciudad')
      // }
      if (!isEdit && !dataCreate.celular) {setActiveStep(0);
         return setError('celular')
      }
      if (!isEdit && !dataCreate.emergency_phone) {setActiveStep(0);
         return setError('emergency_phone')
      }
      if (!isEdit && !dataCreate.email) {setActiveStep(0);
         return setError('email')
      }
      
      if (!dataCreate.idtype_duration_contract || dataCreate.idtype_duration_contract == "") { setActiveStep(1); return setError('idtype_duration_contract'); }
      if (!dataCreate.idworkmode || dataCreate.idworkmode == "") { setActiveStep(1); console.log('idworkmode'); return setError('idworkmode');}
      if (!dataCreate.idworkingtime || dataCreate.idworkingtime == "") { setActiveStep(1); console.log('idworkingtime'); return setError('idworkingtime');}
      if (!dataCreate.iddocumentcontract || dataCreate.iddocumentcontract == "") { setActiveStep(1); console.log('iddocumentcontract'); return setError('iddocumentcontract');}
      if (!dataCreate.unemployment_insurance) { setActiveStep(1); console.log('unemployment_insurance'); return setError('unemployment_insurance');}
      if (!dataCreate.idbranch_office) { setActiveStep(1); console.log('idbranch_office'); return setError('idbranch_office');}
      if (!dataCreate.idcharge) { setActiveStep(1); console.log('idCharge'); return setError('idcharge');}
      if (!dataCreate.idbusinessarea) { setActiveStep(1); return setError('idbusinessarea'); }
      if (!dataCreate.idtype_contract) { setActiveStep(1); return setError('idtype_contract'); }
      // if(!dataCreate.workerstate){setActiveStep(1);return setError('workerstate');}
      if (!dataCreate.workersubsidy) { setActiveStep(1); return setError('workersubsidy'); }
       if(!dataCreate.idcommuneprovisionservices){setActiveStep(1);console.log('aqui en idcommuneprovisionservices');return setError('idcommuneprovisionservices');}
      if (!dataCreate.money) { setActiveStep(1); return setError('money'); }
      if (!dataCreate.idgratificationtype) { setActiveStep(1); return setError('idgratificationtype'); }
      if (!dataCreate.idcostcenter) { setActiveStep(1); return setError('idcostcenter'); }
      // if(!dataCreate.private_role){setActiveStep(1);return setError('private_role');}
      // if(!dataCreate.n_control_card){setActiveStep(1);return setError('n_control_card');}
      if (!dataCreate.years_other_employers) { setActiveStep(1); return setError('years_other_employers'); }



      if (!dataCreate.idAFP) {
         setActiveStep(2);
         return setError('idAFP')
      }
      if (!dataCreate.idsalud) {
         setActiveStep(2);
         return setError('idsalud')
      }
      if (!dataCreate.idAPV) {
         setActiveStep(2);
         return setError('idAPV')
      }
      // if (!dataCreate.reduced_tax) { ¡¡¡¡
      //    setActiveStep(2);
      //    return setError('reduced_tax')
      // } 
      // if (!dataCreate.apv_afp) {
      //    setActiveStep(2);
      //    console.log('aqui')
      //    return setError('apv_afp')
      // }
      if (!dataCreate.n_contrato) {
         setActiveStep(2);
         return setError('n_contrato')
      }
      if (!dataCreate.idctosalud) {
         setActiveStep(2);
         return setError('idctosalud')
      }

      if (dataCreate.idctosalud == 1 && !dataCreate.cot_salud_por) {
         setActiveStep(2);
         return setError('cot_salud_por')
      }
      if (dataCreate.idctosalud == 2 && !dataCreate.cot_salud_$) {
         setActiveStep(2);
         return setError('cot_salud_$')
      }
      if (dataCreate.idctosalud == 3 && !dataCreate.cto_salud_uf) {
         setActiveStep(2);
         return setError('cto_salud_uf')
      }

      // if (!dataCreate.payment_method) {
      //    setActiveStep(2);
      //    console.log('11111')
      //    return setError('payment_method')
      // }

      // if (!dataCreate.iddistrictbranchoffice) {
      //    console.log('aqui'); setActiveStep(0);
      //    return setError('iddistrictbranchoffice')
      // }

      if (!dataCreate.load_number) {
         setActiveStep(3);
         return setError('load_number')
      }
      // if (!dataCreate.payment_method) {
      //    setActiveStep(3);
      //    return setError('payment_method')
      // }
      
      if (!dataCreate.idfamilystretch) {
         setActiveStep(3);
         return setError('idfamilystretch')
      }


      if (!dataCreate.idbank) {
         setActiveStep(5);
         return setError('idbank')
      }
      if (!dataCreate.idwaypay) {
         setActiveStep(5);
         return setError('idwaypay')
      }
      if (!dataCreate.idaccounttype) {
         setActiveStep(5);
         return setError('idaccounttype')
      }
      if (!isEdit && !dataCreate.account_number) {
         setActiveStep(5);
         return setError('account_number')
      }

      if (!dataCreate.idcompensationbox) {
         setActiveStep(5);
         return setError('idcompensationbox')
      }
      if (!dataCreate.last_imponible || dataCreate.last_imponible == 0) {
         setActiveStep(5);
         return setError('last_imponible')
      }

      setLoading(true);
      
      if (isEdit) {
         const resp = await WorkerService.patchEmployeeContract(dataCreate, idContractSelect);
         if (resp.data) {
            setOpenNewWorker(false);
            setActiveStep(0);
            setLoading(false);
            return getWorkers();
         } else {
            setLoading(false);
            return false;
         }
      } else {
         const resp = await WorkerService.postCreateWorkersContract(dataCreate);

         const formData = new FormData();
         if(valueDatosDocuments){
            for (let index = 0; index < valueDatosDocuments.numeroDocuments; index++) {
               formData.append(`nameDocument${index}`, valueDatosDocuments[`nameDocument${index}`]);
               formData.append(`typeDocument${index}`, valueDatosDocuments[`typeDocument${index}`].label);
               formData.append(`doc${index}`, valueDatosDocuments[`doc${index}`]);
            }
         }
         formData.append(`numeroDocuments`, valueDatosDocuments.numeroDocuments)
         if(resp.data) formData.append(`employee`, resp.data.detail.idemployee);

         const resp2 = await WorkerService.createValidationIdentityAdmin(formData);

         if (resp2.data) {
            setOpenNewWorker(false);
            setActiveStep(0);
            setLoading(false);
            return getWorkers();
         } else {
            setLoading(false);
            return false;
         }
      }
   }

   const isStepSkipped = (step) => {
      return skipped.has(step);
   };

   const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
         newSkipped = new Set(newSkipped.values());
         newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };


   const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
         top: 22,
      },
      [`&.${stepConnectorClasses.active}`]: {
         [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
               'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
         },
      },
      [`&.${stepConnectorClasses.completed}`]: {
         [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
               'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
         },
      },
      [`& .${stepConnectorClasses.line}`]: {
         height: 3,
         border: 0,
         backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
         borderRadius: 1,
      },
   }));

   const ColorlibStepIconRoot = styled('div')<{
      ownerState: { completed?: boolean; active?: boolean };
   }>(({ theme, ownerState }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      ...(ownerState.active && {
         backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
         boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      }),
      ...(ownerState.completed && {
         backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
      }),
   }));


   function ColorlibStepIcon(props: StepIconProps) {
      const { active, completed, className } = props;

      const icons: { [index: string]: React.ReactElement } = {
         1: <AssignmentIcon />,
         2: <AssignmentIndIcon />,
         3: <WorkIcon />,
         4: <InfoIcon />,
         5: <FamilyRestroomIcon />,
         6: <PaymentIcon />,

      };

      return (
         <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
         </ColorlibStepIconRoot>
      );
   }

   const mostrarFormulario = () => {

      if (activeStep === 0) {
         return (
            <FormPersonalData
               handleNext={handleNext}
               valuePersonalData={valuePersonalData}
               setvaluePersonalData={setvaluePersonalData}
               dataInitial={dataInitial}
               openWorker={openNewWorker}
               setOpenWorker={setOpenNewWorker}
               createWorkers={createWorkersContract}
               error={error}
               setError={setError}
            />
         )
      }
      else if (activeStep === 1) {
         return (
            <FormIdentityDocuments
               handleNext={handleNext}
               handleBack={handleBack}
               valueDatosDocuments={valueDatosDocuments}
               setvalueDatosDocuments={setvalueDatosDocuments}
               dataInitial={dataInitial}
               openWorker={openNewWorker}
               setOpenWorker={setOpenNewWorker}
               createWorkers={createWorkersContract}
               idEmployee={idEmployee}
               error={error}
               setError={setError}
            />
         )
      }
      else if (activeStep === 2) {
         return (
            <FormLaborData
               handleNext={handleNext}
               handleBack={handleBack}
               valueDatosLaborales={valueDatosLaborales}
               setvalueDatosLaborales={setvalueDatosLaborales}
               dataInitial={dataInitial}
               openWorker={openNewWorker}
               setOpenWorker={setOpenNewWorker}
               createWorkers={createWorkersContract}
               error={error}
               setError={setError}
            />
         )
      }
      else if (activeStep === 3) {
         return (
            <FormInformation
               handleNext={handleNext}
               handleBack={handleBack}
               valueInformation={valueInformation}
               setvalueInformation={setvalueInformation}
               dataInitial={dataInitial}
               openWorker={openNewWorker}
               setOpenWorker={setOpenNewWorker}
               createWorkers={createWorkersContract}
               error={error}
               setError={setError}
            />
         )
      }

      else if (activeStep === 4) {
         return (
            <FormFamilies
               handleNext={handleNext}
               handleBack={handleBack}
               valueFamilyBurden={valueFamilyBurden}
               setValueFamilyBurden={setValueFamilyBurden}
               dataInitial={dataInitial}
               openWorker={openNewWorker}
               setOpenWorker={setOpenNewWorker}
               createWorkers={createWorkersContract}
               error={error}
               setError={setError}
            />
         )
      }
      else if (activeStep === 5) {
         return (
            <FormBankInformation
               handleNext={handleNext}
               handleBack={handleBack}
               valueBankInformation={valueBankInformation}
               setValueBankInformation={setValueBankInformation}
               dataInitial={dataInitial}
               openWorker={openNewWorker}
               setOpenWorker={setOpenNewWorker}
               createWorkers={createWorkersContract}
               error={error}
               isEdit={isEdit}
               setError={setError}
            />
         )
      }
   }

   return (
      <Protected>
         {loading && <Loading />}
         <Container maxWidth='xl'>
            <Typography variant="h6" gutterBottom mb={3} className="tittle">
               {___PHRASE("mantenedorDeClientesTrabajadores")}
            </Typography>
            <Grid item container mb={3} xs={12} >
               <Typography variant="subtitle1" sx={{ pt: 1 }}>
                  {___PHRASE("incorporeSusTrabajadoresEnElSiguienteFormulario")}
               </Typography>
               <IconButton >
                  <PersonAddIcon sx={{ color: '#FF9700' }} onClick={() => { addNewWorker() }} />
               </IconButton>
            </Grid>

            <Grid container flexDirection='row' spacing={2}>
               <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                     <Box>
                        <Box sx={{ mb: 2 }}>
                           {/* <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                              {steps.map((label, index) => {
                                 return (
                                    <Step key={index}>
                                       <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                    </Step>
                                 );
                              })}
                           </Stepper> */}
                        </Box>
                        {/* {activeStep === steps.length ? (
                           <div>AGREGADO</div>
                           //VALIDAR QUE EL ENVIO DE DATOS
                           // history.push(ROUTE_ACCOUNT)
                        ) : (
                           mostrarFormulario()
                        )} */}
                     </Box>
                  </Grid>
               </Grid>
            </Grid>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >
                  {___PHRASE("listadoDeTrabajadoresIngresados")}
               </Typography>
            </div>
         </Container>

         <TableDataV2
            header={header}
            action={dataAction}
            data={dataWorker}
            RecuperarData={recuperarData}
            btn_foolder
            btn_add_person
            btn_disabled
            btn_contract
            status_action
            textSearch={___PHRASE("buscarTrabajador")}
            searchData={searchData}
         />
         {
            openFamily && (
               <DataModalFamily
                  open={openFamily}
                  closeModal={()=>{setOpenFamily(false)}}
                  employee={employeeSelect}
               />
            )
         }
         {
            openWork && (
               <Modal
                  open={openWork} handleClose={handleCloseWork} 
                  disableEscapeKeyDown disableBackdropClick handle size="lg"
               >
                  <ModalHeader
                     text={___PHRASE("mantenedorDeHistorialLaboral")}
                     className='positionElements'
                     onCancel={handleCloseWork}
                  >
                  </ModalHeader>
                  <ModalBody>
                     <DataModalWork employee={employeeSelect} dataInitial={dataInitial} />
                  </ModalBody>
               </Modal>
            )
         }

         {
            openNewWorker && (
               <Modal
                  open={openNewWorker} handleClose={handleCloseContract} 
                  disableEscapeKeyDown disableBackdropClick handle size="lg"
               >
                  <ModalHeader
                     text={___PHRASE("formadorDeEmpleado")}
                     className='positionElements'
                     onCancel={handleCloseContract}
                  >
                  </ModalHeader>
                  <Divider sx={{marginBottom:'15px'}} />
                     <Box sx={{marginBottom: '25px', marginTop: '10px', transform: 'translate(-50 %, -50 %)', paddingLeft:'10px', paddingRight: '10px'}}>
                        <Box sx={{ mb: 2 }}>
                           <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                              {steps.map((label, index) => {
                                 return (
                                    <Step key={index}>
                                       <StepLabel StepIconComponent={ColorlibStepIcon} error={error === ___PHRASE("usuario")+` ${index} `+___PHRASE("noEncontrado") ? true : false}>{label.name}</StepLabel>
                                    </Step>
                                 );
                              })}
                           </Stepper>
                        </Box>
                        <Divider sx={{marginBottom:'20px'}} />
                        {
                           activeStep === steps.length ? (
                              <div>{___PHRASE("agregado")}</div>
                           ) : (
                              mostrarFormulario()
                           )
                        }
                     </Box>
               </Modal>
            )
         }
         {
            openModalDeleteEmployee && (
               <ModalWorkerDelete
                    open={openModalDeleteEmployee}
                    closeModal={()=>{setOpenModalDeleteEmployee(false)}}
                    onListEmployees={getWorkers}
                    employee={employeeSelect}
                    clearState={()=>{setEmployeeSelect('')}}
                />
            )
         }


      </Protected>

   );
};