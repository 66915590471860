import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Alert, Autocomplete, Box, Breadcrumbs, Button, Card, Checkbox, Container, Grid, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { Props } from "./ProfileSession.type"
import { ModalProfileDelete } from "@/components/Modal/ModalProfileDelete/ModalProfileDelete"
import { ModalProfileSession } from "@/components/Modal/ModalProfileSession/ModalProfileSession"
import { ModalProfileAssignment } from "@/components/Modal/ModalProfileAssignment/ModalProfileAssignment"
import Chip from '@mui/material/Chip';
import { emphasize, styled } from '@mui/material/styles';
import GroupsIcon from '@mui/icons-material/Groups';
import { ProfileSessionService } from "@/service/services/ProfileSession.service"
import { ProfilePermissionsService } from "@/service/services/ProfilePermissions.service"
import { ViewsService } from "@/service/services/Views.service"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "@/toolbox/constants/role-type"
import ___PHRASE from "@/service/translate/translateService"

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const ProfileSessionView: React.FC<Props> = (props: any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [section, setSection] = useState<any>('profile')
    const [dataProfile, setDataProfile] = useState<any>([]);
    const [profileSelected, setProfileSelected] = useState(null);
    const [showProfileSessioModalDelete, setShowProfileSessioModalDelete] = useState<boolean>(false)
    const [showProfileSessionModal, setShowProfileSessionModal] = useState<boolean>(false)
    const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false)
    const [actionSelected, setActionSelected] = useState<string>('');

    const [viewsProfile, setViewProfile] = useState<any>([])
    const [permissions, setPermissions] = useState<any>([]);
    
    
    const cabecera = [
        { id: 1, name: 'name', label: ___PHRASE("nombreDelPerfil") },
    ];

    const cabeceraView = [
        { id: 1, name: 'code', label: ___PHRASE("codigo") },
        { id: 2, name: 'name', label: ___PHRASE("nombre") },
        { id: 3, name: 'module', label: ___PHRASE("modulo") },
        { id: 4, name: 'route', label: ___PHRASE("ruta") },
    ];

    const cabeceraPermissions = [
        { id: 1, name: 'user_name', label: ___PHRASE("usuario") },
        { id: 2, name: 'role', label: ___PHRASE("rol") },
        { id: 3, name: 'name', label: ___PHRASE("perfil") },
    ];

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role || '';

    const getProfiles = async () => {
        setLoading(true)
        const dataFilter = [];
        const resp = await ProfileSessionService.getProfiles();
        if(resp.data.status != false){
            resp.data.map((profile)=> {
                if(profile.id !=3) dataFilter.push(profile);
            })
            setDataProfile(dataFilter || []);
        }else{
            setDataProfile([])
        }
        setLoading(false)
    }

    const getViewsByProfile = async () => {
        setLoading(true)
        const resp = await ViewsService.getViewsByProfile(profileSelected.id);
        if(resp.data){
            setViewProfile(resp.data)
        }
        setLoading(false)
    }

    const getPermissionsByIdProfile = async () => {
        setLoading(true)
        const resp = await ProfilePermissionsService.getPermissionsByIdProfile(profileSelected.id);
        if(resp.data){
            setPermissions(resp.data)
        }
        setLoading(false)
    }

    const recuperarData = (item) => {
        !profileSelected && setProfileSelected(item)
        if (item.action == "view-detail") {
            setSection('View Profile')
        }
    }

    useEffect(() => {
        getProfiles();
    }, []);

    useEffect(() => {
        if(profileSelected) {getViewsByProfile(); getPermissionsByIdProfile()}
    }, [profileSelected]);


    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{mt:3, ml:2}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDePerfiles")}
                    onClick={() => {
                        setSection('profile');
                        setProfileSelected(null);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    section == 'View Profile' && (
                        <StyledBreadcrumb
                            label={___PHRASE("detalleDelPerfil")}
                            onClick={() => { setSection('View Profile') }}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
                <Grid container spacing={3} sx={{ marginTop: '10px', marginBottom: '20px', alignItems: 'center', marginLeft: '5px' }}>
                    {section == 'profile' && <TableDataV2
                        data={dataProfile || []}
                        header={cabecera}
                        title={___PHRASE("perfiles")}
                        status_action={true}
                        btn_detail_view
                        RecuperarData={recuperarData}
                        setModalDelete={setShowProfileSessioModalDelete}
                        setModalSave={setShowProfileSessionModal}
                        actionSelect={setActionSelected}
                        btn_disabled={(role && role == ROLE_ADMIN) ? true : false}
                        btn_edit_disable={(role && role == ROLE_ADMIN) ? true : false}
                        btn_delete_disable
                    />}
                    {section == 'View Profile' && 
                        <>
                            <Box sx={{ marginTop: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'start', width: '100%', paddingX: '20px'}}>
                                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>{___PHRASE("vistasDelPerfil")}</Typography>
                            </Box>
                            <TableDataV2
                                data={viewsProfile || []}
                                setData={setViewProfile}
                                header={cabeceraView}
                                disabled_title
                                btn_disabled
                            />

                            {
                                (role && role == ROLE_ADMIN) && (
                                    <>
                                    <Box sx={{ marginTop: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'start', width: '100%', paddingX: '20px'}}>
                                        <Typography>{___PHRASE("listaDeUsuariosAsignadosAlPerfil")+` "${(profileSelected ? profileSelected.name : '').toUpperCase()}"`}</Typography>
                                        {/* <Button color="primary" variant="contained" sx={{justifyContent: 'end'}}
                                            onClick={()=>{setShowAssignmentModal(true)}}
                                        >
                                            {`Asignar usuarios al perfil`}
                                        </Button> */}
                                    </Box>
                                    <TableDataV2
                                        data={permissions || []}
                                        setData={setPermissions}
                                        header={cabeceraPermissions}
                                        disabled_title
                                        btn_disabled
                                    />
                                    </>
                                )
                            }
                        </>
                    }
                </Grid>
            </Container>
            {
                actionSelected == 'delete' &&
                <ModalProfileDelete
                    open={showProfileSessioModalDelete}
                    closeModal={() => setShowProfileSessioModalDelete(false)}
                    actionSelected={actionSelected}
                    onListProfiles={getProfiles}
                    profile={profileSelected}
                    clearState={() => setProfileSelected(null)}
                />
            }
            {
                showProfileSessionModal &&
                <ModalProfileSession
                    open={showProfileSessionModal}
                    closeModal={() => setShowProfileSessionModal(false)}
                    actionSelected={actionSelected}
                    onListProfiles={getProfiles}
                    profile={profileSelected}
                    clearState={() => setProfileSelected(null)}
                />

            }
            {
                showAssignmentModal &&
                <ModalProfileAssignment
                    open={showAssignmentModal}
                    closeModal={() => setShowAssignmentModal(false)}
                    actionSelected={actionSelected}
                    onListPermissions={getPermissionsByIdProfile}
                    profile={profileSelected}
                />

            }
        </Protected>
    )
}
