import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Project.type'
import {  Breadcrumbs, Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { ModalProject } from '@/components/Modal/ModalProject';
import { ProjectService } from '@/service/services/Project.service';
import { ModalProjectDelete } from '@/components/Modal/ModalProjectDelete';
import { Loading } from '@/components/common/Loading';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { ModalProjectEmployee } from '@/components/Modal/ModalProjectEmployee/ModalProjectEmployee';
import { ModalProjectParticipation } from '@/components/Modal/ModalProjectParticipation/ModalProjectParticipation';
import { ModalProjectEmployeeDelete } from '@/components/Modal/ModalProjectEmployeeDelete';
import ___PHRASE from '@/service/translate/translateService';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
       theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[800];
   return {
       backgroundColor,
       height: theme.spacing(3),
       color: theme.palette.text.primary,
       fontWeight: theme.typography.fontWeightRegular,
       '&:hover, &:focus': {
           backgroundColor: emphasize(backgroundColor, 0.06),
       },
       '&:active': {
           boxShadow: theme.shadows[1],
           backgroundColor: emphasize(backgroundColor, 0.12),
       },
   };
});

export const ProjectView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [projects, setProjets] = useState([]);
   const [showProjectModal, setShowProjectModal] = useState<boolean>(false);
   const [showProjectDeleteModal, setShowProjectDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [projectSelected, setProjectSelected] = useState<any>('');

   const [employees, setEmployees] = useState<any>([]);
   const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
   const [showEmployeeModalDelete, setShowEmployeeModalDelete] = useState<boolean>(false);
   const [employeeSelected, setEmployeeSelected] = useState<any>('');
   const [view, setView] = useState<String>('projects');
   const [showEmployees, setShowEmployees] = useState<boolean>(false);

   const [detailParticipation, setDetailParticipation] = useState<any>([]);
   const [showParticipatioModal, setShowParticipatioModal] = useState<boolean>(false);
   const [showParticipation, setShowParticipation] = useState<boolean>(false);

   const cabecera = [
      { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
      { name: 'description', label: ___PHRASE("descripcion"), filter: false, Chip: false },
      { name: 'start_date', label: ___PHRASE("fechaInicio"), filter: false, Chip: false },
      { name: 'end_date', label: ___PHRASE("fechaFin"), filter: false, Chip: false },
   ];

   const cabeceraEmployee = [
      { name: 'n_document', label: ___PHRASE("rutTrabajador") },
      { name: 'name', label: ___PHRASE("nombres") },
      { name: 'surname', label: ___PHRASE("apellidos") },
      { name: 'function_project', label: ___PHRASE("funcion") },
   ];

   const cabeceraParticipation = [
      { name: 'participation_percentage', label: ___PHRASE("participacion")+' (%)' },
      { name: 'totalPeriod', label: 'TOTAL' },
   ];

   useEffect(() => {
      getProjects()
   },[]);

   useEffect(()=> {
      if(projectSelected && actionSelected=='helper' && view=='workers'){
         getDetailProject(projectSelected.id);
      }
  },[projectSelected]);

   useEffect(()=> {
      if(employeeSelected && actionSelected=='helper' && view=='participation'){
         getDetailParticipation(employeeSelected.iddetailproject);
      }
   },[employeeSelected]);

   const getProjects = async () => {
      setLoading(true);
      const response = await ProjectService.getProjects();
      if (response.data) {
         setProjets(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   const getDetailProject = async(idProject) => {
      setLoading(true);
      await ProjectService.getDetailProject(idProject).then((data) => {
         const oldData = data.data || [];
         let newData = [];
         oldData.length > 0 && oldData.map((item) => {
               let obj = {
                  iddetailproject: item.iddetailproject,
                  function_project: item.function_project,
                  total: item.total,
                  idproject: item.idproject,
                  nameProject: item.nameProject,
                  description: item.description,
                  start_date: item.start_date,
                  end_date: item.end_date,
                  projected_total: item.projected_total,
                  totalProject: item.totalProject,
                  idtypeproject: item.idtypeproject,
                  nameTypeProject: item.nameTypeProject,
                  idemployee: item.idemployee,
                  n_document: item.n_document,
                  name: item.name,
                  surname: item.surname+' '+item.second_surname,
                  second_surname: item.second_surname,
                  birth_date: item.birth_date,
                  sexo: item.sexo,
                  direccion: item.direccion,
                  status: item.status
               }
               newData.push(obj);
         });
         setEmployees(newData || []);
         setLoading(false);
      })
   }

   const getDetailParticipation = async(idDetail) => {
      setLoading(true);
      await ProjectService.getDetailParticipation(idDetail).then((data) => {
         setDetailParticipation(data.data || []);
         setLoading(false);
      })
   }

   function RecuperarData(value){
      !projectSelected && setProjectSelected(value);
      projectSelected && setEmployeeSelected(value);
      if(value.action == "helper" && view=='projects'){
         setShowEmployees(true);
         setView('workers');
      }else if(value.action == "helper" && view=='workers'){
         setShowParticipation(true);
         setView('participation');
      }
   }

   const searchDataProject = async (value) => {
      const response = await ProjectService.getProjects(value);
      if (response.data) {
         setProjets(response.data || [])
      } else {
         console.log('error')
      }
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>

            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:5}}>
               <StyledBreadcrumb
                  label={___PHRASE("listaDeProyectos")}
                  onClick={() => {
                     setView('projects');
                     setProjectSelected('');
                     setShowEmployees(false);
                     setShowParticipation(false);
                  }}
                  icon={<GroupsIcon fontSize="small" />}
               />
               {
                  showEmployees && (
                     <StyledBreadcrumb
                           label={___PHRASE("empleadosPorProyecto")}
                           onClick={() => {
                              setView('workers');
                              setEmployeeSelected('');
                              setShowParticipation(false);
                           }}
                           icon={<GroupsIcon fontSize="small" />}
                     />
                  )
               }
               {
                  showParticipation && (
                     <StyledBreadcrumb
                           label={___PHRASE("detalleDeParticipación")}
                           onClick={() => {setView('participation')}}
                           icon={<GroupsIcon fontSize="small" />}
                     />
                  )
               }
            </Breadcrumbs>

            {
               view == 'projects' && (
                  <TableDataV2
                     title={___PHRASE("proyectos")}
                     data={projects}
                     header={cabecera || []}
                     actionSelect={setActionSelected}
                     setModalSave={setShowProjectModal}
                     setModalDelete={setShowProjectDeleteModal}
                     btn_helper
                     status_action
                     textSearch={___PHRASE("buscarProyectos")}
                     searchData={searchDataProject}
                     RecuperarData={RecuperarData}
                  />
               )
            }

            {
               view == 'workers' && (
                  <TableDataV2
                     data={employees}
                     header={cabeceraEmployee}
                     actionSelect={setActionSelected}
                     setModalSave={setShowEmployeeModal}
                     setModalDelete={setShowEmployeeModalDelete}
                     btn_helper
                     status_action
                     disabled_title={false}
                     currentView={view}
                     RecuperarData={RecuperarData}
                     />
               )
            }

            {
               view == 'participation' && (
                  <TableDataV2
                     data={detailParticipation}
                     header={cabeceraParticipation}
                     actionSelect={setActionSelected}
                     setModalSave={setShowParticipatioModal}
                     disabled_title={false}
                     btn_delete_disable={true}
                     currentView={view}
                     RecuperarData={RecuperarData}
                  />
               )
            }

         </Container>

         { 
            showProjectModal && (
               <ModalProject
                  open={showProjectModal}
                  closeModal={()=>{setShowProjectModal(false)}}
                  actionSelected={actionSelected}
                  onListProjects={getProjects}
                  project={projectSelected}
                  clearState={()=>{setProjectSelected('')}}
               />
            )
         }
         {
            (actionSelected === 'delete' && view == 'projects') && (
               <ModalProjectDelete
                  open={showProjectDeleteModal}
                  closeModal={()=>{setShowProjectDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListProjects={getProjects}
                  project={projectSelected}
                  clearState={()=>{setProjectSelected('')}}
               />
            )
         }

         {
            (showEmployeeModal && view == 'workers') && (
               <ModalProjectEmployee
                  open={showEmployeeModal}
                  closeModal={()=>{setShowEmployeeModal(false)}}
                  actionSelected={actionSelected}
                  onListEmployees={getDetailProject}
                  projectSelected={projectSelected}
                  employee={employeeSelected}
                  clearState={()=>{setEmployeeSelected('')}}
               />
            )
         }

         {
            (actionSelected === 'delete' && view == 'workers') && (
               <ModalProjectEmployeeDelete
                  open={showEmployeeModalDelete}
                  closeModal={()=>{setShowEmployeeModalDelete(false)}}
                  actionSelected={actionSelected}
                  onListDetails={getDetailProject}
                  detail={employeeSelected}
                  clearState={()=>{setEmployeeSelected('')}}
               />
            )
         }

         {
            (showParticipatioModal && view == 'participation') && (
               <ModalProjectParticipation
                  open={showParticipatioModal}
                  closeModal={()=>{setShowParticipatioModal(false)}}
                  actionSelected={actionSelected}
                  onListParticipations={getDetailParticipation}
                  detailSelected={employeeSelected}
                  // clearState={()=>{setEmployeeSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};