import React, { useState, useMemo, useRef } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, TextField, InputAdornment } from '@mui/material';
import { ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormFamilies.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormFamilies_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ___PHRASE from '@/service/translate/translateService';

export const FormFamilies: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueInitialSituation, setValueInitialSituation] = React.useState('Informacion de Compra');
      const [error, setError] = useState<any>('')
    const handleInitialSituation = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueInitialSituation(event.target.value);
    };
    
    const [valueFamilyBurden, setValueFamilyBurden] = useState(props.valueFamilyBurden)

   const tramoValue = [
      { label: 'Sin Derecho'},
      { label: 'Tramo A'},
      { label: 'Tramo B'},
      { label: 'Tramo C'},
      { label: 'Tramo D'},
   ]
   const rules = useMemo(() => ({
      unidadMedida: [
         VALIDATORS.REQUIRED,
      ],
      stockMinimo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      stockMaximo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      ubicacionFisica: [
         VALIDATORS.REQUIRED,
      ],

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'load_number':
            props.setError('')
            setValueFamilyBurden(prev => ({ ...prev, load_number: value }));
            break;
         default:
            break;
      }
   };

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueFamilyBurden(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      if(!valueFamilyBurden.load_number){
         return setError('load_number')
      }
      if(!valueFamilyBurden.payment_method){
         return setError('payment_method')
      }
      
      const resp =  props.createWorkers(valueFamilyBurden);
      
      if(!resp){
         console.log('error')
      }
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`} mt={2}>
            <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="load_number"
                     type='text'
                     placeholder={___PHRASE("numeroDeCargas")}
                     value={valueFamilyBurden.load_number}
                     onChange={handleInput}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     error={props.error=='load_number'}
                     errorMessages={props.error=='load_number' && ___PHRASE("campoObligatorio")}
                     height='38px'
                  />
               </Grid>
                  
               <Grid item xs={12} sm={3} >
                  <Autocomplete
                        // disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.FamilyStretch}
                        onChange={(e,value:any)=>{setValueFamilyBurden({...valueFamilyBurden, idfamilystretch: value.id}); props.setError('')}}
                        value={
                           // props.valueFamilyBurden?.idfamilystretch?(
                           //    props.dataInitial.FamilyStretch.filter((value)=>{return value.id == props.valueFamilyBurden?.idfamilystretch})[0]
                           // ):(
                              valueFamilyBurden?.idfamilystretch?(
                                 props.dataInitial.FamilyStretch.filter((value)=>{return value.id == valueFamilyBurden?.idfamilystretch})[0]
                              ):(
                                 null
                              )
                           // )
                           }
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label={___PHRASE("tramoAsigFamiliar")}
                        error={props.error=='idfamilystretch'}
                        helperText={props.error=='idfamilystretch' && ___PHRASE("campoObligatorio")} />}
                     />
               </Grid > 
           
               <Grid item container
                  justifyContent='space-between'
               >
                  <Grid item xs={6}>
                     <Button
                        variant='contained'
                        onClick={() => { props.setValueFamilyBurden(valueFamilyBurden); props.handleBack(); }}
                        disabled={loading}
                        disableElevation
                        className='btn_back'
                        startIcon={<ArrowBackIosIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("atras")}</span>
                        }
                     </Button>
                  </Grid>

                  <Grid item xs={6} container
                     justifyContent='right'>
                     {/* <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Guardar</span>
                        }
                     </Button> */}
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={() => { props.setValueFamilyBurden(valueFamilyBurden); props.handleNext(); }}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        sx={{ml:2}}
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("siguiente")}</span>
                        }
                     </Button>

                 
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
