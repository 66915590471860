import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, Tooltip, Select, MenuItem, InputLabel, Snackbar, Alert, FormHelperText, } from '@mui/material'
import React, { useState, useRef, useEffect } from "react";
import AssignmentIcon from '@mui/icons-material/Assignment';
import '../Workers.css'
import TextField from '@mui/material/TextField';
import { TableData } from "@/components/common/Table";
import { EmploymentHistoryService } from "@/service/services/EmploymentHistory.service";
import { Loading } from "@/components/common/Loading";
import ___PHRASE from '@/service/translate/translateService';
import { ModalHistoryDelete } from '@/components/Modal/ModalHistoryDelete';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const DataModalWork: React.FC<any> = (props): JSX.Element => {

  const header = [
   // { name: 'start_date', label: ___PHRASE("estado"), width: '' },
   { name: 'start_date', label: ___PHRASE("fechaDeInicio"), width: '' },
   { name: 'end_date_descrip', label: ___PHRASE("fechaDeTermino"), width: '' },
   { name: 'causal_descrip', label: 'causal', width: '' },
   { name: 'status_descrip', label: ___PHRASE("estado"), width: '' },
  ]

  const dataAction = [
   { name: 'edit', route: null },
   { name: 'delete', route: null},
  ]
  
   const { employee, dataInitial } = props;

   const contractType = dataInitial?.ContractType || [];
   const contractState = dataInitial?.State || [];

   const [loading, setLoading] = useState(false);
   const [error, setError] = useState<any>({
      idtype_contract: false,
      status_contract: false,
      start_date: false,
      end_date: false,
      causal: false,
   })
   const [statusSave, setStatusSave] = useState<any>('save');
   const [idHistory, setIdHistory] = useState<any>('');
   const [causalSelected, setCausalSelected] = useState<any>('');
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
       open: false,
       severity: 'error',
       message: 'Error',
       autoHideDuration: 3000,
   });
   const [showHistoryDeleteModal, setShowHistoryDeleteModal] = useState<boolean>(false);
   const [employmentHistories, setEmploymentHistories] = useState<any>([]);
   const [formValues, setFormValues] = useState<any>({
      idemployee: employee.id,
      idtype_contract: '',
      status_contract: '',
      start_date: '',
      end_date: '',
      causal: '',
   });

   useEffect(() => {
      getEmploymentHistory();
   }, []);

   const getEmploymentHistory = async () => {
      setLoading(true);
      await EmploymentHistoryService.getEmploymentHistory(employee.id).then((data) => {
         const oldData = data.data || []
         let newData = [];
         oldData.length > 0 && oldData.map((item) => {
               let obj = {
                  id: item.id,
                  idemployee: item.idemployee,
                  start_date: item.start_date,
                  end_date: item.end_date,
                  end_date_descrip: item.end_date ? item.end_date : 'Indefinido',
                  causal: item.causal,
                  causal_descrip: item.causal ? item.causal : '---',
                  idtype_contract: item.idtype_contract,
                  status_contract: item.status_contract,
                  status: item.status,
                  status_descrip: item.status == 1 ? 'Activo' : 'Inactivo'
               }
               newData.push(obj);
         });
         setEmploymentHistories(newData || [])
         setLoading(false);
      });
   }

   const handleInput = (e) => {
      const name = e.name? e.name : e.target.name;
      setError('');
      switch(name){
         case 'start_date':
            setFormValues({...formValues, start_date: e.target.value});
            break;
         case 'end_date':
               setFormValues({ ...formValues, end_date: e.target.value});
            break;
         case 'idtype_contract':
            setFormValues({ ...formValues, idtype_contract: e.target.value });
            break;
         case 'status_contract':
            setFormValues({ ...formValues, status_contract: e.target.value});
            break;
         case 'causal':
            setFormValues({ ...formValues, causal: e.target.value});
            break;
      }
   }

   const recuperarData = (data) => {
      setIdHistory(data.id)
      switch(data.action){
         case 'edit':
            setFormValues((prevFormValues) => ({
               ...prevFormValues,
               idtype_contract: data.idtype_contract,
               status_contract: data.status_contract,
               start_date: data.start_date,
               end_date: data.end_date,
               causal: data.causal,
            }));
            setCausalSelected(data.causal)
            setStatusSave('edit')
            break;
         case 'delete':
            setStatusSave('delete')
            setShowHistoryDeleteModal(true)
            break;
      }
   }

   const causalValue = [
      { label: 'ART. 160 N 2: '+___PHRASE("negociacionesProhibidasPorEscrito") },
      { label: 'ART. 160 N 3: '+___PHRASE("noConcurrenciaALasLabores") },
      { label: 'ART. 160 N 4: '+___PHRASE("negociacionesProhibidasPorEscrito") },
      { label: 'ART. 160 N 5: '+___PHRASE("abandonoDelTrabajo") },
      { label: 'ART. 160 N 6: '+___PHRASE("negociacionesProhibidasPorEscrito") }
   ]

   // const causalValue = [
   //    { id:1, label: 'ART. 160 N 2: Negociaciones prohibidas por escrito' },
   //    { id:2, label: 'ART. 160 N 3: No concurrencia a las labores' },
   //    { id:3, label: 'ART. 160 N 4: Negociaciones prohibidas por escrito' },
   //    { id:4, label: 'ART. 160 N 5: Abandono del trabajo' },
   //    { id:5, label: 'ART. 160 N 6: Negociaciones prohibidas por escrito' }
   // ]

   const createEmploymentHistory = async() => {
      if(!formValues.start_date){
         return setError('start_date');
      }
      // if(!formValues.end_date){
      //    return setError('end_date');
      // }
      if(!formValues.idtype_contract){
         return setError('idtype_contract');
      }
      if(!formValues.status_contract){
         return setError('status_contract');
      }
      if(!causalSelected){
         return setError('causal');
      }

      const payload = {
         idemployee: employee.id,
         idtype_contract: formValues.idtype_contract,
         status_contract: formValues.status_contract,
         start_date: formValues.start_date,
         end_date: formValues.end_date,
         causal: causalSelected,
     }

      setLoading(true);

      if(statusSave == 'save'){
         await EmploymentHistoryService.createEmploymentHistory(payload).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            getEmploymentHistory();
            setFormValues({
               idemployee: employee.id,
               idtype_contract: '',
               status_contract: '',
               start_date: '',
               end_date: '',
               causal: '',
            })
            setCausalSelected('')
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'success',
               message: `${apimessagge}`
               }
            ));
         });
      }
      if(statusSave == 'edit'){
         await EmploymentHistoryService.editEmploymentHistory(payload, idHistory).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            getEmploymentHistory();
            setFormValues({
               idemployee: employee.id,
               idtype_contract: '',
               status_contract: '',
               start_date: '',
               end_date: '',
               causal: '',
            })
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'success',
               message: `${apimessagge}`
               }
            ));
         });
      }
      setLoading(false);
   }

   const statusDisabled = (history) => {
      if (history.status == 0){
          return true
      }
      return false
  }

   return (
      <>
      {loading && <Loading />}
      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
         <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
         </Alert>
      </Snackbar>
      <Container maxWidth='xl'>
         <Grid container >
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="caption" id="modal-modal-title" sx={{fontSize:'16px'}}>
                  {___PHRASE("IncorporeHistorialLaboralDelTrabajador")}
                  </Typography>
               </Grid>
            </Grid>

            <Grid container spacing={3} className={`e-mb-lg`}>

               <Grid item xs={12} sm={4}>
                  <TextField
                     variant="standard"
                     fullWidth
                     name="start_date"
                     label={___PHRASE("fechaDeInicio")}
                     type="date"
                     onChange={(e) => {handleInput(e);}}
                     value={formValues.start_date}
                     InputLabelProps={{
                        shrink: true,
                     }}
                     error={error == 'start_date' ? true: false}
                     helperText={error == 'start_date' ? 'Fecha Inicio es requerido' : ''}
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <TextField
                     variant="standard"
                     fullWidth
                     name="end_date"
                     label={___PHRASE("fechaDeTermino")}
                     type="date"
                     onChange={(e) => {handleInput(e);}}
                     value={formValues.end_date}
                     InputLabelProps={{
                        shrink: true,
                     }}
                     error={error == 'end_date' ? true: false}
                     helperText={error == 'end_date' ? 'Fecha Fin es requerido' : ''}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
               <FormControl variant="standard" sx={{width:'100%'}}>
                  <InputLabel id="demo-simple-select-standard-label">{___PHRASE("tipoDeContrato")}</InputLabel>
                  <Select
                        id="idtype_contract"
                        name="idtype_contract"
                        label={___PHRASE("tipoDeContrato")}
                        variant="standard"
                        value={formValues.idtype_contract}
                        onChange={(e) => {handleInput(e);}}
                        error={error == 'idtype_contract' ? true: false}
                        fullWidth
                  >
                        {
                        contractType.map((type, i) => {
                           return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                        })
                        }
                  </Select>
                  {error == 'idtype_contract' && (
                     <FormHelperText>Selecciona un tipo de contrato</FormHelperText>
                  )}
               </FormControl>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <FormControl variant="standard" sx={{width:'100%'}}>
                  <InputLabel id="demo-simple-select-standard-label">{___PHRASE("estadoContrato")}</InputLabel>
                  <Select
                     id="status_contract"
                     name="status_contract"
                     variant="standard"
                     value={formValues.status_contract}
                     onChange={(e) => {handleInput(e);}}
                     error={error == 'status_contract' ? true: false}
                     fullWidth
                  >
                     {
                        contractState.map((state, i) => {
                           return <MenuItem key={i} value={state.valueStatus}>{state.status}</MenuItem>;
                        })
                     }
                  </Select>
                  {error == 'status_contract' && (
                     <FormHelperText>Selecciona un estado de contrato</FormHelperText>
                  )}
                  </FormControl>
               </Grid>
               
               <Grid item xs={8} sx={{marginTop:'3px'}}>
                  <Autocomplete
                     id="disable-portal"
                     size='small'
                     disablePortal
                     value={causalSelected}
                     options={causalValue}
                     renderInput={(params) => <TextField 
                        {...params} variant="standard" 
                        label={___PHRASE("causalUltimoFiniquito")}
                        error={error == 'causal' ? true: false}
                        helperText={error == 'causal' ? 'Selecciona una causal de la lista' : ''}
                        />}
                     onChange={(e, newValue) => { setCausalSelected(newValue.label) }}
                  />
               </Grid>
            </Grid>
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="center">
                  <button className='btn_add' onClick={createEmploymentHistory} style={{cursor: 'pointer'}}>
                     {statusSave == 'save' ? ___PHRASE("agregar") :  ___PHRASE("editar")}
                  </button>
               </Grid>

            </Grid>
            <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  {___PHRASE("listadoDeHistorialLaboral")}
               </Typography>
            </Grid>
            <TableData
               header={header}
               action={dataAction}
               data={employmentHistories || []}
               RecuperarData={recuperarData}
               status_disabled={statusDisabled}
               // row_initial={3}
            />
         </Grid>
      </Container >
      {
         statusSave === 'delete' && (
            <ModalHistoryDelete
               open={showHistoryDeleteModal}
               closeModal={()=>{setShowHistoryDeleteModal(false)}}
               onListHistory={getEmploymentHistory}
               history={idHistory}
               getSnackBar={setSnackBarConfig}
               clearState={()=>{setIdHistory('')}}
            />
         )
      }
      </>
   );
};

