import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Turn.type'
import { Loading } from '@/components/common/Loading';
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { SpinnerGrow } from '@/components/common/Spinner';
import { TableDataV2 } from '@/components/common/Tablev2';
import { TurnService } from '@/service/services/Turn.service';
import { ModalTurns } from '@/components/Modal/ModalTurn/ModalTurn';
import { ModalTurnDelete } from '@/components/Modal/ModalTurnDelete/ModalTurnDelete';
import ___PHRASE from '@/service/translate/translateService';


export const TurnView: React.FC<Props> = (props: any): JSX.Element => {

//    Vista para mostrar los giros de la empresa y su crud
   const [openView, setOpenView] = useState(false);
   const [Turns, setTuns] = useState([])
   const [showTurnsModal, setShowTurnModal] = useState<boolean>(false);
   const [showTurnDeleteModal, setShowTurnDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [turnSelected, setTurnSelected] = useState<String>('');
   const [loading, setLoading] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const getTurnCompany = async () => {
      setLoading(true);
      const response = await TurnService.getTurns();
      console.log(response)
      if (response.data) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: response.data,
         }));
         setTuns(response.data)
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value) {
        setTurnSelected(value)
   }

   useEffect(() => {
    getTurnCompany()
   }, [])

   return (
      <Protected>
         {loading && <Loading/>}
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>{___PHRASE("cargando")}</strong></h1>
            </Grid>
         </Backdrop>
         <Container>
            <TableDataV2
               data={Turns}
               header={[
                  { name: 'turn', label: ___PHRASE("descripcion"), filter: false, Chip: true },
               ]}
               status_action
               //  checkbox
               title={___PHRASE("girosDeLaEmpresa")}
               RecuperarData={RecuperarData}
               setModalSave={setShowTurnModal}
               setModalDelete={setShowTurnDeleteModal}
               actionSelect={setActionSelected}
            />
         </Container>
         {
            showTurnsModal && (
               <ModalTurns
                  open={showTurnsModal}
                  closeModal={() => { setShowTurnModal(false) }}
                  actionSelected={actionSelected}
                  onListTurns={getTurnCompany}
                  turn={turnSelected}
                  clearState={() => { setTurnSelected('') }}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalTurnDelete
                  open={showTurnDeleteModal}
                  closeModal={()=>{setShowTurnDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListTurns={getTurnCompany}
                  turn={turnSelected}
                  clearState={()=>{setTurnSelected('')}}
               />
            )
         }
      </Protected>
   )
};