import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useState } from "react";
import { Alert, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalWorkerContact.type";
import { WorkerContactService } from "@/service/services/WorkerContact.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalWorkerContact: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, contact, onListContacts, workerSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: contact ? contact.id : '',
        email: contact ? contact.email : '',
        landline: contact ? contact.landline : '',
        personal_phone: contact ? contact.personal_phone : '',
        emergency_phone: contact ? contact.emergency_phone : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const validateForm = (values) => {
        let errors: any = {};
        let regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let regPhone = /^(\+\d{1,3}( )?)?((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/;
        if(!(values.email.match(regEmail))) errors.email = "correo no válido";
        if(!(values.landline.match(regPhone))) errors.landline = "teléfono no válido";
        if(!(values.personal_phone.match(regPhone))) errors.personal_phone = "teléfono no válido";
        if(!(values.emergency_phone.match(regPhone))) errors.emergency_phone = "teléfono no válido";
        if(!values.email) errors.email = "correo requerido";
        if(!values.landline) errors.landline = "telefono fijo requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            email: values.email,
            landline : values.landline,
            personal_phone : values.personal_phone,
            emergency_phone : values.emergency_phone,
        }
        setLoading(true);
        if(contact && actionSelected=='edit'){
            await WorkerContactService.editEmployeeContact(formData, contact.id).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true) {
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge}`}
                    ))
                    onListContacts && onListContacts(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }));
                }
                
            })
        }else{
            await WorkerContactService.createEmployeeContact(formData, workerSelected.id).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true) {
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge}`}
                    ))
                    onListContacts && onListContacts(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${apimessagge ||  ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }));
                }
            })
        }
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={((contact && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar")) +" "+ ___PHRASE("contacto")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("correo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="email"
                                                variant="standard"
                                                fullWidth
                                                name="email"
                                                type="email"
                                                onChange={handleChange}
                                                placeholder={___PHRASE("correo")}
                                                value={values.email}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.email && touched.email ? true : false}
                                                helperText={errors.email && touched.email ? errors.email: ""}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("telefonoFijo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="landline"
                                            variant="standard"
                                            type="text" 
                                            name="landline"
                                            fullWidth
                                            value={values.landline}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("telefonoFijo")}
                                            error={errors.landline && touched.landline ? true : false}
                                            helperText={errors.landline  && touched.landline ? errors.landline: ""}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("telefonoPersonal")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="personal_phone"
                                            variant="standard"
                                            type="text" 
                                            name="personal_phone"
                                            fullWidth
                                            value={values.personal_phone}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("telefonoPersonal")}
                                            error={errors.personal_phone && touched.personal_phone ? true : false}
                                            helperText={errors.personal_phone && touched.personal_phone ? errors.personal_phone : ""}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("telefonoDeEmergencia")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="emergency_phone"
                                            variant="standard"
                                            type="text" 
                                            name="emergency_phone"
                                            fullWidth
                                            value={values.emergency_phone}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("telefonoDeEmergencia")}
                                            error={errors.emergency_phone && touched.emergency_phone ? true : false}
                                            helperText={errors.emergency_phone && touched.emergency_phone ? errors.emergency_phone : "" }
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(contact && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}