import { useEffect, useState } from "react"
import { Protected } from "@/components/layout/Protected"
import { Box, Breadcrumbs, Button, ButtonGroup, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { WorkerValidationService } from "@/service/services/WorkerValidation.service"
import { ModalWorkerValidation } from "@/components/Modal/ModalWorkerValidation"
import EditIcon from '@mui/icons-material/Edit';
import { API_URL_BASE } from "@/toolbox/defaults/app"
import { Props } from "./AdminValidation.type"
import { AdminValidationService } from "@/service/services/AdminValidation.service"
import { ModalAdminValidation } from "@/components/Modal/ModalAdminValidation"
import GroupsIcon from '@mui/icons-material/Groups';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { TableDataV2 } from "@/components/common/Tablev2"
import { WorkerService } from "@/service/services/Worker.service"
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ___PHRASE from "@/service/translate/translateService"

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const AdminValidationView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [validations, setValidations] = useState([]);
    const [showValidationModal, setShowValidationModal] = useState<boolean>(false);
    const [showValidationDeleteModal, setShowValidationDeleteModal] = useState<boolean>(false);
    const [validationSelected, setValidationSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState(false);

    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }
    ];

    useEffect(()=> {
        getWorkers()
    },[]);

    useEffect(()=> {
        if(workerSelected){
            getValidationbyEmployee(workerSelected.id);
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getValidationbyEmployee = async(idEmployee) => {
        setLoading(true);
        await AdminValidationService.getValidationbyEmployee(idEmployee).then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    iddetailusercompany: item.iddetailusercompany,
                    iduser: item.iduser,
                    userName: item.userName,
                    userEmail: item.userEmail,
                    userN_document: item.userN_document,
                    idcompany: item.idcompany,
                    idrole: item.idrole,
                    idemployee: item.idemployee,
                    employeeName: item.employeeName,
                    EmployeeN_document: item.EmployeeN_document,
                    date_assignment: item.date_assignment,
                    assign_ip: item.assign_ip,
                    VIid: item.VIid,
                    VIface_img: `${API_URL_BASE}/${item.VIface_img}`,
                    VIdoc_img: `${API_URL_BASE}/${item.VIdoc_img}`,
                    VIface_matching: item.VIface_matching && (item.VIface_matching == '1' ? ___PHRASE("aprobado") : (item.VIface_matching == '2' ? ___PHRASE("rechazado") : ___PHRASE("pendiente"))),
                    VIobservations: item.VIobservations,
                    VIstatus: item.VIstatus,
                }
                newData.push(data);
            });
            setValidations(newData|| []);

            if(oldData.length == 0){
                setWorkerSelected('')
                setShowDialog(true);
            } else {
                setShowValidation(true);
                setView('validation');
            }
        })
        setLoading(false);
    }

    const getAdminValidations = async() => {
        setLoading(true);
        await AdminValidationService.getAdminValidations('[0,1,2,3]').then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    iddetailusercompany: item.iddetailusercompany,
                    iduser: item.iduser,
                    userName: item.userName,
                    userEmail: item.userEmail,
                    userN_document: item.userN_document,
                    idcompany: item.idcompany,
                    idrole: item.idrole,
                    idemployee: item.idemployee,
                    employeeName: item.employeeName,
                    EmployeeN_document: item.EmployeeN_document,
                    date_assignment: item.date_assignment,
                    assign_ip: item.assign_ip,
                    VIid: item.VIid,
                    VIface_img: `${API_URL_BASE}/${item.VIface_img}`,
                    VIdoc_img: `${API_URL_BASE}/${item.VIdoc_img}`,
                    VIface_matching: item.VIface_matching && (item.VIface_matching == '1' ? 'Apropado' : (item.VIface_matching == '2' ? 'Rechazado' : '---')),
                    VIobservations: item.VIobservations,
                    VIstatus: item.VIstatus,
                    // review_status: item.review_status == '0' ? 'pendiente' : (item.review_status == '1' ? 'aceptada' : 'rechazada'),
                }
                newData.push(data);
            });

            setValidations(newData|| []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        !workerSelected && setWorkerSelected(value);
        // workerSelected && setValidationSelected(value)
        console.log(value)
    }

    const searchData = async (value) => {
       const resp = await WorkerService.getWorkers(value);
       setWorkers(resp.data || []);
    }

    const showDocument = (image) => {
        setLoading(true);

        if(image){
            window.open(image, '_blank');
        }

        setLoading(false);
    }

    const handleClose = () => {
        setShowDialog(false);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:3}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeEmpleados")}
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowValidation(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showValidation && (
                        <StyledBreadcrumb
                            label={___PHRASE("validacionesDeIdentidad")}
                            onClick={() => {setView('validation')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={___PHRASE("validacionesDeIdentidad")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<HowToRegIcon fontSize="small" ></HowToRegIcon>}
                        toolTipBtnHelper={___PHRASE("verValidaciones")}
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'validation' && (
                    <>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ mt: 1 , fontWeight:600}}>
                        {`Validación de Indentidad de "${workerSelected?.name}"`}
                     </Typography>
                        <Card sx={{width:"100%"}}>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#F4F6F8' }}>
                                        <TableRow >
                                            <TableCell sx={{minWidth:'180px'}}>
                                                <Typography>{___PHRASE("nombreEmpleado")}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{___PHRASE("nDocumento")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("usuario")} / {___PHRASE("correo")}</Typography>
                                            </TableCell>
                                            {/* <TableCell >
                                                <Typography>Correo</Typography>
                                            </TableCell> */}
                                            <TableCell >
                                                <Typography>{___PHRASE("imagenRostro")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("imagenDocumento")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("coincidenciasDeRostro")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("observaciones")}</Typography>
                                            </TableCell>
                                            <TableCell   align="center" >
                                                <Typography>{___PHRASE("acciones")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            validations.length > 0 && validations.map((item) => (
                                                <TableRow >
                                                    <TableCell width="auto">
                                                        {item.employeeName}
                                                    </TableCell>
                                                    <TableCell width="auto">
                                                        {item.EmployeeN_document}
                                                    </TableCell>
                                                    <TableCell width="auto">
                                                        {item.userName} - 
                                                        {item.userEmail}
                                                    </TableCell>
                                                    {/* <TableCell width="auto">
                                                        {item.userEmail}
                                                    </TableCell> */}
                                                    <TableCell >
                                                        <img src={item.VIface_img} style={{maxHeight:'100px', maxWidth:'100px', cursor: 'pointer'}} onClick={()=>{showDocument(item.VIface_img)}}/>
                                                    </TableCell>
                                                    <TableCell width="auto">
                                                    <img src={item.VIdoc_img} style={{maxHeight:'100px', maxWidth:'100px', cursor: 'pointer'}} onClick={()=>{showDocument(item.VIdoc_img)}}/>
                                                    </TableCell>
                                                    <TableCell width="auto">
                                                        {item.VIface_matching || '---'}
                                                    </TableCell>
                                                    <TableCell width="auto">
                                                        {item.VIobservations || '---'}
                                                    </TableCell>
                                                    <TableCell width="auto"  align="center" >
                                                    <Tooltip title="Validar">
                                                    <ButtonGroup variant="contained" color="inherit" aria-label="button-group-custom-table" style={{padding: "0px 5px"}}>
                                                        <IconButton size="small" color="success" aria-label="view" 
                                                            onClick={() => {
                                                                setValidationSelected(item);
                                                                setShowValidationModal(true);
                                                            }}
                                                            disabled={item.VIface_matching == 'Pendiente' ? false : true}
                                                        >
                                                            <EditIcon fontSize='small' />
                                                        </IconButton>
                                                    </ButtonGroup>
                                                    </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </>
                )
            }
            </Container>

            {
                showValidationModal && (
                    <ModalAdminValidation
                        open={showValidationModal}
                        closeModal={()=>{setShowValidationModal(false)}}
                        actionSelected={actionSelected}
                        onListValidations={getAdminValidations}
                        validation={validationSelected}
                        clearState={()=>{setValidationSelected('')}}
                    />
                )
            }

            <Dialog open={showDialog} onClose={handleClose}>
                <DialogTitle>{___PHRASE("noHayDatosDisponibles")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {___PHRASE("loSientoNoHayDatosParaMostrar")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">{___PHRASE("cerrar")}</Button>
                </DialogActions>
            </Dialog>
        </Protected>
    )
}