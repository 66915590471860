import * as React from 'react';
import { Box, List, ListItemText } from '@mui/material';
import { ListItemIcon, ListItemButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_RUTA, KEY_SUB_RUTA, KEY_USER_DATA, KEY_USER_PERMISSION } from '@/toolbox/constants/local-storage';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/defaults/static-roles';
import { useLocation } from 'react-router-dom';
import {  ROUTE_ACTIVE_LIQUIDATION_WORKERS, ROUTE_ACTIVE_STRUCTURE, ROUTE_ANNOUNCEMENT, ROUTE_APPLICANTS, ROUTE_BRANCH_OFFICE, ROUTE_CHARGES, ROUTE_COST_CENTER, ROUTE_DEPARMENT, ROUTE_VALUE_EXTRA_HOURS, ROUTE_HOME, ROUTE_LIQUIDATION_MASIVE, ROUTE_LIQUIDATION_PROJECT, ROUTE_LIQUIDATION_SALARY, ROUTE_PHASES, ROUTE_PROFILES, ROUTE_PROJECT, ROUTE_RECRUITING_TEAM, ROUTE_STRUCTURE_LRE, ROUTE_USER, ROUTE_WORKER, ROUTE_ASSISTANCES, ROUTE_EXTRA_HOURS, ROUTE_ARREARS, ROUTE_ADVANCES, ROUTE_INVENTORY_WORKERS, ROUTE_MOVEMENTS,ROUTE_TEMPLATE_LIQUIDATION_MONTHLY, ROUTE_MY_LIQUIDATION_SALARY, ROUTE_REQUEST_TYPE, ROUTE_MY_REQUEST, ROUTE_CREATE_REQUEST, ROUTE_REPORT_EMPLOYEES, ROUTE_REPORT_LIQUIDATIONS, ROUTE_REPORT_REQUEST, ROUTE_REPORT_WORKER_DELAY, ROUTE_REPORT_EXPENS, ROUTE_EMPLOYEE_EXPENSE, ROUTE_EXPENSE_REQUESTS, ROUTE_MY_VALIDATIONS, ROUTE_ADMIN_VALIDATIONS, ROUTE_CONTRACT_EMPLOYEE, ROUTE_COMPANIES, ROUTE_TURNS, ROUTE_VARIABLE_GLOBAL, ROUTE_WORKER_CONTACT, ROUTE_ANNEXED, ROUTE_TYPE_NOTIFICATIONS, ROUTE_TYPE_REMINDER , ROUTE_IP_ACCEPT, ROUTE_IP_DENIED, ROUTE_COUNTRIES, ROUTE_DEPARTAMENT, ROUTE_PROVINCE, ROUTE_DISTRICT, ROUTE_TIME_ZONE, ROUTE_STRUCTURE_MANTEINER, ROUTE_BRANCH_OFFICE_TYPE, ROUTE_POLITICS, ROUTE_POLITICS_MASTER,ROUTE_PRODUCTS, ROUTE_REPORT_INVENTORY_PRODUCTS, ROUTE_REPORT_LIQUIDATION_PROJECTS, ROUTE_CUSTOMIZE_CONTRACT, ROUTE_GROUP_COMPANIES, ROUTE_GROUP_COMPANIES_ALL, ROUTE_REPORT_MULTI_COMPANY, ROUTE_DOCUMENT_CONTRACT, ROUTE_CREATE_CONTRACT_DOCUMENT, ROUTE_ASSISTANCES_WORKER, ROUTE_ABSENCES, ROUTE_SCHEDULE, ROUTE_SCHEDULE_EMPLOYEE, ROUTE_VIEW_CONTROL} from '@/toolbox/constants/route-map';

import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import PublicIcon from '@mui/icons-material/Public';
import FlagIcon from '@mui/icons-material/Flag';
import BusinessIcon from '@mui/icons-material/Business';
import TableViewIcon from '@mui/icons-material/TableView';
import GroupsIcon from '@mui/icons-material/Groups';
import TerminalIcon from '@mui/icons-material/Terminal';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DescriptionIcon from '@mui/icons-material/Description';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StoreIcon from '@mui/icons-material/Store';
import CampaignIcon from '@mui/icons-material/Campaign';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import GppBadIcon from '@mui/icons-material/GppBad';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BarChartIcon from '@mui/icons-material/BarChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import Groups3Icon from '@mui/icons-material/Groups3';
import WorkIcon from '@mui/icons-material/Work';
import CommitIcon from '@mui/icons-material/Commit';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import GavelIcon from '@mui/icons-material/Gavel';
import PreviewIcon from '@mui/icons-material/Preview';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ___PHRASE from '@/service/translate/translateService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const dataUser = readLocalStorage(KEY_USER_DATA);
const userSession = dataUser?.user;

// const rutasSuperAdmin = [
//   {
//     id:1,
//     name: "Maestros",
//     icon: <HandymanIcon />,
//     rutas: [
//       {
//         name_ruta: "Empresas",
//         location: ROUTE_COMPANIES,
//         icon: <GroupIcon/>,
//       },
//       {
//         name_ruta: "Grupo de Empresas",
//         location: ROUTE_GROUP_COMPANIES_ALL,
//         icon: <GroupIcon/>,
//       },
//       {
//         name_ruta: "Usuarios",
//         location: ROUTE_USER,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Variables Globales",
//         location: ROUTE_VARIABLE_GLOBAL,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Estructuras",
//         location: ROUTE_STRUCTURE_MANTEINER,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Tipos de Notificaciones",
//         location: ROUTE_TYPE_NOTIFICATIONS,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Tipos de Recordatorios",
//         location: ROUTE_TYPE_REMINDER,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Zonas Horarias",
//         location: ROUTE_TIME_ZONE,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Tipos de Sucursales",
//         location: ROUTE_BRANCH_OFFICE_TYPE,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Politicas del Software",
//         location: ROUTE_POLITICS_MASTER,
//         icon: <LeaderboardSharpIcon/>,
//       },
//     ] 
//   },
//   {
//     id:2,
//     name: "Ubigeo",
//     icon: <HandymanIcon />,
//     rutas: [
//       {
//         name_ruta: "Paises",
//         location: ROUTE_COUNTRIES,
//         icon: <GroupIcon/>,
//       },
//       {
//         name_ruta: "Regiones",
//         location: ROUTE_DEPARTAMENT,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Provincias",
//         location: ROUTE_PROVINCE,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Comunas",
//         location: ROUTE_DISTRICT,
//         icon: <AccountBoxSharpIcon/>,
//       },
//     ] 
//   },
// ]

// const rutasAdmin = [
//   {
//     id:1,
//     name: "Maestros",
//     icon: <HandymanIcon />,
//     rutas: [ 
//       {
//         name_ruta: "Empresas",
//         location: ROUTE_COMPANIES,
//         icon: <GroupIcon/>,
//       },
//       {
//         name_ruta: "Grupo de Empresas",
//         location: ROUTE_GROUP_COMPANIES,
//         icon: <GroupIcon/>,
//       },
//       {
//         name_ruta: "Usuarios",
//         location: ROUTE_USER,
//         icon: <AccountBoxSharpIcon/>,
//       },
//       {
//         name_ruta: "Trabajadores",
//         location: ROUTE_WORKER,
//         icon: <GroupSharpIcon/>,
//       },
//       {
//         name_ruta: "Anexos",
//         location: ROUTE_ANNEXED,
//         icon: <GroupSharpIcon/>,
//       },
//       {
//         name_ruta: "Contactos del Trabajdor",
//         location: ROUTE_WORKER_CONTACT,
//         icon: <GroupSharpIcon/>,
//       },
//       {
//         name_ruta: "Sucursales",
//         location: ROUTE_BRANCH_OFFICE,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Proyectos",
//         location: ROUTE_PROJECT,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Centros De Costos",
//         location: ROUTE_COST_CENTER,
//         icon: <TableChartSharpIcon/>,
//       },
//       {
//         name_ruta: "Departamentos",
//         location: ROUTE_DEPARMENT,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Cargos",
//         location: ROUTE_CHARGES,
//         icon: <GroupSharpIcon/>,
//       },
//       // {
//       //   name_ruta: "Valor Horas Extras",
//       //   location: ROUTE_VALUE_EXTRA_HOURS,
//       //   icon: <LeaderboardSharpIcon/>,
//       // },
//       {
//         name_ruta: "Tipos de solicitudes",
//         location: ROUTE_REQUEST_TYPE,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Giros de la empresa",
//         location: ROUTE_TURNS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Productos",
//         location: ROUTE_PRODUCTS,
//         icon: <LeaderboardSharpIcon/>,
//       }, {
//         name_ruta: "Mis Politicas y Privacidad",
//         location: ROUTE_POLITICS_MASTER,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Documento Contratos",
//         location: ROUTE_DOCUMENT_CONTRACT,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Horarios",
//         location: ROUTE_SCHEDULE,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Control de Vistas",
//         location: ROUTE_VIEW_CONTROL,
//         icon: <LeaderboardSharpIcon/>,
//       },
//     ] 
//   },
//   {
//     id:2,
//     name: "Novedades Del Mes",
//     icon: <ShoppingCartSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Asistencias",
//         location: ROUTE_ASSISTANCES,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Inasistencias",
//         location: ROUTE_ABSENCES,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Horas Extras",
//         location: ROUTE_EXTRA_HOURS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Atrasos",
//         location: ROUTE_ARREARS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Anticipos",
//         location: ROUTE_ADVANCES,
//         icon: <HttpsSharpIcon/>,
//       },
//       {
//         name_ruta: "Solicitudes",
//         location: ROUTE_MY_REQUEST,
//         icon: <HttpsSharpIcon/>,
//       },
//       {
//         name_ruta: "Movimientos",
//         location: ROUTE_MOVEMENTS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Validaciones de Identidad",
//         location: ROUTE_ADMIN_VALIDATIONS,
//         icon: <HttpsSharpIcon/>,
//       },
//       {
//         name_ruta: "Inventarios De Trabajadores",
//         location: ROUTE_INVENTORY_WORKERS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Solicitudes Gastos",
//         location: ROUTE_EXPENSE_REQUESTS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "IPs Aceptadas",
//         location: ROUTE_IP_ACCEPT,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Ips Denegadas",
//         location: ROUTE_IP_DENIED,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Horarios",
//         location: ROUTE_SCHEDULE_EMPLOYEE,
//         icon: <LeaderboardSharpIcon/>,
//       }
//     ] 
//   },
//   {
//     id:3,
//     name: "Liquidaciones",
//     icon: <LeaderboardSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Estructura LRE",
//         location: ROUTE_STRUCTURE_LRE,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Liquidación Activa - Trabajadores",
//         location: ROUTE_ACTIVE_LIQUIDATION_WORKERS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Liquidación De Sueldo",
//         location: ROUTE_LIQUIDATION_SALARY,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Liquidación Masiva",
//         location: ROUTE_LIQUIDATION_MASIVE,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Planilla Remuneraciones Mensuales",
//         location: ROUTE_TEMPLATE_LIQUIDATION_MONTHLY,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       // {
//       //   name_ruta: "Liquidación por Proyecto",
//       //   location: ROUTE_LIQUIDATION_PROJECT,
//       //   icon: <LeaderboardSharpIcon/>,
//       // }
//     ] 
//   },
//   {
//     id:4,
//     name: "Reclutamiento",
//     icon: <LeaderboardSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Perfiles",
//         location: ROUTE_PROFILES,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       // {
//       //   name_ruta: "Estructura Activa - Trabajadores",
//       //   location: ROUTE_ACTIVE_STRUCTURE,
//       //   icon: <LeaderboardSharpIcon/>,
//       // },
//       {
//         name_ruta: "Equipo de Reclutamiento",
//         location: ROUTE_RECRUITING_TEAM,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Fases",
//         location: ROUTE_PHASES,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Convocatorias",
//         location: ROUTE_ANNOUNCEMENT,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Postulantes",
//         location: ROUTE_APPLICANTS,
//         icon: <LeaderboardSharpIcon/>,
//       }
//     ] 
//   },
//   {
//     id:5,
//     name: "Reportes",
//     icon: <LeaderboardSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Empleados",
//         location: ROUTE_REPORT_EMPLOYEES,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Grupo de Empresas",
//         location: ROUTE_REPORT_MULTI_COMPANY,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Liquidaciones",
//         location: ROUTE_REPORT_LIQUIDATIONS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Liquidacion por Proyectos",
//         location: ROUTE_REPORT_LIQUIDATION_PROJECTS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Solicitudes",
//         location: ROUTE_REPORT_REQUEST,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Faltas, Tardanzas y Horas Ext.",
//         location: ROUTE_REPORT_WORKER_DELAY,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Gastos",
//         location: ROUTE_REPORT_EXPENS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Productos",
//         location: ROUTE_REPORT_INVENTORY_PRODUCTS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       // {
//       //   name_ruta: "Reporte de Gasto Mensual",
//       //   location: "/gestion-alertas",
//       //   icon: <LeaderboardSharpIcon/>,
//       // },
//       // {
//       //   name_ruta: "Reporte De Inventario Trabajador",
//       //   location: "/gestion-alertas",
//       //   icon: <LeaderboardSharpIcon/>,
//       // },
//       // {
//       //   name_ruta: "Reporte Por Proyectos",
//       //   location: "/gestion-alertas",
//       //   icon: <LeaderboardSharpIcon/>,
//       // }
//     ] 
//   },
//   ,
//   {
//     id:5,
//     name: "Politicas y Privacidad",
//     icon: <LeaderboardSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Politicas y Privacidad del Software",
//         location: ROUTE_POLITICS,
//         icon: <LeaderboardSharpIcon/>,
//       }
//     ] 
//   }
// ]

// const rutasEmployee = [
//   {
//     id:2,
//     name: "Novedades Del Mes",
//     icon: <ShoppingCartSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Mis Anticipos",
//         location: ROUTE_ADVANCES,
//         icon: <HttpsSharpIcon/>,
//       },
//       {
//         name_ruta: "Mis Solicitudes",
//         location: ROUTE_MY_REQUEST,
//         icon: <HttpsSharpIcon/>,
//       },
//       {
//         name_ruta: "Mis Gastos",
//         location: ROUTE_EMPLOYEE_EXPENSE,
//         icon: <HttpsSharpIcon/>,
//       },
//     ] 
//   },
//   {
//     id:3,
//     name: "Liquidaciones",
//     icon: <LeaderboardSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Estructura LRE",
//         location: ROUTE_STRUCTURE_LRE,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Mis liquidaciones",
//         location: ROUTE_MY_LIQUIDATION_SALARY,
//         icon: <LeaderboardSharpIcon/>,
//       },
//       {
//         name_ruta: "Planilla Remuneraciones Mensuales",
//         location: ROUTE_TEMPLATE_LIQUIDATION_MONTHLY,
//         icon: <LeaderboardSharpIcon/>,
//       }
//     ] 
//   },
//   {
//     id:4,
//     name: "Validación de Identidad",
//     icon: <ShoppingCartSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Mis Validaciones",
//         location: ROUTE_MY_VALIDATIONS,
//         icon: <HttpsSharpIcon/>,
//       },
//     ] 
//   },
//   {
//     id:5,
//     name: "Contrato",
//     icon: <ShoppingCartSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Mi Contrato",
//         location: ROUTE_CONTRACT_EMPLOYEE,
//         icon: <HttpsSharpIcon/>,
//       },
//     ] 
//   },
//   {
//     id:6,
//     name: "Politicas y Privacidad",
//     icon: <LeaderboardSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Documentos",
//         location: ROUTE_POLITICS,
//         icon: <LeaderboardSharpIcon/>,
//       },
//     ] 
//   },
//   {
//     id:2,
//     name: "Control Asistencia",
//     icon: <ShoppingCartSharpIcon/>,
//     rutas: [
//       {
//         name_ruta: "Mis Asistencias",
//         location: ROUTE_ASSISTANCES_WORKER,
//         icon: <HttpsSharpIcon/>,
//       },
//     ] 
//   },
// ]


// const icons = [
//     { name: "handyIcon" },
//     { name: "otherIcon" },
//     { name: "anotherIcon" }
// ]

// const IconList = (props) => {
//     const iconsComponents = {handyIcon, otherIcon, anotherIcon}
//     return (
//         <div>
//             {props.icons.map((icon, index) => (
//                 <div key={index}>
//                     {React.createElement(iconsComponents[icon.name])}
//                 </div>
//             ))}
//         </div>
//     );
// }

const iconsComponents = {
  DisabledByDefaultIcon, // default

  //ubigeo
  PublicIcon, //icon module
  FlagIcon, //paises
  
  //Maestros
  ConstructionIcon, // icon module
  TableViewIcon, //control de vistas
  PreviewIcon, //vistas
  BusinessIcon, //company
  GroupsIcon, //usuarios
  HomeWorkIcon, //sucursales - tipos de sucursales - centro de costos
  LocationOnIcon, //zona horaria
  NotificationsActiveIcon, //notificaciones
  NotificationImportantIcon, //recordatorios
  BorderColorIcon, //personalizar contrato 
  DescriptionIcon, //documentos contrato
  NoteAddIcon, //crear contrato
  AccessTimeFilledIcon, //horarios empresa - empleados
  AdminPanelSettingsIcon, //politicas y privacidad
  StoreIcon, //productos
  CampaignIcon, //tipos de solicitudes - solicitudes
  MeetingRoomIcon, //departamentos (areas de trabajo)
  BadgeIcon, //cargos
  Diversity3Icon, //trabajadores
  AccountTreeIcon, //proyectos
  PermContactCalendarIcon, //contacto trabajadores
  TextSnippetIcon, //anexos
  
  //Novedades del mes
  NewspaperIcon, //icon module
  GppBadIcon, //ip denegadas
  VerifiedUserIcon, //ip aceptadas
  AlarmOnIcon, //asistencias
  AlarmOffIcon, //inasistencias

  //control de asistencias
  DateRangeIcon, //icon module
  ManageHistoryIcon, //mis asistencias

  //liquidacion 
  RequestQuoteIcon, //icon module
  PictureAsPdfIcon, //pdf

  //reportes
  BarChartIcon, // icon module
  TimelineIcon, //reportes

  //reclutamiento
  ContentPasteSearchIcon, //icon module
  Groups3Icon, //equipo de reclutamiento
  WorkIcon, //perfil de trabajo
  CommitIcon, //fases

  //validacion de indentidad
  AssuredWorkloadIcon, //icon module
  FingerprintIcon, //Mis validaciones

  //contrato
  GavelIcon, //icon module 
  

  ShoppingCartSharpIcon,
  AccountCircleIcon,
  NotificationsIcon
};

export default function NavSection() {

  const history = useHistory()
  const { pathname } = useLocation();
  const initialRuta = readLocalStorage(KEY_RUTA)
  const initialSubRuta = readLocalStorage(KEY_SUB_RUTA)
  const [ruta, setRuta] = React.useState(initialRuta || '');
  const [subRuta, setSubRuta] = React.useState(initialSubRuta || '');
  const data_user = readLocalStorage(KEY_USER_DATA);
  const type_user = data_user.user.role;
  const [rutasHeader, setRutasHeader] = React.useState<any>([]);


  const getPermissions = () => {
    let newArray    = [];

    const permissionsDB = readLocalStorage(KEY_USER_PERMISSION);
    const ArrayData = readLocalStorage(KEY_USER_DATA);
    const idRole = ArrayData?.user?.idrole;
    const role = ArrayData?.user?.role;
    
    let permissions = permissionsDB.filter(permission => permission.role == role);
    
    if(role == ROLE_ADMIN || role == ROLE_TRABAJADOR){
      let permissionsFilter =  permissions.filter(permission => {
        return permission.name != 'PDF Personalizado' && 
              permission.name != 'PDF de certificación de empleado' &&  
              permission.name != 'PDF de Montos Generales' && 
              permission.name != 'PDF de Liquidación de Sueldos' &&
              permission.name != 'Liquidación por Proyectos' &&
              permission.name != 'Generar Liquidación Masiva' &&
              permission.name != 'Contrato Empleado' &&
              permission.name != 'Personalizar Contrato'
      });
      permissions = permissionsFilter;
    }

    if(permissions && permissions.length > 0) {
      let arrayTemporal = [];

      for(let i=0; i<permissions.length; i++){
        arrayTemporal = newArray.filter(resp => resp["name"] == permissions[i]["module"]);

          if(arrayTemporal.length > 0){
            newArray[newArray.indexOf(arrayTemporal[0])]["rutas"].push(
              {
                'icon' : React.createElement(iconsComponents[permissions[i]["icon"]]),
                'name_ruta': permissions[i]["name"], 
                // 'name_ruta': ___PHRASE(permissions[i]["name"].toLowerCase()), 
                'location': permissions[i]["route"]
              }
            )
          }else{
            newArray.push(
              {
                'id' : i+1,
                'icon' : React.createElement(iconsComponents[permissions[i]["iconModule"]]),
                'name' : permissions[i]["module"], 
                // 'name' : ___PHRASE(permissions[i]["module"].toLowerCase()), 
                'rutas' : [
                  {
                    'icon' : React.createElement(iconsComponents[permissions[i]["icon"]]),
                    'name_ruta': permissions[i]["name"], 
                    'location': permissions[i]["route"]
                  }
                ]
              }
            )
        }        
      }

    }

    return(newArray) ;
  }

  
  const validateType = () => {
    const permissions = getPermissions();

    switch (type_user) {
      case ROLE_SUPER_ADMIN:
        setRutasHeader(permissions)
        break;
      case ROLE_ADMIN:
        setRutasHeader(permissions)
        break;
      case ROLE_TRABAJADOR:
        setRutasHeader(permissions)
        break;
      default:
        console.log('vamos a ver que ponemos')
        break;
    }
  }
 
  React.useEffect(()=>{
  validateType();
  },[]);

  const renderPrueba =
    <Box>
      <List disablePadding sx={{ pr: 1 }}>
        {
          rutasHeader.map((value,i) => {
            const menu = value.name;
            const lower = menu?.toLowerCase();
            const notSpace = lower?.replace(/\s+/g, '');
            const notAccent = notSpace.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            const translate = ___PHRASE(notAccent) == notAccent ? menu : ___PHRASE(notAccent);
          return(
          <>
              <ListItemButton
                key={i}
            sx={{
              color: ruta ===  value.name && '#516E80',
              // bgcolor: ruta ===  value.name && '#007ea7',
              fontWeight: ruta ===  value.name && 'fontWeightBold',
              height: 48,
              position: 'relative',
              textTransform: 'capitalize',
              borderTopRightRadius: 100,
              borderBottomRightRadius: 100,
              // backgroundImage: ruta ===  value.name && 'linear-gradient(to right, #a9cce3, #007ea7)',

              // borderTop: 10;
              // border-bottom-right-radius: 10px;
            }}
            onClick={() => {
              if (ruta ===  value.name) {
                setRuta('')
              } else {
                saveLocalStorage(KEY_RUTA,  value.name)
                setRuta( value.name)
              }
            }}
          >
              <ListItemIcon
               sx={{
                width: 22,
                height: 18,
                color: (ruta ===  value.name) ?'inherit': 'grey',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >{value.icon}</ListItemIcon>

              <ListItemText disableTypography primary={___PHRASE(translate)} sx={{marginRight:1, fontSize:16}}/>
              {
                ruta ===  value.name ?
                <ExpandMoreIcon
                  onClick={() => {setRuta('')}}
              />:
                <ExpandLessIcon
                onClick={()=>{
                  saveLocalStorage(KEY_RUTA,  value.name)
                  setRuta( value.name)
                }}
                />
              }
              
          </ListItemButton>

          {ruta === value.name &&
              value.rutas.map((item, i)=>{
                const menu = item.name_ruta;
                const lower = menu?.toLowerCase();
                const notSpace = lower?.replace(/\s+/g, '');
                const notAccent = notSpace.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                const notScript = notAccent.replace(/-/gi, "");
                const translateSub = ___PHRASE(notScript) == notScript ? menu : ___PHRASE(notScript);
                
               return(
                 <ListItemButton
                   key={i}
                onClick={() => {
                    saveLocalStorage(KEY_SUB_RUTA, item.location)
                    setSubRuta(item.location)
                    history.push(item.location)
                  }
                }
                sx={{
                  marginTop: i == 0? 0.5: 0,
                  height: 40,
                  position: 'relative',
                  textTransform: 'capitalize',
                  borderRadius: 2,
                  marginLeft: '35px',
                  color: (subRuta === item.location || pathname === item.location) && '#516E80',
                  fontWeight: (subRuta === item.location || pathname === item.location)  && 'fontWeightBold',
                  bgColor: (subRuta === item.location || pathname === item.location)  && '#516E80',
                  // backgroundImage: (subRuta === item.location || pathname === item.location)  && 'linear-gradient(to right, #a9cce3, #007ea7)',
                  borderTopRightRadius: 100,
                  borderBottomRightRadius: 100
                }}
              >
                <ListItemIcon
                sx={{
                  width: 22,
                  height: 14,
                  color: (subRuta === item.location || pathname === item.location) ?'inherit': 'grey',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >{item.icon}</ListItemIcon>

                <ListItemText disableTypography primary={translateSub} sx={{fontSize:12}} />
              </ListItemButton>
               )
              })
              }
          </>
          )
          })
        }
      </List>
    </Box>

  return (
    <>
     { renderPrueba}
    </>
 
  );
}
