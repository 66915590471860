import {Props} from "./ModalMaxLoan.type"
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Loading } from "@/components/common/Loading";
import { useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ModalFooter } from "../ModalFooter";
import { LoanService } from "@/service/services/Loan.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalMaxLoan: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, onList, workerSelected } = props;
    console.log(workerSelected)
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState({
        limit_max:''
    })

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.limit_max) errors.limit_max = "requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            idEmployee: workerSelected.id,
            limit_max : values.limit_max
        }
        setLoading(true)
        await LoanService.createMaxEmployeeLoan(formData).then((data)=>{
            onList && onList(0,workerSelected.id)
            closeModal && closeModal();
        });
       
        setLoading(false);
    }
  
    return (
        <>
         {loading && <Loading/>}
         <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={___PHRASE("habilitarPrestamo")}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={6}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("montoMaximoaAprobar")} ($):</b></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="limit_max"
                                                variant="standard"
                                                fullWidth
                                                name="limit_max"
                                                type="number"
                                                onChange={handleChange}
                                                value={values.limit_max}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.limit_max && touched.limit_max ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    )
}