import React, { useEffect, useRef, useState } from 'react';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Breadcrumbs, Typography, TextField, Select, Autocomplete, Button, Input } from "@mui/material";
import { Protected } from '@/components/layout/Protected';
import { ModalContractVariables } from '@/components/Modal/ModalContractVariables/ModalContractVariables';
import { ContractService } from '@/service/services/Contract.service';
import '../ContractEditStyle.css';
import { useHistory } from 'react-router-dom';
import { ROUTE_DOCUMENT_CONTRACT } from '@/toolbox/constants/route-map';
import { WorkerService } from '@/service/services/Worker.service';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const ContractEditView: React.FC<any> = (props: any): JSX.Element => {

    const history = useHistory();
    const idEmployee = props?.location?.state?.idEmployee;
    const idContractDocument = props?.location?.state?.idContractDocument;
    const dataContract = props?.location?.state?.documentData

    const [document, setDocument] = useState([])
    const [dataVariables, setDataVariables] = useState([]);
    const [documentClausulas, setDocumentClausulas] = useState([])

    const [loading, setLoading] = useState(false);
    const [cursorActive, setCursorActive] = useState(null)
    const [idActive, setIdActive] = useState(null)
    const [openModal, setOpenModal] = useState(false);
    const [dataCompanySelected, setDataCompanySelected] = useState(null)
    const [clausulaSelected, setClausulaSelected] = useState(0)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [varEdit, setVarEdit] = useState(null)
    const [enable, setEnable] = useState(true)

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        documentContractClauses();
    }, []);

    useEffect(() => {
        if(idEmployee){
            getEmployeeVariables(idEmployee)
        } else {
            getEmployeUnique()
        };
    }, [idEmployee]);

    useEffect(() => {
        if(dataVariables.length > 0 && document.length > 0) {
            setDocumentClausulas(EstructurarData(document, true))
        }
    }, [dataVariables.length > 0 && document.length > 0])

    useEffect(() => {
        if(varEdit) {
            setDocumentClausulas(EstructurarData(document, true))
        }
    }, [varEdit, document])

    const getEmployeUnique = async () => {
        await WorkerService.getWorkersUnique().then((data) => {
            getEmployeeVariables(data.data['id'])
        })
    }

    const getEmployeeVariables = async(employee) => {
        setLoading(true);
        await ContractService.getEmployeeVariables(employee).then((data) => {
            const dataFilter = data.data[0] || [];
            let newArray = [
                { key: `{{district_company}}`, name: ___PHRASE("distritoDeLaEmpresa"), value: dataFilter.district_company},
                { key: `{{signature_date_contract}}`, name: ___PHRASE("datoFirmaContrato"), value: dataFilter.signature_date_contract},
                { key: `{{company}}`, name: ___PHRASE("nombreDeLaEmpresa"), value: dataFilter.company},
                { key: `{{rut_company}}`, name: ___PHRASE("rutDeLaEmpresa"), value: dataFilter.rut_company},
                { key: `{{manager}}`, name: ___PHRASE("administradorDeLaEmpresa"), value: dataFilter.manager},
                { key: `{{rut_manager}}`, name: ___PHRASE("rutAdministradorDeLaEmpresa"), value: dataFilter.rut_manager},
                { key: `{{address_manager}}`, name: ___PHRASE("direccionAdministradorDeLaEmpresa"), value: dataFilter.address_manager},
                { key: `{{district_manager}}`, name:  ___PHRASE("distritoAdministradorDeLaEmpresa"), value: dataFilter.district_manager},
                { key: `{{employee}}`, name: ___PHRASE("nombreDelEmpleado"), value: dataFilter.employee},
                { key: `{{rut_employee}}`, name: ___PHRASE("rutDelEmpleado"), value: dataFilter.rut_employee},
                { key: `{{nationality_employee}}`, name: ___PHRASE("nacionalidadDelEmpleado"), value: dataFilter.nationality_employee},
                { key: `{{date_of_birth}}`, name: ___PHRASE("fechaNacimientoDelEmpleado"), value: dataFilter.date_of_birth},
                { key: `{{address_employee}}`, name: ___PHRASE("direccionDelEmpleado"), value: dataFilter.address_employee},
                { key: `{{district_employee}}`, name: ___PHRASE("distritoDelEmpleado"), value: dataFilter.district_employee},

                { key: `{{charge_employee}}`, name:  ___PHRASE("cargoDelEmpleado"), value: dataFilter.charge_employee},
                { key: `{{address_branchOffice}}`, name: ___PHRASE("direccionDeLaSucursal"), value: dataFilter.address_branchOffice},
                { key: `{{district_branchOffice}}`, name: ___PHRASE("distritoDeLaSucursal"), value: dataFilter.district_branchOffice},

                { key: `{{weekly_hour_employee}}`, name: ___PHRASE("horarioSemanalDelEmpleado"), value: dataFilter.weekly_hour_employee},
                { key: `{{start_hour_employee}}`, name: ___PHRASE("horarioInicioDelEmpleado"), value: dataFilter.start_hour_employee},
                { key: `{{end_hour_employee}}`, name: ___PHRASE("horarioFinDelEmpleado"), value: dataFilter.end_hour_employee},
                { key: `{{hour_collation}}`, name: ___PHRASE("colaciónHoraDelEmpleado"), value: dataFilter.hour_collation},

                { key: `{{base_salary_employee}}`, name: ___PHRASE("salarioBaseDelEmpleado"), value: dataFilter.base_salary_employee},
                { key: `{{salary_letters_employee}}`, name: ___PHRASE("cartasSalarialesDelEmpleado"), value: dataFilter.salary_letters_employee},

            ];
            setDataVariables(newArray || []);
            setLoading(false);
        })
    }

    const documentContractClauses = async() => {
        setLoading(true);
        if(idContractDocument){
            await ContractService.documentClausesByIdContract(idContractDocument).then((data) => {
                setDocument(data.data || []);
                setLoading(false);
            })
            setName(dataContract.name)
            setDescription(dataContract.description)
        }else{
            await ContractService.documentContractClausesDefecto().then((data) => {
                setDocument(data.data || []);
                setLoading(false);
            })
        }
    }

    const handleInput = (e) => {
        const value = e.target.value
        if (value[e.target.selectionEnd - 1] == "@") {
            setCursorActive(e.target.selectionEnd);
            setIdActive(e.target.name)
            setOpenModal(true);
            return
        } else {
            let changedForm;
            let indice = 0;
            documentClausulas.map((item, i) => {
                if (item.id == e.target.name) {
                    changedForm = {
                        ...item,
                        text: e.target.value
                    }
                    indice = i
                }
            })
            documentClausulas.splice(indice, 1, changedForm)
            setDocumentClausulas([...documentClausulas]);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const evaluateVar = (e, obj) => {
        setOpenModal(false)
        let changedForm;
        let indice = 0;
        documentClausulas.map((item, i) => {
            if (item.id == idActive) {
                let newText = item.text.split('');
                newText.splice(cursorActive, 0, ' ' + obj.value + ' ')
                changedForm = {
                    ...item,
                    text: newText.join('')
                }
                indice = i
            }
        })
        documentClausulas.splice(indice, 1, changedForm)
        setDocumentClausulas([...documentClausulas]);
    }

    const TextToVariable = (text) => {
        let textChange = text;
        dataVariables && dataVariables.map((item) => {
            textChange = textChange.replace(item.name, item.key)
        })
        return textChange
    }

    const VariableToText = (textVariable) => {
        let newText = textVariable
        dataVariables && dataVariables.map((item) => {
            newText = newText.replace(item.key, item.name)
        })
        return newText
    }

    const Enviar = () => {
        let textSend = EstructurarData(documentClausulas, false)
        console.log(textSend)
    }

    const EstructurarData = (documentData, toText) => {
        let newArrayEstructura = [];
        documentData.map((item, i) => {
            let objNew = {
                id: item.id,
                text: toText ? VariableToText(item.text) : TextToVariable(item.text),
                position: i,
                headboard: item.headboard,
                status: item.status
            }
            newArrayEstructura.push(objNew);
        })

        return newArrayEstructura
    }

    const editClause = async(clause) => {
        setLoading(true);
        let textSend = EstructurarData(documentClausulas, false)
        const clauseCurrent = textSend.find(item => item.id == clause.id);
        if(!clauseCurrent) return alert(___PHRASE("ocurrioAlgunErrorIntentaloMasTarde"));
        await ContractService.editClause(clauseCurrent, clause.id).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            if(data.data.status == true){
                setLoading(false);
                documentContractClauses();
                setClausulaSelected(0)
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, 
                        open: true,
                        severity: 'success',
                        message: `${ apimessagge || ___PHRASE("clausulaEditadaConExito")}`
                    }
                    ))
                );
            }else{
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, 
                        open: true,
                        severity: 'error',
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                    }
                    ))
                );
            }
            setLoading(false);

        })
    }

    const deleteClause = async(clause) => {
        setLoading(true);
        await ContractService.deleteClauseDocument(idContractDocument, clause.id).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
        // await ContractService.deleteClause(clause.id).then((data) => {
            if(data.data.status == false) {
                setSnackBarConfig(prev => ({
                    ...prev, 
                    open: true,
                    severity: 'error',
                    message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                }
                ))
                setVarEdit(1)
            }else{
                documentContractClauses();
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: `${apimessagge || ___PHRASE("clausulaEliminadaConExito")}`
                    }
                ))
                setVarEdit(1)
            }
        })
        setLoading(false);
    }

    const handleInputName = (e) => {
        setName(e.target.value)
    }

    const handleInputDescription = (e) => {
        setDescription(e.target.value)
    }

    const NewClausula = async() =>{
        console.log(documentClausulas)
        const newObj = {
            headboard: ___PHRASE("nombreDeLaClausula"),
            id: 'new',
            position:documentClausulas.length +1 ,
            status: 1,
            text:'',
        }
        setDocumentClausulas([...documentClausulas,newObj ]);
    }

    const editarDocument = async() => {
        setLoading(true);
        const obj = {
            name: name,
            description:description
        }

        await ContractService.editDocument(idContractDocument, obj).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                setLoading(false);
                setEnable(true);
                setSnackBarConfig(prev => ({
                    ...prev, 
                    open: true,
                    severity: 'success',
                    message: `${apimessagge || ___PHRASE("datosActualizadosSatisfactoriamente")}`
                }
                ))
            }).catch((error) => {
                setLoading(false);
                setSnackBarConfig(prev => ({
                    ...prev, 
                    open: true,
                    severity: 'error',
                    message: ___PHRASE("errorAlEditarElDocumentoContrato")
                }
                ))
            })
    }

    const bodyForm = <form onSubmit={handleSubmit}>
        {
            documentClausulas && documentClausulas.map((item) => {
                return (
                    <Grid container style={{minHeight: "auto", maxHeight: "auto", overflow: "hidden", width:'100%'}} sx={{ py: 2 }}>

                        <Button onClick={()=>{ setClausulaSelected(item.id) }}>{___PHRASE("editar")}</Button>
                        {/* <Button onClick={()=>{ }}>Subir</Button>
                        <Button onClick={()=>{  }}>Bajar</Button> */}
                        <Button onClick={()=>{ deleteClause(item) }}>{___PHRASE("eliminar")}</Button>

                        <Grid sx={{width: '100%'}} className='clauses-container-text'>
                            {
                                item.headboard && (
                                    <Grid item className='clauses-container-title'>
                                        <TextField
                                            className='name-clauses'
                                            type="text"
                                            variant="standard"
                                            value={item.headboard}
                                            fullWidth
                                            onChange={()=>{}}
                                            placeholder={___PHRASE("nombreDeLaClausula")}
                                            disabled={clausulaSelected!=item.id}
                                        /><span >:</span>
                                    </Grid>
                                )
                            }
                            <Grid item >
                                <TextField
                                    fullWidth
                                    id="text-clausula"
                                    className='text-field'
                                    variant="standard"
                                    name={item.id}
                                    disabled={clausulaSelected!=item.id}
                                    type="text"
                                    multiline
                                    value={item.text}
                                    onChange={handleInput}
                                />
                            </Grid>
                        </Grid>
                        <Grid sx={{width: '100%', textAlign: 'end'}}>
                            <Button disabled={clausulaSelected!=item.id} onClick={()=>{ editClause(item) }}>{___PHRASE("guardarCambios")}</Button>
                        </Grid>
                    </Grid>
                )
            })
        }

    </form>

    return (
        <>
            <Protected>
                {loading && <Loading />}
                <Snackbar
                    open={snackBarConfig.open} 
                    autoHideDuration={snackBarConfig.autoHideDuration} 
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                    <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                        {snackBarConfig.message || ""}
                    </Alert>
                </Snackbar>

                <Grid>
                    <Typography variant="h1" style={{fontSize: '1.5em', margin: '30px 0'}}>{___PHRASE("editarDocumentoDeContrato")}</Typography>
                </Grid>
                <Grid item style={{margin: '10px 0'}}>
                    <Button onClick={()=>{ setEnable(false) }} variant="outlined" style={{marginRight: '5px'}}>{___PHRASE("editar")}</Button>
                    <Button onClick={() => history.push(ROUTE_DOCUMENT_CONTRACT)} variant="contained" color="success" style={{float: 'right'}}>{___PHRASE("regresar")}</Button>
                </Grid>
                <Grid item className='clauses-container-title'>
                    <TextField
                        className='name-clauses'
                        type="text"
                        variant="standard"
                        name={'name'}
                        value={name}
                        fullWidth
                        onChange={handleInputName}
                        placeholder={___PHRASE("nombreDelContrato")}
                        disabled={enable}
                    />
                </Grid>
                <Grid item className='clauses-container-title'>
                    <TextField
                        className='description-clauses'
                        type="text"
                        variant="standard"
                        multiline
                        name={'description'}
                        value={description}
                        fullWidth
                        onChange={handleInputDescription}
                        placeholder={___PHRASE("descripcionDelContrato")}
                        disabled={enable}
                    /><span >:</span>
                </Grid>
                <Grid sx={{width: '100%', textAlign: 'end'}}>
                    <Button disabled={enable} onClick={editarDocument}>{___PHRASE("guardarCambios")}</Button>
                </Grid>
                {bodyForm}

            </Protected>
            {
                openModal &&
                <ModalContractVariables
                    open={openModal}
                    closeModal={() => { setOpenModal(false) }}
                    dataCompany={dataVariables}
                    dataCompanySelected={dataCompanySelected}
                    setDataCompanySelected={setDataCompanySelected}
                    evaluateVar={evaluateVar}
                />
            }
        </>
    )
};