import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './VaribaleGlobal.type'
import { Loading } from '@/components/common/Loading';
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { SpinnerGrow } from '@/components/common/Spinner';
import { TableDataV2 } from '@/components/common/Tablev2';
import { chargesRepository } from '@/service/repositories/Charge.repository';
import { ModalCharges } from '@/components/Modal/ModalCharges';
import { ModalchargeDelete } from '@/components/Modal/ModalchargeDelete/ModalChargeDelete';
import { VariableGlobalsService } from '@/service/services/VariableGlobal.service';
import { ModalVariableGlobal } from '@/components/Modal/ModalVariableGlobal/ModalVariableGlobal';
import { ModalVariableGlobalDelete } from '@/components/Modal/ModalVariableGlobalDelete';
import ___PHRASE from '@/service/translate/translateService';


export const VariableGlobalView: React.FC<Props> = (props: any): JSX.Element => {

   
   const [openView, setOpenView] = useState(false);
   const [VariableGlobals, setVariableGlobals] = useState([])
   const [showVariableGlobalModal, setShowVariableGlobalModal] = useState<boolean>(false);
   const [showVariableGlobalDeleteModal, setShowVariableGlobalDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [variableGlobalSelected, setVariableGlobalSelected] = useState<String>('');
   const [loading, setLoading] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const getVariableGlobals = async () => {
      setLoading(true);
      const response = await VariableGlobalsService.getVaribaleGlobal();
      console.log(response)
      if (response.data) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: response.data,
         }));
         setVariableGlobals(response.data)
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value) {
      setVariableGlobalSelected(value)
   }

   useEffect(() => {
      getVariableGlobals()
   }, [])

   return (
      <Protected>
         {loading && <Loading/>}
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>{___PHRASE("cargando")}</strong></h1>
            </Grid>
         </Backdrop>
         <Container>
            <TableDataV2
               data={VariableGlobals}
               header={[
                  { name: 'dateFormated', label: ___PHRASE("fecha"), filter: false, Chip: true },
                  { name: 'uf', label: 'UF', filter: false, Chip: false },
                  { name: 'utm', label: 'UTM', filter: false, Chip: true },
                  { name: 'tope_cot', label: ___PHRASE("topeCotizacion"), filter: false, Chip: false },
                  { name: 'tope_cs', label: ___PHRASE("topeCesantia"), filter: false, Chip: true },
                  { name: 'tcs1_i', label: 'TCS1', filter: false, Chip: false },
                  { name: 'tcs2_f', label: 'TCS2', filter: false, Chip: true },
                  { name: 'ecs1_i', label: 'ECS1', filter: false, Chip: false },
                  { name: 'ecs2_f', label: 'ECS2', filter: false, Chip: true },
                  { name: 'rmi1', label: ___PHRASE("remuneracionMensualMinima")+' 1', filter: false, Chip: false },
                  { name: 'rmi2', label: ___PHRASE("remuneracionMensualMinima")+' 2', filter: false, Chip: true },
                  { name: 'rmi3', label: ___PHRASE("remuneracionMensualMinima")+' 3', filter: false, Chip: false },
                  { name: 'taf_a', label: ___PHRASE("tramoFamiliar")+' A', filter: false, Chip: true },
                  { name: 'taf_b', label: ___PHRASE("tramoFamiliar")+' B', filter: false, Chip: false },
                  { name: 'taf_c', label: ___PHRASE("tramoFamiliar")+' C', filter: false, Chip: true },
                  { name: 'taf_d', label: ___PHRASE("tramoFamiliar")+' D', filter: false, Chip: false },
                //   { name: 'idcountry', label: 'Pais', filter: false, Chip: true },
               ]}
               status_action
               //  checkbox
               title={___PHRASE("variablesglobales")}
               RecuperarData={RecuperarData}
               setModalSave={setShowVariableGlobalModal}
               setModalDelete={setShowVariableGlobalDeleteModal}
               actionSelect={setActionSelected}
            />
         </Container>
         {
            showVariableGlobalModal && (
               <ModalVariableGlobal
                  open={showVariableGlobalModal}
                  closeModal={() => { setShowVariableGlobalModal(false) }}
                  actionSelected={actionSelected}
                  onListVariableGlobal={getVariableGlobals}
                  variableGlobal={variableGlobalSelected}
                  setVariableGlobal={setVariableGlobalSelected}
                  listVariablesGlobals={VariableGlobals}
                  clearState={() => { setVariableGlobalSelected('') }}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalVariableGlobalDelete
                  open={showVariableGlobalDeleteModal}
                  closeModal={()=>{setShowVariableGlobalDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListVariableGlobal={getVariableGlobals}
                  variableGlobal={variableGlobalSelected}
                  clearState={()=>{setVariableGlobalSelected('')}}
               />
            )
         }
      </Protected>
   )
};