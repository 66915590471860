import ___PHRASE from "@/service/translate/translateService";

export function apiResponseTranslate (text: string) {
    const value = text;
    const lower = value?.toLowerCase();
    const notSpace = lower?.replace(/\s+/g, '');
    const notAccent = notSpace.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const notScript = notAccent.replace(/-/gi, "");
    
    const translateSub = ___PHRASE(notScript) == notScript ? value : ___PHRASE(notScript);

    console.log(notScript)
    console.log(___PHRASE(notScript))

    console.log(___PHRASE(value))
    console.log(___PHRASE(___PHRASE(notScript)))

    return ___PHRASE(translateSub);
}