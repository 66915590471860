import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, Snackbar, Typography, Button, Autocomplete, Breadcrumbs, Tabs, Tab, Tooltip } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { TableBody, Select, FormControl, InputLabel, MenuItem, TextField, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { deparmentRepository } from '@/service/repositories/Deparment.repository';
import { chargesRepository } from '@/service/repositories/Charge.repository';
import { costCentersRepository } from '@/service/repositories/CostCenters.repository';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { DetailLiquidation } from '../DetailLiquidation/DetailLiquidation';
import { RemunerationService } from '@/service/services/Remuneration.service';
import { ROUTE_CUSTOM_VISUALIZER_PDF } from '@/toolbox/constants/route-map';
import { saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import DownloadIcon from '@mui/icons-material/Download';
import { ProjectService } from '@/service/services/Project.service';
import ___PHRASE from '@/service/translate/translateService';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
      theme.palette.mode === 'light'
         ? theme.palette.grey[100]
         : theme.palette.grey[800];
   return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
         backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
         boxShadow: theme.shadows[1],
         backgroundColor: emphasize(backgroundColor, 0.12),
      },
   };
});


interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}


export const PreviewLiquidationMasiveView = (props: any): JSX.Element => {

   let location = useLocation();
   const dataprops: any = location?.state;
   const dataEmployees: any = dataprops?.dataMassive;
   const periodSelected: any = dataprops?.period;

   // console.log(dataEmployees)

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   const [loading, setLoading] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<any>('');
   const [projects, setProjets] = useState([]);
   const [projectSelected, setProjectSelected] = useState<String>('');
   const [departments, setDepartments] = useState<any>([]);
   const [departmentSelected, setDepartmentSelected] = useState<any>('');
   const [charges, setCharges] = useState<any>([]);
   const [chargeSelected, setChargeSelected] = useState<any>('');
   const [costCenters, setCostCenters] = useState([]);
   const [costCentersSelected, setCostCentersSelected] = useState<any>('');
   const [view, setView] = useState<String>('workers');
   const [showDetail, setShowDetail] = useState<boolean>(false);
   const [workerSelected, setWorkerSelected] = useState<any>('');

   const cabeceraWorkers = [
      { name: 'n_document', label: 'RUT', filter: false, Chip: false },
      { name: 'surname', label: ___PHRASE("apellidoPaterno"), filter: false, Chip: true },
      { name: 'second_surname', label: ___PHRASE("apellidoMaterno"), filter: false, Chip: true },
      { name: 'name', label: ___PHRASE("nombres"), filter: false, Chip: false },
   ];
   const [filterMassive, setFilterMassive] = useState<any>({
      idbusinessarea: '',
      idcharge: '',
      idcostcenter: '',
      idproject: ''
   })
   const limpiarFiltros = () => {
      setDepartmentSelected('')
      setChargeSelected('')
      setCostCentersSelected('')
      setProjectSelected('')
      setFilterMassive({
         idbusinessarea: '',
         idcharge: '',
         idcostcenter: '',
         idproject: ''
      })
   }
   useEffect(() => {
      getProjects();
      getDeparments();
      getCenterCostos();
      getChargesCompany();
   }, []);

   const getProjects = async () => {
      setLoading(true);
      const response = await ProjectService.getProjects();
      if (response.data) {
         setProjets(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   const getDeparments = async () => {
      setLoading(true);
      const response = await deparmentRepository.getDeparments();
      if (response.data) {
         setDepartments(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }
   const getChargesCompany = async () => {
      setLoading(true);
      const response = await chargesRepository.getChargesCompany();
      if (response.data) {
         setCharges(response.data)
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   const getCenterCostos = async () => {
      setLoading(true);
      const response = await costCentersRepository.getCostCenters();
      if (response.data) {
         setCostCenters(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   const showPDF = async () => {
      console.log('ver pdf')
      setLoading(true);
      const dataPdf = {
         idperiod: periodSelected?.id,
         month: periodSelected?.month,
         year: periodSelected?.year,
         idproject: filterMassive.idproject ? filterMassive.idproject : '',
         idbusinessarea: filterMassive.idbusinessarea ? filterMassive.idbusinessarea : '',
         idcharge: filterMassive.idcharge ? filterMassive.idcharge : '',
         idcostcenter: filterMassive.idcostcenter ? filterMassive.idcostcenter : ''
      }

      const pdfResp: any = await RemunerationService.generatePreRemunerationPDFMassive(dataPdf);

      if(pdfResp.size == 153){
         setSnackBarConfig(prev => ({
            ...prev, open: true,
            severity: 'warning',
            message: `No hay remuneracion generadas`
            }
         ));
      }else{
         const blob = new Blob([pdfResp], { type: 'application/pdf' });
         const blobURL = URL.createObjectURL(blob);
         const dataRemuMassive = {
            pdf: blobURL,
            idperiod: periodSelected.id,
            month: periodSelected.month,
            year: periodSelected.year,
            idproject: filterMassive.idproject ? filterMassive.idproject : '',
            idbusinessarea: filterMassive.idbusinessarea ? filterMassive.idbusinessarea : '',
            idcharge: filterMassive.idcharge ? filterMassive.idcharge : '',
            idcostcenter: filterMassive.idcostcenter ? filterMassive.idcostcenter : ''
         }
         saveLocalStorage('dataRemuMassive', dataRemuMassive);
         window.open(`${ROUTE_CUSTOM_VISUALIZER_PDF}?type=massive`, '_blank');
      }
      
      limpiarFiltros();
      setLoading(false);
   }

   function RecuperarData(value) {
      setWorkerSelected(value);
      setShowDetail(true);
      setView('detail');
   }

   return (
      <Protected>
         {loading && <Loading />}
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>
         <Container>
            <Grid container spacing={5} >
               <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ fontWeight:600}}
                  >
                     LIQUIDACIÓN DE SUELDO MASIVO
                  </Typography>
               </Grid>

               <Grid item xs={12} container direction={'row'} justifyContent='space-between' alignItems={'center'}>
                  <Grid item container direction={'row'} xs={10} spacing={2} >
                     <Grid item xs={12} md={3}>
                        <Autocomplete
                           id="disable-portal"
                           disablePortal
                           value={projectSelected}
                           options={projects || []}
                           getOptionLabel={(option) => option.name || ""}
                           fullWidth
                           renderInput={(params) => <TextField {...params} variant="standard" label="Proyecto" />}
                           onChange={(e, newValue) => {
                              if (newValue) {
                                 setFilterMassive(prev => ({ ...prev, idproject: newValue.id }))
                                 setProjectSelected(newValue)
                              }
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} md={3}>
                        <Autocomplete
                           id="disable-portal"
                           disablePortal
                           value={departmentSelected}
                           options={departments || []}
                           getOptionLabel={(option) => option.name || ""}
                           fullWidth
                           renderInput={(params) => <TextField {...params} variant="standard" label="Departamento" />}
                           onChange={(e, newValue) => {
                              if (newValue) {
                                 setFilterMassive(prev => ({ ...prev, idbusinessarea: newValue.id }))
                                 setDepartmentSelected(newValue)
                              }
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} md={3}>
                        <Autocomplete
                           id="disable-portal"
                           disablePortal
                           value={chargeSelected}
                           options={charges || []}
                           getOptionLabel={(option) => option.name || ""}
                           fullWidth
                           renderInput={(params) => <TextField {...params} variant="standard" label="Cargo" />}
                           onChange={(e, newValue) => {
                              if (newValue) {
                                 setFilterMassive(prev => ({ ...prev, idcharge: newValue.id }))
                                 setChargeSelected(newValue)
                              }
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} md={3}>
                        <Autocomplete
                           id="disable-portal"
                           disablePortal
                           value={costCentersSelected}
                           options={costCenters || []}
                           getOptionLabel={(option) => option.name || ""}
                           fullWidth
                           renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("centroDeCosto")} />}
                           onChange={(e, newValue) => {
                              if (newValue) {
                                 setFilterMassive(prev => ({ ...prev, idcostcenter: newValue.id }))
                                 setCostCentersSelected(newValue)
                              }
                           }}
                        />
                     </Grid>
                  </Grid>
                  <Grid item container xs={2} spacing={2}>
                     <Grid item container justifyContent='end' xs={12}>
                     <Tooltip title={`descargar previsualizacion pdf`}>
                        <Button variant="contained" endIcon={<DownloadIcon />} onClick={showPDF}>
                           VER PDF
                        </Button>
                     </Tooltip>
                     </Grid>
                  </Grid>
               </Grid>

               <Grid item xs={12} >
                  <Typography variant='h6'>Previsualización de Liquidaciones</Typography>
                  <span>Periodo: {periodSelected?.year} - Mes: {periodSelected?.month}</span>
               </Grid>

               <Grid item xs={12} >

                  <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:5}}>
                     <StyledBreadcrumb
                        label={___PHRASE("listaDeEmpleados")}
                        onClick={() => {
                           setView('workers');
                           setWorkerSelected('');
                           setShowDetail(false);
                        }}
                        icon={<GroupsIcon fontSize="small" />}
                     />
                     {
                        showDetail && (
                           <StyledBreadcrumb
                              label={`Detalle del empleado`}
                              onClick={() => { setView('detail') }}
                              icon={<GroupsIcon fontSize="small" />}
                           />
                        )
                     }
                  </Breadcrumbs>
                  {
                     view == 'workers' && (
                        <TableDataV2
                           title={'Valor de hora extras por empleado'}
                           data={dataEmployees}
                           header={cabeceraWorkers}
                           actionSelect={setActionSelected}
                           status_action
                           disabled_title={true}
                           btn_disabled
                           btn_edit_disable={true}
                           btn_delete_disable={true}
                           btn_helper
                           currentView={view}
                           RecuperarData={RecuperarData}
                        />
                     )
                  }

                  {
                     view == 'detail' && (
                        <DetailLiquidation data={workerSelected}/>
                     )
                  }

               </Grid>
            </Grid>

         </Container>
      </Protected>
   )
};