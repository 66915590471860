import React, { useState, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Box, Typography, TextField, Snackbar, Alert, styled, } from '@mui/material';
import { ArrowRightIcon } from "@toolbox/constants/icons";
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormIdentityDocuments_style.css";
import { Props } from './FormIdentityDocuments.type';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { WorkerValidationService } from '@/service/services/WorkerValidation.service';
import { API_URL_BASE } from '@/toolbox/defaults/app';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ___PHRASE from '@/service/translate/translateService';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const FormIdentityDocuments: React.FC<Props> = (props: Props): JSX.Element => {
   
    const inputRefs = useRef<Array<InputRef | null>>([]);

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<any>({});
    const [valueDatosDocuments, setvalueDatosDocuments] = useState<any>('');
    // const [valueDatosDocuments, setvalueDatosDocuments] = useState<any>(props?.valueDatosDocuments);

    const [countReport, setCountReport] = useState<any>([]);
    const [numberReport, setnumberReport] = useState<any>(0);
    const [validations, setValidations] = useState<any>([]);
    const [confirmValidation, setConfirmValidation] = useState<any>(true);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const typesDocument = [
        {id: '0', label: ___PHRASE("imagen")},
        {id: '1', label: 'pdf'},
    ]

    useEffect(() => {
        if(props?.valueDatosDocuments){
            setvalueDatosDocuments(props.valueDatosDocuments);
            let count = props.valueDatosDocuments?.numeroDocuments;
            let array = [];
            if(count && count > 0){
                for (let index = 0; index < count; index++) {
                    let obj = {
                        name: `Documento N° ${index + 1}`
                    }
                    array.push(obj);
                }
                setCountReport(array);
                setnumberReport(props.valueDatosDocuments?.numeroDocuments);
            }
            
        }
    }, [props?.valueDatosDocuments])

    useEffect(() => {
        if(props?.idEmployee) getValidationsAdmin(props?.idEmployee);
    }, [props?.idEmployee ]);

    const getValidationsAdmin = async(idemployee) => {
        setLoading(true);
        await WorkerValidationService.listValidationIdentityAdmin(idemployee).then((data) => {
            const oldData = data.data || [];
            let newData = [];
            let statusReview = true;
            oldData.length > 0 && oldData.map((item) => {
                if(item.reviewStatus == 0){statusReview = false;}
                data = {
                    id: item.id,
                    nameDocument: item.nameDocument,
                    typeDocument: item.typeDocument,
                    doc: `${API_URL_BASE}/${item.doc}`,
                    idemployee: item.idemployee,
                    idcompany: item.idcompany,
                    reviewStatus: item.reviewStatus,
                    status: item.status,
                }
                newData.push(data);
            });
            setValidations(newData|| []);
            setConfirmValidation(statusReview);
            setLoading(false);
        })
    }

    const setNameFile = (event, type) => {
        const files = event.target && event.target.files || [];
        const currentCv = files[0];
        
        if(currentCv){

            const nameAsArray = currentCv.name.split(".") || [];
            const extension = nameAsArray.slice(-1);
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);

            if(extension[0] == 'jpg' || extension[0] == 'JPG' || extension[0] == 'jpeg' || extension[0] == 'JPEG' ||
                 extension[0] == 'png' || extension[0] == 'PNG' || extension[0] == 'pdf'|| extension[0] == 'PDF'){
                return currentCv;
            }else{
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: ___PHRASE("errorDeFormatoPDF") }
                    ))
                );
            }
        }

        return "";
    };

    const selectCountReport = (operador, num) => {
        let count = num;
        if(operador == '+') {count = num +1};
        if(operador == '-' && num > 1) {count = num -1};
        setnumberReport(count);
        if(count > 0){
            let array = [];
            let newData;
            for (let index = 0; index < count; index++) {
                let obj = {
                    name: `Documento N° ${index + 1}`
                }
                array.push(obj);
                newData = {
                    ...newData,
                    numeroDocuments : count,
                    ['nameDocument'+''+index]: '',
                    ['typeDocument'+''+index]: '',
                    ['doc'+''+index]: '',
                }
            }
            setCountReport(array);
            setvalueDatosDocuments(newData);
        }
    }

    const showDocument = (image) => {
        setLoading(true);
        if(image){
            window.open(image, '_blank');
        }
        setLoading(false);
    }

    const handleSubmit = async () => {

        let inputConfirm = false;

        if(!(props?.idEmployee) && countReport.length == 0){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("debeSubirDocumentosDeIdentidadDelEmpleado") }
                ))
            );
        }

        countReport.length >= 0 && countReport.map((item, index)=>{
            if(!valueDatosDocuments[`nameDocument${index}`]) inputConfirm = true;
            if(!valueDatosDocuments[`typeDocument${index}`]) inputConfirm = true;
            if(!valueDatosDocuments[`doc${index}`]) inputConfirm = true;
        })

        if(inputConfirm){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("asegureseDeLlenarTodosLosCamposYSubirLosArchivosCorrespondientes") }
                ))
            );
        }

        props.setvalueDatosDocuments && props.setvalueDatosDocuments(valueDatosDocuments); 
        props.handleNext && props.handleNext();
    }

    return (
        <>
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>
        <Container maxWidth="xl">
            <Grid container >

                {
                    !(props?.idEmployee) && (
                        <>
                        <Grid item xs={6} container alignItems="center" justifyContent="start" marginTop='25px' >                
                            <Grid item xs={5}>
                                <Typography variant="subtitle1"><b>{___PHRASE("cantidadDeDocumentos")}:</b> </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Grid onClick={()=>{selectCountReport('-', numberReport)}} sx={{cursor: 'pointer'}}>
                                    <RemoveCircleIcon sx={{fontSize:'30px'}}/>
                                </Grid>
                                <Grid>
                                    <TextField
                                        size='small'
                                        id="numberReport"
                                        type="text" 
                                        name="numberReport"
                                        value={numberReport}
                                        sx={{textAlign:'center', alignItems:'center', width:'100px'}}
                                    />
                                </Grid>
                                <Grid onClick={()=>{selectCountReport('+', numberReport)}} sx={{cursor: 'pointer'}}>
                                    <AddCircleIcon sx={{fontSize:'30px'}}/>
                                </Grid>
                            </Grid> 
                        </Grid>
                        {
                            (countReport && countReport.length > 0) && (
                                <Grid  item xs={12} container alignItems="center" justifyContent="space-between" 
                                    sx={{paddingTop: '5px', marginBottom: '25px', overflowX: 'hidden', maxHeight:'400px'}}>
                                {
                                    (countReport && countReport.length > 0) && countReport.map((item, index)=>{
                                        return (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                                                sx={{border: '1px #b9b1a9 solid', marginTop: '10px', padding:'25px'}} >
                                                <Grid item xs={5} container alignItems="center" justifyContent="center" >
                                                    <TextField
                                                        type="text" 
                                                        size='small'
                                                        name={`nameDocument${index}`}
                                                        multiline
                                                        maxRows={4}
                                                        value={valueDatosDocuments[`nameDocument${index}`]}
                                                        onChange={(e)=>{setvalueDatosDocuments({...valueDatosDocuments, ['nameDocument'+''+index]: e.target.value})}}
                                                        placeholder={___PHRASE("nombreDelDocumento")}
                                                        fullWidth
                                                    />
                                                </Grid> 
                                                <Grid item xs={3} container alignItems="center" justifyContent="center" >
                                                    <Autocomplete
                                                        disablePortal
                                                        size='small'
                                                        id="combo-box-demo"
                                                        options={typesDocument}
                                                        getOptionLabel={(option) => option.label || ""}
                                                        onChange={(e, option:any)=>{
                                                            setvalueDatosDocuments({...valueDatosDocuments, ['typeDocument'+''+index]: option})
                                                        }}
                                                        value={valueDatosDocuments[`typeDocument${index}`]}
                                                        renderInput={(params) => <TextField {...params} label={___PHRASE("tipoDeArchivo")}/>}
                                                        sx={{width:'200px'}}
                                                    />
                                                </Grid>  
                                                <Grid item xs={4} container alignItems="center" justifyContent="center">
                                                    <Button variant="contained" component="label" fullWidth>
                                                        <input
                                                            type="file"
                                                            name={`doc${index}`}
                                                            onChange={(event) => {
                                                                setvalueDatosDocuments({...valueDatosDocuments, ['doc'+''+index]: setNameFile(event, 'face')})
                                                            }}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    })
                                }
                                </Grid>
                            )
                        }
                        </>
                    )
                }
                {
                    (props?.idEmployee) && (validations.length > 0) && (
                        <>
                        <Grid item xs={12} container alignItems="center" display='flex' justifyContent="start" marginTop='15px' paddingLeft='20px' paddingRight='20px'>
                        {
                            validations.length > 0 && validations.map((item, key)=>{
                                return <>
                                    {
                                        //Es Imagen (con la "I" mayuscula)
                                        item.typeDocument == ___PHRASE("imagen") && (
                                            <>
                                            
                                                <Grid item xs={3} marginBottom='15px'>
                                                    <Grid sx={{border: '1px #b9b1a9 solid', height:'100px', width:'100px', cursor: 'pointer',
                                                            textAlign: 'center', padding:'5px', borderRadius:'10px'}}
                                                    >
                                                        <img
                                                            src={item.doc || ''}
                                                            alt={___PHRASE("imagen")}
                                                            style={{maxHeight:'85px', cursor: 'pointer'}}
                                                            onClick={() => {
                                                                showDocument(item.doc)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Typography variant="subtitle1" className="custom-input"><b>{item.nameDocument}</b></Typography>
                                                </Grid>
                                            
                                            </>
                                        )
                                    }
                                    {
                                        item.typeDocument == 'pdf' && (
                                            <>
                                                <Grid item xs={3} marginBottom='15px'>
                                                    <Grid sx={{border: '1px #b9b1a9 solid', height:'100px', width:'100px', cursor: 'pointer',
                                                            textAlign: 'center', paddingTop:'15px', borderRadius:'10px'}}
                                                        onClick={() => {
                                                            showDocument(item.doc)
                                                        }}
                                                    >
                                                        <PictureAsPdfIcon sx={{fontSize:'60px'}}/>
                                                    </Grid>
                                                    <Typography variant="subtitle1" className="custom-input"><b>{item.nameDocument}</b></Typography>
                                                </Grid>
                                            
                                            </>
                                        )
                                    }
                                </>
                            })
                        }
                        </Grid>
                        {
                            validations.length > 0 && (
                                <Grid item xs={12} container alignItems="center" display='flex' justifyContent="end" marginBottom='25px' >
                                    <Typography variant="subtitle2" className="custom-input" sx={{border: '1px #b9b1a9 solid', borderRadius:'8px', padding: '5px'}}>
                                        {
                                            confirmValidation ? (
                                                ___PHRASE("losDocumentosYaHanSidoValidadosPorElEmpleado")
                                            ) : (
                                                ___PHRASE("losDocumentosAunNoHanSidoValidadosPorElEmpleado")
                                            )
                                        }
                                    </Typography>
                                </Grid>
                            )
                        }
                        </>
                    )
                }
                {
                    !(props?.idEmployee) && countReport.length == 0 && (
                        <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                        sx={{marginLeft:'25px', paddingRight:'25px', marginTop:'20px', marginBottom:'25px'}} >
                            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%'}}>
                                <Div>{___PHRASE("subirDocumentosDeIdentidadDelEmpleado")}</Div>
                            </Box>
                        </Grid>
                    )
                }
                {
                    (props?.idEmployee) && validations.length == 0 && (
                        <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                        sx={{marginLeft:'25px', paddingRight:'25px', marginTop:'20px', marginBottom:'25px'}} >
                            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%'}}>
                                <Div>{___PHRASE("noSeEncontraronDocumento")}</Div>
                            </Box>
                        </Grid>
                    )
                }

                <Grid item container justifyContent='space-between' >
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            onClick={() => {props.handleBack(); }}
                            disabled={loading}
                            disableElevation
                            className='btn_back'
                            startIcon={<ArrowBackIosIcon />}
                        >
                            {
                            loading ?
                                <CircularProgress className="CircularProgress" size={24} /> :
                                <span>{___PHRASE("atras")}</span>
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={6} container justifyContent='right'>
                        <Button
                            type='submit'
                            variant='contained'
                            onClick={handleSubmit}
                            disabled={loading}
                            disableElevation
                            className='btn_save'
                            sx={{ml:2}}
                            endIcon={<ArrowRightIcon />}
                        >
                            {
                            loading ?
                                <CircularProgress className="CircularProgress" size={24} /> :
                                <span>{___PHRASE("siguiente")}</span>
                            }
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </Container>
        </>
    );
}
