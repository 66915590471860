import { AssistanceService } from "@/service/services/Assistance.service";
import { ChargeService } from "@/service/services/Charge.service";
import { CostCenterService } from "@/service/services/CostCenter.service";
import { TurnService } from "@/service/services/Turn.service";
import { typesExpenseService } from "@/service/services/TypesExpense.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalTypeExpenseDelete.type";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalTypeExpenseDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, onListTypesExpense, typeExpense, clearState, getSnackBar } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeleted= async()=>{
        setLoading(true);
        await typesExpenseService.deleteTypeExpense(typeExpense.id).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            onListTypesExpense && onListTypesExpense();
            closeModal && closeModal();
            getSnackBar && getSnackBar('success', `${apimessagge || ___PHRASE("eliminadoCorrectamente")} `)
        }).catch((data) => {
            getSnackBar && getSnackBar('error', ___PHRASE("errorAlEliminarTipoDeGasto"))
        })
        setLoading(false);
    }
  
    return (
        <>
        <Modal
        open={open}
        handleClose={closeModal}
        size="xs"
        >
        <ModalHeader
            text={___PHRASE("eliminarTipoDeGasto")}
            className='positionElements'
            onCancel={closeModal}
            clearState={clearState}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
              {___PHRASE("estaSeguroDeEliminarEsteTipoDeGasto")}
            </Typography>
        </ModalBody>

        <ModalFooter 
            confirmText={___PHRASE("eliminar")}
            cancelText={___PHRASE("cancelar")}
            onCancel={closeModal}
            className="footerRight"
            onConfirm={onDeleted}
            clearState={clearState}
        />
        </Modal>
        </>
    )
}