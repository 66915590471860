import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './CostCenter.type'
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { costCentersRepository } from '@/service/repositories/CostCenters.repository';
import { Loading } from '@/components/common/Loading';
import { ModalCostCenter } from '@/components/Modal/ModalCostCenter';
import { ModalCostCenterDelete } from '@/components/Modal/ModalCostCenterDelete';
import ___PHRASE from '@/service/translate/translateService';


export const CostCenterView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [costCenters, setCostCenters] = useState([]);
   const [showCostCenterModal, setShowCostCenterModal] = useState<boolean>(false);
   const [showCostCenterDeleteModal, setShowCostCenterDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [costCenterSelected, setCostCenterSelected] = useState<String>('');

   useEffect(() => {
      getCenterCostos()
   },[]);

   const getCenterCostos = async () => {
      setLoading(true);
      const response = await costCentersRepository.getCostCenters();
      if (response.data) {
         setCostCenters(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value)
      setCostCenterSelected(value)
   }

   const searchData = async (value) => {
      const response = await costCentersRepository.getCostCenters(value);
      if (response.data) {
         setCostCenters(response.data || [])
      } else {
         console.log('error')
      }
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={___PHRASE("centroDeCosto")}
               data={costCenters}
               header={[
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                  { name: 'description', label: ___PHRASE("descripcion"), filter: false, Chip: false },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowCostCenterModal}
               setModalDelete={setShowCostCenterDeleteModal}
               status_action
               textSearch={___PHRASE("buscarCentroDeCostos")}
               searchData={searchData}
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showCostCenterModal && (
               <ModalCostCenter
                  open={showCostCenterModal}
                  closeModal={()=>{setShowCostCenterModal(false)}}
                  actionSelected={actionSelected}
                  onListCostCenter={getCenterCostos}
                  costCenter={costCenterSelected}
                  clearState={()=>{setCostCenterSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalCostCenterDelete
                  open={showCostCenterDeleteModal}
                  closeModal={()=>{setShowCostCenterDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListCostCenter={getCenterCostos}
                  costCenter={costCenterSelected}
                  clearState={()=>{setCostCenterSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};