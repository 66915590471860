import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalAbsence.type";
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

const typeAbsence = [
    {
        id: 1,
        description: "Inasistencia",
        status: "1"
    },
    {
        id: 2,
        description: "Licencia",
        status: "1"
    }
]

export const ModalAbsence: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, absence, onListAbsences, workerSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [typeAbsences, setTypeAbsences] = useState<any>(typeAbsence);
    const [typeDiscounts, setTypeDiscounts] = useState<any>([]);
    const [disableTypeDesc, setDisabledTypeDesc] = useState<boolean>(false)

    const [data, setData] = useState({
        id: absence ? absence.id : '',
        idemployee: absence ? absence.idemployee : '',
        idtypeabsence: absence ? absence.idtypeabsence : '',
        type_discount: absence ? absence.type_discount : '',
        date_registration: absence ? absence.date_registration : '',
        date_end: absence ? absence.date_end : '',
        observation: absence ? absence.observation : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(()=> {
        getComboAbsences()
    },[]);

    const getComboAbsences = async() => {
        setLoading(true);
        const resp:any = await AssistanceService.getComboAbsences();
        setTypeDiscounts(resp.data.typeDiscount || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.date_registration) errors.date_registration = "fecha requerido";
        if(!values.date_end) errors.date_end = "fecha requerido";
        if(!values.idtypeabsence) errors.idtypeabsence = "tipo inasistencia requerido";
        if(!values.observation) errors.observation = "observación requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        let currentDate = moment().format("YYYY/MM/DD");
        let currentMonth = moment().format("MMMM-YYYY");
        let dateStart= '';
        let dateEnd= '';
        if(values.date_registration) dateStart = moment(values.date_registration).format("YYYY/MM/DD");
        if(values.date_end) dateEnd = moment(values.date_end).format("YYYY/MM/DD");

        if(values.date_registration && values.date_end){
            if(dateStart >= dateEnd) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `${___PHRASE("aseguresequelafechainicialseamenorquelafinal")}` }
                ))
            );
    
            if(dateStart > currentDate || dateEnd > currentDate ) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("asegureseQueLaFechaDeInicioyFinSeaMenorQueLaFechaActual") }
                ))
            );
    
            if(currentMonth != moment(values.date_registration).format("MMMM-YYYY") || currentMonth != moment(values.date_end).format("MMMM-YYYY")) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("elMesDeLasFechasSeleccionadasDebeSerIgualAlDelMesActual")+` ${currentMonth}).` }
                ))
            );
        }

        const formData = {
            id: values.id,
            idemployee: workerSelected.id,
            idtypeabsence : values.idtypeabsence,
            type_discount : values.type_discount,
            date_registration : values.date_registration,
            date_end : values.date_end,
            observation : values.observation,
        }
        setLoading(true);
        if(absence && actionSelected=='edit'){
            await AssistanceService.editAbsence(formData, absence.id).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge}`}
                    ))
                    onListAbsences && onListAbsences(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge}`}
                    ))
                }
            })
        }else{
            await AssistanceService.createAbsence(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge}`}
                    ))
                    onListAbsences && onListAbsences(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge}`}
                    ))
                }
            })
        }
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={(absence ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("inasistencia")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("tipoDeInasistencia")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idtypeabsence"
                                                variant="standard"
                                                value={values.idtypeabsence}
                                                onChange={(event) => { 
                                                setFieldValue("idtypeabsence", event.target.value);
                                                setDisabledTypeDesc(false)
                                                if(event.target.value == typeAbsence[1].id){
                                                    setFieldValue("type_discount", typeDiscounts[0].id);
                                                    setDisabledTypeDesc(true)
                                                }
                                               
                                                }}
                                                error={ errors.idtypeabsence && touched.idtypeabsence ? true : false }
                                                fullWidth
                                            >
                                                {
                                                    typeAbsences && typeAbsences.map((typeAbsence, i) => {
                                                        return <MenuItem key={i} value={typeAbsence.id}>{typeAbsence.description}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE
                                            ("tipoDeDescuento")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="type_discount"
                                                variant="standard"
                                                value={values.type_discount}
                                                disabled={disableTypeDesc}
                                                onChange={(event) => { 
                                                setFieldValue("type_discount", event.target.value);
                                                }}
                                                error={ errors.type_discount && touched.type_discount ? true : false }
                                                fullWidth
                                            >
                                                {
                                                    typeDiscounts && typeDiscounts.map((typeDiscount, i) => {
                                                        return <MenuItem key={i} value={typeDiscount.id}>{typeDiscount.description}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaInicio")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="date_registration"
                                                variant="standard"
                                                fullWidth
                                                name="date_registration"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.date_registration}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.date_registration && touched.date_registration ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaFin")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="date_end"
                                                variant="standard"
                                                fullWidth
                                                name="date_end"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.date_end}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.date_end && touched.date_end ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("observacion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observation"
                                            variant="standard"
                                            type="text" 
                                            name="observation"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.observation}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("observacion")}
                                            error={errors.observation && touched.observation ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(absence && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}