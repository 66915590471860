import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Props } from './CM.type';
import { cmService } from '@/service/services/CM.service';
import ___PHRASE from '@/service/translate/translateService';
import { ModalCM } from '@/components/Modal/ModalCM';
import { ModalCMDelete } from '@/components/Modal/ModalCMDelete';


export const CMView: React.FC<Props> = (props: any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [cm, setCM] = useState<any>([]);
    const [showCMModal, setShowCMModal] = useState<boolean>(false);
    const [showCMDeleteModal, setShowCMDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [cmSelected, setCMSelected] = useState<any>('');

    const headerCM = [
        { name: 'year', label: "año", filter: false, Chip: false },
        { name: 'C1', label: "C1", filter: false, Chip: true },
        { name: 'C2', label: "C2", filter: false, Chip: true },
        { name: 'C3', label: "C3", filter: false, Chip: true },
        { name: 'C4', label: "C4", filter: false, Chip: true },
        { name: 'C5', label: "C5", filter: false, Chip: true },
        { name: 'C6', label: "C6", filter: false, Chip: true },
        { name: 'C7', label: "C7", filter: false, Chip: true },
        { name: 'C8', label: "C8", filter: false, Chip: true },
        { name: 'C9', label: "C9", filter: false, Chip: true },
        { name: 'C10', label: "C10", filter: false, Chip: true },
        { name: 'C11', label: "C11", filter: false, Chip: true },
        { name: 'C12', label: "C12", filter: false, Chip: true },
        { name: 'additional', label: "adicional", filter: false, Chip: false },
    ]

    useEffect(() => {
        getCM();
    }, []);


    const getCM = async () => {
        setLoading(true);
        const response = await cmService.getCM().then((data) => {
            setCM(data.data)
            setCMSelected(data.data[0])
        })
        setLoading(false);
    }

    function RecuperarData(value){
        console.log(value);
        setCMSelected(value);
    }

    const searchData = async (value) => {
        // const response = await companiesService.getCompanies(value);
        // if (response.data) {
        //     setCompanies(response.data || [])
        // } else {
        //     console.log('error')
        // }
    }

    return (
        <Protected>
            {loading && <Loading/>}

            <Container>
                <TableDataV2
                title={"Variables de CM"}
                data={cm || []}
                header={headerCM}
                actionSelect={setActionSelected}
                setModalSave={setShowCMModal}
                setModalDelete={setShowCMDeleteModal}
                btn_disabled={false}
                btn_delete_disable={false}
                status_action
                // textSearch={"buscar por año"}
                // searchData={searchData}
                RecuperarData={RecuperarData}
                />
            </Container>

            {
                showCMModal && (
                <ModalCM
                    open={showCMModal}
                    closeModal={()=>{setShowCMModal(false)}}
                    actionSelected={actionSelected}
                    onListCM={getCM}
                    cm={cmSelected}
                    clearState={()=>{setCMSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalCMDelete
                    open={showCMDeleteModal}
                    closeModal={()=>{setShowCMDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListCM={getCM}
                    cm={cmSelected}
                    clearState={()=>{setCMSelected('')}}
                />
                )
            }
        </Protected>
    )
};