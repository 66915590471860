import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const IntegrationdiviceRepository = {

    getDivicesByCompany: async(idCompany:any): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/integrationDivice?idCompany=${idCompany}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getEmployeeRemyZkteco: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/integrationDivice/employee`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getAssitenceRemyZktecoByEmployee: async(idemployee, start_date, end_date): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/integrationDivice/attendanceByEmployee?idemployee=${idemployee}&start_date=${start_date}&end_date=${end_date}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createDivice: async (dataDivice) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/integrationDivice`, dataDivice);
        const {data, error, message} = res;
        return { data, error, message };
    },

    syncEmployee: async () : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/integrationDivice/sync-employee`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editDivice: async (dataDivice, idDivice: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/integrationDivice/${idDivice}`, dataDivice);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteDivice: async(idDivice:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/integrationDivice/${idDivice}`)
        return res;
    },

    createAssists: async (dataAssists) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/integrationDivice/createAssists`, {
            idemployee: dataAssists.idemployee,
            date: dataAssists.date,
            start_time: dataAssists.start_time,
            start_refreshment: dataAssists.start_refreshment,
            end_refreshment: dataAssists.end_refreshment,
            end_time: dataAssists.end_time,
            observations: dataAssists.observations,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createAssistMasive: async (dataAssists) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/integrationDivice/createAssists-massive`, {
            idemployees: dataAssists.idemployees,
            start_date: dataAssists.start_date,
            end_date: dataAssists.end_date,
            start_time: dataAssists.start_time,
            start_refreshment: dataAssists.start_refreshment,
            end_refreshment: dataAssists.end_refreshment,
            end_time: dataAssists.end_time,
            observations: dataAssists.observations,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    getReportAbsenceDelay: async(start_date:any, end_date:any, idEmployee:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/integrationDivice/reportAssistance?start_date=${start_date}&end_date=${end_date}&idemployee=${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
}   