import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
// import Scrollbar from '../Scrollbar/Scrollbar';
import NavSection from '../Header/NavSection';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROLE_ADMIN, ROLE_SUPERVISOR } from '@/toolbox/constants/role-type';
import { ROLE_SUPER_ADMIN } from '@/toolbox/defaults/static-roles';
import { HeaderView } from '../Header';
import ___PHRASE from '@/service/translate/translateService';
import { Height } from '@mui/icons-material';

// import navConfig from './config';
// import navSubConfigdashboard from './sub-config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 350;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 3,
  backgroundColor:'white',
  border: '3px solid #516E80'
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg', '');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const data_user = readLocalStorage(KEY_USER_DATA);
  const account = {
    photoURL: '#',
    displayName: data_user?.user?.user_name,
    role: data_user?.user?.role,
    firstLetterUser: ((data_user?.user?.user_name)[0]),
  }
  const renderContent = (
     <>
      <HeaderView />
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        {/* logo de la empresa */}
      </Box>

      <Box sx={{ mb: 5, mx: 2.5}}>
        <Link underline="none">
          <StyledAccount>

            <Avatar src={account.photoURL} alt={`${(account.firstLetterUser && (account.firstLetterUser).toUpperCase()) || 'photoURL'}`} />

            <Box sx={{ ml: 2 }}>
              <Typography variant="body2" sx={{ color: '#516E80', fontWeight:'bolder'}}>
                {___PHRASE((account.role).toLowerCase())}
              </Typography>
              <Box sx={{ bgcolor:'#9EC189', px:1, borderRadius:5}}>
              <Typography variant="body2" sx={{ color: 'white', fontWeight:'bolder', fontSize:12}}>
                {___PHRASE((account.displayName).toLowerCase())}
              </Typography>
              </Box>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection />

      <Box sx={{ flexGrow: 1 }} />
     </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
