import { Loading } from "@/components/common/Loading";
import { TableDataV2 } from "@/components/common/Tablev2";
import { Protected } from "@/components/layout/Protected";
import { ModalRequest } from "@/components/Modal/ModalRequest";
import { PeriodService } from "@/service/services/Period.service";
import { RequestService } from "@/service/services/Request.service";
import ___PHRASE from "@/service/translate/translateService";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROUTE_ABSENCES, ROUTE_ARREARS, ROUTE_ASSISTANCES, ROUTE_LIQUIDATION_SALARY } from "@/toolbox/constants/route-map";
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Box, Button, Container, Grid, MenuItem, Select, Snackbar, styled, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const Div = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'rgba(102, 102, 102, 0.4)',
}));

export const MyRequestView: React.FC<any> = (props: any): JSX.Element => {
   const history = useHistory();
   let location = useLocation();
   const dataprops: any = location?.state;
   const yearSend: any = dataprops?.year;
   const monthSend: any = dataprops?.month;

   const [years, setYears] = useState<any>([]);
   const [periods, setPeriods] = useState<any>([]);
   const [yearSelected, setYearSelected] = useState<any>(null);
   const [loading, setLoading] = useState<boolean>(false);
   const [monthSelected, setMonthSelected] = useState<any>(null);
   const userData = readLocalStorage(KEY_USER_DATA);
   const name_role = userData?.user?.role;
   const id_detail_user = userData?.user?.id;

   const [openModal, setOpenModal] = useState<boolean>(false)
   const [myRequest, setMyRequest] = useState<any>(null || []);
   const [requestSelected, setRequestSelected] = useState<any>(null)
   const [openTable, setOpenTable] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });
  
  const cabeceraworker = [
   { name: 'requestTypeName', label: ___PHRASE("tipoDeSolicitud") },
   { name: 'date_creation', label: ___PHRASE("fechaDeSolicitud") },
   { name: 'detail', label: ___PHRASE("motivo")},
   { name: 'from_date_request', label:___PHRASE("fechaInicio")},
   { name: 'to_date_request', label:___PHRASE("fechaFin")},
   { name: 'observations', label:___PHRASE("observacionDelAdmin")},
   { name: 'name_status_review', label:___PHRASE("estado")}
  ]

  const cabeceraAdmin = [
   { name: 'requestTypeName', label: ___PHRASE("tipoDeSolicitud") },
   { name: 'n_document_employee', label: ___PHRASE("rutTrabajador") },
   { name: 'name_employee', label: ___PHRASE("nombre") },
   { name: 'surname_employee', label: ___PHRASE("apellidoPaterno") },
   { name: 'second_surname_employee', label: ___PHRASE("apellidoMaterno") },
   { name: 'date_creation', label: ___PHRASE("fechaDeSolicitud") },
   { name: 'detail', label: ___PHRASE("detalle")},
   { name: 'observations', label:___PHRASE("observacion")},
   { name: 'name_status_review', label:___PHRASE("estado")}
  ]

   useEffect(() => {
      if (periods.length == 0) {
         getPeriods();
      }
   }, []);

   useEffect(()=> {
      if(dataprops){
       getRequest()
      }
   },[dataprops]);

   const getRequest = async () => {
      setLoading(true);
      setYearSelected(yearSend)
      setMonthSelected(monthSend)

      if(name_role == ROLE_ADMIN){
         const respMyRequest = await RequestService.getRequestForAdmin(yearSend, monthSend);
         if((respMyRequest?.data).length == 0){
          setOpenTable(true);
          setLoading(false);
          return (
             setSnackBarConfig(prev => ({
                ...prev, open: true,
                severity: 'error',
                message: ___PHRASE("noSeEncontraronSolicitudes")
             }
             ))
          );
         }
         setMyRequest(respMyRequest?.data || []);
      }else{
         const respMyRequest = await RequestService.getRequestWorker(yearSend, monthSend, id_detail_user);
         if((respMyRequest?.data).length == 0){
          setOpenTable(true);
          setLoading(false);
          return (
             setSnackBarConfig(prev => ({
                ...prev, open: true,
                severity: 'error',
                message: ___PHRASE("noSeEncontraronSolicitudes")
             }
             ))
          );
         }
         setMyRequest(respMyRequest?.data || []);
      }
      setOpenTable(true);
      setLoading(false);
   }

   const getPeriods = async () => {
      setLoading(true);
      const resp: any = await PeriodService.getPeriods();
      const newData = [];
      const arrayYears = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            id: item.id,
            month: item.month,
            year: item.year,
            alias_name: item.month + '-' + item.year,
         })
         const found = arrayYears.find(element => element == item.year);
         if (!found) { arrayYears.push(item.year); }

      });
      setYears(arrayYears);
      setPeriods(newData || []);

      if(dataprops){

      }else{
         setYearSelected(moment().format('YYYY'))
         setMonthSelected(moment().format('MM'))
         await onSubmit(moment().format('YYYY'),moment().format('MM') );
      }
      setLoading(false);
   }

   const onSubmit = async (yearSelected,monthSelected) => {
      setMyRequest([]);
      setLoading(true);
      if (!monthSelected || !yearSelected) {
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'warning',
               message: `${___PHRASE("debeLlenarTodosLosCampos")}`
            }
            ))
         );
      }

     if(name_role == ROLE_ADMIN){
        const respMyRequest = await RequestService.getRequestForAdmin(yearSelected,monthSelected);
        if((respMyRequest?.data).length == 0){
         setOpenTable(true);
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'error',
               message: ___PHRASE("noSeEncontraronSolicitudes")
            }
            ))
         );
        }
        setMyRequest(respMyRequest?.data || []);
     }else{
        const respMyRequest = await RequestService.getRequestWorker(yearSelected,monthSelected, id_detail_user);
        console.log(respMyRequest)
        if((respMyRequest?.data) && (respMyRequest?.data).length == 0){
         setOpenTable(true);
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'error',
               message: ___PHRASE("noSeEncontraronSolicitudes")
            }
            ))
         );
        }
        setMyRequest(respMyRequest?.data || []);
     }
      setOpenTable(true);
      setLoading(false);
   }

   const requestCreate = async (data) =>{
      const dataRequest = {
         detail: data.detail,
         from_date_request: data.from_date_request,
         to_date_request: data.to_date_request,
         idrequests_type: data.idrequests_type,
         idremuneration: data.idremuneration,
      }
      const res = await RequestService.createRequestWorker(dataRequest);
      return res;
   }

   const goRedirectRequest = async(request) => {
      if(request && request.status_review == '0') return (
         setSnackBarConfig(prev => ({
            ...prev, open: true,
            severity: 'warning',
            message: `${___PHRASE("lasolicitudaunnohasidorespondida")}`
         }))
      );
      if(request && request.status_review == '2') return (
         setSnackBarConfig(prev => ({
            ...prev, open: true,
            severity: 'info',
            message: `${___PHRASE("lasolicitudhasidorechazada")}`
         }))
      );
      setLoading(true);
      switch(request.requestTypeName){
         case 'Justificación de inasistencias' :
               history.push(ROUTE_ASSISTANCES,  {
                  id: request.idemployee, 
                  n_document: request.n_document_employee, 
                  name: request.name_employee,
                  name_complete: request.name_employee+' '+request.surname_employee+' '+request.second_surname_employee,
                  second_surname: request.second_surname_employee,
                  surname: request.surname_employee,
                  idTypeRequest: request.idrequests_type,
                  requestTypeName:request.requestTypeName,
                  from_date_request: request.from_date_request,
                  to_date_request: request.to_date_request,
                  detail: request.detail,
               });
               break;
         case 'Atrasos' :
               history.push(ROUTE_ARREARS,  {
                  id: request.idemployee, 
                  n_document: request.n_document_employee, 
                  name: request.name_employee,
                  name_complete: request.name_employee+' '+request.surname_employee+' '+request.second_surname_employee,
                  second_surname: request.second_surname_employee,
                  surname: request.surname_employee,
                  idTypeRequest: request.idrequests_type,
                  requestTypeName:request.requestTypeName,
                  from_date_request: request.from_date_request,
                  to_date_request: request.to_date_request,
                  detail: request.detail,
               });
               break;
         case 'Vacaciones' :
               history.push(ROUTE_ABSENCES,  {
                  id: request.idemployee, 
                  n_document: request.n_document_employee, 
                  name: request.name_employee,
                  name_complete: request.name_employee+' '+request.surname_employee+' '+request.second_surname_employee,
                  second_surname: request.second_surname_employee,
                  surname: request.surname_employee,
                  idTypeRequest: request.idrequests_type,
                  requestTypeName:request.requestTypeName,
                  from_date_request: request.from_date_request,
                  to_date_request: request.to_date_request,
                  detail: request.detail,
               });
               break;
         case 'Licencia' :
               history.push(ROUTE_ABSENCES,  {
                  id: request.idemployee, 
                  n_document: request.n_document_employee, 
                  name: request.name_employee,
                  name_complete: request.name_employee+' '+request.surname_employee+' '+request.second_surname_employee,
                  second_surname: request.second_surname_employee,
                  surname: request.surname_employee,
                  idTypeRequest: request.idrequests_type,
                  requestTypeName:request.requestTypeName,
                  from_date_request: request.from_date_request,
                  to_date_request: request.to_date_request,
                  detail: request.detail,
               });
               break;  
         case 'Liquidación':
               const requestLiquidation = await RequestService.getRequestLiquidation(request?.id)
               history.push(ROUTE_LIQUIDATION_SALARY, {
                  id: request.idemployee, 
                  n_document: request.n_document_employee, 
                  name: request.name_employee,
                  name_complete: request.name_employee+' '+request.surname_employee+' '+request.second_surname_employee,
                  second_surname: request.second_surname_employee,
                  surname: request.surname_employee,
                  idTypeRequest: request.idrequests_type,
                  requestTypeName:request.requestTypeName,
                  from_date_request: request.from_date_request,
                  to_date_request: request.to_date_request,
                  detail: request.detail,
                  idRemuneration:requestLiquidation.data.idremuneration , 
                  idPeriod: requestLiquidation.data.idperiod,
               });
               break;
         default:
               console.log('hsd');
      }
      setLoading(false);
    }

   const RecuperarData = (data) => {
      setRequestSelected(data)
      if(data.action == "redirect"){
         goRedirectRequest(data)
      }
    
   }
   
   return (
      <Protected>
         {loading && <Loading />}
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>

         <Container>

            <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
               {name_role == ROLE_ADMIN  ? ___PHRASE("solicitudesDeEmpleados"):___PHRASE("missolicitudes")}
            </Typography>

            <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
               <Grid xs={3} display='flex' alignItems="center" 
                  sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                  <Grid item xs={4}>
                        <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                        <Select
                           id="id_education_level"
                           variant="standard"
                           value={yearSelected}
                           onChange={(event) => {
                           setYearSelected(event.target.value);
                           }}
                           fullWidth
                        >
                           {
                           years.map((year, i) => {
                              return <MenuItem key={i} value={year}>{year}</MenuItem>;
                           })
                           }
                        </Select>
                  </Grid>
               </Grid>
               <Grid xs={3} display='flex' alignItems="center" 
                  sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                  <Grid item xs={4}>
                        <Typography variant="body1"><b>{___PHRASE("mes")}:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                        <Select
                           id="id_education_level"
                           variant="standard"
                           value={monthSelected}
                           onChange={(event) => {setMonthSelected(event.target.value)}}
                           fullWidth
                        >
                          <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                          <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                          <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                          <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                          <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                          <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                          <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                          <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                          <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                          <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                          <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                          <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                        </Select>
                  </Grid>
               </Grid>

               <Grid xs={3} display='flex'>
                  <Button onClick={() => { onSubmit(yearSelected, monthSelected) }} type={'submit'} size="small" color={"primary"} variant="contained" >
                     {name_role == ROLE_ADMIN  ? ___PHRASE("verSolicitudes"):___PHRASE("verMisSolicitudes")}
                  </Button>
               </Grid>
            </Grid>

            {
               loading && (
                  <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                        <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                  </Box>
               )
            }

            {
               (openTable && !loading) && 
                  <TableDataV2
                     data={myRequest || []}
                     header={name_role == ROLE_ADMIN? cabeceraAdmin: cabeceraworker}
                     status_action={name_role == ROLE_ADMIN? true :false}
                     btn_disabled={name_role == ROLE_ADMIN? true :false}
                     btn_edit_disable={name_role == ROLE_ADMIN? false:true}
                     btn_delete_disable={true}
                     btn_redirect={name_role == ROLE_ADMIN? true :false}
                     setModalSave={setOpenModal}
                     actionSelect={setActionSelected}
                     RecuperarData={RecuperarData}
                  />
            }
         

         <ModalRequest
            open={openModal}
            closeModal={()=>setOpenModal(false)}
            requestCreate={requestCreate}
            actionSelected={actionSelected}
            request={actionSelected == 'edit' && requestSelected}
            onList={()=>onSubmit(yearSelected, monthSelected)}
            clearState={()=>{setRequestSelected('')}}
            periods={periods}
         />
         </Container>
      </Protected>
   )
}