import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalAbsenceReport.type";
import moment from "moment";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";

export const ModalAbsenceReport: React.FC<Props> = (props:any): JSX.Element => {
    
    const typeAbsence = [
      {
        id: 1,
        description: ___PHRASE("inasistencia"),
        status: "1"
      },
      {
        id: 2,
        description: ___PHRASE("licencia"),
        status: "1"
      }
    ];

    const { open, closeModal, dataInasis, worker } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [typeAbsences, setTypeAbsences] = useState<any>(typeAbsence);
    const [typeDiscounts, setTypeDiscounts] = useState<any>([]);
    const [disableTypeDesc, setDisabledTypeDesc] = useState<boolean>(false);
    const [dayOptions, setDayOptions] = useState(dataInasis.missing_date);
    const [daySelected, setDaySelected] = useState(dataInasis.missing_date);

    const [data, setData] = useState({
        idemployee: worker?.idemployee ,
        idtypeabsence: 1,
        type_discount: 0,
        observation: 'A partir del Reporte',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(()=> {
        getComboAbsences()
    },[]);

    const getComboAbsences = async() => {
        setLoading(true);
        const resp:any = await AssistanceService.getComboAbsences();
        setTypeDiscounts(resp.data.typeDiscount || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
       return errors;
    }

    const onSubmit = async(values, {resetForm})  => {
        setLoading(true)
        
        let date_registration = daySelected.length > 0 && moment(daySelected[0].split(" - ")[0]).format("YYYY-MM-DD");
        let date_end = daySelected.length > 0 && moment(daySelected[daySelected.length-1].split(" - ")[0]).format("YYYY-MM-DD");

        const formData = {
            idemployee: worker.id,
            idtypeabsence : values.idtypeabsence,
            type_discount : values.type_discount,
            date_registration : date_registration,
            date_end : date_end,
            observation : values.observation,
        }
        
        await AssistanceService.createAbsence(formData).then((data) => {
            if(data.data.status == true){
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'success', 
                    message: `${data.data.message}`}
                ))
                setTimeout(function(){
                    closeModal && closeModal();
                }, 1000);
            }else{
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'error', 
                    message: `${data.data.message}`}
                ))
            }
        })

        setLoading(false);
    }

    // const onSubmit = async(values, {resetForm})  => {
    //     setLoading(true)
    //     daySelected.map(async(day)=> {
    //         const formData = {
    //             idemployee: worker.idemployee,
    //             idtypeabsence : values.idtypeabsence,
    //             type_discount : values.type_discount,
    //             date_registration : day.split(" - ")[0],
    //             total_absence :1,
    //             observation : values.observation,
    //         }
           
    //             await AssistanceService.createAbsence(formData).then((data) => {
    //                 closeModal && closeModal();
    //             })
    //     })
    //     setLoading(false);
    // }
    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={___PHRASE("agregar")+' '+___PHRASE("inasistencia")}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("tipoDeInasistencia")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idtypeabsence"
                                                variant="standard"
                                                value={values.idtypeabsence}
                                                onChange={(event) => { 
                                                setFieldValue("idtypeabsence", event.target.value);
                                                setDisabledTypeDesc(false)
                                                if(event.target.value == typeAbsence[1].id){
                                                    setFieldValue("type_discount", typeDiscounts[0].id);
                                                    setDisabledTypeDesc(true)
                                                }
                                               
                                                }}
                                                error={ errors.idtypeabsence && touched.idtypeabsence ? true : false }
                                                fullWidth
                                                inputProps={{ readOnly: true }}
                                            >
                                                {
                                                    typeAbsences && typeAbsences.map((typeAbsence, i) => {
                                                        return <MenuItem key={i} value={typeAbsence.id}>{typeAbsence.description}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("tipoDeDescuento")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="type_discount"
                                                variant="standard"
                                                value={values.type_discount}
                                                onChange={(event) => { 
                                                setFieldValue("type_discount", event.target.value);
                                                }}
                                                error={ errors.type_discount && touched.type_discount ? true : false }
                                                fullWidth
                                                inputProps={{ readOnly: true }}
                                            >
                                                {
                                                    typeDiscounts && typeDiscounts.map((typeDiscount, i) => {
                                                        return <MenuItem key={i} value={typeDiscount.id}>{typeDiscount.description}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("inasistencias")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={daySelected}
                                                options={dayOptions || []}
                                                getOptionLabel={(option) => option|| ""}
                                                fullWidth
                                                multiple
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { setDaySelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}