import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalWorkerValidation.type";
import { WorkerValidationService } from "@/service/services/WorkerValidation.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalWorkerValidation: React.FC<Props> = (props: any): JSX.Element => {

    const { open, closeModal, actionSelected, validation, onListValidations, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [faceImage, setFaceImage] = useState<any>(null);
    const [docImage, setDocImage] = useState<any>(null);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: validation ? validation.id : '',
        face_img: validation ? validation.face_img : '',
        doc_img: validation ? validation.doc_img : '',
    });

    const validateForm = (values) => {

    }

    const setNameFile = (event, type) => {
        const files = event.target && event.target.files || [];
        const currentCv = files[0];

        if (currentCv) {
            console.log(currentCv)

            const nameAsArray = currentCv.name.split(".") || [];
            const extension = nameAsArray.slice(-1);
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length - 1);
            const nameFile = nameWithoutExtension.join(".") || "";

            if (extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' ||
                extension == 'JPEG' || extension == 'png' || extension == 'PNG') {
                if (type == 'face') setFaceImage(currentCv);
                if (type == 'doc') setDocImage(currentCv);

                return currentCv;
            } else {
                event.target.value = "";
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'warning',
                        message: ___PHRASE("elFormatoDeImagenNoEsCorrecta")
                    }
                    ))
                );
            }
        }
        return "";
    };

    const onSubmit = async (values, { resetForm }) => {

        const formData = new FormData();
        formData.append("id", values.id);

        if (faceImage) formData.append("face_img", values.face_img);
        if (docImage) formData.append("doc_img", values.doc_img);

        setLoading(true);
        if (validation) {
            if ((docImage || faceImage)) {
                await WorkerValidationService.editWorkerValidation(validation.id, formData).then((data) => {
                    if (!data.data.status) {
                        setLoading(false)
                        return (
                            setSnackBarConfig(prev => ({
                                ...prev, open: true,
                                severity: 'warning',
                                message: `${data.data.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                            }
                            ))
                        );
                    }
                    console.log('snack')

                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'success',
                        message: ___PHRASE("registroActualizadoConExito")
                    }
                    ))

                    setTimeout(() => {
                        onListValidations && onListValidations();
                        closeModal && closeModal();
                    }, 1000)
                })
            } else {
                setLoading(false)
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'warning',
                        message: ___PHRASE("debeSubirLaImagenCorrespondiente")
                    }
                    ))
                );
            }
        } else {
            if (!(docImage && faceImage)) {
                setLoading(false)
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'warning',
                        message: ___PHRASE("debeSubirLaImagenCorrespondiente")
                    }
                    ))
                );
            }

            await WorkerValidationService.createWorkerValidation(formData).then((data) => {
                if (!data.data.id) {
                    setLoading(false)
                    return (
                        setSnackBarConfig(prev => ({
                            ...prev, open: true,
                            severity: 'warning',
                            message: `${data.data.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                        }
                        ))
                    );
                }

                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'success',
                    message: ___PHRASE("registroActualizadoConExito")
                }
                ))
                setTimeout(() => {
                    onListValidations && onListValidations();
                    closeModal && closeModal();
                }, 1000)
            })
        }
        setLoading(false);
    }

    const showDocument = (image) => {
        setLoading(true);

        if (image) {
            window.open(image, '_blank');
        }

        setLoading(false);
    }


    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Modal open={open} handleClose={closeModal} handle size="sm">
                <ModalHeader
                    text={((validation && actionSelected == 'edit') ? ___PHRASE("editar") : ___PHRASE("enviar"))+' '+___PHRASE("validacion")}
                    className='positionElements'
                    onCancel={closeModal}
                    clearState={clearState}
                >
                </ModalHeader>
                <ModalBody>
                    <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                        {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container >

                                        {
                                            validation && (<>
                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("imagenActualDelRostro")}:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={2}></Grid>
                                                    <Grid item xs={8} sx={{ display: 'flex' }}>
                                                        <>

                                                            <img
                                                                src={validation.face_img}
                                                                style={{ maxHeight: '100px', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    showDocument(validation.face_img)
                                                                }}
                                                            />

                                                        </>
                                                    </Grid>
                                                </Grid>
                                            </>)
                                        }

                                        {
                                            ((validation && validation.status != '3') &&
                                                (validation && validation.status != '1') ||
                                                (!validation)) && (
                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("imagenDelRostro")}:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Button variant="contained" component="label" fullWidth>
                                                            <input
                                                                id="face_img"
                                                                type="file"
                                                                accept="image/jpeg,image/jpg"
                                                                name="face_img"
                                                                onChange={(event) => {
                                                                    setFieldValue("face_img", setNameFile(event, 'face'));
                                                                }}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }

                                        {
                                            validation && (<>
                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("imagenActualDelDocumentoDeIdentidad")}:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={2}></Grid>
                                                    <Grid item xs={8} sx={{ display: 'flex' }}>
                                                        <>

                                                            <img
                                                                src={validation.doc_img}
                                                                style={{ maxHeight: '100px', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    showDocument(validation.doc_img)
                                                                }}
                                                            />

                                                        </>
                                                    </Grid>
                                                </Grid>
                                            </>)
                                        }

                                        {
                                            ((validation && validation.status != '3') &&
                                                (validation && validation.status != '1') ||
                                                (!validation)) && (
                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("imagenDeDocumentoDeIdentidad")}:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sx={{ justifyContent: 'left', alignItems: 'flex-start' }}>
                                                        <Button variant="contained" component="label" fullWidth>
                                                            <input
                                                                id="doc_img"
                                                                type="file"
                                                                accept="image/jpeg,image/jpg"
                                                                name="doc_img"
                                                                onChange={(event) => {
                                                                    setFieldValue("doc_img", setNameFile(event, 'doc'));
                                                                }}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }


                                    </Grid>
                                    <Divider />
                                    {
                                        (
                                            (validation && validation.status != '3') &&
                                            (validation && validation.status != '1') ||
                                            (!validation)) && (
                                            <ModalFooter
                                                buttonType="submit"
                                                confirmText={(validation) ? ___PHRASE("actualizar") : ___PHRASE("enviar")}
                                                // cancelText="CANCELAR" 
                                                // onCancel={closeModal}
                                                onConfirm={handleSubmit}
                                                clearState={clearState}
                                            />
                                        )
                                    }

                                </form>
                            )
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
}