import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, RouteProps, Switch, Redirect, useLocation } from 'react-router-dom';
import * as Routes from '@constants/route-map';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA, KEY_USER_PERMISSION } from '@toolbox/constants/local-storage';
import {
   ROLE_ADMIN,
   ROLE_SUPER_ADMIN,
   ROLE_TRABAJADOR,
} from '@/toolbox/defaults/static-roles';
import { PrivateRoute, AuthRoute } from './PrivateRoute';
import { HomeView } from '@/views/Home';
import { LoginView } from '@/views/Login';
import { UsersView } from '@/views/Mainteiners/Users';
import { BranchOfficeView } from '@/views/Mainteiners/BranchOffices';
import { CostCenterView } from '@/views/Mainteiners/CostCenters';
import { DeparmentView } from '@/views/Mainteiners/Departaments';
import { ChargeView } from '@/views/Mainteiners/Charges';
import { ProjectView } from '@/views/Mainteiners/Proyects';
import { PhasesView } from '@/views/Recruitment/Phases';
import { RecluitingTeamView } from '@/views/Recruitment/RecluitingTeam';
import { Announcement } from '@/views/Recruitment/Announcement/Announcement';
import { ProfileView } from '@/views/Recruitment/Profiles';
import { ApplicantsView } from '@/views/Recruitment/Applicants';
import { LiquidationMasiveView } from '@/views/Liquidations/LiquidationMasive';
import {PreviewLiquidationMasiveView} from '@/views/Liquidations/LiquidationMasive/PreviewLiquidationMasive';
import { LiquidationProjectView } from '@/views/Liquidations/LiquidationProject';
import { LiquidationSalaryView } from '@/views/Liquidations/LiquidationSalary';
import { StructureLreView } from '@/views/Liquidations/StructureLre';
import { SettlementActive } from '@/views/Liquidations/SettlementActive';
import { ExtraHoursView } from '@/views/NewMonth/ExtraHours';
import { ValueExtraHoursView } from '@/views/Mainteiners/ValueExtraHours';
import { AssistancesView } from '@/views/NewMonth/Assistances';
import { AdvancesView } from '@/views/NewMonth/Advances';
import { InventoryWorkersView } from '@/views/NewMonth/InventoryWorkers';
import { DelayView } from '@/views/NewMonth/Delay';
import { PDFView } from '@/views/PDF';
import { Workers } from '@/views/Mainteiners/Workers';
import { MovementsView } from '@/views/NewMonth/Movements';
import { TemplateMonthlyView } from '@/views/Liquidations/TemplateMonthly';
import { CertificateEmployeeView } from '@/views/Liquidations/TemplateMonthly/PDF';
import { RequestTypeView } from '@/views/Mainteiners/RequestType';
import { MyLiquidationSalaryView } from '@/views/Liquidations/MyLiquidationSalary';
import { DelayAbsenceExtraHourView } from '@/views/Reports/DelayAbsenceExtraHour';
import { ReportLiquidations } from '@/views/Reports/ReportLiquidations';
import { ReportEmployeesView } from '@/views/Reports/ReportEmployees';
import { ReportExpenses } from '@/views/Reports/ReportExpenses';
import { RequestView } from '@/views/Request';
import { BillsView } from '@/views/Bills';
import { MyRequestView } from '@/views/MyRequest';
import { EmployeeExpenseView } from '@/views/NewMonth/EmployeeExpense';
import { AdminExpenseView } from '@/views/NewMonth/AdminExpense';
import { PreviewLiquidationRequestView } from '@/components/Modal/ModalGenerateLiquidation/PreviewLiquitacionRequest';
import { PreviewAmountGeneral } from '@/components/Modal/ModalAmountGeneral/PreviewAmountGeneral';
import { WorkerValidationView } from '@/views/IdentityValidations/WorkerValidation';
import { AdminValidationView } from '@/views/IdentityValidations/AdminValidation';
import { ContractEmployee } from '@/views/Contract/ContractEmployee';
import { CompaniesView } from '@/views/Mainteiners/Companies';
import { TurnView } from '@/views/Mainteiners/Turn';
import { CompanySettingsView } from '@/views/CompanySettings';
import { VariableGlobalView } from '@/views/Mainteiners/VariableGlobal';
import { WorkerContactView } from '@/views/Mainteiners/WorkerContact';
import { AnnexedView } from '@/views/Annexed';
import { NotificationsTypeView } from '@/views/Mainteiners/TypeNotifications';
import { ReminderTypeView } from '@/views/Mainteiners/TypeReminder';
import { IpsAcceptView } from '@/views/NewMonth/IPS/IpsAccept';
import { IpsDeniedView } from '@/views/NewMonth/IPS/IpsDenied';
import { CountryView } from '@/views/Mainteiners/Ubigeo/Country';
import { DepartamentView } from '@/views/Mainteiners/Ubigeo/Departament';
import { DistrictView } from '@/views/Mainteiners/Ubigeo/District';
import { ProvinceView } from '@/views/Mainteiners/Ubigeo/Province';
import { TimeZoneView } from '@/views/Mainteiners/TimeZone';
import { BranchOfficeTypesView } from '@/views/Mainteiners/BranchOfficeTypes';
import { StructureMaintenerView } from '@/views/Mainteiners/StructureManteiner';
import { PoliticsView } from '@/views/Politics';
import { ReportLiquidationByProject } from '@/views/Reports/ReportLiquidationByProject';
import { ProductsView } from '@/views/Mainteiners/Products';
import { ReportProducts } from '@/views/Reports/ReportProducts';
import { GroupCompanyView } from '@/views/Mainteiners/GroupCompanies';
import { GroupCompaniesAllView } from '@/views/Mainteiners/GroupCompaniesAll';
import { ReportgGroupCompany } from '@/views/Reports/ReportGroupCompany';
import { ContractEditView } from '@/views/CustomizeContract/ContractEdit';
import { ContractDocumentsView } from '@/views/CustomizeContract/ContractDocuments';
import { CustomizeContract } from '@/views/Mainteiners/Prueba';
import { NewContractCreateView } from '@/views/CustomizeContract/NewContractCreate';
import { AssistanceWorkerView } from '@/views/AssistanceWorker';
import { AbsenceView } from '@/views/NewMonth/Absence';
import { ScheduleView } from '@/views/Mainteiners/ScheduleCompany';
import { ScheduleWorkerView } from '@/views/NewMonth/ScheduleWorker';
import { ViewsControlView } from '@/views/Mainteiners/ViewsControl';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { ProfileSessionView } from '@/views/Mainteiners/ProfileSession';
import { PageNoFoundView } from '@/views/PageNoFound/PageNoFound';
import { UnauthorizedPageView } from '@/views/UnauthorizedPage/UnauthorizedPage';
import { ViewsView } from '@/views/Mainteiners/Views';
import { TypesExpenseView } from '@/views/Mainteiners/TypesExpense';
import { NotificationHeader } from '@/views/Notifications/Notification';


import { LoanManteiner } from '@/views/Loan/LoanManteiner';
import { LoanEmployee } from '@/views/Loan/LoanEmployee';
import { CMView } from '@/views/Mainteiners/CM';
import { AFPView } from '@/views/AFP';


const AllRoutes: React.FC = () => {

   const dataUser = readLocalStorage(KEY_USER_DATA);
   const permissions = readLocalStorage(KEY_USER_PERMISSION);


   const moduleSuperAdmin = [

      //MODULO MAESTROS - SUPERADMIN
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROFILE_SESSION} component={ProfileSessionView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_GROUP_COMPANIES_ALL} component={GroupCompaniesAllView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_VARIABLE_GLOBAL} component={VariableGlobalView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_STRUCTURE_MANTEINER} component={StructureMaintenerView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_TYPE_NOTIFICATIONS} component={NotificationsTypeView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_TYPE_REMINDER} component={ReminderTypeView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REQUEST_TYPE} component={RequestTypeView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_TIME_ZONE} component={TimeZoneView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_BRANCH_OFFICE_TYPE} component={BranchOfficeTypesView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_COMPANIES} component={CompaniesView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_USER} component={UsersView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_POLITICS_MASTER} component={PoliticsView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_VIEW_CONTROL} component={ViewsControlView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_MAINTENER_VIEW} component={ViewsView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_CM} component={CMView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_AFP} component={AFPView} />,
      //MODULE UBIGEO - SUPERADMIN
      <PrivateRoute key={5} exact path={Routes.ROUTE_COUNTRIES} component={CountryView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_DEPARTAMENT} component={DepartamentView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROVINCE} component={ProvinceView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_DISTRICT} component={DistrictView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_NOTIFY} component={NotificationHeader} />,
   ]

   const moduleAdmin = [
      //MODULO MAESTROS - ADMIN
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROFILE_SESSION} component={ProfileSessionView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_GROUP_COMPANIES} component={GroupCompanyView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_WORKER} component={Workers} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_ANNEXED} component={AnnexedView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_WORKER_CONTACT} component={WorkerContactView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_BRANCH_OFFICE} component={BranchOfficeView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROJECT} component={ProjectView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_COST_CENTER} component={CostCenterView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_DEPARMENT} component={DeparmentView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_CHARGES} component={ChargeView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_TURNS} component={TurnView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_PRODUCTS} component={ProductsView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_DOCUMENT_CONTRACT} component={ContractDocumentsView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_SCHEDULE} component={ScheduleView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_VALUE_EXTRA_HOURS} component={ValueExtraHoursView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_COMPANIES} component={CompaniesView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_USER} component={UsersView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_POLITICS_MASTER} component={PoliticsView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_VIEW_CONTROL} component={ViewsControlView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_TYPES_EXPENSE} component={TypesExpenseView} />,
      //MODULO NOVEDADES DEL MES - ADMIN
      <PrivateRoute key={5} exact path={Routes.ROUTE_ASSISTANCES} component={AssistancesView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_ABSENCES} component={AbsenceView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_EXTRA_HOURS} component={ExtraHoursView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_ARREARS} component={DelayView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_MOVEMENTS} component={MovementsView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_ADMIN_VALIDATIONS} component={AdminValidationView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_INVENTORY_WORKERS} component={InventoryWorkersView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_EXPENSE_REQUESTS} component={AdminExpenseView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_IP_ACCEPT} component={IpsAcceptView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_IP_DENIED} component={IpsDeniedView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_SCHEDULE_EMPLOYEE} component={ScheduleWorkerView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_ADVANCES} component={AdvancesView} />,
      <PrivateRoute key={6} exact path={Routes.ROUTE_MY_REQUEST} component={MyRequestView} />,
      //MODULO LIQUIDACIONES - ADMIN 
      <PrivateRoute key={5} exact path={Routes.ROUTE_ACTIVE_LIQUIDATION_WORKERS} component={SettlementActive} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIQUIDATION_SALARY} component={LiquidationSalaryView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIQUIDATION_MASIVE} component={LiquidationMasiveView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIQUIDATION_MASIVE_PREVIEW} component={PreviewLiquidationMasiveView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIQUIDATION_PROJECT} component={LiquidationProjectView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_CERTIFICATE_EMPLOYEE_PDF} component={CertificateEmployeeView} />,
      // CUSTOM VIEW PDF
      <PrivateRoute key={5} exact path={Routes.ROUTE_PDF_LIQUIDATION_SALARY} component={PreviewLiquidationRequestView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_PDF_AMOUNT_GENERAL} component={PreviewAmountGeneral} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_CUSTOM_VISUALIZER_PDF} component={PDFView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_CUSTOMIZE_CONTRACT} component={ContractEditView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_CREATE_CONTRACT_DOCUMENT} component={NewContractCreateView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_STRUCTURE_LRE} component={StructureLreView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_TEMPLATE_LIQUIDATION_MONTHLY} component={TemplateMonthlyView} />,
      //MODULO RECLUTAMIENTO - ADMIN 
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROFILES} component={ProfileView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_RECRUITING_TEAM} component={RecluitingTeamView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_PHASES} component={PhasesView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_ANNOUNCEMENT} component={Announcement} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_APPLICANTS} component={ApplicantsView} />,
      //MODULO REPORTES - ADMIN 
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_EMPLOYEES} component={ReportEmployeesView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_MULTI_COMPANY} component={ReportgGroupCompany} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_LIQUIDATIONS} component={ReportLiquidations} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_LIQUIDATION_PROJECTS} component={ReportLiquidationByProject} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_REQUEST} component={RequestView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_WORKER_DELAY} component={DelayAbsenceExtraHourView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_EXPENS} component={ReportExpenses} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_INVENTORY_PRODUCTS} component={ReportProducts} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_BILLS} component={BillsView} />,
      //MODULO POLITICAS Y PRIVACIDAD - ADMIN - EMPLOYEE 
      <PrivateRoute key={5} exact path={Routes.ROUTE_POLITICS} component={PoliticsView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_COMPANY_SETTING} component={CompanySettingsView} />,
      //MODULO CONTRATO - EMPLOYEE
      <PrivateRoute key={5} exact path={Routes.ROUTE_CONTRACT_EMPLOYEE} component={ContractEmployee} />,
      //MODULO NOTIFICACION - ADMIN
      <PrivateRoute key={5} exact path={Routes.ROUTE_NOTIFY} component={NotificationHeader} />,
   ]

   const moduleEmployee = [

      //MODULO NOVEDADES DEL MES - EMPLOYEE
      <PrivateRoute key={5} exact path={Routes.ROUTE_EMPLOYEE_EXPENSE} component={EmployeeExpenseView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_ADVANCES} component={AdvancesView} />,
      <PrivateRoute key={6} exact path={Routes.ROUTE_MY_REQUEST} component={MyRequestView} />,
      //MODULO LIQUIDACIONES - EMPLOYEE
      <PrivateRoute key={5} exact path={Routes.ROUTE_MY_LIQUIDATION_SALARY} component={MyLiquidationSalaryView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_STRUCTURE_LRE} component={StructureLreView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_TEMPLATE_LIQUIDATION_MONTHLY} component={TemplateMonthlyView} />,
      //MODULO POLITICAS Y PRIVACIDAD - ADMIN - EMPLOYEE 
      <PrivateRoute key={5} exact path={Routes.ROUTE_POLITICS} component={PoliticsView} />,
      //MODULO VALIDACION DE IDENTIDAD - EMPLOYEE 
      <PrivateRoute key={5} exact path={Routes.ROUTE_MY_VALIDATIONS} component={WorkerValidationView} />,
      //MODULO CONTRATO - EMPLOYEE
      <PrivateRoute key={5} exact path={Routes.ROUTE_CONTRACT_EMPLOYEE} component={ContractEmployee} />,
      //MODULO CONTROL ASISTENCIA -  EMPLOYEE
      <PrivateRoute key={5} exact path={Routes.ROUTE_ASSISTANCES_WORKER} component={AssistanceWorkerView} />,
      //VISTA DE PDF DE PLANILLA DE REMUNERACIONES
      <PrivateRoute key={5} exact path={Routes.ROUTE_CERTIFICATE_EMPLOYEE_PDF} component={CertificateEmployeeView} />,
      //MODULO NOTIFICACION - ADMIN
      <PrivateRoute key={5} exact path={Routes.ROUTE_NOTIFY} component={NotificationHeader} />,
   ]

   const moduleViewsGeneral = [   
      <PrivateRoute key={5} exact path={Routes.ROUTE_HOME} component={dataUser && dataUser?.user.idrole === 5 ? AssistanceWorkerView : CompaniesView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_PAGE_NOT_FOUND} component={PageNoFoundView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_UNAUTHORIZED} component={UnauthorizedPageView} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROFILE_SESSION} component={ProfileSessionView} />,
      <PrivateRoute key={6} exact path={Routes.ROUTE_LOAN} component={LoanManteiner} />,
      <PrivateRoute key={6} exact path={Routes.ROUTE_MY_LOAN} component={LoanEmployee} />,
   ]



   const routes = useMemo(() => {
      let role: string = 'prueba';

      if (!!dataUser) {
         if (!!dataUser.user) {
            role = dataUser.user.role;
         }
      }

      const validateRoutes = () => {
         const AllRoutes = moduleSuperAdmin.concat(moduleAdmin, moduleEmployee, moduleViewsGeneral)
         let pathname = window.location.pathname;

         if(AllRoutes.find(item => item.props.path == pathname)){
            return Routes.ROUTE_UNAUTHORIZED;
         }else{
            return Routes.ROUTE_PAGE_NOT_FOUND;
         }
         
      }

      const arrayPermissions = (arrayModules) => {    
         const filteredArray = arrayModules.filter(item => {
            return permissions.some(route => route.route === item.props.path);
         });

         return filteredArray;
      }

   
      switch (role) {
         case ROLE_SUPER_ADMIN:
            return (
               <Router>
                  <Switch>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />

                     {arrayPermissions(moduleSuperAdmin)}
                     {moduleViewsGeneral}
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={validateRoutes()} />
                     }} />}

                  </Switch>
               </Router>
            );
         case ROLE_ADMIN:
            return (
               <Router>
                  <Switch>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />

                     {arrayPermissions(moduleAdmin)}
                     {moduleViewsGeneral}

                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={validateRoutes()} />
                     }} />}
                  </Switch>
               </Router>
            );
         case ROLE_TRABAJADOR:
            return (
               <Router>
                  <Switch>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     
                     {arrayPermissions(moduleEmployee)}
                     {moduleViewsGeneral}

                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={validateRoutes()} />
                     }} />}
                  </Switch>
               </Router>
            );

         default:
            return (
               <Router>
                  <Switch>

                     {moduleSuperAdmin}
                     {moduleAdmin}
                     {moduleEmployee}
                     {moduleViewsGeneral}

                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
      }
   },// eslint-disable-next-line
      [JSON.stringify(dataUser)]);

   return routes;
}

export default AllRoutes;
