import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { CountryService } from "@/service/services/Country.service";
import { Props } from "./ModalCompany.type";
import { companiesService } from "@/service/services/Companies.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalCompany: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, company, onListCompanies, clearState } = props;

    const entry = [
        {id: 1, name: 'Privada'},
    ]

    const [loading, setLoading] = useState<boolean>(false);
    const [compensationBox, setCompensationBox] = useState<any>([]);
    const [imageDoc, setImageDoc] = useState<any>(null);
    //for get idDistrict
    const [countries, setCountries] = useState<any>([]);
    const [countrySelected, setCountrySelected] = useState<any>('');
    const [departaments, setDepartaments] = useState<any>([]);
    const [departamentSelected, setDepartamentSelected] = useState<any>('');
    const [provinces, setProvinces] = useState<any>([]);
    const [provinceSelected, setProvinceSelected] = useState<any>('');
    const [districts, setDistricts] = useState<any>([]);
    const [districtSelected, setDistrictSelected] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: company ? company.id : '',
        registry_number: company ? company.registry_number : '',
        business_name: company ? company.business_name : '',
        phone: company ? company.phone : '',
        email: company ? company.email : '',
        contact_name: company ? company.contact_name : '',
        contact_email: company ? company.contact_email : '',
        contact_phone: company ? company.contact_phone : '',
        website: company ? company.website : '',
        fax: company ? company.fax : '',
        main_address: company ? company.main_address : '',
        iddistrict: company ? company.iddistrict : '',
        idcompensationbox: company ? company.idcompensationbox : '',
        identry: company ? company.identry : '',
        image: company ? company.image : '',
    });

    useEffect(() => {
        getCajas();
        if(!company) getCountries();
    }, []);

    useEffect(() => {
        if(company) getUbigeoCompanySelected(company.iddistrict);
    }, [company]);

    const getCajas = async() => {
        await companiesService.getCajas().then((data) => {
            setCompensationBox(data || []);
        })
    }

    const getCountries = async () => {
        setLoading(true);
        await CountryService.getCountries().then((data) => {
            setCountries(data.data || [])
           setLoading(false);
        });
    }

    const getDepartments = async (idCountry) => {
        setLoading(true);
        await CountryService.getDepartments(idCountry).then((data) => {
            setDepartaments(data.data || [])
           setLoading(false);
        });
    }

    const getProvinces = async (idDepartament) => {
        setLoading(true);
        await CountryService.getProvinces(idDepartament).then((data) => {
            setProvinces(data.data || [])
           setLoading(false);
        });
    }

    const getDistricts = async (idProvince) => {
        setLoading(true);
        await CountryService.getDistricts(idProvince).then((data) => {
            setDistricts(data.data || [])
           setLoading(false);
        });
    }

    const getUbigeoCompanySelected = async (id) => {
        setLoading(true);

        let response = await CountryService.findDistrict(id);
        let district = response.data;
        if(district){
            setDistrictSelected(district || '');

            await CountryService.getDistricts(district?.idprovince).then((data) => {
                setDistricts(data.data || [])
            });

            await CountryService.getProvinces(district?.province?.iddepartment).then((data) => {
                let provinces = data.data;
                setProvinces(provinces || []);
                let province = provinces && provinces.find(province => province.id == district?.idprovince);
                setProvinceSelected(province || '');
            });

            await CountryService.getDepartments(district?.province?.department?.idcountry).then((data) => {
                let departaments = data.data;
                setDepartaments(departaments || [])
                let departament = departaments && departaments.find(departament => departament.id == district?.province?.iddepartment);
                setDepartamentSelected(departament || '')
            });

            await CountryService.getCountries().then((data) => {
                let countries = data.data;
                setCountries(countries || []);
                let country = countries && countries.find(country => country.id == district?.province?.department?.idcountry);
                setCountrySelected(country.id || '')
            });
        }
        
        setLoading(false);
    }

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const fileSignature = files[0];

        if (fileSignature) {

            const nameAsArray = fileSignature.name.split(".") || [];
            const extension = nameAsArray.slice(-1)
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length - 1);

            if (extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' ||
                extension == 'JPEG' || extension == 'png' || extension == 'PNG') {
                setImageDoc(fileSignature) 
                return fileSignature
            } else {
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'warning',
                        message: ___PHRASE("elFormatoDeImagenNoEsCorrecta")
                    }
                    ))
                );
            }
        }
        return "";
    };

    const validateForm = (values) => {
        let errors: any = {};
        let regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let regPhone = /^(\+\d{1,3}( )?)?((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/;
        let regWebsite = /^(ftp|http|https):\/\/[^ "]+$/;
        let regFax = /^\+?[0-9]{6,14}$/;
        let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;
        if(!values.registry_number) errors.registry_number = ___PHRASE("numeroDeRegistro") +' '+ ___PHRASE("requerido");
        if(values.registry_number && !(regRut.test(values.registry_number))) errors.registry_number = "numero de registro no válido";
        if(!values.business_name) errors.business_name = ___PHRASE("nombre") +' '+ ___PHRASE("requerido");
        if(!values.phone) errors.phone = ___PHRASE("telefono") +' '+ ___PHRASE("requerido");
        if(!(regPhone.test(values.phone))) errors.phone =  ___PHRASE("telefono") +' '+"no válido";
        if(!values.email) errors.email = ___PHRASE("correo") +' '+ ___PHRASE("requerido");
        if(!(regEmail.test(values.email))) errors.email =  ___PHRASE("correo") +' '+"no válido";
        if(!values.contact_name) errors.contact_name = ___PHRASE("nombreDeContacto") +' '+ ___PHRASE("requerido");
        if(!values.contact_email) errors.contact_email = ___PHRASE("correoDeContacto") +' '+ ___PHRASE("requerido");
        if(!(regEmail.test(values.contact_email))) errors.contact_email =  ___PHRASE("correoDeContacto") +' '+ "no válido";
        if(!values.contact_phone) errors.contact_phone = ___PHRASE("telefonoDeContacto") +' '+ ___PHRASE("requerido");
        if(!(regPhone.test(values.contact_phone))) errors.contact_phone = ___PHRASE("telefonoDeContacto") +' '+"no válido";
        if(!values.website) errors.website = ___PHRASE("sitioWeb") +' '+ ___PHRASE("requerido");
        if(!(regWebsite.test(values.website))) errors.website = ___PHRASE("sitioWeb") +' '+"no válido";
        if(!values.fax) errors.fax = "fax "+___PHRASE("requerido");
        if(!(regFax.test(values.fax))) errors.fax = "fax "+' '+"no válido";
        if(!values.main_address) errors.main_address = ___PHRASE("direccion") +' '+ ___PHRASE("requerido");
        if(!values.identry) errors.identry = ___PHRASE("tipoDeEmpresa") +' '+ ___PHRASE("requerido");
        if(!values.idcompensationbox) errors.idcompensationbox = ___PHRASE("compensacion") +' '+ ___PHRASE("requerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!districtSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("debeSeleccionarUnDistrito") }
                ))
            );
        }

        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("registry_number", values.registry_number);
        formData.append("business_name", values.business_name);
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("contact_name", values.contact_name);
        formData.append("contact_email", values.contact_email);
        formData.append("contact_phone", values.contact_phone);
        formData.append("website", values.website);
        formData.append("fax", values.fax);
        formData.append("main_address", values.main_address);
        formData.append("iddistrict", districtSelected?.id);
        formData.append("idcompensationbox", values.idcompensationbox);
        formData.append("identry", values.identry);
        if(imageDoc)  formData.append("image", values.image);

        const formDataEdit = {
            id: values.id,
            registry_number: values.registry_number,
            business_name: values.business_name,
            phone: values.phone,
            email: values.email,
            contact_name: values.contact_name,
            contact_email: values.contact_email,
            contact_phone: values.contact_phone,
            website: values.website,
            fax: values.fax,
            main_address: values.main_address,
            iddistrict: districtSelected?.id,
            idcompensationbox: values.idcompensationbox,
            identry: values.identry,
        }

        setLoading(true);
        if(company && actionSelected=='edit'){
            await companiesService.editCompany(company.id, formDataEdit).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: apimessagge})
                } 
                setTimeout(()=>{
                    onListCompanies && onListCompanies();
                    closeModal && closeModal();
                    clearState && clearState();
                    setLoading(false);
                },2000);
            })
        }else{
            await companiesService.createCompany(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: apimessagge})
                }else{
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: apimessagge})
                }
                setTimeout(()=>{
                    onListCompanies && onListCompanies();
                    closeModal && closeModal();
                    clearState && clearState();
                    setLoading(false);
                },2000);
            })                
        }
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={(company && actionSelected=='edit') ? ___PHRASE("editar")+' '+___PHRASE("empresa") : ___PHRASE("agregar")+' '+___PHRASE("empresa")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{overflowY:'scroll', maxHeight:'400px', paddingX:4}}>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("numeroDeRut")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="registry_number"
                                            variant="standard"
                                            type="text" 
                                            name="registry_number"
                                            fullWidth
                                            value={values.registry_number}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("numeroDeRegistro")}
                                            error={errors.registry_number && touched.registry_number ? true : false}
                                            helperText={errors.registry_number && touched.registry_number ? errors.registry_number : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("nombreDeNegocio")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="business_name"
                                            variant="standard"
                                            type="text" 
                                            name="business_name"
                                            fullWidth
                                            multiline
                                            value={values.business_name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombreDeNegocio")}
                                            error={errors.business_name && touched.business_name ? true : false}
                                            helperText={errors.business_name && touched.business_name ? errors.business_name : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("telefono")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="phone"
                                            variant="standard"
                                            type="text" 
                                            name="phone"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.phone}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("telefono")}
                                            error={errors.phone && touched.phone ? true : false}
                                            helperText={errors.phone && touched.phone ? errors.phone : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("correo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="email"
                                            variant="standard"
                                            type="email" 
                                            name="email"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.email}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("correo")}
                                            error={errors.email && touched.email ? true : false}
                                            helperText={errors.email && touched.email ? errors.email : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombreDeContacto")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="contact_name"
                                            variant="standard"
                                            type="text" 
                                            name="contact_name"
                                            multiline
                                            fullWidth
                                            value={values.contact_name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombreDeContacto")}
                                            error={errors.contact_name && touched.contact_name ? true : false}
                                            helperText={errors.contact_name && touched.contact_name ? errors.contact_name : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("correoDeContacto")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="contact_email"
                                            variant="standard"
                                            type="email" 
                                            name="contact_email"
                                            multiline
                                            fullWidth
                                            value={values.contact_email}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("correoDeContacto")}
                                            error={errors.contact_email && touched.contact_email ? true : false}
                                            helperText={errors.contact_email && touched.contact_email ? errors.contact_email : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("telefonoDeContacto")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="contact_phone"
                                            variant="standard"
                                            type="text" 
                                            name="contact_phone"
                                            multiline
                                            fullWidth
                                            value={values.contact_phone}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("telefonoDeContacto")}
                                            error={errors.contact_phone && touched.contact_phone ? true : false}
                                            helperText={errors.contact_phone && touched.contact_phone ? errors.contact_phone : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("sitioWeb")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="website"
                                            variant="standard"
                                            type="text" 
                                            name="website"
                                            multiline
                                            fullWidth
                                            value={values.website}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("sitioWeb")}
                                            error={errors.website && touched.website ? true : false}
                                            helperText={errors.website && touched.website ? errors.website : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fax:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="fax"
                                            variant="standard"
                                            type="text" 
                                            name="fax"
                                            multiline
                                            fullWidth
                                            value={values.fax}
                                            onChange={handleChange}
                                            placeholder="fax"
                                            error={errors.fax && touched.fax ? true : false}
                                            helperText={errors.fax && touched.fax ? errors.fax : ''}

                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("pais")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_nationality"
                                                variant="standard"
                                                value={countrySelected}
                                                onChange={(event) => { 
                                                    setCountrySelected(event.target.value);
                                                    setDepartamentSelected('');
                                                    setProvinceSelected('');
                                                    setDistrictSelected('');
                                                }}
                                                fullWidth
                                            >
                                                {
                                                countries.map((country, i) => {
                                                    return <MenuItem key={i} value={country.id} onClick={ () => {getDepartments(country.id)}}>{country.name}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("departamento")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={departamentSelected}
                                                options={departaments || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { 
                                                    setDepartamentSelected(newValue); 
                                                    setProvinceSelected('');
                                                    setDistrictSelected('');
                                                    newValue && getProvinces(newValue?.id);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("provincia")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={provinceSelected}
                                                options={provinces || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { 
                                                    setProvinceSelected(newValue);
                                                    setDistrictSelected('');
                                                    newValue && getDistricts(newValue?.id); 
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("distrito")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={districtSelected}
                                                options={districts || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { setDistrictSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("direccionPrincipal")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="main_address"
                                            variant="standard"
                                            type="text" 
                                            name="main_address"
                                            multiline
                                            fullWidth
                                            value={values.main_address}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("direccion")}
                                            error={errors.main_address && touched.main_address ? true : false}
                                            helperText={errors.main_address && touched.main_address ? errors.main_address : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>{___PHRASE("tipoDeEmpresa")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="identry"
                                                variant="standard"
                                                value={values.identry}
                                                onChange={(event) => {
                                                    setFieldValue("identry", event.target.value);
                                                }}
                                                error={errors.identry && touched.identry ? true : false}
                                                fullWidth
                                            >
                                                {
                                                    entry.map((item, i) => {
                                                        return <MenuItem key={i} value={item.id}>{item.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>{___PHRASE("cajaDeCompensacion")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idcompensationbox"
                                                variant="standard"
                                                value={values.idcompensationbox}
                                                onChange={(event) => {
                                                    setFieldValue("idcompensationbox", event.target.value);
                                                }}
                                                error={errors.idcompensationbox && touched.idcompensationbox ? true : false}
                                                fullWidth
                                            >
                                                {
                                                    compensationBox.map((box, i) => {
                                                        return <MenuItem key={i} value={box.id}>{box.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    {
                                        !company && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Logo:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Button variant="contained" component="label" fullWidth>
                                                        <input
                                                            id="image"
                                                            type="file"
                                                            name="image"
                                                            onChange={(event) => {
                                                                setFieldValue("image", setNameFile(event));
                                                            }}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(company && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}