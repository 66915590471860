import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Charge } from '../models/Charge';
import { ChargeDTO } from '../http/dto/ChargesDTO';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idcompany = dataUser?.user?.id_company;

export const chargesRepository = {

   getCharges: async () : Promise<Charge>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<ChargeDTO>(`${API_URL_BASE}/v1/charge?idbussinesarea=8&id_company=3`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            name: raw?.name,
            charge_description: raw?.charge_description,
            idbussinesarea: raw?.idbussinesarea,
            bussinesarea: raw?.bussinesarea,
            idcompany: raw?.idcompany,
            nameCompany: raw?.nameCompany,
         })),
         error,
         message
      }
   },

   getChargesCompany: async (search= ''): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/charge/chargeCompany?id_company=${idcompany}&search=${search}`);
      const { data, error, message } = res;
      return { data, error, message };
   },

   createCharge: async (dataCharge): Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/charge`, {
         name: dataCharge.name,
         charge_description: dataCharge.charge_description,
         production: 1,
         idbussinesarea: dataCharge.idbussinesarea,
         status: dataCharge.status,
         // idcompany : dataCharge.idcompany,

      });
      const { data, error, message } = res;
      return { data, error, message };
   },

   editCharge: async (id: number,dataCharge): Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/charge/${id}`, {
         name: dataCharge.name,
         charge_description: dataCharge.charge_description,
         production: 1,
         idbussinesarea: dataCharge.idbussinesarea
      });
      const { data, error, message } = res;
      return { data, error, message };
   },

   deleteCharge: async(idCharge:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/charge/${idCharge}`)
      return res;
   },
   getChargesResponsability: async (id): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/charge/getFunction/${id}`);
      const { data, error, message } = res;
      return { data, error, message };
   },
   createChargeResponsability: async (dataCharge): Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/charge/createFunction`, {
         idcharge: dataCharge.idcharge,
         description: dataCharge.description,
      });
      const { data, error, message } = res;
      return { data, error, message };
   },
   editChargeResponsability: async (id: number,dataCharge): Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/charge/editarFunction/${id}`, {
         idcharge: dataCharge.idcharge,
         description: dataCharge.description,
      });
      const { data, error, message } = res;
      return { data, error, message };
   },
   deleteChargeResponsability: async(id:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/charge/deleteFunction/${id}`)
      return res;
   },

}


