import {Props} from "./ModalRequestLoan.type"
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Loading } from "@/components/common/Loading";
import { useState } from "react";
import { Autocomplete, Chip, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ModalFooter } from "../ModalFooter";
import { LoanService } from "@/service/services/Loan.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalRequestLoan: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, onList, workerSelected, validateMaxLoan, snackbar, setSnackbar } = props;
 
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState({
        // year_initial: '',
        // month_initial: '',
        loan: ''
    })

    const validateForm = (values) => {
        let errors: any = {};
        // if(!values.year_initial) errors.year_initial = "requerido";
        // if(!values.month_initial) errors.month_initial = "requerido";
        if(!values.loan) errors.loan = "requerido";
        if(values.loan > validateMaxLoan?.limit_max) errors.loan = "No puedes solicitar mas del límite";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {        
        const formData = {
            idEmployee: workerSelected,
            // year_initial: values.year_initial,
            // month_initial: values.month_initial,
            idLimit: validateMaxLoan.id,
            loan: values.loan
        }
        setLoading(true)
        await LoanService.requestLoan(formData).then((data)=>{
            onList && onList(0,workerSelected)
            closeModal && closeModal();
        });
       
        setLoading(false);
    }
  
    return (
        <>
         {loading && <Loading/>}
         <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={___PHRASE("habilitarPrestamo")}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
                <Typography>{___PHRASE("tienesDisponibilidadDeSolicitarHasta")} ${validateMaxLoan?.limit_max}</Typography>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                
                                    {/* <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={6}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Periodo de Inicio de Pago:</b></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="year_initial"
                                                variant="standard"
                                                fullWidth
                                                name="year_initial"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.year_initial}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.year_initial && touched.year_initial ? true : false}
                                            />
                                        </Grid>
                                    </Grid> */}
                                    {/* <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={6}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Mes de Inicio de Pago:</b></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="month_initial"
                                                variant="standard"
                                                fullWidth
                                                name="month_initial"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.month_initial}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.month_initial && touched.month_initial ? true : false}
                                            />
                                        </Grid>
                                    </Grid> */}
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={6}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("montoaSolicitar")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="loan"
                                                variant="standard"
                                                fullWidth
                                                name="loan"
                                                type="number"
                                                onChange={handleChange}
                                                value={values.loan}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.loan && touched.loan ? true : false}
                                                helperText={errors.loan && touched.loan ? errors.loan: ''}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    )
}