import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalScheduleWorker.type";
import { ScheduleService } from "@/service/services/Schedule.service";
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalScheduleWorker: React.FC<Props> = (props:any): JSX.Element => {

    const arrayDays = [
        {id: 1, name: 'Lunes'},
        {id: 2, name: 'Martes'},
        {id: 3, name: 'Miercoles'},
        {id: 4, name: 'Jueves'},
        {id: 5, name: 'Viernes'},
        {id: 6, name: 'Sabado'},
        {id: 7, name: 'Domingo'},
    ]

    const { open, closeModal, actionSelected, schedule, scheduleDetailSelected, onList, clearState, workerSelected } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [scheduleCompany, setScheduleCompany] = useState<string[]>([]);
    const [scheduleCompanySelected, setScheduleCompanySelected] = useState(null);
    const [arrayDaysSelected, setArrayDaysSelected] = useState([]);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: '',
        start_collation: '',
        end_collation: '', 
        weekly: '',
        weekly_hour: '',
        observations: '',
        start_date: '',
        end_date: '',
    });

    useEffect(()=> {
        if(scheduleDetailSelected) setData({
            id: '',
            start_collation: '',
            end_collation:  '', 
            weekly_hour: '', 
            weekly:  scheduleDetailSelected ? scheduleDetailSelected.weekly : '',
            observations: scheduleDetailSelected ? scheduleDetailSelected.observations : '',
            start_date: scheduleDetailSelected ? scheduleDetailSelected.start_date : '',
            end_date: scheduleDetailSelected ? scheduleDetailSelected.end_date : '',
        });
    },[open]);

    useEffect(()=> {
        if(schedule) setData({
            id:  schedule ? schedule.id : '',
            start_collation: schedule ? schedule.start_collation : '',
            end_collation: schedule ? schedule.end_collation : '', 
            weekly_hour: schedule ? schedule.weekly_hour : '', 
            weekly: '',
            observations: '',
            start_date: '',
            end_date: '',
        });
    },[open]);

    useEffect(()=> {
        if(scheduleDetailSelected) setArrayDaysSelected(setCustomDays(arrayDays, scheduleDetailSelected.weeklyText));
    },[open]);

    const validateForm = (values) => {
        let errors: any = {};
        if(actionSelected=='save' && !values.start_collation) errors.start_collation = "requerido";
        if(actionSelected=='save' && !values.end_collation) errors.end_collation = "requerido";
        if(actionSelected=='save' && !values.start_date) errors.start_date = "requerido";
        if(actionSelected=='save' && !values.end_date) errors.end_date = "requerido";
        
        if(schedule && actionSelected=='edit' && !values.start_collation) errors.start_collation = "requerido";
        if(schedule && actionSelected=='edit' && !values.end_collation) errors.end_collation = "requerido";

        if(scheduleDetailSelected && actionSelected=='edit' && !values.start_date) errors.start_date = "requerido";
        if(scheduleDetailSelected && actionSelected=='edit' && !values.end_date) errors.end_date = "requerido";
        return errors;
    }

    const setCustomDays = (arrayDays, idDays) => {
        let ArrayIds = idDays ? JSON.parse(idDays) : [];
        const data = ArrayIds ? ArrayIds.map( id => arrayDays.find(value => value.id == id)) : [];
        return getCustomDays(data);
    }
    const getCustomDays = (originalDays) => {
        return originalDays ? originalDays.map(day => ({id: day.id, name: day.name})) : [];
    }
    const customDays = getCustomDays(arrayDays || []);

    const structureDays = (dataArray) => {
        const newArray = dataArray.map(item => item.id)
        return JSON.stringify(newArray)
    }

    const onSubmit = async(values, {resetForm})  => {  

        if(actionSelected=='save' || (schedule && actionSelected=='edit')){
            if(values.start_collation > values.end_collation) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("eliniciodecolacionnopuedesermayoralfinaldecolacion") }
                ))
            );
        }

        if(actionSelected=='save' || (scheduleDetailSelected && actionSelected=='edit')){
            let dateStart= '';
            let dateEnd= '';

            if(arrayDaysSelected.length == 0) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message:  ___PHRASE("aseguresedeseleccionarlosdiaslaborales")}
                ))
            )
            if(values.start_date && values.end_date){
                dateStart = moment(values.start_date).format("YYYY/MM/DD");
                dateEnd = moment(values.end_date).format("YYYY/MM/DD");

                if(dateStart >= dateEnd) return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message:  ___PHRASE("aseguresequelafechainicialseamenorquelafinal") }
                    ))
                );
            }
        }

        if(actionSelected=='save'){
            if(scheduleCompanySelected==null) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message:  ___PHRASE("aseguresedeelegirunhorario")}
                ))
            );

            const formData = {
                start_collation: values.start_collation,
                end_collation: values.end_collation,
                idworkschedulecompany: scheduleCompanySelected?.id,
                idemployee:  workerSelected.id,
                start_date: values.start_date,
                end_date: values.end_date,
                weekly: structureDays(arrayDaysSelected),
                observations: values.observations,
            }      

            setLoading(true);

            await ScheduleService.createScheduleEmployee(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge || ___PHRASE("horarioCreadoSatisfactoriamente")}`}
                    ))
                    onList && onList(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal(workerSelected.id);
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}
                    ))
                }
            })
        }

        if(schedule && actionSelected=='edit'){
            const formDataEdit = {
                start_collation: values.start_collation,
                end_collation : values.end_collation,
                weekly_hour : values.weekly_hour,
            }

            setLoading(true);

            await ScheduleService.editScheduleEmployee(schedule.id, formDataEdit).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge|| ___PHRASE("registroActualizadoConExito")}`}
                    ))
                    onList && onList(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                        clearState && clearState();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}
                    ))
                }
            })
        }

        if(scheduleDetailSelected && actionSelected=='edit'){
            const formDataDetail = {
                idworkerschedules: scheduleDetailSelected.idworkerschedules,
                start_date: values.start_date,
                end_date: values.end_date,
                weekly: structureDays(arrayDaysSelected),
                observations: values.observations,
            }

            setLoading(true);

            await ScheduleService.editDetailWorkerSchedule(scheduleDetailSelected.id, formDataDetail).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge || ___PHRASE("registroActualizadoConExito")}`}
                    ))
                    onList && onList(workerSelected.id, scheduleDetailSelected.idworkerschedules);
                    setTimeout(function(){
                        closeModal && closeModal();
                        clearState && clearState();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}
                    ))
                }
            })
        }
        
        setLoading(false);
    }

    const getScheduleCompany = async() => {
        setLoading(true)
        const response = await ScheduleService.getScheduleCompany();
        if (response.data) {
            setScheduleCompany(response.data || [])
        } else {
            console.log('error')
        }
        setLoading(false);
    }

    useEffect(()=>{
        if(actionSelected != 'edit'){getScheduleCompany();}
    },[])

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(schedule ? ___PHRASE("editar")+' '+___PHRASE("horario") : (scheduleDetailSelected ? ___PHRASE("editar")+' '+___PHRASE("detalle") : ___PHRASE("agregar")+' '+___PHRASE("horario")))}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                {
                                    actionSelected != 'edit' && 
                                    <> 
                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("horariosDeLaEmpresa")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={scheduleCompanySelected}
                                                    options={scheduleCompany || []}
                                                    getOptionLabel={(option) => `${option.check_in_times} - ${option.closing_hour} ` || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                    onChange={(e, newValue) => {  setScheduleCompanySelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                {
                                    (actionSelected == 'save' ||  scheduleDetailSelected) && (
                                        <>
                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("diasLaborales")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={arrayDaysSelected}
                                                    options={customDays || []}
                                                    getOptionLabel={(option) => `${option.name}` || ""}
                                                    fullWidth
                                                    multiple
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                    onChange={(e, newValue) => {  setArrayDaysSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>
                                        </>
                                    )
                                }
                                {
                                    (actionSelected == 'save' ||  schedule) && (
                                        <>
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("inicioDeColacion")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <TextField
                                                id="start_collation"
                                                variant="standard"
                                                type="time" 
                                                name="start_collation"
                                                fullWidth
                                                value={values.start_collation}
                                                onChange={handleChange}
                                                placeholder={___PHRASE("inicioDeColacion")}
                                                error={errors.start_collation && touched.start_collation ? true : false}
                                            />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("finDeColacion")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <TextField
                                                id="end_collation"
                                                variant="standard"
                                                type="time" 
                                                name="end_collation"
                                                fullWidth
                                                value={values.end_collation}
                                                onChange={handleChange}
                                                placeholder={___PHRASE("finDeColacion")}
                                                error={errors.end_collation && touched.end_collation ? true : false}
                                            />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("horasSemanales")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <TextField
                                                id="weekly_hour"
                                                variant="standard"
                                                type="number" 
                                                name="weekly_hour"
                                                fullWidth
                                                value={values.weekly_hour}
                                                onChange={handleChange}
                                                placeholder={___PHRASE("horasSemanales")}
                                                error={errors.weekly_hour && touched.weekly_hour ? true : false}
                                            />
                                            </Grid>
                                        </Grid>
                                        </>
                                    )
                                }
                                { 
                                    (actionSelected == 'save' ||  scheduleDetailSelected) && (
                                        <>
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaInicio")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <TextField
                                                id="start_date"
                                                variant="standard"
                                                type="date" 
                                                name="start_date"
                                                fullWidth
                                                value={values.start_date}
                                                onChange={handleChange}
                                                placeholder={___PHRASE("fechaInicio")}
                                                error={errors.start_date && touched.start_date ? true : false}
                                            />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaFin")} :</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <TextField
                                                id="end_date"
                                                variant="standard"
                                                type="date" 
                                                name="end_date"
                                                fullWidth
                                                value={values.end_date}
                                                onChange={handleChange}
                                                placeholder={___PHRASE("fechaFin")}
                                                error={errors.end_date && touched.end_date ? true : false}
                                            />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("observacion")} :</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <TextField
                                                id="observations"
                                                variant="standard"
                                                type="text" 
                                                name="observations"
                                                fullWidth
                                                value={values.observations}
                                                onChange={handleChange}
                                                placeholder={___PHRASE("observacion")}
                                                error={errors.observations && touched.observations ? true : false}
                                            />
                                            </Grid>
                                        </Grid>
                                        </>
                                    )
                                }

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={((schedule && actionSelected=='edit') || (scheduleDetailSelected && actionSelected=='edit') ) ? ___PHRASE("actualizar"): ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}