import { useEffect, useState } from 'react'
// @mui
import { notificationService } from "@/service/services/Notifications.service";
import EmailIcon from '@mui/icons-material/Email'
import NotificationsIcon from '@mui/icons-material/Notifications'
import RedoIcon from '@mui/icons-material/Redo'
import { Badge, Divider, Grid, IconButton, ListItemIcon, MenuItem, Popover, Tooltip, Typography, Snackbar, Alert } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import 'moment/locale/es'
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/defaults/static-roles';
import { ROUTE_NOTIFY } from '@/toolbox/constants/route-map';
import { Link, useHistory } from 'react-router-dom'
import ___PHRASE from '@/service/translate/translateService';
moment.locale('es')

const themeAvenir = createTheme({
  typography: {
    fontSize: 15
  },
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
}

export const NotificationPopoverView = (props: any) => {
  //Hooks
  const userData = readLocalStorage(KEY_USER_DATA)
  const role = userData.user.role

  const history = useHistory();
  moment.locale('es')
  //NOTIFICATION POPOVER
  const [open, setOpen] = useState(null)
  const [idPopover, setidPopover] = useState(0);
  const [booleanPopover, setBooleanPopover] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any>([]);
  // const [counter, setCounter] = useState<any>(0);
  const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
  });

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  useEffect(() => {
    getNotifyNotView()
  }, [props.$store.NotificationReducer.notifications])

  // useEffect(() => {
  //   getNotifyNotView()
  // }, [])

  const getNotifyNotView = async () => {
     const resp: any = await notificationService.getNotifyNotView(5, 1);
     
    setNotifications(resp.data.data)
    props.$action.actionSetListNotification(resp.data.count)
  }

  const changeToSeenStatusMassive = async () => {
    const response: any = await notificationService.editVistoMassive();
    if (response.data.status == true) {
       setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: 'success',
          message: `${response.data.message}`
          }
       ));
       getNotifyNotView()
    } else {
       setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: 'warning',
          message: `${response.data.message}`
          }
       ));
    }
  }

  const changeToSeenStatus = async (id) => {
    const response: any = await notificationService.editVisto(id);
    if (response.data.status == true) {
       setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: 'success',
          message: `${response.data.message}`
          }
       ));
       getNotifyNotView()
    } else {
       setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: 'warning',
          message: `${response.data.message}`
          }
       ));
    }
  }

  const removeReceivedNotification = async (id) => {
    alert('eliminado')
  }

  const handleClose = (option: Notification) => {
    setOpen(null)
  }
  //NEW VIEW POPOVER
  const [openAction, setOpenAction] = useState(null)

  const handleOpenAction = (event) => {
    setOpenAction(event.currentTarget)
  }

  const handleCloseAction = () => {
    setOpenAction(null)
  }

  const handleOpenOption = (e, option) => {
    e.preventDefault()
    handleOpenAction(e)
    setidPopover(option?.id);
    setBooleanPopover((option?.date_seen !== null) ? true : false);
  }

  const formatDate = (date) => {
    const moment = require('moment');
    require('moment-timezone');

    // Establecer la zona horaria a Chile
    moment.tz.setDefault('America/Santiago');

    // Obtener la fecha actual en el horario de Chile
    const fechaActual = moment().tz('America/Santiago');

    // Obtener la fecha enviada en el horario de Chile
    const fechaEnviada = moment(date).tz('America/Santiago');

    // Verificar si la fecha enviada es mayor a la fecha actual
    if (fechaEnviada.isAfter(fechaActual)) {
      return (`Hace unos segundos`);
    } else {
      // Calcular la diferencia relativa entre las fechas
      const diferenciaRelativa = fechaEnviada.from(fechaActual);
      return diferenciaRelativa
    }
  }

  const limitedNotifications = notifications
  return (
    <>
        <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>
      <IconButton
        sx={{ marginLeft: '5px' }}
        aria-label="delete"
        size="large"
        onClick={handleOpen} color="primary" >
        <Badge badgeContent={ props.$store.NotificationReducer.notifications} color="error">
          <NotificationsIcon sx={{ color: notifications.length > 0 ? '#F15A29' : '#28c4ac' }} fontSize="inherit" />
        </Badge>
      </IconButton>
      <ThemeProvider theme={themeAvenir}>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              width: 380,
              borderRadius: '8px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0px 5px 10px', alignItems: 'center' }}>
            <Typography variant='subtitle1' sx={{ color: '#121c4c' }}>{ ___PHRASE("notificaciones") }</Typography>
            <ListItemIcon>
              <IconButton onClick={() =>  history.push(ROUTE_NOTIFY) } color="secondary" size="large">
                <Tooltip title={___PHRASE("recordatorios")}>
                  <EmailIcon sx={{ color: '#121c4c' }} fontSize="small" />
                </Tooltip>

              </IconButton>
            </ListItemIcon>
          </div>
          <div>
          <Divider sx={{ marginBottom: '0px !important', marginTop: '0px !important', opacity: '0.8', background: '#121c4c', border: '1px solid #121c4c' }} />

            {limitedNotifications.length > 0 && limitedNotifications.map((option) => {
              return (
                <Link
                  onContextMenu={(e) => handleOpenOption(e, option)}
                  key={option.id}
                  to={{ pathname:option.url,state: option.variables }}
                  style={{ textDecoration: 'none' }}>
                  <div onClick={() => handleClose(option)} style={{ cursor: 'pointer', background: `${(option?.date_seen !== null) ? '#fff' : '#efefef'}` }} >
                    <div style={{ wordWrap: 'break-word', justifyContent: 'space-between', display: 'flex', padding: '10px 10px 10px 10px', }}>
                      <Typography variant='body2' sx={{ color: '#000 !important' }}>{option.title}</Typography>
                      <Typography variant='caption' sx={{ color: '#000 !important' }}>{formatDate(option.date_sent)}</Typography>

                    </div>
                    <Divider />
                  </div>
                </Link>
              )

            })}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 15px' }}>
            <Grid sx={{ cursor: 'pointer' }} onClick={() => changeToSeenStatusMassive()}>
              <Typography variant='caption' sx={{ cursor: 'pointer', fontWeight: '600 !important', color: '#121c4c' }}>{ ___PHRASE("marcarTodasComoLeidas") }</Typography>
            </Grid>

            <Grid item display={'flex'} alignItems='center'>
              <Typography onClick={() => history.push(ROUTE_NOTIFY) } variant='caption' sx={{ cursor: 'pointer', fontWeight: '600 !important', marginRight: '3px', color: '#121c4c' }}>{___PHRASE("verTodas")}</Typography>
              <RedoIcon fontSize='small' sx={{ color: '#121c4c' }} />
            </Grid>
          </div>
        </Popover>

        <Popover
          open={Boolean(openAction)}
          anchorEl={openAction}
          onClose={handleCloseAction}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              width: 280,
              overflow: 'visible',
              filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
              mt: -3,
              ml: 2,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              }

            },
          }}
        >
          <div style={{ cursor: 'pointer' }}>
            <Link to={ROUTE_NOTIFY} target='_blank' style={{ textDecoration: 'none', color: '#000' }}>
              <MenuItem onClick={() => handleCloseAction()}>{ ___PHRASE("abrirEnUnaPestanaNueva") }</MenuItem>
            </Link>
            <MenuItem disabled={booleanPopover} onClick={() => changeToSeenStatus(idPopover)}>{ ___PHRASE("marcarComoLeido") }</MenuItem>

            <Divider />
            <MenuItem onClick={() => removeReceivedNotification(idPopover)}>{ ___PHRASE("quitar") }</MenuItem>
          </div>
        </Popover>
      </ThemeProvider>
    </>
  )
}
