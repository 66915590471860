import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalCharges.type";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { ChargeService } from "@/service/services/Charge.service";
import { Loading } from "@/components/common/Loading";
import { BusinessAreaService } from "@/service/services/BusinessArea.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalCharges: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, charge, onListCharges, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    //bussinesArea
    const [businessArea, setBusinessArea] = useState<any>([]);

    const [data, setData] = useState({
        id: charge ? charge.id : '',
        name: charge ? charge.name : '',
        charge_description: charge ? charge.charge_description : '',
        production: charge ? charge.production : '',
        idbussinesarea: charge ? charge.idbussinesarea : '',
        status: charge ? charge.status : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });


    const getBusinessArea = async () => {
        setLoading(true);
        await BusinessAreaService.getBusinessArea().then((data) => {
            setBusinessArea(data.data || [])
            setLoading(false);
        });
    }


    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.charge_description) errors.charge_description = "descripción requerido";
        // if(!values.production) errors.production = "produccion requerido";
        if(!values.idbussinesarea) errors.idbussinesarea = "negocio requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            name: values.name,
            charge_description : values.charge_description,
            idbussinesarea : values.idbussinesarea,
            // status : values.status
        }
        setLoading(true);
        if(charge && actionSelected=='edit'){
            await ChargeService.editCharge(charge.id, {...formData, production:values.production}).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true) {
                    onListCharges && onListCharges();
                    closeModal && closeModal();
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")} ` }
                    ))
                }
            })
        }else{
            await ChargeService.createCharge(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true) {
                    onListCharges && onListCharges();
                    closeModal && closeModal();
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")} ` }
                    ))
                }
            })
        }
        setLoading(false);
    }
    useEffect(() => {
        if(open){
            getBusinessArea();
        }else{
            clearState();
            setData({
                id: '',
                name:  '',
                charge_description:  '',
                production: '',
                idbussinesarea: '',
                status: '',
            })
        }
    }, [open]);
    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((charge && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("cargo")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombre")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("descripcion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="charge_description"
                                            variant="standard"
                                            type="text" 
                                            name="charge_description"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.charge_description}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("descripcion")}
                                            error={errors.charge_description && touched.charge_description ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>{___PHRASE("departamento")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idbussinesarea"
                                                variant="standard"
                                                value={values.idbussinesarea}
                                                onChange={(event) => {
                                                    setFieldValue("idbussinesarea", event.target.value);
                                                }}
                                                error={errors.idbussinesarea && touched.idbussinesarea ? true : false}
                                                fullWidth
                                            >
                                                {
                                                    businessArea.map((busArea, i) => {
                                                        // var formatRole = {...role, name: getCustomRoleName(role.name)};
                                                        return <MenuItem key={i} value={busArea.id}>{busArea.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    {/* {
                                        charge && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >                
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1"><b>En producción:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="production"
                                                        variant="standard"
                                                        value={values.production}
                                                        onChange={(event) => {
                                                            setFieldValue("production", event.target.value)
                                                        }}
                                                        fullWidth
                                                    >
                                                        <MenuItem value="1">Activo</MenuItem>
                                                        <MenuItem value="0">No activo</MenuItem>
                                                    </Select>
                                                </Grid> 
                                            </Grid>
                                        )
                                    } */}
                                    
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(charge && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}