import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { useEffect, useState } from "react";
import { Autocomplete, Box, Divider, Grid, MenuItem, Select, TextField, Typography, styled } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalNewViewPermission.type";
import { TableDataV2 } from "@/components/common/Tablev2";
import { ModuleService } from "@/service/services/Module.service";
import { ViewsService } from "@/service/services/Views.service";
import { PermissionsService } from "@/service/services/Permissions.service";
import { ProfileService } from "@/service/services/Profile.service";
import ___PHRASE from "@/service/translate/translateService";

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const ModalNewViewPermission: React.FC<Props> = (props: any): JSX.Element => {

    const { dataValidProfile, dataValidView, onListViewAdiccional, idUser, closeModal, open, user } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [roles, setRoles] = useState<any>([]);
    const [rolesSelected, setRolesSelected] = useState<any>([])
    const [modules, setModules] = useState<any>([]);
    const [modulesSelected, setModuleSelected] = useState<any>([]);

    const [views, setViews] = useState([]);

    const cabecera = [
      { id: 1, name: 'code', label: ___PHRASE("codigo") },
      { id: 2, name: 'name', label: ___PHRASE("nombre") },
      { id: 3, name: 'module', label: ___PHRASE("modulo") },
      { id: 4, name: 'route', label: ___PHRASE("ruta") },
    ];

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        getViewsByRoles(); getModulos();
    }, [rolesSelected])

    useEffect(() => {
        markedViewsbyModules();
    }, [modulesSelected])


    const setCustomRoles = (findRoles, response) => {
        const data = findRoles ? findRoles.map( role => response.find(value => value.id == role)) : [];
        return getCustomRoles(data);
    }
    const getCustomRoles = (originalRoles) => {
        return originalRoles ? originalRoles.map(role => ({id: role.id, name: role.name})) : [];
    }
    const customRoles = getCustomRoles(roles || []);

    const getRoles = async () => {
        let idroles = (user && user.idroles) ? user.idroles : '';
        if(idroles && idroles.length > 0) {idroles = JSON.stringify(idroles);}
        await ProfileService.getRoles(idroles).then((data) => {
            if(user) setRolesSelected(setCustomRoles(user.idroles, data.data));
            setRoles(data.data || '');
        });

    }

    const getModulos = async () => {
        if (rolesSelected.length > 0) {
            let arraysIds = rolesSelected.map((item) => { return item.id })
            const resp = await ModuleService.getByRoles(JSON.stringify(arraysIds));
            setModules(resp.data || [])
        } else {
            setModules([])
        }
    }

    const excluyedViewsActives = (data) => {
        const dataFilter =  data.map((item) => {
            if(!dataValidView.find(view => view.id == item.id) && !dataValidProfile.find(view => view.id == item.id)){
                return item
            }
        })
        return dataFilter.filter(item => item)
    }

    const getViewsByRoles = async () => {
        setLoading(true);
        if (rolesSelected.length > 0) {
            const ids = rolesSelected.map((item) => { return item.id })
            if (views.length > 0) {
                const resp = await ViewsService.getViewsByRoles(JSON.stringify(ids));
                const filterViews = views.filter(item => item.active == true)
                if (resp.data) {
                    let dataAction = (resp.data).map(raw => {

                        if (filterViews.find((item) => item.id == raw.id)) {
                            return { ...raw, active: true }
                        } else {
                            return { ...raw, active: false }
                        }
                    })
                    setViews(excluyedViewsActives(dataAction))
                    setLoading(false);
                }
            } else {
                const resp = await ViewsService.getViewsByRoles(JSON.stringify(ids));
                if (resp.data) {
                    let dataAction = excluyedViewsActives(resp.data).map(raw => { return { ...raw, active: false } })
                    setViews(excluyedViewsActives(dataAction))
                    setLoading(false);
                }
            }
        } else {
            setViews([])
            setLoading(false);
        }
        setLoading(false);
    }

    const markedViewsbyModules = () => {
        if (modulesSelected.length > 0) {
            const filteredArray = views.filter(item => {
                return modulesSelected.some(route => route.id === item.idmodule);
            });

            let dataAction = views.map((raw) => {
                if (filteredArray.find(item => item.id == raw.id)) {
                    return { ...raw, active: true }
                } else {
                    return { ...raw, active: false }
                }
            })

            setViews(dataAction)
        } else {
            let dataAction = views.map((item) => {
                return { ...item, active: false }
            })
            setViews(dataAction)
        }
    }

    const getIdsViews = (arrayData) => {
        let filterData = arrayData.filter(item => item.active == true)
        const ids = filterData.map((i) => {
            return i.id;
        })
        return JSON.stringify(ids)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await PermissionsService.createPermission({
            idviews: getIdsViews(views) ,
            iduser: idUser,
        } ).then((data) => {
            onListViewAdiccional && onListViewAdiccional();
            closeModal && closeModal();
        })
        setLoading(false)

    }

    return (
        <>
            {loading && <Loading />}

            <Modal open={open} handleClose={closeModal} handle size="lg">
                <ModalHeader
                    text={___PHRASE("agregarNuevasVistas")}
                    className='positionElements'
                    onCancel={closeModal}
                >
                </ModalHeader>
                <ModalBody>

                    <Grid container flexDirection={'row'} alignItems='flex-start'>
                        <Grid item xs={4} container marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("filtrarPorRoles")}:</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Autocomplete
                                    id="disable-portal"
                                    disablePortal
                                    value={rolesSelected}
                                    options={customRoles || []}
                                    getOptionLabel={(option) => option.name || ""}
                                    fullWidth
                                    multiple
                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                    onChange={(e, newValue) => { setRolesSelected(newValue) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("seleccionarPorModulos")}:</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Autocomplete
                                    id="disable-portal"
                                    disablePortal
                                    value={modulesSelected}
                                    options={modules || []}
                                    getOptionLabel={(option) => option.name || ""}
                                    fullWidth
                                    multiple
                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                    onChange={(e, newValue) => { setModuleSelected(newValue) }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={8} container alignItems="center" justifyContent="center" marginBottom='25px'>
                            {
                                (loading && rolesSelected.length > 0) && (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%', marginLeft:'20px'}}>
                                        <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                                    </Box>
                                )
                            }
                            {
                                (rolesSelected.length == 0 && !loading) && (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%', marginLeft:'20px'}}>
                                        <Div>{___PHRASE("seleccioneAlMenosUnRol")}</Div>
                                    </Box>
                                )
                            }
                            {
                                (views.length > 0 && rolesSelected.length > 0 && !loading) && (
                                    <TableDataV2
                                        data={views}
                                        setData={setViews}
                                        header={cabecera}
                                        checkbox
                                        disabled_title
                                        btn_disabled
                                    />
                                )
                            }
                            {
                                (views.length == 0 && rolesSelected.length > 0 && !loading) && (
                                    (
                                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%', marginLeft:'20px'}}>
                                            <Div>{___PHRASE("tieneAccesoaTodasLasVistasDelRolSeleccionado")}</Div>
                                        </Box>
                                    )
                                )
                            }
                        </Grid>
                    </Grid>
                    <Divider />
                    <ModalFooter
                        buttonType="submit"
                        confirmText={___PHRASE("anadir")}
                        cancelText={___PHRASE("cancelar")}
                        onCancel={closeModal}
                        onConfirm={onSubmit}
                    // clearState={clearState}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}