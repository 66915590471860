import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { branchOfficeRepository } from '@/service/repositories/BranchOffice.repository';
import { ModalBranchOffice } from '@/components/Modal/ModalBranchOffice';
import { ModalBranchOfficeDelete } from '@/components/Modal/ModalBranchOfficeDelete';
import { Props } from './TypesExpense.type';
import { typesExpenseService } from '@/service/services/TypesExpense.service';
import { ModalTypeExpense } from '@/components/Modal/ModalTypeExpense';
import { ModalTypeExpenseDelete } from '@/components/Modal/ModalTypeExpenseDelete';
import ___PHRASE from '@/service/translate/translateService';


export const TypesExpenseView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);

   const [typesExpense, setTypesExpense] = useState<any>([]);
   const [showtypeExpenseModal, setShowtypeExpenseModal] = useState<boolean>(false);
   const [showTypeExpenseDeleteModal, setShowTypeExpenseDeleteModal] = useState<boolean>(false);
   const [typeExpenseSelected, setTypeExpenseSelected] = useState<any>('');
   const [actionSelected, setActionSelected] = useState<any>('');
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
       open: false,
       severity: 'error',
       message: 'Error',
       autoHideDuration: 3000,
   });

   useEffect(() => {
    getTypesExpense()
   },[]);

   const getTypesExpense = async () => {
      setLoading(true);
      const response = await typesExpenseService.getTypesExpense();
      if (response.data) {
        setTypesExpense(response.data || [])
      } else {
      console.log('error')
      }
      console.log(typesExpense)
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setTypeExpenseSelected(value);
   }

   const getSnackBar = (severity, message) => {
      setSnackBarConfig(prev => ({
         ...prev, open: true,
         severity: severity,
         message: message
         }
      ));
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Snackbar
               open={snackBarConfig.open} 
               autoHideDuration={snackBarConfig.autoHideDuration} 
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
               <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                  {snackBarConfig.message || ""}
               </Alert>
         </Snackbar>

         <Container>
            <TableDataV2
               title={___PHRASE("tiposdegasto")}
               data={typesExpense || []}
               header={[
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: false },
                  { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: true },
                  { name: 'rlestructureoptional', label: ___PHRASE("estructurasOpcionales"), filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowtypeExpenseModal}
               setModalDelete={setShowTypeExpenseDeleteModal}
               status_action
            //    textSearch={'Buscar Sucursal'}
            //    searchData={searchData}
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showtypeExpenseModal && (
               <ModalTypeExpense
                  open={showtypeExpenseModal}
                  closeModal={()=>{setShowtypeExpenseModal(false);setTypeExpenseSelected('')}}
                  actionSelected={actionSelected}
                  onListTypesExpense={getTypesExpense}
                  typeExpense={typeExpenseSelected}
                  clearState={()=>{setTypeExpenseSelected('')}}
                  getSnackBar={getSnackBar}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalTypeExpenseDelete
                  open={showTypeExpenseDeleteModal}
                  closeModal={()=>{setShowTypeExpenseDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListTypesExpense={getTypesExpense}
                  typeExpense={typeExpenseSelected}
                  clearState={()=>{setTypeExpenseSelected('')}}
                  getSnackBar={getSnackBar}
               />
            )
         }
      </Protected>
   )
};