export const lang = {
  bienvenido: {
    es: "Bienvenido",
    en: "Welcome"
  },
  hola: {
    es: "Hola",
    en: "Hello"
  },
  rol: {
    es: "Rol",
    en: "Role"
  },
  cambiarRol: {
    es: "Cambiar Rol",
    en: "Change Role"
  },
  perfil: {
    es: "Perfil",
    en: "Profile"
  },
  empresas: {
    es: "Empresas",
    en: "Companies"
  },
  configuracionempresa: {
    es: "Configuración Empresa",
    en: "Company Configuration"
  },
  inicio: {
    es: "Inicio",
    en: "Home"
  },
  actividades: {
    es: "Actividades",
    en: "Activities"
  },
  cliente: {
    es: "Cliente",
    en: "Client"
  },
  negocios: {
    es: "Negocios",
    en: "Business"
  },
  miCuenta: {
    es: "Mi Cuenta",
    en: "My Account"
  },
  salir: {
    es: "Salir",
    en: "Exit"
  },
  notificaciones: {
    es: "Notificaciones",
    en: "Notifications"
  },
  marcarTodasComoLeidas: {
    es: "Marcar Todas Como Leídas",
    en: "Mark All as Read"
  },
  verTodas: {
    es: "Ver Todas",
    en: "See All"
  },
  abrirEnUnaPestanaNueva: {
    es: "Abrir en una pestaña nueva",
    en: "Open in a new tab"
  },
  marcarComoLeido: {
    es: "Marcar como leído",
    en: "Mark as read"
  },
  quitar: {
    es: "Quitar",
    en: "Remove"
  },
  recordatorios: {
    es: "Recordatorios",
    en: "Reminders"
  },
  miEmpresa: {
    es: "Mi Empresa",
    en: "My Company"
  },
  buscarEmpresa: {
    es: "Buscar Empresa",
    en: "Search Company"
  },
  buscarProyectos: {
    es: "Buscar Proyectos",
    en: "Search Projects"
  },
  numeroDeRegistro: {
    es: "Número de RUT",
    en: "Registry number"
  },
  numeroDeDocumento: {
    es: "Número de Documento",
    en: "Document number"
  },
  numeroDeCargas: {
    es: "Número de Cargas",
    en: "Number of Charges"
  },
  nombre: {
    es: "Nombre",
    en: "Name"
  },
  nombres: {
    es: "Nombres",
    en: "Names"
  },
  telefono: {
    es: "Teléfono",
    en: "Phone"
  },
  telefonoFijo: {
    es: "Teléfono Fijo",
    en: "Landline Phone"
  },
  celular: {
    es: "Celular",
    en: "Mobile Phone"
  },
  celularDeEmergencia: {
    es: "Celular de Emergencia",
    en: "Emergency Mobile Phone"
  },
  correo: {
    es: "Correo",
    en: "Email"
  },
  maestros: {
    es: "Maestros",
    en: "Manteiners"
  },
  editar: {
    es: "Editar",
    en: "Edit"
  },
  acciones: {
    es: "Acciones",
    en: "Actions"
  },
  empresa: {
    es: "Empresa",
    en: "Company"
  },
  agregar: {
    es: "Agregar",
    en: "Add"
  },
  agregado: {
    es: "Agregado",
    en: "aggregated"
  },
  numeroDeRut: {
    es: "Número de RUT",
    en: "RUT number"
  },
  nombreDeNegocio: {
    es: "Nombre de Negocio",
    en: "Business Name"
  },
  nombreDeContacto: {
    es: "Nombre de Contacto",
    en: "Contact name"
  },
  nombreDeUsuario: {
    es: "Nombre de Usuario",
    en: "User name"
  },
  correoDeUsuario: {
    es: "Correo de Usuario",
    en: "User email"
  },
  correoDeContacto: {
    es: "Correo de Contacto",
    en: "Contact Email"
  },
  telefonoDeContacto: {
    es: "Teléfono de Contacto",
    en: "Telephone contact"
  },
  sitioWeb: {
    es: "Sitio Web",
    en: "Website"
  },
  pais: {
    es: "País",
    en: "Country"
  },
  departamento: {
    es: "Departamento",
    en: "Department"
  },
  escribeParaBuscar: {
    es: "Escriba para Buscar",
    en: "Write to Search"
  },
  provincia: {
    es: "Provincia",
    en: "Province"
  },
  distrito: {
    es: "Distrito",
    en: "District"
  },
  direccionPrincipal: {
    es: "Dirección Principal",
    en: "Main Address"
  },
  direccion: {
    es: "Dirección",
    en: "Address"
  },
  tipoDeEmpresa: {
    es: "Tipo de Empresa",
    en: "Type of Company"
  },
  cajaDeCompensacion: {
    es: "Caja de Compensación",
    en: "Compensation Box"
  },
  actualizar: {
    es: "Actualizar",
    en: "Update"
  },
  registrar: {
    es: "Registrar",
    en: "Register"
  },
  cancelar: {
    es: "Cancelar",
    en: "Cancel"
  },
  requerido: {
    es: "Requerido",
    en: "Required"
  },
  noRequerido: {
    es: "No Requerido",
    en: "Not Required"
  },
  compensacion: {
    es: "Compensación",
    en: "Compensation"
  },
  debeSeleccionarUnDistrito: {
    es: "Debe seleccionar un Distrito",
    en: "You must select a District"
  },
  debeSeleccionarUnEmpleado: {
    es: "Debe seleccionar un empleado",
    en: "You must select a employee"
  },
  debeSeleccionarMesyAno: {
    es: "Debe seleccionar Mes y Año",
    en: "You must select Month and Year"
  },
  eliminar: {
    es: "Eliminar",
    en: "Delete"
  },
  estaSeguroDeEliminarEsteUsuario: {
    es: "¿Está seguro de eliminar este usuario?",
    en: "Are you sure to remove this user?"
  },
  estaSeguroDeEliminarEstaVariableGlobal: {
    es: "¿Está seguro de eliminar esta variable global?",
    en: "Are you sure to remove this global variable?"
  },
  estaSeguroDeEliminarEsteTipoDeSucursal: {
    es: "¿Está seguro de eliminar este tipo de Sucursal?",
    en: "Are you sure to delete this type of Branch?"
  },
  estaSeguroDeEliminarEstaZonaHoraria: {
    es: "¿Está seguro de eliminar esta Zona Horaria?",
    en: "Are you sure to delete this Time Zone?"
  },
  estaSeguroDeEliminarEsteTipoDeNotificacion: {
    es: "¿Está seguro de eliminar este tipo de notificación?",
    en: "Are you sure to remove this type of notification?"
  },
  estaSeguroDeEliminarEstaComuna: {
    es: "¿Está seguro de eliminar esta Comuna?",
    en: "Are you sure to delete this Commune?"
  },
  estaSeguroDeEliminarEstaProvincia: {
    es: "¿Está seguro de eliminar esta Provincia?",
    en: "Are you sure to delete this Province?"
  },
  estaSeguroDeEliminarEstaRegion: {
    es: "¿Está seguro de eliminar esta Región?",
    en: "Are you sure to delete this Region?"
  },
  estaSeguroDeEliminarEstePais: {
    es: "¿Está seguro de eliminar este País?",
    en: "Are you sure to delete this Country?"
  },
  estaSeguroDeEliminarElGasto: {
    es: "¿Está seguro de eliminar el gasto?",
    en: "Are you sure to eliminate the expense?"
  },
  estaSeguroDeEliminarEsteTipoDeGasto: {
    es: "¿Está seguro de eliminar este tipo de gasto?",
    en: "Are you sure to eliminate this type of expense?"
  },
  estaSeguroDeEliminarEstePostulante: {
    es: "¿Está seguro de eliminar a este postulante?",
    en: "Are you sure to delete this applicant?"
  },
  estaSeguroDeEliminarEstaFase: {
    es: "¿Está seguro de eliminar esta Fase?",
    en: "Are you sure to delete this Phase?"
  },
  estaSeguroDeEliminarEsteEquipoDeReclutamiento: {
    es: "¿Está seguro de eliminar este equipo de reclutamiento?",
    en: "Are you sure to delete this recruiting team?"
  },
  estaSeguroDeEliminarEstaEmpresa: {
    es: "¿Está seguro de eliminar esta Empresa?",
    en: "Are you sure to delete this Company?"
  },
  estaSeguroDeEliminarEsteProyecto: {
    es: "¿Está seguro de eliminar este proyecto?",
    en: "Are you sure to delete this project?"
  },
  estaSeguroDeEliminarEsteCentroDeCosto: {
    es: "¿Está seguro de eliminar este centro de costo?",
    en: "Are you sure to eliminate this cost center?"
  },
  estaSeguroDeEliminarEsteDepartamento: {
    es: "¿Está seguro de eliminar este Departamento?",
    en: "Are you sure to delete this Department?"
  },
  estaSeguroDeEliminarEstaResponsabilidadDelDepartamento: {
    es: "¿Está seguro de eliminar esta responsabilidad del Departamento?",
    en: "Are you sure to remove this responsibility from the Department?"
  },
  estaSeguroDeEliminarEsteCargo: {
    es: "¿Está seguro de eliminar este cargo?",
    en: "Are you sure to remove this charge?"
  },
  estaSeguroDeEliminarEsteGiro: {
    es: "¿Está seguro de eliminar este GIRO?",
    en: "Are you sure to delete this TURN?"
  },
  estaSeguroDeEliminarEstePerfil: {
    es: "¿Está seguro de eliminar este Perfil?",
    en: "Are you sure to delete this Profile?"
  },
  estaSeguroDeEliminarLaAsistencia: {
    es: "¿Está seguro de eliminar la asistencia?",
    en: "Are you sure to delete the assistance?"
  },
  debeLlenarTodosLosCamposParaLaIntegracionDelControlAsistencia: {
    es: "Debe llenar todos los campos para la integración del control asistencia",
    en: "You must fill in all the fields for the integration of attendance control"
  },
  debeLlenarTodosLosCampos: {
    es: "Debe llenar todos los campos.",
    en: "You must fill in all the fields."
  },
  configuracionDeEmpresa: {
    es: "Configuración de Empresa",
    en: "Company Configuration"
  },
  estadoDeLasIntegraciones: {
    es: "ESTADO DE LAS INTEGRACIONES",
    en: "STATUS OF THE INTEGRATIONS"
  },
  activo: {
    es: "Activo",
    en: "Active"
  },
  desactivado: {
    es: "Desactivado",
    en: "Disabled"
  },
  verDispositivos: {
    es: "Ver Dispositivos",
    en: "View Devices"
  },
  agregarDispositivo: {
    es: "Agregar Dispositivo",
    en: "Add Devices"
  },
  sucursal: {
    es: "Sucursal",
    en: "Branch"
  },
  sucursalOficina: {
    es: "Sucursal Oficina",
    en: "branch office"
  },
  editarDispositivo: {
    es: "Editar Dispositivo",
    en: "Edit Devices"
  },
  areaDelDispositivo: {
    es: "Area del Dispositivo",
    en: "Device Area"
  },
  usuarioDeBaseDeDatos: {
    es: "Usuario de Base de Datos",
    en: "Database User"
  },
  contrasena: {
    es: "Contraseña",
    en: "Password"
  },
  nombreDeLaBaseDeDatos: {
    es: "Nombre de la Base de Datos",
    en: "Database Name"
  },
  serieDelDispositivo: {
    es: "Serie del Dispositivo",
    en: "Device Series"
  },
  modeloDelDispositivo: {
    es: "Modelo del Dispositivo",
    en: "Device Model"
  },
  serie: {
    es: "Serie",
    en: "Series"
  },
  modelo: {
    es: "Modelo",
    en: "Model"
  },
  usuario: {
    es: "Usuario",
    en: "User"
  },
  noEncontrado: {
    es: "No Encontrado",
    en: "Not Found"
  },
  buscarTrabajador: {
    es: "Buscar Trabajador",
    en: "Search Worker"
  },
  empleado: {
    es: "Empleado",
    en: "Employee"
  },
  sincronizarEmpleados: {
    es: "Sincronizar Trabajadores",
    en: "Synchronize Workers"
  },
  habilitarPrestamo: {
    es: "Habilitar Préstamo",
    en: "Enable Borrow"
  },
  solicitarPrestamo: {
    es: "Solicitar Prestamo",
    en: "Request Loan"
  },
  ocurrioAlgunErrorIntentaloMasTarde: {
    es: "Ocurrió algun error, intentelo mas tarde.",
    en: "An error occurred, please try again later."
  },
  registroExitoso: {
    es: "Registro Exitoso",
    en: "Successful registration"
  },
  cargando: {
    es: "Cargando...",
    en: "Loading..."
  },
  grupoDeCompania: {
    es: "Grupo de Compañias",
    en: "Group of Companies"
  },
  compania: {
    es: "Compañia",
    en: "Company"
  },
  estado: {
    es: "Estado",
    en: "State"
  },
  deseaAceptarORechazarLaSolicitud: {
    es: "¿Desea Aceptar o Recharzar la Solicitud?",
    en: "Do you want to Accept or Reload the Application?"
  },
  aceptar: {
    es: "Aceptar",
    en: "Accept"
  },
  solicitar: {
    es: "Solicitar",
    en: "Request"
  },
  solicitudAceptada: {
    es: "Solicitud Aceptada",
    en: "Request accepted"
  },
  valorRequerido: {
    es: "valor requerido",
    en: "required value"
  },
  anticipoeditadosatisfactoriamente: {
    es: "Anticipo editado satisfactoriamente.",
    en: "Advance edited satisfactorily."
  },
  rechazar: {
    es: "Rechazar",
    en: "Decline"
  },
  eliminarEmpresa: {
    es: "Eliminar Empresa",
    en: "Delete Company"
  },
  estaSeguroDeEliminarEstaCompañia: {
    es: "¿Está seguro de eliminar esta compañia?",
    en: "Are you sure to delete this company?"
  },
  estaSeguroDeEliminarEsteContacto: {
    es: "¿Está seguro de eliminar este contacto?",
    en: "Are you sure to delete this contact?"
  },
  estaSeguroDeEliminarEstaSucursal: {
    es: "¿Está seguro de eliminar esta sucursal?",
    en: "Are you sure to delete this branch?"
  },
  estaSeguroDeEliminarAlEmpleado2: {
    es: "¿Está seguro de eliminar al empleado?",
    en: "Are you sure to delete the employee?"
  },
  alEliminarUnEmpleadoSeEliminaraElUsuarioyContratoAlCualEsteAfiliadoDentroDeLaEmpresa:{
    es: "Al eliminar un empleado, se eliminará el usuario y contrato, al cual este afiliado dentro de la empresa.",
    en: "When deleting an employee, the user and contract will be deleted, to which he is affiliated within the company."
  },
  estaSeguroDeEliminarAlEmpleado: {
    es: "¿Está seguro de eliminar al empleado con sus funcion(es) y su participación dentro del proyecto?",
    en: "Are you sure to delete the employee with his function(s) and his participation within the project?"
  },
  estaSeguroDeEliminarHistoriaLaboral: {
    es: "¿Está seguro de eliminar el historial laboral?",
    en: "Are you sure to delete the employment history?"
  },
  usuarios: {
    es: "Usuarios",
    en: "Users"
  },
  documentN: {
    es: "Documento N°",
    en: "Document No."
  },
  nDocumento: {
    es: "N° Documento",
    en: "Document No."
  },
  buscarUsuarios: {
    es: "Buscar Usuarios",
    en: "Seach Users"
  },
  cargasFamiliares: {
    es: "Cargas Familiares",
    en: "Family Responsibilities"
  },
  historialLaboral: {
    es: "Historial Laboral",
    en: "Employment History"
  },
  verContrato: {
    es: "Ver Contrato",
    en: "See Contract"
  },
  reprocesarLiquidacion: {
    es: "Reprocesar Liquidación",
    en: "Reprocess Settlement"
  },
  redirigir: {
    es: "Redirigir",
    en: "Redirect"
  },
  verDocumento: {
    es: "Ver Documento",
    en: "See Document"
  },
  empresaALaQueElUsuarioPertenece: {
    es: "Empresas a la que el Usuario Pertenece",
    en: "Companies to which the User belongs"
  },
  restriccionPorIP: {
    es: "Restricción por IP",
    en: "IP restriction"
  },
  conRestriccion: {
    es: "Con Restricción",
    en: "With Restriction"
  },
  sinRestriccion: {
    es: "Sin Restricción",
    en: "Without Restrictions"
  },
  apellidoPaterno: {
    es: "Apellido Paterno",
    en: "Paternal Surname"
  },
  apellidoMaterno: {
    es: "Apellido Materno",
    en: "Maternal Surname"
  },
  certificadoDeAntiguedad: {
    es: "Certificado de Antigüedad",
    en: "Certificate of Antiquity"
  },
  mantenedorDeClientesTrabajadores: {
    es: "Mantenedor de Clientes Trabajadores",
    en: "Maintainer of Employee Clients"
  },
  incorporeSusTrabajadoresEnElSiguienteFormulario: {
    es: "Incorpore sus Trabajadores en el siguiente Formulario.",
    en: "Incorporate your Workers in the following Form."
  },
  listadoDeCargasFamiliares: {
    es: "Listado de Cargas Familiares",
    en: "List of Family Burdens"
  },
  listadoDeTrabajadoresIngresados: {
    es: "Listado de Trabajadores Ingresados",
    en: "List of Entered Workers"
  },
  mantenedorDeCargasFamiliaresDelTrabajador: {
    es: "Mantenedor de Cargas Familiares del Trabajador",
    en: "Maintainer of Worker's Family Burdens"
  },
  mantenedorDeHistorialLaboral: {
    es: "Mantenedor de Historial Laboral",
    en: "Employment Record Keeper"
  },
  formadorDeEmpleado: {
    es: "Formulario de Empleado",
    en: "Employee Form"
  },
  masculino: {
    es: "Masculino",
    en: "Male"
  },
  femenino: {
    es: "Femenino",
    en: "Female"
  },
  campoObligatorio: {
    es: "Campo Obligatorio",
    en: "Obligatory field"
  },
  obligatorio: {
    es: "Obligatorio",
    en: "Obligatory"
  },
  estadoCivil: {
    es: "Estado Civil",
    en: "Civil status"
  },
  estadoContrato: {
    es: "Estado de Contrato",
    en: "Contract Status"
  },
  tipoDeTrabajador: {
    es: "Tipo de Trabajador",
    en: "Employee Type"
  },
  sexo: {
    es: "Sexo",
    en: "Sex"
  },
  estadoDelTrabajador: {
    es: "Estado del Trabajador",
    en: "Worker Status"
  },
  ultimoNivelDeEstudios: {
    es: "Último Nivel de Estudios",
    en: "Last Level of Studies"
  },
  comuna: {
    es: "Comuna",
    en: "commune"
  },
  siguiente: {
    es: "Siguiente",
    en: "Next"
  },
  errorDeFormatoPDF: {
    es: `El formato de imagen no es correcta, o esta intentando subir un archivo en formato distinto a ".pdf"`,
    en: `The image format is not correct, or you are trying to upload a file in a format other than ".pdf"`
  },
  imagen: {
    es: "Imagen",
    en: "Image"
  },
  debeSubirDocumentosDeIdentidadDelEmpleado: {
    es: "Debe subir documentos de identidad del empleado.",
    en: "You must upload identity documents of the employee."
  },
  asegureseDeLlenarTodosLosCamposYSubirLosArchivosCorrespondientes: {
    es: "Asegurese de llenar todos los campos y subir los archivos correspondientes.",
    en: "Be sure to fill in all the fields and upload the corresponding files."
  },
  cantidadDeDocumentos: {
    es: "Cantidad de documentos",
    en: "Number of documents"
  },
  nombreDelDocumento: {
    es: "Nombre del documento",
    en: "Document Name"
  },
  tipoDeArchivo: {
    es: "Tipo de Archivo",
    en: "Type of file"
  },
  atras: {
    es: "Atrás",
    en: "Back"
  },
  volver: {
    es: "Volver",
    en: "Return"
  },
  noSeEncontraronDocumento: {
    es: "No se Encontraron Documentos",
    en: "Not Documents Found"
  },
  subirDocumentosDeIdentidadDelEmpleado: {
    es: "Subir Documentos de Identidad del Empleado",
    en: "Upload Employee Identity Documents"
  },
  losDocumentosYaHanSidoValidadosPorElEmpleado: {
    es: "Los documentos ya han sido validados por el empleado.",
    en: "The documents have already been validated by the employee."
  },
  losDocumentosAunNoHanSidoValidadosPorElEmpleado: {
    es: "Los documentos aún no han sido validados por el empleado.",
    en: "The documents have not yet been validated by the employee."
  },
  datosPersonales: {
    es: "Datos Personales",
    en: "Personal information"
  },
  documentosDeIdentidad: {
    es: "Documentos de Identidad",
    en: "Identity Documents"
  },
  datosLaborales: {
    es: "Datos Laborales",
    en: "Labor data"
  },
  informacionPrevisional: {
    es: "Información Previsional",
    en: "Social Security Information"
  },
  informacionAdicional: {
    es: "Información Adicional",
    en: "Additional information"
  },
  informacionBancaria: {
    es: "Información Bancaria",
    en: "Bank information" 
  },
  siTieneSubsidio: {
    es: "Si Tiene Subsidio",
    en: "if you have a subsidy"
  },
  noTieneSubsidio: {
    es: "No Tiene Subsidio",
    en: "Does not have subsidy"
  },
  si: {
    es: "Si",
    en: "Yes"
  },
  no: {
    es: "No",
    en: "Not"
  },
  cargo: {
    es: "Cargo",
    en: "Charge"
  },
  modoDeTrabajo: {
    es: "Modo de Trabajo",
    en: "Working mode"
  },
  tiempoDeTrabajo: {
    es: "Tiempo de Trabajo",
    en: "Working time"
  },
  seguroDeCesantia: {
    es: "Seguro de Cesantía",
    en: "Unemployment insurance"
  },
  duracionDeContrato: {
    es: "Duración de Contrato",
    en: "Contract duration"
  },
  tipoDeContrato: {
    es: "Tipo de Contrato",
    en: "Type of contract"
  },
  tipoDocumentoContrato: {
    es: "Tipo Documento Contrato",
    en: "Contract Document Type"
  },
  subsidioTrabajadorJoven: {
    es: "Subsidio Trabajador Joven",
    en: "Young Worker Subsidy"
  },
  comunaDePrestacionDeServicios: {
    es: "Comuna de prestación de servicios",
    en: "Commune of provision of services"
  },
  sueldoPactado: {
    es: "Sueldo Pactado",
    en: "Agreed salary"
  },
  tipoDeGratificacion: {
    es: "Tipo Gratificación",
    en: "Gratification Type"
  },
  Gratificacion: {
    es: "Gratificación",
    en: "Gratification"
  },
  centroCosto: {
    es: "Centro Costo",
    en: "Cost Center"
  },
  anosOtrosControladores: {
    es: "Años otros Empleadores",
    en: "Years Other Employers"
  },
  afp: {
    es: "A.F.P.",
    en: "P.F.A."
  },
  salud: {
    es: "Salud",
    en: "Health"
  },
  institucionAPV: {
    es: "Institución APV",
    en: "APV Institution"
  },
  libroRemElect: {
    es: "Libro Rem. Elect.",
    en: "Elect. Pay. Book"
  },
  nContrato: {
    es: "N° Contrato",
    en: "Contract No."
  },
  tipoCargaFamiliar: {
    es: "Tipo de Carga Familiar",
    en: "Type of Family Load"
  },
  tipoContratoSalud: {
    es: "Tipo Contrato Salud",
    en: "Health Contract Type"
  },
  pactadoCotSalud: {
    es: "Pactado % Cot. Salud",
    en: "Agreed % Cot. Health"
  },
  pactadoCotSalud2: {
    es: "Pactado $ Cot. Salud",
    en: "Agreed $ Cot. Health"
  },
  pactadoCotSalud3: {
    es: "Pactado Uf Cot. Salud",
    en: "Agreed Uf Cot. Health"
  },
  tramoAsigFamiliar: {
    es: "Tramo Asig Familiar",
    en: "Tranche Asig Family"
  },
  banco: {
    es: "Banco",
    en: "Bank"
  },
  formaDePago: {
    es: "Forma de Pago",
    en: "Method of Payment"
  },
  tipoDeCuenta: {
    es: "Tipo de Cuenta",
    en: "Account type"
  },
  numero: {
    es: "Número",
    en: "Number"
  },
  guardar: {
    es: "Guardar",
    en: "Save"
  },
  fechaDeNacimiento: {
    es: "Fecha de Nacimiento",
    en: "Birthdate"
  },
  desde: {
    es: "Desde",
    en: "From"
  },
  hasta: {
    es: "Hasta",
    en: "Until"
  },
  vigenciaDesde: {
    es: "Vigencia desde",
    en: "Valid since"
  },
  vigenciaInicio: {
    es: "Vigencia Inicio",
    en: "Validity Start"
  },
  vigenciaTermino: {
    es: "Vigencia Término",
    en: "Validity Term"
  },
  tipo: {
    es: "Tipo",
    en: "Type"
  },
  parentesco: {
    es: "Parentesco",
    en: "Relationship"
  },
  visualizar: {
    es: "Visualizar",
    en: "Visualize"
  },
  enlazarProspecto: {
    es: "Enlazar Prospecto",
    en: "Link Lead"
  },
  verDetalles: {
    es: "Ver Detalles",
    en: "See details"
  },
  mover: {
    es: "Mover",
    en: "Move"
  },
  nota: {
    es: "Nota",
    en: "Note"
  },
  cotizacion: {
    es: "Cotización",
    en: "Price"
  },
  hiloDeCorreo: {
    es: "Hilo de Correo",
    en: "Mail Thread"
  },
  enviarLandingPorEmail: {
    es: "Enviar Landing por correo",
    en: "Send landing by email"
  },
  enviarAccesoPorEmail: {
    es: "Enviar Acceso por correo",
    en: "Send access by email"
  },
  envio: {
    es: "Envio",
    en: "Mailing"
  },
  correosEnviados: {
    es: "Correos Enviados",
    en: "Emails Sent"
  },
  fechaDeInicio: {
    es: "Fecha de Inicio",
    en: "Start Date"
  },
  fechaDeTermino: {
    es: "Fecha de Termino",
    en: "End Date"
  },
  negociacionesProhibidasPorEscrito: {
    es: "Negociaciones prohibidas por escrito",
    en: "Negotiations prohibited in writing"
  },
  noConcurrenciaALasLabores: {
    es: "No concurrencia a las labores",
    en: "No attendance at work"
  },
  abandonoDelTrabajo: {
    es: "Abandono del trabajo",
    en: "abandonment of work"
  },
  IncorporeHistorialLaboralDelTrabajador: {
    es: "Incorpore Historial Laboral del trabajador",
    en: "Incorporate the Work History of the worker"
  },
  causalUltimoFiniquito: {
    es: "Causal Último Finiquito",
    en: "Cause Last Settlement"
  },
  listadoDeHistorialLaboral: {
    es: "Listado de Historial Laboral",
    en: "Employment History List"
  },
  micontrato: {
    es: "Mi Contrato",
    en: "My Contract"
  },
  firmar: {
    es: "Firmar",
    en: "Sign"
  },
  verFirma: {
    es: "Ver Firma",
    en: "See Signature"
  },
  suEmpresaCuentaConLaIntegracionDeFirmaDigital: {
    es: "Su empresa cuenta con la Integración de Firma Digital",
    en: "Your company has Digital Signature Integration"
  },
  atrasos: {
    es: "Atrasos",
    en: "Delays"
  },
  atraso: {
    es: "Atraso",
    en: "Delay"
  },
  atrasosPorEmpleado: {
    es: "Atrasos por Empleado",
    en: "Employee Delays"
  },
  atrasosDe: {
    es: "Atrasos de ",
    en: "Delays by"
  },
  anexosDe: {
    es: "Anexos de ",
    en: "Annexes by "
  },
  editarAtraso: {
    es: "Editar Atraso",
    en: "Edit Delay"
  },
  agregarAtraso: {
    es: "Agregar Atraso",
    en: "Add Delay"
  },
  rutTrabajador: {
    es: "RUT Trabajador",
    en: "RUT Worker"
  },
  fecha: {
    es: "Fecha",
    en: "Date"
  },
  totalDeHoras: {
    es: "Total de Horas",
    en: "Total hours"
  },
  observacion: {
    es: "Observación",
    en: "Observation"
  },
  listaDeEmpleados: {
    es: "Lista de Empleados",
    en: "List of employees"
  },
  verAtrasos: {
    es: "Ver Atrasos",
    en: "See Delays"
  },
  nHorasDeAtrasos: {
    es: "N° horas de atrasos",
    en: "Hours of delays No."
  },
  totalAtrasos: {
    es: "Total de Atrasos",
    en: "Total delay"
  },
  estaSeguroDeEliminarEsteAtraso: {
    es: "¿Está seguro de eliminar este Atraso?",
    en: "Are you sure to remove this Delay?"
  },
  eliminadoCorrectamente: {
    es: "Eliminado Correctamente",
    en: "Deleted Successfully"
  },
  editadoCorrectamente: {
    es: "Editado Correctamente",
    en: "Edit Successfully"
  },
  guardadoCorrectamente: {
    es: "Guardado Correctamente",
    en: "Save Successfully"
  },
  campo: {
    es: "Campo",
    en: "Field"
  },
  antes: {
    es: "Antes",
    en: "Before"
  },
  despues: {
    es: "Despues",
    en: "After"
  },
  noExistenAnexosEnElContrato: {
    es: "No existen anexos en el contrato",
    en: "There are no annexes in the contract"
  },
  anexos: {
    es: "Anexos",
    en: "Annexes"
  },
  anexosPorEmpleado: {
    es: "Anexos por Empleado",
    en: "Annexes by Employee"
  },
  verAnexos: {
    es: "Ver Anexos",
    en: "See Annexes"
  },
  administrador: {
    es: "Administrador",
    en: "Administrator"
  },
  grupodeempresas: {
    es: "Grupo de Empresas",
    en: "Business group"
  },
  trabajadores: {
    es: "Trabajadores",
    en: "Workers"
  },
  trabajador: {
    es: "Trabajador",
    en: "Worker"
  },
  contactosdeltrabajdor: {
    es: "Contactos del Trabajador",
    en: "Worker Contacts"
  },
  contactosde: {
    es: "Contactos de ",
    en: "'s Contacts"
  },
  sucursales: {
    es: "Sucursales",
    en: "Branch offices"
  },
  proyectos: {
    es: "Proyectos",
    en: "Projects"
  },
  proyecto: {
    es: "Proyecto",
    en: "Project"
  },
  proyectoEliminado: {
    es: "Proyecto Eliminado",
    en: "Deleted Project"
  },
  centrosdecostos: {
    es: "Centro de Costos",
    en: "Cost center"
  },
  areasdetrabajo: {
    es: "Areas de Trabajo",
    en: "Work areas"
  },
  cargos: {
    es: "Cargos",
    en: "Charges"
  },
  tiposdesolicitudes: {
    es: "Tipo de solicitudes",
    en: "Type of requests"
  },
  girosdelaempresa: {
    es: "Giros de la Empresa",
    en: "Turns of the Company"
  },
  productos: {
    es: "Productos",
    en: "Products"
  },
  mispoliticasyprivacidad: {
    es: "Mis Politicas y Privacidad",
    en: "My Policies and Privacy"
  },
  documentocontrato: {
    es: "Documento Contrato",
    en: "Contract Document"
  },
  horarios: {
    es: "Horarios",
    en: "Schedules"
  },
  controldevistas: {
    es: "Control de Vistas",
    en: "View Control"
  },
  creardocumentocontrato: {
    es: "Crear Documento Contrato",
    en: "Create Contract Document"
  },
  personalizarcontrato: {
    es: "Personalizar Contrato",
    en: "Customize Contract"
  },
  perfilesdeusuario: {
    es: "Perfiles de Usuario",
    en: "User Profiles"
  },
  configuraciónempresa: {
    es: "Configuración Empresa",
    en: "Company Configuration"
  },
  contratoempleado: {
    es: "Contrato Empleado",
    en: "Employee Contract"
  },
  informacionDeContacto: {
    es: "Informacion de Contacto",
    en: "Contact information"
  },
  telefonoDeEmergencia: {
    es: "Teléfono de Emergencia",
    en: "Telephone emergency "
  },
  telefonoPersonal: {
    es: "Teléfono Personal",
    en: "Telephone personal "
  },
  tardanzasPorEmpleado: {
    es: "Tardanzas por empleado",
    en: "Tardies per employee"
  },
  verInformacionDeContacto: {
    es: "Ver información de contacto",
    en: "See contact information"
  },
  contacto: {
    es: "Contacto",
    en: "Contact"
  },
  codigo: {
    es: "Código",
    en: "Code"
  },
  buscarSucursal: {
    es: "Buscar Sucursal",
    en: "Search Branch"
  },
  codigoDeSucursal: {
    es: "Código de Sucursal",
    en: "Branch Code"
  },
  tipoDeSucursal: {
    es: "Tipo de Sucursal",
    en: "Branch Type"
  },
  sucursalEliminada: {
    es: "Sucursal Eliminada",
    en: "Deleted branch"
  },
  descripcion: {
    es: "Descripción",
    en: "Description"
  },
  fechaInicio: {
    es: "Fecha Inicio",
    en: "Start date"
  },
  fechaFin: {
    es: "Fecha Fin",
    en: "End date"
  },
  apellidos: {
    es: "Apellidos",
    en: "Surnames"
  },
  funcion: {
    es: "Función",
    en: "Function"
  },
  participacion: {
    es: "Participación",
    en: "participation"
  },
  listaDeProyectos: {
    es: "Lista de Proyectos",
    en: "Project List"
  },
  empleadosPorProyecto: {
    es: "Empleados por Proyectos",
    en: "Employees by Projects"
  },
  detalleDeParticipación: {
    es: "Detalle de Participación",
    en: "Participation Detail"
  },
  deIngenieria: {
    es: "De Ingeniería",
    en: "Engineering"
  },
  economicos: {
    es: "Económicos",
    en: "Economic"
  },
  fiscales: {
    es: "Fiscales",
    en: "Prosecutors"
  },
  legales: {
    es: "Legales",
    en: "Legal"
  },
  artisticos: {
    es: "Artísticos",
    en: "Artistic"
  },
  tecnologicos: {
    es: "Tecnológicos",
    en: "Technological"
  },
  productivos: {
    es: "Productivos",
    en: "productive"
  },
  otros: {
    es: "Otros",
    en: "Others"
  },
  inicioDelProyecto: {
    es: "Inicio del proyecto",
    en: "Project start"
  },
  finDelProyecto: {
    es: "Fin del proyecto",
    en: "Project end"
  },
  totalProyectado: {
    es: "Total Proyectado",
    en: "Total projected"
  },
  tipoDeProyecto: {
    es: "Tipo de Proyecto",
    en: "Project Type"
  },
  eliminarProyecto: {
    es: "Eliminar Proyecto",
    en: "Delete Project"
  },
  empleadoEliminadoConExito: {
    es: "Empleado eliminado con éxito",
    en: "Employee successfully removed"
  },
  ElPeriodoSeleccionadoNoSeEncuentraEnElRangoDeInicioyFinDElPoryecto: {
    es: "El periodo seleccionado no se encuentra en el rango de Inicio y Fin del Proyecto.",
    en: "The selected period is not in the Project Start and End range."
  },
  agregarParticipacion: {
    es: "Agregar Participación",
    en: "Add Participation"
  },
  periodo: {
    es: "Periodo",
    en: "Period"
  },
  mes: {
    es: "Mes",
    en: "Month"
  },
  enero: {
    es: "Enero",
    en: "January"
  },
  febrero: {
    es: "Febrero",
    en: "February"
  },
  marzo: {
    es: "Marzo",
    en: "March"
  },
  abril: {
    es: "Abril",
    en: "April"
  },
  mayo: {
    es: "Mayo",
    en: "May"
  },
  junio: {
    es: "Junio",
    en: "June"
  },
  julio: {
    es: "Julio",
    en: "July"
  },
  agosto: {
    es: "Agosto",
    en: "August"
  },
  septiembre: {
    es: "Septiembre",
    en: "September"
  },
  octubre: {
    es: "Octubre",
    en: "October"
  },
  noviembre: {
    es: "Noviembre",
    en: "November"
  },
  diciembre: {
    es: "Diciembre",
    en: "December"
  },
  elegirLaParticipacionDelEmpleado: {
    es: "Elegir la participación del empleado",
    en: "Choosing Employee Participation"
  },
  porcentajeParticipacion: {
    es: "Porcentaje Participacion",
    en: "Percentage Participation"
  },
  totalParticipacion: {
    es: "Total Participacion",
    en: "Total Participation"
  },
  verPorcentaje: {
    es: "Ver Porcentaje",
    en: "See Percentage"
  },
  verTotal: {
    es: "Ver Total",
    en: "See Total"
  },
  buscarCentroDeCostos: {
    es: "Buscar Centro de Costos",
    en: "Search Cost Center"
  },
  centroDeCosto: {
    es: "Centro de Costo",
    en: "Cost center"
  },
  centroDeCostoEliminado: {
    es: "Centro de Costo Eliminado",
    en: "Eliminated Cost Center"
  },
  cantidadDeTrabajadores: {
    es: "Cantidad de Trabajadores",
    en: "Number of Workers"
  },
  responsabilidadesDelDepartamento: {
    es: "Responsabilidades del departamento",
    en: "Department responsibilities"
  },
  listaDeDepartamentos: {
    es: "Lista de departamentos",
    en: "department list"
  },
  departamentos: {
    es: "Departamentos",
    en: "Departments"
  },
  buscarDepartamentos: {
    es: "Buscar Departamentos",
    en: "Search Departments"
  },
  debeSeleccionarUnaSucursal: {
    es: "Debe seleccionar una Sucursal",
    en: "You must select a Branch"
  },
  responsabilidad: {
    es: "Responsabilidad",
    en: "Responsibility"
  },
  verHorasExtras: {
    es: "Ver horas extras",
    en: "See overtime"
  },
  crearLiquidacion: {
    es: "Crear liquidación",
    en: "Create settlement"
  },
  verInasistencia: {
    es: "Ver inasistencias",
    en: "See absences"
  },
  verAnticipos: {
    es: "Ver anticipos",
    en: "See advances"
  },
  verEstructuras: {
    es: "Ver estructuras",
    en: "See structures"
  },
  verDetalle: {
    es: "Ver detalle",
    en: "See detail"
  },
  verMovimientos: {
    es: "Ver movimientos",
    en: "See movements"
  },
  verDetalleDelReporte: {
    es: "Ver detalle del reporte",
    en: "See report detail"
  },
  verEmpresasALaQuePertenece: {
    es: "Ver empresas a la que pertenece",
    en: "See companies to which it belongs"
  },
  detalles: {
    es: "Detalles",
    en: "Details"
  },
  detalle: {
    es: "Detalle",
    en: "Detail"
  },
  verResponsabilidades: {
    es: "Ver responsabilidades",
    en: "See responsibilities"
  },
  verFunciones: {
    es: "Ver funciones",
    en: "See functions"
  },
  ver: {
    es: "Ver",
    en: "See"
  },
  verEspecificaciones: {
    es: "Ver especificaciones",
    en: "See specifications"
  },
  listaDeCargos: {
    es: "Lista de cargos",
    en: "Charges list"
  },
  funciones: {
    es: "Funciones",
    en: "Functions"
  },
  buscarCargos: {
    es: "Buscar cargos",
    en: "Search charges"
  },
  funcionesDelCargo: {
    es: "Funciones del cargo",
    en: "functions of the charge"
  },
  cargoEliminado: {
    es: "Cargo eliminado",
    en: "Deleted charge"
  },
  girosDeLaEmpresa: {
    es: "Giros de la Empresa",
    en: "Turns of the Company"
  },
  politicasyPrivacidad: {
    es: "Politicas y Privacidad",
    en: "Policies and Privacy"
  },
  politicasyPrivacidadDelSoftware: {
    es: "Politicas y Privacidad del Software",
    en: "Software Policies and Privacy"
  },
  politicasyPrivacidadDeLaEmpresa: {
    es: "Politicas y Privacidad de la Empresa",
    en: "Company Policies and Privacy"
  },
  documento: {
    es: "Documento",
    en: "Document"
  },
  tipoDeDocumento: {
    es: "Tipo de Documento",
    en: "Document type"
  },
  cargar: {
    es: "Cargar",
    en: "Load"
  },
  subirArchivo: {
    es: "Subir archivo",
    en: "Upload file"
  },
  archivoSubido: {
    es: "Archivo subido",
    en: "Uploaded file"
  },
  documentocontratos: {
    es: "Documento Contratos",
    en: "Contracts document"
  },
  distritoDeLaEmpresa: {
    es: "Distrito de la Empresa",
    en: "Company District"
  },
  datoFirmaContrato: {
    es: "Dato firma contrato",
    en: "Contract signature data"
  },
  nombreDeLaEmpresa: {
    es: "Nombre de la empresa",
    en: "Company name"
  },
  rutDeLaEmpresa: {
    es: "RUT de la empresa",
    en: "RUT Company"
  },
  administradorDeLaEmpresa: {
    es: "Administrador de la Empresa",
    en: "Company manager"
  },
  rutAdministradorDeLaEmpresa: {
    es: "Rut Administrador de la Empresa",
    en: "Rut Company Administrator"
  },
  direccionAdministradorDeLaEmpresa: {
    es: "Dirección Administrador de la Empresa",
    en: "Company Administrator Address"
  },
  distritoAdministradorDeLaEmpresa: {
    es: "Distrito de Administrador de la Empresa",
    en: "Company Administrator District"
  },
  nombreDelEmpleado: {
    es: "Nombre del Empleado",
    en: "Name of the employee"
  },
  rutDelEmpleado: {
    es: "RUT del Empleado",
    en: "Employee's RUT"
  },
  nacionalidadDelEmpleado: {
    es: "Nacionalidad del Empleado",
    en: "Employee Nationality"
  },
  fechaNacimientoDelEmpleado: {
    es: "Fecha Nacimiento del Empleado",
    en: "Employee's Date of Birth"
  },
  direccionDelEmpleado: {
    es: "Dirección del Empleado",
    en: "Employee Address"
  },
  distritoDelEmpleado: {
    es: "Distrito del Empleado",
    en: "Employee District"
  },
  cargoDelEmpleado: {
    es: "Cargo del Empleado",
    en: "Employee Title"
  },
  direccionDeLaSucursal: {
    es: "Dirección de la Sucursal",
    en: "Branch Address"
  },
  distritoDeLaSucursal: {
    es: "Distrito de la Sucursal",
    en: "Branch District"
  },
  horarioSemanalDelEmpleado: {
    es: "Horario Semanal del Empleado",
    en: "Employee Weekly Schedule"
  },
  horarioInicioDelEmpleado: {
    es: "Horario Inicio del Empleado",
    en: "Employee Start Hours"
  },
  horarioFinDelEmpleado: {
    es: "Horario Fin del Empleado",
    en: "Employee End Hours"
  },
  colaciónHoraDelEmpleado: {
    es: "Colación Hora del Empleado",
    en: "Employee Hour Snack"
  },
  salarioBaseDelEmpleado: {
    es: "Salario base del Empleado",
    en: "Employee base salary"
  },
  cartasSalarialesDelEmpleado: {
    es: "Cartas Salariales del Empleado",
    en: "Employee Pay Letters"
  },
  errorAlCrearElDocumentoContrato: {
    es: "Error al crear el Documento Contrato",
    en: "Error when creating the Contract Document"
  },
  errorAlEditarElDocumentoContrato: {
    es: "Error al editar el Documento Contrato",
    en: "Error when editing the Contract Document"
  },
  errorAlCrearClausulas: {
    es: "Error al crear Clausulas",
    en: "Error creating Clauses"
  },
  nombreDeLaClausula: {
    es: "Nombre de la Cláusula",
    en: "Clause Name"
  },
  nuevaClausula: {
    es: "Nueva Cláusula",
    en: "New Clause"
  },
  crearDocumentoContrato: {
    es: "Crear Documento Contrato",
    en: "Create Contract Document"
  },
  crearContrato: {
    es: "Crear Contrato",
    en: "Create Contract"
  },
  regresar: {
    es: "Regresar",
    en: "Return"
  },
  nombreDelContrato: {
    es: "Nombre del contrato",
    en: "Contract name"
  },
  descripcionDelContrato: {
    es: "Descripción del contrato",
    en: "Contract description"
  },
  clausulaEditadaConExito: {
    es: "Cláusula editada con éxito",
    en: "Clause Edited Successfully"
  },
  clausulaEliminadaConExito: {
    es: "Cláusula eliminada con éxito",
    en: "Clause removed successfully"
  },
  guardarCambios: {
    es: "Guardar",
    en: "Save changes"
  },
  editarDocumentoDeContrato: {
    es: "Editar Documento de Contrato",
    en: "Edit Contract Document"
  },
  horarioDeEntrada: {
    es: "Horario de entrada",
    en: "Check in times"
  },
  horarioDeSalida: {
    es: "Horario de salida",
    en: "Closing hour"
  },
  toleranciaMin: {
    es: "Tolerancia (min)",
    en: "Tolerance (min)"
  },
  horarioEditadoSatisfactoriamente: {
    es: "Horario editado satisfactoriamente.",
    en: "Schedule edited satisfactorily."
  },
  horarioCreadoSatisfactoriamente: {
    es: "Horario creado satisfactoriamente.",
    en: "Schedule created satisfactorily."
  },
  horario: {
    es: "Horario",
    en: "Schedule"
  },
  minutosDeTolerancia: {
    es: "Minutos de Tolerancia",
    en: "Minutes of Tolerance"
  },
  modulo: {
    es: "Módulo",
    en: "Module"
  },
  ruta: {
    es: "Ruta",
    en: "Route"
  },
  debeSeleccionarUnUsuario: {
    es: "Debe seleccionar un usuario",
    en: "You must select a user"
  },
  escribaParaBuscarUnUsuario: {
    es: "Escriba para buscar un usuario",
    en: "Type to search for a user"
  },
  buscar: {
    es: "Buscar",
    en: "Search"
  },
  verVistas: {
    es: "Ver vistas",
    en: "See views"
  },
  vistasPorPerfil: {
    es: "Vistas por Perfil",
    en: "Views by Profile"
  },
  vistasAdicionales: {
    es: "Vistas Adicionales",
    en: "Additional Views"
  },
  agregarVistaAdicional: {
    es: "Agregar Vista Adicional",
    en: "Add Additional View"
  },
  cargandoDatosEspereUnMomento: {
    es: "CARGANDO DATOS, ESPERE UN MOMENTO",
    en: "LOADING DATA, WAIT A MOMENT"
  },
  seleccioneUnUsuarioParaVerSusVistasDisponibles: {
    es: "SELECCIONE UN USUARIO PARA VER SUS VISTAS DISPONIBLES",
    en: "SELECT A USER TO SEE HIS AVAILABLE VIEWS"
  },
  agregarNuevasVistas: {
    es: "Agregar Nuevas Vistas",
    en: "Add New Views"
  },
  filtrarPorRoles: {
    es: "Filtrar por Roles",
    en: "Filter by Roles"
  },
  seleccionarPorModulos: {
    es: "Seleccionar por Modulos",
    en: "Select by Modules"
  },
  seleccioneAlMenosUnRol: {
    es: "SELECCIONE ALMENOS UN ROL",
    en: "SELECT AT LEAST ONE ROLE"
  },
  tieneAccesoaTodasLasVistasDelRolSeleccionado: {
    es: "TIENE ACCESO A TODAS LAS VISTAS DEL ROL SELECCIONADO",
    en: "HAS ACCESS TO ALL VIEWS OF THE SELECTED ROLE"
  },
  anadir: {
    es: "Añadir",
    en: "Add"
  },
  perfiles: {
    es: "Perfiles",
    en: "Profiles"
  },
  salarioMinimo: {
    es: "Salario Mínimo",
    en: "Minimun Salary"
  },
  salarioMaximo: {
    es: "Salario Maximo",
    en: "Maximun Salary"
  },
  debeInsertarElMontoDeLaEstructura: {
    es: "Debe insertar el monto de la estructura.",
    en: "You must insert the amount of the structure."
  },
  yaSeEncuentraSeleccionado: {
    es: "ya se encuentra seleccionado.",
    en: "is already selected."
  },
  noAgregadoNada: {
    es: "No ha agregado nada",
    en: "You have not added anything"
  },
  nombreDelPerfil: {
    es: "Nombre del perfil",
    en: "Profile name"
  },
  agregarEstructuras: {
    es: "Agregar Estructuras",
    en: "Add Structures"
  },
  opcional: {
    es: "Opcional",
    en: "Optional"
  },
  monto: {
    es: "Monto",
    en: "Amount"
  },
  montoDeEstructura: {
    es: "Monto de estructura",
    en: "Structure amount"
  },
  agregarEspecificacion: {
    es: "Agregar especificación",
    en: "Add specification"
  },
  listaDePerfiles: {
    es: "Lista de Perfiles",
    en: "Profile List"
  },
  detalleDelPerfil: {
    es: "Detalle por Perfil",
    en: "Profile Detail"
  },
  vistasDelPerfil: {
    es: "VISTAS DEL PERFIL",
    en: "PROFILE VIEWS"
  },
  listaDeUsuariosAsignadosAlPerfil: {
    es: "LISTA DE USUARIOS ASIGNADOS AL PERFIL",
    en: "LIST OF USERS ASSIGNED TO THE PROFILE"
  },
  asignarUsuariosAlPerfil: {
    es: "Asignar Usuarios al Perfil",
    en: "Assign Users to Profile"
  },
  configuracionDeLaEmpresa: {
    es: "Configuración de la Empresa",
    en: "Company Configuration"
  },
  configuracionDeAccesoPorIP: {
    es: "Configuración de Acceso por IP",
    en: "IP Access Configuration"
  },
  inicioDeAcceso: {
    es: "Inicio de Acceso",
    en: "Start of Access"
  },
  finDeAcceso: {
    es: "Fin de Acceso",
    en: "End of Access"
  },
  noTieneRestriccionPorHorario: {
    es: "No tiene restricción por horario",
    en: "It has no time restriction"
  },
  tieneRestriccionPorIpPeroNoPorHorario: {
    es: "Tiene restricción por ip, pero no por horario",
    en: "Has restriction by IP, but not by time"
  },
  tieneRestriccionPorIpYPorHorarioDeAcuerdoALaEmpresa: {
    es: "Tiene restricción por ip y  por horario, de acuerdo a la empresa",
    en: "It has a restriction by IP and by time, according to the company"
  },
  tieneRestriccionPorIpyPorHorarioDeAcuerdoAlTrabajador: {
    es: "Tiene restricción por ip y por horario, de acuerdo al trabajador",
    en: "It has a restriction by IP and by schedule, according to the worker"
  },
  tasaMutua: {
    es: "Tasa Mutua",
    en: "Mutual Rate"
  },
  mutuaYTasaMutua: {
    es: "Mutua y Tasa Mutua",
    en: "Mutual y Mutual Rate"
  },
  mutua: {
    es: "Mutua",
    en: "Mutual"
  },
  valorDeHorasExtras: {
    es: "Valor de Horas Extras",
    en: "Overtime Value"
  },
  horasExtras: {
    es: "Horas Extras",
    en: "Extra hours"
  },
  diaDeCierre: {
    es: "Día de Cierre",
    en: "Closing day"
  },
  deCadaMes: {
    es: "de cada mes",
    en: "of each month"
  },
  novedadesdelmes: {
    es: "Novedades del mes",
    en: "News of the month"
  },
  liquidaciones: {
    es: "Liquidaciones",
    en: "Closeouts"
  },
  reclutamiento: {
    es: "Reclutamiento",
    en: "Recruitment"
  },
  reportes: {
    es: "Reportes",
    en: "Reports"
  },
  politicasyprivacidad: {
    es: "Politicas y Privacidad",
    en: "Policies and Privacy"
  },
  asistencias: {
    es: "Asistencias",
    en: "Assists"
  },
  asistencia: {
    es: "Asistencia",
    en: "Assist"
  },
  inasistencias: {
    es: "Inasistencias",
    en: "Absences"
  },
  inasistencia: {
    es: "Inasistencia",
    en: "Absence"
  },
  anticipos: {
    es: "Anticipos",
    en: "Abvance"
  },
  solicitudes: {
    es: "Solicitudes",
    en: "Requests"
  },
  movimiento: {
    es: "Movimiento",
    en: "Movement"
  },
  movimientos: {
    es: "Movimientos",
    en: "Movements"
  },
  validacionesdeidentidad: {
    es: "Validaciones de identidad",
    en: "Identity validations"
  },
  inventariosdetrabajadores: {
    es: "Inventarios de Trabajadores",
    en: "Worker Inventories"
  },
  solicitudesgastos: {
    es: "Solicitudes Gastos",
    en: "Requests Expenses"
  },
  ipsaceptadas: {
    es: "IP's Aceptadas",
    en: "Accepted IP's"
  },
  ipsdenegadas: {
    es: "IP's Denegadas",
    en: "Denied IP's"
  },
  estructuralre: {
    es: "Estructura LRE",
    en: "LRE structure"
  },
  liquidacionactivatrabajadores: {
    es: "Liquidación activa - Trabajadores",
    en: "Active liquidation - Workers"
  },
  liquidaciondesueldo: {
    es: "Liquidación de sueldo",
    en: "Salary settlement"
  },
  liquidacionmasiva: {
    es: "Liquidación masiva",
    en: "Mass liquidation"
  },
  planillaremuneracionesmensuales: {
    es: "Planilla Remuneraciones Mensuales",
    en: "Monthly Remuneration Sheet"
  },
  liquidacionporproyectos: {
    es: "Liquidación por proyectos",
    en: "Settlement by projects"
  },
  generarliquidacionmasiva: {
    es: "Generar liquidación masiva",
    en: "Generate mass liquidation"
  },
  equipodereclutamiento: {
    es: "Equipo de reclutamiento",
    en: "Recruiting team"
  },
  fases: {
    es: "Fases",
    en: "Phases"
  },
  fase: {
    es: "Fase",
    en: "Phase"
  },
  convocatorias: {
    es: "Convocatorias",
    en: "Calls"
  },
  postulantes: {
    es: "Postulantes",
    en: "Applicants"
  },
  postulante: {
    es: "Postulante",
    en: "Applicant"
  },
  politicasyprivacidaddelsoftware: {
    es: "Políticas y Privacidad del Software",
    en: "Software Policies and Privacy"
  },
  reporteempleados: {
    es: "Reporte Empleados",
    en: "Employees Report"
  },
  reportegrupodeempresas: {
    es: "Reporte Grupo de Empresas",
    en: "Group of Companies Report"
  },
  reporteliquidaciones: {
    es: "Reporte Liquidaciones",
    en: "Settlements Report"
  },
  reporteliquidacionporproyectos: {
    es: "Reporte Liquidación por proyectos",
    en: "Settlement report by projects"
  },
  reportesolicitudes: {
    es: "Reporte solicitudes",
    en: "Report requests"
  },
  reportefaltastardanzasyhorasext: {
    es: "Reporte de Faltas, Tardanzas y Horas Ext.",
    en: "Absences, Tardies and Overtime Report"
  },
  reportegastos: {
    es: "Reporte Gastos",
    en: "Report Expenses"
  },
  reporteproductos: {
    es: "Reporte Productos",
    en: "Report Products"
  },
  tipoDeInasistencia: {
    es: "Tipo de inasistencia",
    en: "Type of absence"
  },
  tipoDeDescuento: {
    es: "Tipo de descuento",
    en: "Discount rate"
  },
  totalInasistencia: {
    es: "Total Inasistencia",
    en: "Total absence"
  },
  inasistenciasPorEmpleado: {
    es: "Inasistencias por empleado",
    en: "Absences per employee"
  },
  inasistenciaDe: {
    es: "Inasistencia de",
    en: "Absence of"
  },
  asegurateDeAgregarLaInasistenciaDeAcuerdoALaSolicitudDe: {
    es: "Asegurese de agregar la(s) inasistencia(s) de acuerdo a la solicitud de",
    en: "Be sure to add the absence(s) according to the request for"
  },
  laCualCorrespondeDeLaFecha: {
    es: "; la cual corresponde de la fecha",
    en: "; which corresponds to the date"
  },
  asegureseQueLaFechaDeInicioyFinSeaMenorQueLaFechaActual: {
    es: "Asegurese que la fecha de inicio y fin, sean menor que la fecha actual.",
    en: "Make sure that the start and end dates are less than the current date."
  },
  elMesDeLasFechasSeleccionadasDebeSerIgualAlDelMesActual: {
    es: "El mes de las fechas seleccionadas debe ser igual al del mes actual",
    en: "El mes de las fechas seleccionadas debe ser igual al del mes actual"
  },
  asistenciasPorEmpleado: {
    es: "Asistencias por empleado",
    en: "Attendances per employee"
  },
  verAsistencias: {
    es: "Ver asistencias",
    en: "See assists"
  },
  asistenciaDe: {
    es: "Asistencias de",
    en: "Assists of"
  },
  filtrar: {
    es: "Filtrar",
    en: "Filter"
  },
  horaIngreso: {
    es: "Hora ingreso",
    en: "Entry time"
  },
  inicioRefrigerio: {
    es: "Inicio refrigerio",
    en: "Home snack"
  },
  finRefrigerio: {
    es: "Fin refrigerio",
    en: "End of snack"
  },
  horaSalida: {
    es: "Hora Salida",
    en: "Departure Time"
  },
  horasExtrasMin: {
    es: "Horas Extras (min)",
    en: "Overtime (min)"
  },
  tardanzasMin: {
    es: "Tardanzas (min)",
    en: "Tardies (min)"
  },
  estadoDeSincronizacion: {
    es: "Estado de Sincronización",
    en: "Synchronization Status"
  },
  asegureseDeQueLasHorasSeEncuentrenDeManeraAscendente: {
    es: "Asegurese de que las horas se encuentren de manera ascendente.",
    en: "Make sure the hours are in ascending order."
  },
  asegureseQueLaFechaInicialSeaMenorQueLaFinal: {
    es: "Asegúrese que la fecha inicial sea menor que la final.",
    en: "Make sure the start date is less than the end date."
  },
  seleccioneAlMenosUnEmpleado: {
    es: "Seleccione al menos un empleado.",
    en: "Select at least one employee."
  },
  elMesDeLaFechaSeleccionadaDebeSerIgualAlDelMesActual: {
    es: "El mes de la fecha seleccionada debe ser igual al del mes actual",
    en: "The month of the selected date must be the same as the current month"
  },
  asegureseQueLaFechaSeleccionadaSeaMenorQueLaFechaActual: {
    es: "Asegúrese que la fecha seleccionada sea menor que la fecha actual.",
    en: "Make sure the selected date is less than the current date"
  },
  empleados: {
    es: "Empleados",
    en: "Employees"
  },
  noPuedeEliminarAsistenciasQueNoPertenecenAlMesActual: {
    es: "No puede eliminar asistencias que no pertenecen al mes actual",
    en: "You cannot delete attendances that do not belong to the current month"
  },
  asistenciaEliminada: {
    es: "Asistencia eliminada.",
    en: "Assistance removed."
  },
  licencia: {
    es: "Licencia",
    en: "License"
  },
  anticiposDe: {
    es: "Anticipos de",
    en: "Advances of"
  },
  misanticipos: {
    es: "Mis Anticipos",
    en: "My advances"
  },
  valor: {
    es: "Valor",
    en: "Value"
  },
  noPuedeSolicitarAnticiposComuniqueseConSuAdministrador: {
    es: "No puede solicitar anticipos, comuníquese con su Administrador",
    en: "You cannot request advances, contact your Administrator"
  },
  asegureseQueTengaAsignadoDescuentoPorAnticipos: {
    es: "Asegurese que tenga asignado descuento por anticipos",
    en: "Make sure you have an advance discount assigned"
  },
  elEmpleadoNoTienePermisoParaSolicitarAnticipos: {
    es: "El empleado no tiene permiso para solicitar anticipos",
    en: "The employee does not have permission to request advances"
  },
  valorDeAnticipo: {
    es: "Valor de anticipo",
    en: "Advance value"
  },
  estadoDePeticion: {
    es: "Estado de petición",
    en: "Request status"
  },
  peticion: {
    es: "Petición",
    en: "Request"
  },
  aceptarPeticion: {
    es: "Aceptar Petición",
    en: "Accept Request"
  },
  rechazarPeticion: {
    es: "Rechazar Petición",
    en: "Reject Request"
  },
  laSolicitudYaHaSidoRespondida: {
    es: "La solicitud ya ha sido respondida",
    en: "The request has already been answered"
  },
  solicitudesDeEmpleados: {
    es: "Solicitudes de empleados",
    en: "Employee requests"
  },
  missolicitudes: {
    es: "Mis solicitudes",
    en: "My requests"
  },
  tipoDeSolicitud: {
    es: "Tipo de Solicitud",
    en: "Type of request"
  },
  fechaDeSolicitud: {
    es: "Fecha de Solicitud",
    en: "Application date"
  },
  motivo: {
    es: "Motivo",
    en: "Reason"
  },
  observacionDelAdmin: {
    es: "Observación del Admin",
    en: "Admin Remark"
  },
  noSeEncontraronSolicitudes: {
    es: "No se encontraron solicitudes.",
    en: "No requests found."
  },
  verSolicitudes: {
    es: "Ver Solicitudes",
    en: "See Requests"
  },
  verMisSolicitudes: {
    es: "Ver mis Solicitudes",
    en: "See my Requests"
  },
  movimientosPorEmpleado: {
    es: "Movimientos por empleado",
    en: "Movements per employee"
  },
  fechaDeRegistro: {
    es: "Fecha de Registro",
    en: "Registration date"
  },
  tipoMovimiento: {
    es: "Tipo movimiento",
    en: "Movement type"
  },
  movimientosDe: {
    es: "Movimientos de",
    en: "Movement of"
  },
  validacionesDeIdentidad: {
    es: "Validaciones de Identidad",
    en: "Identity Validations"
  },
  verValidaciones: {
    es: "Ver Validaciones",
    en: "See Validations"
  },
  noHayDatosDisponibles: {
    es: "No hay datos disponibles",
    en: "No data available"
  },
  loSientoNoHayDatosParaMostrar: {
    es: "Lo siento, no hay datos para mostrar.",
    en: "Sorry, there is no data to display."
  },
  cerrar: {
    es: "Cerrar",
    en: "Close"
  },
  solicitudesDeGastos: {
    es: "Solicitudes de Gastos",
    en: "Expense Requests"
  },
  conceptoDeDetalle: {
    es: "Concepto de Detalle",
    en: "Concept of Detail"
  },
  verDetalleDelGasto: {
    es: "Ver detalle del gasto",
    en: "See expense detail"
  },
  detalleDelGasto: {
    es: "Detalle del gasto",
    en: "Expense detail"
  },
  ipsAceptadas: {
    es: "IP's Aceptadas",
    en: "Accepted IP's"
  },
  laEmpresaNoCuentaConRestriccionPorIp: {
    es: "LA EMPRESA NO CUENTA CON RESTRICCIÓN POR IP",
    en: "THE COMPANY DOES NOT HAVE RESTRICTION BY IP"
  },
  ipsDenegadas: {
    es: "IP's Denegadas",
    en: "Denied IP's"
  },
  horariosPorEmpleado: {
    es: "Horario por empleados",
    en: "Hours by employees"
  },
  horariosDeLaEmpresa: {
    es: "Horarios de la Empresa",
    en: "Company Hours"
  },
  verHorario: {
    es: "Ver horario",
    en: "See Hour"
  },
  detalleDeHorario: {
    es: "Detalle de Horario",
    en: "Detail Schedules"
  },
  horarioDe: {
    es: "Horario de",
    en: "Hour of"
  },
  detalleDeHorariosDe: {
    es: "Detalle de Horarios de",
    en: "Detail of Schedules"
  },
  diasLaborales: {
    es: "Dias laborales",
    en: "Business days"
  },
  inicioDeColacion: {
    es: "Inicio de Colación",
    en: "Start of Snack"
  },
  finDeColacion: {
    es: "Fin de Colación",
    en: "End of Snack"
  },
  horasALaSemana: {
    es: "Horas a la Semana",
    en: "Hours per week"
  },
  horasSemanales: {
    es: "Horas Semanales",
    en: "Hours weeks"
  },
  estructuraDeLiquidacion: {
    es: "Estructura de Liquidación",
    en: "Settlement Structure"
  },
  buscarEstructura: {
    es: "Buscar Estructura",
    en: "Search Structure"
  },
  agregarEstructuraOpcional: {
    es: "Agregar Estructura Opcional",
    en: "Add Optional Structure"
  },
  conceptoDT: {
    es: "Concepto DT",
    en: "TD concept"
  },
  otraDescripcion: {
    es: "Otra descripción",
    en: "Other description"
  },
  vigenciaIndefinida: {
    es: "Vigencia indefinida",
    en: "indefinite validity"
  },
  debeSeleccionarUnaEstructura: {
    es: "Debe seleccionar una estructura",
    en: "You must select a structure"
  },
  estructuras: {
    es: "Estructuras",
    en: "Structures"
  },
  alias: {
    es: "Alias",
    en: "Alias"
  },
  aliasDeLaEstructua: {
    es: "Alias de la estructura",
    en: "Structure aliases"
  },
  validez: {
    es: "Validez",
    en: "Validity"
  },
  indefinido: {
    es: "Indefinido",
    en: "Undefined"
  },
  noIndefinido: {
    es: "No Indefinido",
    en: "Not indefinite"
  },
  estructurasOpcionales: {
    es: "Estructuras Opcionales",
    en: "Optional Structures"
  },
  estructurasObligatorias: {
    es: "Estructuras Obligatorias",
    en: "Mandatory Structures"
  },
  fechaVigencia: {
    es: "Fecha Vigencia",
    en: "Effective date"
  },
  fechaRegistro: {
    es: "Fecha Registro",
    en: "Registration date"
  },
  liquidacionDeSueldoPorTrabajadores: {
    es: "Liquidacion de sueldos por trabajadores",
    en: "Settlement of wages for workers"
  },
  subirPlantilla: {
    es: "Subir Plantilla",
    en: "Upload Template"
  },
  descargarPlantilla: {
    es: "Descargar Plantilla",
    en: "Download Template"
  },
  liquidacionPara: {
    es: "Liquidación para",
    en: "Clearance for"
  },
  generar: {
    es: "Generar",
    en: "Generate"
  },
  reprocesar: {
    es: "Reprocesar",
    en: "Reprocess"
  },
  verLiquidación: {
    es: "Ver liquidación",
    en: "See settlement"
  },
  crearNueva: {
    es: "Crear nueva",
    en: "Create new"
  },
  vuelvaaGenerarLaLiquidacion: {
    es: "Vuelva a generar la liquidación.",
    en: "Regenerate the settlement."
  },
  elPeriodoActual: {
    es: "El Periodo Actual",
    en: "The Current Period"
  },
  noSeEncuentraDisponible: {
    es: "No se Encuentra Disponible",
    en: "Not Available"
  },
  elPeriodoActualNoSeEncuentraDisponible: {
    es: "El periodo actual no se encuentra disponible",
    en: "The current period is not available"
  },
  liquidacionesMasivas: {
    es: "Liquidaciones Masivas",
    en: "Mass Liquidations"
  },
  seleccionePeriodoyMesParaGenerarRemuneracionMasiva: {
    es: "Seleccione periodo y mes para generar remuneración masiva",
    en: "Select period and month to generate massive remuneration."
  },
  noHayRemuneracionGeneradas: {
    es: "No hay remuneraciones generadas",
    en: "There are no remunerations generated"
  },
  planillasDeRemuneraciones: {
    es: "Planillas de Remuneraciones",
    en: "Salary Sheets"
  },
  previredMesyPeriodo: {
    es: "Previred - Mes y Periodo",
    en: "Previred - Month and Period"
  },
  ir: {
    es: "Ir",
    en: "Go"
  },
  libroElectronicoDeRemuneraciones: {
    es: "Libro Electrónico de Remuneraciones",
    en: "Electronic Remuneration Book"
  },
  certificadoDelTrabajador: {
    es: "Certificado del Trabajador",
    en: "Worker's Certificate"
  },
  seleccioneUnTrabajador: {
    es: "Seleccione un trabajador",
    en: "Select a worker"
  },
  generaDj: {
    es: "Genera DJ",
    en: "Generate DJ"
  },
  subtotalMutual: {
    es: "Subtotal Mutual",
    en: "Mutual Subtotal"
  },
  institucion: {
    es: "Institución",
    en: "Institution"
  },
  totalaPagar: {
    es: "Total a Pagar",
    en: "Total to pay"
  },
  mutual: {
    es: "Mutual",
    en: "Mutual"
  },
  subtotalIsapre: {
    es: "Subtotal ISAPRE",
    en: "ISAPRE subtotal"
  },
  subTotalAFP: {
    es: "Subtotal AFP",
    en: "AFP subtotal"
  },
  descargar: {
    es: "Descargar",
    en: "Download"
  },
  verMontosPrevired: {
    es: "Ver montos Previred",
    en: "See Previred amounts"
  },
  previredMontosGenerales: {
    es: "Previred montos generales",
    en: "General amounts anticipated"
  },
  previredFormatoCsv: {
    es: "Previred formato CSV",
    en: "Expected CSV format"
  },
  previredFormatoExcel: {
    es: "Previred formato Excel",
    en: "Expected Excel format"
  },
  centroDeCostosSucursalAgenciaObraRegion: {
    es: "Centro de Costos, Sucursal, Agencia,Obra, Región",
    en: "Cost Center, Branch, Agency, Project, Region"
  },
  dvPagadoraSubsidio: {
    es: "DV Pagadora Subsidio",
    en: "DV Subsidy Payer"
  },
  rutPagadoraSubsidio: {
    es: "Rut Pagadora Subsidio",
    en: "Rut Subsidy Payer"
  },
  aporteEmpleadorSeguroCesantia: {
    es: "Aporte Empleador Seguro Cesantía",
    en: "Employer Contribution Unemployment Insurance"
  },
  aporteTrabajadorSeguroCesantia: {
    es: "Aporte Trabajador Seguro Cesantía",
    en: "Worker Contribution Unemployment Insurance"
  },
  rentaImponibleSeguroCesantia: {
    es: "Renta Imponible Seguro Cesantía (Informar Renta Total Imponible)",
    en: "Taxable Income Unemployment Insurance (Inform Total Taxable Income)"
  },
  cotizacionAccidenteDelTrabajo: {
    es: "Cotización Accidente del Trabajo (MUTUAL)",
    en: "Work Accident Quote (MUTUAL)"
  },
  sucursalParaPagoMutual: {
    es: "Sucursal para pago Mutual",
    en: "Branch for Mutual payment"
  },
  rentaImponibleMutual: {
    es: "Renta Imponible Mutual",
    en: "Mutual Taxable Income"
  },
  codigoMutualidad: {
    es: "Código Mutualidad",
    en: "Mutual Code"
  },
  codigoDeSucursalUsoFuturo: {
    es: "Código de Sucursal (Uso Futuro)",
    en: "Branch Code (Future Use)"
  },
  libroDtFormatoCsv: {
    es: "Libro DT formato csv",
    en: "DT book csv format"
  },
  libroDtFormatoExcelSinCabecera: {
    es: "Libro DT formato excel sin cabecera",
    en: "Excel format DT book without header"
  },
  libroDtFormatoExcelConCabecera: {
    es: "Libro DT formato excel con cabecera",
    en: "Excel format DT book with header"
  },
  libroRemuneracionesElectronico: {
    es: "Libro Remuneraciones Electronico",
    en: "Electronic Remuneration Book"
  },
  liquidacionesPorProyecto: {
    es: "Liquidaciones por Proyecto",
    en: "Settlements by Project"
  },
  equipoDeReclutamiento: {
    es: "Equipo de reclutamiento",
    en: "Recruiting team"
  },
  especificacionDeOfertasLaborales: {
    es: "Especificación de ofertas laborales"
  },
  ofertasLaborales: {
    es: "Ofertas laborales",
    en: "Job offers"
  },
  modo: {
    es: "Modo",
    en: "Mode"
  },
  tipoDeDia: {
    es: "Tipo de día",
    en: "Type of day"
  },
  remoto: {
    es: "Remoto",
    en: "Remote"
  },
  presencial: {
    es: "Presencial",
    en: "Face to face"
  },
  hibrido: {
    es: "Híbrido",
    en: "Hybrid"
  },
  noHaAgregadoNada: {
    es: "No ha agregado nada",
    en: "You have not added anything"
  },
  ofertaLaboral: {
    es: "Oferta laboral",
    en: "Job offer"
  },
  inicioDePostulacion: {
    es: "Inicio de postulación",
    en: "Start of application"
  },
  finDePostulacion: {
    es: "Fin de postulación",
    en: "End of application"
  },
  vacantesDisponibles: {
    es: "Vacantes disponibles",
    en: "Vacancies available"
  },
  agregarEspecificaciones: {
    es: "Agregar Especificaciones",
    en: "Add Specifications"
  },
  nombreDeLaEspecificacion: {
    es: "Nombre de la especificación",
    en: "Specification Name"
  },
  certificado: {
    es: "Certificado",
    en: "Certificate"
  },
  constancia: {
    es: "Constancia",
    en: "Constancy"
  },
  especificaciones: {
    es: "Especificaciones",
    en: "Specifications"
  },
  usuarioAsignado: {
    es: "Usuario Asignado",
    en: "Assigned User"
  },
  filtrarPorPerfil: {
    es: "Filtrar por perfil",
    en: "Filter by profile"
  },
  fasesDelReclutamiento: {
    es: "Fases del reclutamiento",
    en: "Recruitment phases"
  },
  seleccioneUnArchivo: {
    es: "Seleccione un Archivo",
    en: "Select a File"
  },
  tipoDeAfp: {
    es: "Tipo de AFP",
    en: "Type of AFP"
  },
  codigoSalud: {
    es: "Código Salud",
    en: "Health Code"
  },
  nivelDeEducacion: {
    es: "Nivel de Educación",
    en: "Education Level"
  },
  situacionMilitar: {
    es: "Situación Militar",
    en: "Military situation"
  },
  profesion: {
    es: "Profesión",
    en: "Profession"
  },
  nacionalidad: {
    es: "Nacionalidad",
    en: "Nationality"
  },
  pensioner: {
    es: "Pensioner",
    en: "Pensioner"
  },
  curriculum: {
    es: "Curriculum",
    en: "Resume"
  },
  reporteDeEmpleados: {
    es: "Reporte de Empleados",
    en: "Employee Report"
  },
  seEncontro: {
    es: "Se encontró",
    en: "It was found"
  },
  empleadosConLiquidacionGeneradaEnElPeriodoyMesSeleccionado: {
    es: "Empleado(s) con liquidación generada en el periodo y mes seleccionado.",
    en: "Employee(s) with settlement generated in the selected period and month."
  },
  totalEmpleados: {
    es: "Total empleados",
    en: "Total employees"
  },
  totalEmpleadosConContrato: {
    es: "Total empleados con contrato",
    en: "Total employees with contract"
  },
  descargarExcel: {
    es: "Descargar Excel",
    en: "Download excel"
  },
  tiposDeContrato: {
    es: "Tipos de contrato",
    en: "Contract types"
  },
  cantidadEmpleados: {
    es: "Cantidad Empleados",
    en: "Number of employees"
  },
  totalPago: {
    es: "Total Pago",
    en: "Total Payment"
  },
  noSeEncontraronEmpleadosConLiquidacion: {
    es: "No se encontraron empleados con liquidación",
    en: "No severance employees found"
  },
  tiposDeGratificaciones: {
    es: "Tipo de gratificaciones",
    en: "Type of gratuities"
  },
  tramosFamiliares: {
    es: "Tramos Familiares",
    en: "Family Tranches"
  },
  reporteDeGrupoDeEmpresas: {
    es: "Reporte de Grupo de Empresa",
    en: "Company Group Report"
  },
  empleadosPorLiquidacion: {
    es: "Empleados con Liquidación",
    en: "Employees with Liquidation"
  },
  pagoAfp: {
    es: "Pago AFP",
    en: "AFP payment"
  },
  pagoSalud: {
    es: "Pago Salud",
    en: "Health Payment"
  },
  pagototal: {
    es: "Pago Total",
    en: "Full payment"
  },
  noSeEncontraronDatos: {
    es: "No se encontraron datos",
    en: "No data found"
  },
  reportePorLiquidaciones: {
    es: "Reporte por Liquidaciones",
    en: "Report for Settlements"
  },
  tipoDeCargos: {
    es: "Tipos de cargos",
    en: "Types of charges"
  },
  reporteDeLiquidacionesPorProyecto: {
    es: "Reporte de liquidaciones por proyecto",
    en: "Settlement report by project"
  },
  proyectosEnElPeriodoyMesSeleccionado: {
    es: "Proyecto(s) en el periodo y mes seleccionado.",
    en: "Project(s) in the selected period and month."
  },
  totalProyectos: {
    es: "Total Proyectos",
    en: "Total Projects"
  },
  descripcionDelProyecto: {
    es: "Descripción del Proyecto",
    en: "Project description"
  },
  costoTotalDelProyecto: {
    es: "Costo total del proyecto",
    en: "Total project cost"
  },
  detalleDeParticipacionEnElPeriodoSeleccionado: {
    es: "Detalle de participación en el periodo seleccionado",
    en: "Detail of participation in the selected period"
  },
  porcentajeDeParticipacion: {
    es: "Porcentaje de participación",
    en: "Participation Percentage"
  },
  noSeEncontroParticipacionParaElPeriodoyMesSeleccionado: {
    es: "No se encontró participación para el periodo y mes seleccionado.",
    en: "No participation was found for the selected period and month."
  },
  reporteDeSolicitud: {
    es: "Reporte de solicitud",
    en: "Application report"
  },
  seEncontraron: {
    es: "Se encontraron",
    en: "Were found"
  },
  empleadosRegistrados: {
    es: "Empleados registrados",
    en: "Registered Employees"
  },
  nombresEmpleados: {
    es: "Nombres Empleados",
    en: "Employee Names"
  },
  estados: {
    es: "Estados",
    en: "States"
  },
  solicitudesEnElPeriodoyMesSeleccionado: {
    es: "Solicitud(es) en el periodo y mes seleccionado.",
    en: "Request(s) in the period and month selected."
  },
  estadoDeSolicitud: {
    es: "Estado de solicitud",
    en: "application status"
  },
  pendientes: {
    es: "Pendientes",
    en: "Earrings"
  },
  aceptados: {
    es: "Aceptados",
    en: "Accepted"
  },
  rechazados: {
    es: "Rechazados",
    en: "Rejected"
  },
  debeCompletarTodosLosCamposParaRealizarElFiltrado: {
    es: "Debe completar todos los campos para realizar el filtrado",
    en: "You must complete all the fields to filter"
  },
  reporteDeTardanzasInasistenciasyHorasExtras: {
    es: "Reporte de tardanzas, inasistencias y horas extras",
    en: "Report of tardies, absences and extra hours"
  },
  extrasMin: {
    es: "Extras (min)",
    en: "Extras (min)"
  },
  fechasInasistencias: {
    es: "Fechas Inasistencias",
    en: "Dates Absences"
  },
  crearInasistencias: {
    es: "Crear Inasistencias",
    en: "Create Absences"
  },
  solicitudesPendientes: {
    es: "Solicitudes Pendientes",
    en: "Awaiting requests"
  },
  solicitudesAprobadas: {
    es: "Solicitudes Aprobadas",
    en: "Approved Requests"
  },
  solicitudesRechazadas: {
    es: "Solicitudes Rechazadas",
    en: "Rejected Applications"
  },
  tipoDeGasto: {
    es: "Tipo de gasto",
    en: "Expense type"
  },
  gastoDelEmpleado: {
    es: "Gasto del Empleado",
    en: "Employee Expense"
  },
  gastoDeLaEmpresa: {
    es: "Gasto de la Empresa",
    en: "Company Expense"
  },
  gastoTotal: {
    es: "Gasto total",
    en: "Total cost"
  },
  rendicionDeGastos: {
    es: "Rendición de Gastos",
    en: "Expense report"
  },
  detalleDeLaSolicitud: {
    es: "Detalle de la solicitud",
    en: "Request detail"
  },
  noHaySolicitudesPendientes: {
    es: "No hay solicitudes pendientes",
    en: "There are no pending requests"
  },
  noHaySolicitudesAprobadas: {
    es: "No hay solicitudes aprobadas",
    en: "No approved requests"
  },
  noHaySolicitudesRechazadas: {
    es: "No hay solicitudes rechazadas",
    en: "No rejected applications"
  },
  tiposdegasto: {
    es: "Tipos de Gastos",
    en: "Types of Expenses"
  },
  errorAlEditarElTipoDeGasto: {
    es: "Error al editar el Tipo de Gasto",
    en: "Error when editing the Type of Expense"
  },
  errorAlCrearElTipoDeGasto: {
    es: "Error al crear el Tipo de Gasto",
    en: "Error when creating the Type of Expense"
  },
  errorAlEliminarTipoDeGasto: {
    es: "Error al eliminar el Tipo de Gasto",
    en: "Error when deleting the Type of Expense"
  },
  nombreDelTipoDeGasto: {
    es: "Nombre del tipo de gasto",
    en: "Expense type name"
  },
  eliminarTipoDeGasto: {
    es: "Eliminar tipo de gasto",
    en: "Delete expense type"
  },
  tipoDeMovimiento: {
    es: "Tipo  de Movimiento",
    en: "Movement type"
  },
  fechaDeRetornoAcordado: {
    es: "Fecha de Retorno Acordado",
    en: "Agreed Return Date"
  },
  fechaDeCompra: {
    es: "Fecha de Compra",
    en: "Date of purchase"
  },
  netCost: {
    es: "net_cost",
    en: "net_cost"
  },
  estadoInicial: {
    es: "Estado Inicial",
    en: "Initial state"
  },
  duracionEstimada: {
    es: "Duración Estimada",
    en: "Estimated duration"
  },
  listaDeMateriales: {
    es: "Lista de Materiales",
    en: "Material's list"
  },
  comprasPorMaterial: {
    es: "Compras por Material",
    en: "Purchases by Material"
  },
  movimientosDeLaCompra: {
    es: "Movimientos de la Compra",
    en: "Purchase Movements"
  },
  materiales: {
    es: "Materiales",
    en: "Materials"
  },
  buscarMaterial: {
    es: "Buscar material",
    en: "search material"
  },
  compras: {
    es: "Compras",
    en: "Shopping"
  },
  material: {
    es: "Material",
    en: "Material"
  },
  datosDelMaterial: {
    es: "Datos del material",
    en: "Material data"
  },
  marca: {
    es: "Marca",
    en: "Brand"
  },
  darDeBajaAlMaterial: {
    es: "Dar de baja al material",
    en: "Dismiss the material"
  },
  darDeAltaAlMaterial: {
    es: "Dar de alta el material",
    en: "Discharge the material"
  },
  datosDeLaCompra: {
    es: "Datos de la Compra",
    en: "Purchase information"
  },
  precioDeCompra: {
    es: "Precio de Compra",
    en: "Purchase price"
  },
  datosDelMovimiento: {
    es: "Datos del Movimiento",
    en: "Movement Data"
  },
  fechaDeRegresoAcordado: {
    es: "Fecha de Regreso Acordado",
    en: "Agreed Return Date"
  },
  queTipoDeMovimientoRealizaraHoy: {
    es: "¿Qué tipo de movimiento realizarás hoy?",
    en: "What kind of movement will you make today?"
  },
  cambiarSucursal: {
    es: "Cambiar Sucursal",
    en: "Change Branch"
  },
  beneficio: {
    es: "Beneficio",
    en: "Benefit"
  },
  reembolso: {
    es: "Reembolso",
    en: "Refund"
  },
  prestamosPorEmpleado: {
    es: "Préstamos por Empleado",
    en: "Employee Loans"
  },
  prestamos: {
    es: "Préstamos",
    en: "Loans"
  },
  prestamosDe: {
    es: "Préstamos de",
    en: "Loans from"
  },
  tieneUnLimiteDePrestamoDe: {
    es: "Tiene un límite de prestamo de",
    en: "It has a loan limit of"
  },
  montoMaximoaAprobar: {
    es: "Monto Máximo a Aprobar",
    en: "Maximum Amount to Approve"
  },
  debeSeleccionarUnPerido: {
    es: "Debe seleccionar un Periodo",
    en: "You must select a Period"
  },
  debeSeleccionarUnMes: {
    es: "Debe seleccionar un Mes",
    en: "You must select a month"
  },
  debeSeleccionarUnAnoyMesMayoresoIgualesAlActual: {
    es: "Debe seleccionar un año y mes mayores o iguales al actual",
    en: "You must select a year and month greater than or equal to the current one"
  },
  revisaTusCuotasHasSuperadoElMontoMaximoPorLey: {
    es: "Revisa tus cuotas, has superado el monto máximo por ley",
    en: "Check your quotas, you have exceeded the maximum amount by law"
  },
  revisaTusCuotasHayMontosIgualaCero: {
    es: "Revisa tus cuotas, hay montos igual a 0",
    en: "Check your quotas, there are amounts equal to 0"
  },
  yaSeDisponibilizoLasPosiblesCuotas: {
    es: "Ya se disponibilizo las posibles cuotas",
    en: "The possible quotas are already available"
  },
  porCuotaSegunLeyDeProteccionAlTrabajador: {
    es: "por cuota segun Ley de Protección al Trabajador",
    en: "by quota according to the Worker Protection Law"
  },
  disponibilizarPosiblesFormasDePago: {
    es: "Disponibilizar Posibles Formas de Pago",
    en: "Make Available Possible Forms of Payment"
  },
  periodoDeInicioDePago: {
    es: "Periodo de Inicio de Pago",
    en: "Payment Start Period"
  },
  mesDeInicioDePago: {
    es: "Mes de inicio de pago",
    en: "Payment start month"
  },
  agregarFila: {
    es: "Agregar Fila",
    en: "Add Row"
  },
  nCuotas: {
    es: "N° Cuotas",
    en: "No. Quotas"
  },
  cuotas: {
    es: "Cuotas",
    en: "Quotas"
  },
  debesDeSeleccionarUnaCuota: {
    es: "Debes de seleccionar una cuota",
    en: "You must select a quota"
  },
  aunNoSeDisponibilizaLasCuotas: {
    es: "Aún no se disponibiliza las cuotas",
    en: "Quotas not yet available"
  },
  eligeLaCuotaDePago: {
    es: "Elige la cuota de pago",
    en: "Choose the payment rate"
  },
  detallesDeLaCuotas: {
    es: "Detalle de la cuotas",
    en: "Details of the quotas"
  },
  seleccionado: {
    es: "Seleccionado",
    en: "Selected"
  },
  seleccionar: {
    es: "Seleccionar",
    en: "Select"
  },
  misNotificaciones: {
    es: "Mis notificaciones",
    en: "My notifications"
  },
  haceUnosSegundos: {
    es: "Hace unos Segundos",
    en: "A few seconds ago"
  },
  misprestamos: {
    es: "Mis Préstamos",
    en: "My Loans"
  },
  puedesSolicitarComoMaximoUnPrestamoDe: {
    es: "Puedes solicitar como máximo un prestamo de",
    en: "You can request a maximum loan of"
  },
  noTienesDisponibleSolicitarPrestamo: {
    es: "No tienes disponible solicitar préstamo",
    en: "You are not available to apply for a loan"
  },
  tienesDisponibilidadDeSolicitarHasta: {
    es: "Tienes disponibilidad de solicitar hasta",
    en: "You are available to request up to"
  },
  montoaSolicitar: {
    es: "Monto a Solicitar",
    en: "Amount to Request"
  },
  misgastos: {
    es: "Mis Gastos",
    en: "My expenses"
  },
  concepto: {
    es: "Concepto",
    en: "Concept"
  },
  tipoDeReporte: {
    es: "Tipo de Reporte",
    en: "Report Type"
  },
  gastoEmpresa: {
    es: "Gasto Empresa",
    en: "Company Expense"
  },
  estadoDeRevision: {
    es: "Estado de Revisión",
    en: "Review Status"
  },
  debeSeleccionarElAnoyMesActual: {
    es: "Debe seleccionar el año y mes actual",
    en: "You must select the current year and month"
  },
  gastoCreadoSatisfactoriamente: {
    es: "Gasto creado satisfactoriamente.",
    en: "Expense created successfully."
  },
  gasto: {
    es: "Gasto",
    en: "Spent"
  },
  nDeReportes: {
    es: "N° de reportes",
    en: "No. of reports"
  },
  nDetalle: {
    es: "N° Detalle",
    en: "No. Detail"
  },
  debeAgregarUnDocumento: {
    es: "Debe agregar un documento",
    en: "You must add a document"
  },
  solicitudDeGastoEliminado: {
    es: "Solicitud de gasto eliminado.",
    en: "Expense request removed."
  },
  eliminarGasto: {
    es: "Eliminar Gasto",
    en: "Delete Expense"
  },
  noSePuedeTomarAccionesSolicitudNoRealizada: {
    es: "¡No se puede tomar acciones!. La solicitud no fue realizada en el periodo actual.",
    en: "Actions cannot be taken! The request was not made in the current period."
  },
  laSolicitudHaSidoAceptada: {
    es: "La solicitud ha sido aceptada. El monto del gasto se sumo a la estructura opcional del trabajador.",
    en: "The request has been accepted. The amount of the expense was added to the optional structure of the worker."
  },
  noSePuedeRealizarAccionesRechazada: {
    es: "No puede realizar acciones. La solicitud ha sido rechazada.",
    en: "You cannot perform actions. The request has been rejected."
  },
  detalleEditadoSatisfactoriamente: {
    es: "Detalle editado satisfactoriamente.",
    en: "Detail edited successfully."
  },
  aceptarRechazarSolicitud: {
    es: "Aceptar o Rechazar Solicitud",
    en: "Accept or Reject Request"
  },
  editarDetalle: {
    es: "Editar Detalle",
    en: "Edit Detail"
  },
  agregarDetalle: {
    es: "Agregar Detalle",
    en: "Add Detail"
  },
  coberturaEmpresa: {
    es: "Cobertura Empresa",
    en: "Company Coverage"
  },
  estadoDelDetalle: {
    es: "Estado del Detalle",
    en: "Detail Status"
  },
  habilitado: {
    es: "Habilitado",
    en: "Enabled"
  },
  deshabilitado: {
    es: "Deshabilitado",
    en: "Disabled"
  },
  actualDocumento: {
    es: "Actual Documento",
    en: "Current Document"
  },
  nuevoDocumento: {
    es: "Nuevo Documento",
    en: "New Document"
  },
  aceptarSolicitud: {
    es: "Aceptar Solicitud",
    en: "Accept request"
  },
  rechazarSolicitud: {
    es: "Rechazar Solicitud",
    en: "Reject Request"
  },
  seleccioneUnaEstructura: {
    es: "Seleccione una Estructura",
    en: "Select a Structure"
  },
  laOperacionSeRealizoConExito: {
    es: "La operación se realizó con éxito.",
    en: "The operation was carried out successfully."
  },
  agregarMontoaEstructura: {
    es: "Agregar Monto a Estructura",
    en: "Add Amount to Structure"
  },
  nombreEmpleado: {
    es: "Nombre Empleado",
    en: "Employee Name"
  },
  estructura: {
    es: "Estructura",
    en: "Structure"
  },
  noSeEncontroLaEstructura: {
    es: "No se encontro la estructura",
    en: "Structure not found"
  },
  confirmar: {
    es: "Confirmar",
    en: "Confirm"
  },
  elEmpleadoNoTieneAsignadoLaEstructuraOpcionalaLaCualPerteneceElTipoDeGasto: {
    es: "El empleado no tiene asignado la estructura opcional a la cual pertenece el tipo de gasto",
    en: "The employee has not been assigned the optional structure to which the type of expense belongs"
  },
  detalleEliminado: {
    es: "Detalle Eliminado",
    en: "Detail Removed"
  },
  eliminarDetalleDeGasto: {
    es: "Eliminar Detalle de Gasto",
    en: "Delete Expense Detail"
  },
  noEsPosibleEliminarElDetalle: {
    es: "No es posible eliminar el detalle. La solicitud ya ha sido respondida",
    en: "It is not possible to remove the detail. The request has already been answered"
  },
  estaSeguroDeEliminarEsteMovimiento: {
    es: "¿Está seguro de eliminar este Movimiento?",
    en: "Are you sure to delete this Movement?"
  },
  estaSeguroDeEliminarEstaEstructura: {
    es: "¿Está seguro de eliminar esta Estructura?",
    en: "Are you sure to delete this Structure?"
  },
  estaSeguroDeEliminarEsteTipoDeSolicitud: {
    es: "¿Está seguro de eliminar este tipo de solicitud?",
    en: "Are you sure to delete this request type?"
  },
  estaSeguroDeEliminarElDetalle: {
    es: "¿Está seguro de eliminar el detalle?",
    en: "Are you sure to delete the detail?"
  },
  misliquidaciones: {
    es: "Mis Liquidaciones",
    en: "My Settlements"
  },
  liquidacionConfirmada: {
    es: "Liquidación Confirmada",
    en: "Confirmed Settlement"
  },
  verLiquidacion: {
    es: "Ver Liquidación",
    en: "See Liquidation"
  },
  aunNoSeHaGeneradoSuLiquidacion: {
    es: "Aún no se ha generado su Liquidación",
    en: "Your Settlement has not yet been generated"
  },
  solicitarRevision: {
    es: "Solicitar Revisión",
    en: "Request Review"
  },
  confirmando: {
    es: "Confirmando...",
    en: "Confirming..."
  },
  laFechaInicialTieneQueEncontrarseDentroDelMesDeLaLiquidación: {
    es: "La fecha inicial tiene que encontrarse dentro del mes de la liquidación",
    en: "The initial date must be within the month of the settlement"
  },
  laFechaFinalTieneQueEncontrarseDentroDelMesDeLaLiquidación: {
    es: "La fecha final tiene que encontrarse dentro del mes de la liquidación",
    en: "The final date must be within the month of the settlement"
  },
  solicitudGeneradaConExito: {
    es: "Solicitud generada con éxito.",
    en: "Request generated successfully."
  },
  generarSolicitudDeRevision: {
    es: "Generar Solicitud de Revisión",
    en: "Generate Review Request"
  },
  especifiqueDetallamenteElIncovenienteConSuLiquidacion: {
    es: "Especifique detallamente el incoveniente con su liquidación",
    en: "Specify in detail the inconvenience with your settlement"
  },
  yaHaRealizadoUnaSolicitudDeRevisionParaLaLiquidacionActual: {
    es: "Ya ha realizado una solicitud de revisión para la liquidación actual.",
    en: "You have already made a review request for the current settlement."
  },
  validaciondeidentidad: {
    es: "Validación de Identidad",
    en: "Identity Validation"
  },
  misvalidaciones: {
    es: "Mis Validaciones",
    en: "My Validations"
  },
  contactosDelEmpleado: {
    es: "Contactos del Empleado",
    en: "Employee Contacts"
  },
  documentos: {
    es: "Documentos",
    en: "Documents"
  },
  contrato: {
    es: "Contrato",
    en: "Contract"
  },
  estadoDelContrato: {
    es: "Estado del Contrato",
    en: "Contract Status"
  },
  registrarElDocumentoIntegroColoresNitidoLegible: {
    es: "Registrar el documento íntegro, a colores, nítido y legible. - Formato jpg, jpeg ó png.",
    en: "Register the entire document, in color, clear and legible. - Format jpg, jpeg or png."
  },
  faltaSubirDatosDeSuFirma: {
    es: "Falta subir datos de su firma",
    en: "You need to upload your signature data"
  },
  elFormatoDeImagenNoEsCorrecta: {
    es: "El formato de imagen no es correcta",
    en: "The image format is not correct"
  },
  debeSubirLaImagenCorrespondiente: {
    es: "Debe subir la imagen correspondiente",
    en: "You must upload the corresponding image"
  },
  elijaUnTipoDeFirma: {
    es: "Elija un tipo de Firma",
    en: "Choose a type of Signature"
  },
  firmaEnviadaConExitoPendienteDeValidacion: {
    es: "Firma enviada con éxito. Pendiente de validación.",
    en: "Signature sent successfully. Validation pending."
  },
  noSePudoRealizarLaOperacion: {
    es: "No se pudo realizar la operación",
    en: "The operation could not be performed"
  },
  enviarFirma: {
    es: "Enviar Firma",
    en: "Send signature"
  },
  tipoDeFirma: {
    es: "Tipo de Firma",
    en: "Signature Type"
  },
  enviar: {
    es: "Enviar",
    en: "Send"
  },
  suFirmaDigitalSeRealizoConExito: {
    es: "Su firma digital se realizó con exito!.",
    en: "Your digital signature was successful!"
  },
  elUsuarioEstaPendienteDeEnviarSuFirma: {
    es: "El usuario esta pendiente de enviar su firma",
    en: "The user is waiting to send his signature"
  },
  elEstadoDelContratoFueActualizadoSatisfactoriamente: {
    es: "El estado del contrato fue actualizado satisfactoriamente.",
    en: "The contract status was successfully updated."
  },
  validarFirma: {
    es: "Validar Firma",
    en: "Validate Signature"
  },
  verImagen: {
    es: "Ver Imagen",
    en: "See image"
  },
  elEmpleadoAunNoHaEnviadoSuFirma: {
    es: "El empleado aún no ha enviado su firma.",
    en: "The employee has not yet submitted their signature."
  },
  laVerificacionDeLaFirmaEstaEnProceso: {
    es: "La verificación de la firma esta en proceso",
    en: "Signature verification is in process"
  },
  firmaVerificada: {
    es: "Firma verificada",
    en: "Verified signature"
  },
  solicitudDeVerificacionEliminada: {
    es: "Solicitud de verificación eliminada",
    en: "Removed verification request"
  },
  verificacionRechazada: {
    es: "Verificación rechazada",
    en: "Rejected verification"
  },
  controlDeAsistencias: {
    es: "CONTROL DE ASISTENCIAS",
    en: "ASSISTANCE CONTROL"
  },
  registrarAsistencia: {
    es: "Registrar asistencia",
    en: "Record attendance"
  },
  controlasistencia: {
    es: "Control Asistencia",
    en: "Control assistance"
  },
  misasistencias: {
    es: "Mis Asistencias",
    en: "My Assists"
  },
  suEmpresaCuentaConLaIntegracionDeAsistencia: {
    es: "Su empresa cuenta con la integración de asistencia",
    en: "Your company has support integration"
  },
  horas: {
    es: "Horas",
    en: "Hours"
  },
  eliminarHoraExtra: {
    es: "ELIMINAR HORA EXTRA",
    en: "Hours"
  },
  estaSeguroDeEliminarEstaHoraExtra: {
    es: "¿Está seguro de eliminar esta Hora extra?",
    en: "Hours"
  },
  haMarcadoSalidaaLas: {
    es: "Ha marcado Salida a las",
    en: "You have marked Departure at"
  },
  haMarcadoFinDeRefrigerioaLas: {
    es: "Ha marcado Fin de refrigerio a las",
    en: "You have marked End of snack at"
  },
  haMarcadoInicioDeRefrigerioaLas: {
    es: "Ha marcado Inicio de refrigerio a las",
    en: "You have marked Snack Start at"
  },
  haMarcadoAsistenciaaLas: {
    es: "Ha marcado asistencia a las",
    en: "Has marked attendance at"
  },
  horasDel: {
    es: "horas del",
    en: "hours of"
  },
  registrarInicioDeRefrigerio: {
    es: "Registrar Inicio de Refrigerio",
    en: "Register Snack Start"
  },
  registrarFinDeRefrigerio: {
    es: "Registrar Fin de Refrigerio",
    en: "Register End of Refreshment"
  },
  registrarSalida: {
    es: "Registrar Salida",
    en: "Check out"
  },
  verCuotas: {
    es: "Ver Cuotas",
    en: "See Quotas"
  },
  observaciones: {
    es: "Observaciones",
    en: "observations"
  },
  imagenRostro: {
    es: "Imagen Rostro",
    en: "Image Face"
  },
  imagenDocumento: {
    es: "Imagen Documento",
    en: "Image Document"
  },
  coincidenciasDeRostro: {
    es: "Coincidencias de rostros",
    en: "Face Matches"
  },
  contrasenaRequerida: {
    es: "Contraseña requerida",
    en: "Password required"
  },
  lasContraseñasNoCoinciden: {
    es: "Las contraseñas no coinciden",
    en: "Passwords do not match"
  },
  cambiarContrasena: {
    es: "Cambiar Contraseña",
    en: "Change Password"
  },
  ingreseActualContrasena: {
    es: "Ingrese actual contraseña",
    en: "Enter current password"
  },
  ingreseNuevaContrasena: {
    es: "Ingrese nueva contraseña",
    en: "Enter new password"
  },
  vuelvaaIngresarNuevaContrasena: {
    es: "Vuelva a ingresar nueva contraseña",
    en: "Re-enter new password"
  },
  cambiar: {
    es: "Cambiar",
    en: "Change"
  },
  elFormatoImagenNoEsCorrecto: {
    es: "El formato de imagen no es correcta",
    en: "The image format is not correct"
  },
  documentosConfirmados: {
    es: "Documentos Confirmados.",
    en: "Confirmed Documents."
  },
  confirmarDocumentosDeValidacionRegistradosPorSuAdministrador: {
    es: "Confirmar documentos de validación registrados por su administrador",
    en: "Confirm validation documents registered by your administrator"
  },
  fotosoImagenes: {
    es: "Fotos o Imágenes",
    en: "Photos or Images"
  },
  noSeRegistraronImagenes: {
    es: "No se registraron imagenes",
    en: "No images registered"
  },
  documentosPDF: {
    es: "Documentos PDF",
    en: "PDF documents"
  },
  noSeRegistraronDocumentos: {
    es: "No se registraron documentos",
    en: "No documents registered"
  },
  noSeRegistraronDocumentosDeIdentidad: {
    es: "No se registraron documentos de identidad",
    en: "No identity documents were registered"
  },
  registroActualizadoConExito: {
    es: "Registro actualizado con éxito",
    en: "Record updated successfully"
  },
  validacion: {
    es: "Validación",
    en: "Validation"
  },
  imagenActualDelRostro: {
    es: "Imagen Actual del Rostro",
    en: "Current Face Image"
  },
  imagenDelRostro: {
    es: "Imagen del Rostro (.jpg, .jpeg)",
    en: "Face Image (.jpg, .jpeg)"
  },
  imagenActualDelDocumentoDeIdentidad: {
    es: "Imagen Actual del Documento de Identidad",
    en: "Current Image of the Identity Document"
  },
  imagenDeDocumentoDeIdentidad: {
    es: "Imagen de Documento de Identidad (.jpg, .jpeg)",
    en: "Identity Document Image (.jpg, .jpeg)"
  },
  listaDeUsuariosAdmin: {
    es: "Lista de usuarios Admin",
    en: "Admin User List"
  },
  usuarioAdmin: {
    es: "Usuario Administrador",
    en: "User Administator"
  },
  gruposDeEmpresa: {
    es: "Grupos de Empresa",
    en: "Company Groups"
  },
  variablesglobales: {
    es: "Variables Globales",
    en: "Global Variables"
  },
  reportemateriales:{
    es: "Reporte Materiales",
    en: "Materials Report"
  },
  sinInformacion:{
    es: "Sin información",
    en: "No information"
  },
  estadoDelProducto: {
    es: "Estado del producto",
    en: "Product status"
  },
  movimientosDelMaterial: {
    es: "Movimientos del material",
    en: "Material movements"
  },
  fechaAcordadaDeRetorno: {
    es: "Fecha Acordada de Retorno",
    en: "Agreed Return Date"
  },
  noSeEncontraronMovimientos: {
    es: "No se encontraron movimientos",
    en: "No movements found"
  },
  ubigeo: {
    es: "Ubigeo",
    en: "Ubigeo"
  },
  paises: {
    es: "Países",
    en: "Countries"
  },
  abreviacion: {
    es: "Abreviación",
    en: "Abbreviation"
  },
  lenguaje: {
    es: "Lenguaje",
    en: "Language"
  },
  moneda: {
    es: "Moneda",
    en: "Currency"
  },
  idioma: {
    es: "Idioma",
    en: "Idiom"
  },
  codigoDeMoneda:{
    es: "Código de Moneda",
    en: "Currency Code"
  },
  zonaHoraria: {
    es: "Zona Horaria",
    en: "Time zone"
  },
  filtrosDeBusqueda:{
    es: "Filtros de búsqueda",
    en: "Search filters"
  },
  filtrarPorPais: {
    es: "Filtrar por país",
    en: "Filter by country"
  },
  filtrarPorDepartamento: {
    es: "Filtra por Departamento",
    en: "Filter by Department"
  },
  filtrarPorProvincia: {
    es: "Filtra por Provincia",
    en: "Filter by Province"
  },
  buscarRegion: {
    es: "Buscar región",
    en: "Search region"
  },
  region:{
    es: "Región",
    en: "Region"
  },
  regiones: {
    es: "Regiones",
    en: "Regions"
  },
  provincias: {
    es: "Provincias",
    en: "Provinces"
  },
  debeSeleccionarUnDepartamento: {
    es: "Debe seleccionar un departamento",
    en: "You must select a department"
  },
  comunas: {
    es: "Comunas",
    en: "Communes"
  },
  debeSeleccionarUnaProvincia: {
    es: "Debe seleccionar una provincia",
    en: "You must select a province"
  },
  tiposdenotificaciones: {
    es: "Tipos de Notificaciones",
    en: "Types of Notifications"
  },
  debeSeleccionarUnaURL: {
    es: "Debe seleccionar una URL",
    en: "You must select a URL"
  },
  creadoConExito: {
    es: "Creado con Éxito",
    en: "Successfully Created"
  },
  tipoDeNotificacion: {
    es: "Tipo de Notificación",
    en: "Notification Type"
  },
  zonashorarias: {
    es: "Zonas Horarias",
    en: "Time Zones"
  },
  tiposdesucursales: {
    es: "Tipos de sucursales",
    en: "Types of branches"
  },
  politicasdelsoftware: {
    es: "Políticas del Software",
    en: "Software Policies"
  },
  topeCotizacion: {
    es: "Tope Cotización",
    en: "Quotation ceiling"
  },
  topeCesantia: {
    es: "Tope Cesantía",
    en: "Unemployment ceiling"
  },
  remuneracionMensualMinima: {
    es: "Rem. Mensual Mínima",
    en: "Minimum Monthly Rem."
  },
  tramoFamiliar: {
    es: "Tramo Familiar",
    en: "Family Tranche"
  },
  variableGlobal:{
    es: "Variable Global",
    en: "Global Variable"
  },
  firmaDigital: {
    es: "Firma Digital",
    en: "Digital signature"
  },
  suFirmaDigitalEstaSiendoProcesada: {
    es: "Su firma digital está siendo procesada...",
    en: "Your digital signature is being processed..."
  },
  firmaManual: {
    es: "Firma Manual",
    en: "Manual Signature"
  },
  estructurasDeLiquidacion: {
    es: "Estructuras de Liquidación",
    en: "Settlement Structures"
  },
  tipoDeEstructura: {
    es: "Tipo de Estructura",
    en: "Structure Type"
  },
  asegureseDeSeleccionarUnaEmpresa: {
    es: "Asegurese de seleccionar una empresa",
    en: "Be sure to select a company"
  },
  asegureseDeSeleccionarAlMenosUnaEmpresa: {
    es: "Asegurese de seleccionar al menos una empresa",
    en: "Be sure to select at least one company"
  },
  debeDeSeleccionarUnPerfil: {
    es: "Debe de Seleccionar un Perfil",
    en: "You must select a profile"
  },
  editarDatosDelFamiliar: {
    es: "Editar datos del familiar",
    en: "Edit relative's data"
  },
  datosActualizadosSatisfactoriamente: {
    es: "Datos actualizados satisfactoriamente",
    en: "Data updated successfully"
  },
  eliminarFamiliar: {
    es: "Eliminar Familiar",
    en: "Delete Family"
  },
  estaSeguroDeEliminarAlFamiliar: {
    es: "¿Está seguro de eliminar al familiar?",
    en: "Are you sure to delete the familiar?"
  },

  crearRemuneracion: {
    es: "crear remuneracion",
    en: "create remuneration"
  },
  crearRemuneracionMasiva: {
    es: "crear remuneracion masiva",
    en: "create massive remuneration"
  },

  clase: {
    es: "Clase",
    en: "Class"
  },

  rangoInicio: {
    es: "Rango de Inicio",
    en: "Start Range"
  },

  rangoFinal: {
    es: "Rango Final",
    en: "Start Range"
  },

  rangoDirecciones: {
    es: "Rango de direcciones",
    en: "Address range"
  },

  escribirIP: {
    es: "escribir IP",
    en: "write IP"
  },

  agregarRangoIP: {
    es: "Agregar Rango de IP",
    en: "Add IP Range"
  },

  editarRangoIP: {
    es: "Editar Rango de IP",
    en: "Edit IP Range"
  },

  eliminarIP: {
    es: "ELIMINAR RANGO DE IP",
    en: "DELETE IP RANGE"
  },

  estaSeguroDeEliminarElRangoDeIP: {
    es: "¿Está seguro de eliminar el rango de IP?",
    en: "Are you sure to remove the IP range?"
  },

  estaSeguroDeEliminarLaIP: {
    es: "¿Está seguro de eliminar la IP?",
    en: "Are you sure to remove the IP?"
  },

  agregarIP: {
    es: "Agregar IP",
    en: "Add IP"
  },

  editarIP: {
    es: "Editar IP",
    en: "Edit IP"
  },
  /* RESPONSE API */

  // companies service
  empresacreadasatisfactoriamente: {
    es: "Empresa creada satisfactoriamente",
    en : "Company successfully created"
  },
  empresaactualizadasatisfactoriamente: {
    es: "Empresa actualizada satisfactoriamente",
    en : "Company updated successfully"
  },
  empresaeliminadasatisfactoriamente: {
    es: "Empresa eliminada satisfactoriamente",
    en : "Company deleted successfully"
  },
  accesorestringido: {
    es: "Acceso Restringido",
    en: "Access Denied"
  },
  elrutyaseencuentraregistrado: {
    es: "El rut ya se encuentra registrado",
    en : "The rut is already registered"
  },
  eltipodedocumentoesrequerido: {
    es: "El tipo de documento es requerido",
    en : "The type of document is required"
  },
  seleccioneunarchivodetipoPDF: {
    es: "Seleccione un archivo de tipo PDF",
    en : "Select a file of type PDF"
  },
  documentoregistradosatisfactoriamente: {
    es: "documento registrado satisfactoriamente",
    en : "document successfully registered"
  },
  nosepudorealizarlaasignacion: {
    es: "No se pudo realizar la asignación",
    en : "Assignment failed"
  },
  laasignacionserealizosatisfactoriamente: {
    es: "La asignación se realizó satisfactoriamente",
    en : "The assignment was successful"
  },
  //integrationService
  noseencontrarondispositivos: {
    es: "No se encontraron dispositivos",
    en : "No devices found"
  },
  eldispositivoyaseencuentravinculado: {
    es: "El dispositivo ya se encuentra vinculado",
    en : "The device is already paired"
  },
  basededatosenuso: {
    es: "Base de datos en uso",
    en : "Database in use"
  },
  credencialesdebasededatosincorrectas: {
    es: "Credenciales de Base de Datos Incorrectas",
    en : "Incorrect Database Credentials"
  },
  dispositivoregistradosatisfactoriamente: {
    es: "Dispositivo registrado satisfactoriamente",
    en : "Device successfully registered"
  },
  dispositivonodisponible: {
    es: "Dispositivo no disponible",
    en : "device not available"
  },
  nosepuedeincluirdosdispositivosenunamismaareadeunasucursal: {
    es: "No se puede incluir dos dispositivos en una misma area de una sucursal",
    en : "Two devices cannot be included in the same area of a branch"
  },
  dispositivoactualizadosatisfactoriamente: {
    es: "Dispositivo actualizado satisfactoriamente",
    en : "Device updated successfully"
  },
  dispositivoeliminadosatisfactoriamente: {
    es: "Dispositivo eliminado satisfactoriamente",
    en : "Device deleted successfully"
  },
  lafechafinnopuedesermenoralafechadeinicio: {
    es: "La fecha fin no puede ser menor a la fecha de inicio",
    en : "The end date cannot be less than the start date"
  },
  laasistenciayahasidoregistradaporeldispositivo: {
    es: "La asistencia ya ha sido registrada por el dispositivo",
    en : "The attendance has already been registered by the device"
  },
  asistenciacreadasatisfactoriamente: {
    es: "Asistencia creada satisfactoriamente",
    en : "Attendance created successfully"
  },
  asistenciamasivacreadasatisfactoriamente: {
    es: "Asistencia masiva creada satisfactoriamente",
    en : "mass attendance created successfully"
  },
  suasistenciaparahoyyahasidoregistrada: {
    es: "Su Asistencia para hoy, ya ha sido registrada.",
    en : "Your Attendance for today has already been recorded."
  },
  noseencontrolainasistencia: {
    es: "No se encontro la inasistencia",
    en : "No absence found"
  },
  inasistenciaeditadasatisfactoriamente: {
    es: "Inasistencia editada satisfactoriamente",
    en : "Absence Edited Successfully"
  },
  ocurrioalgunerroraseguresequeelempleadocuenteconunhorarioasignado: {
    es: "Ocurrió algún error, asegurese que el empleado cuente con un horario asignado.",
    en : "An error occurred, make sure that the employee has an assigned schedule."
  },
  inasistenciacreadasatisfactoriamente: {
    es: "Inasistencia creada satisfactoriamente",
    en : "Absence Created Successfully"
  },

  //Group company service
  notienepermisosparacrearungrupodecompanias: {
    es: "No tiene permisos para crear un grupo de compañias",
    en : "You do not have permission to create a group of companies"
  },
  yaseencuentradentrodelgrupodecompanias: {
    es: "Ya se encuentra dentro del grupo de Compañias",
    en : "You are already in the group of Companies"
  },
  grupodecompaniaregistradosatisfactoriamente: {
    es: "Grupo de Compañia registrado satisfactoriamente",
    en : "Company Group successfully registered"
  },
  grupodecompaniaactualizadosatisfactoriamente: {
    es: "Grupo de Compañia actualizado satisfactoriamente",
    en : "Company Group updated successfully"
  },
  grupodecompaniaeliminadosatisfactoriamente: {
    es: "Grupo de compañia eliminado satisfactoriamente",
    en : "Company group deleted successfully"
  },
  //user service
  sesioncerrada: {
    es: "Sesión cerrada",
    en : "closed session"
  },
  elnombredeusuarioyaseencuentraregistrado: {
    es: "El nombre de usuario ya se encuentra registrado",
    en : "Username is already registered"
  },
  elcorreoyaseencuentraregistrado: {
    es: "El correo ya se encuentra registrado",
    en : "The email is already registered"
  },
  elempleadonoseencuentraregistrado: {
    es: "El empleado no se encuentra registrado",
    en : "The employee is not registered"
  },
  elusuarionoexiste: {
    es: "El usuario no existe",
    en : "Username does not exist"
  },
  usuarioactualizadosatisfactoriamente: {
    es: "Usuario actualizado satisfactoriamente",
    en : "User updated successfully"
  },
  eliddeusuarioesrequerido: {
    es: "El id de usuario es requerido",
    en : "The user id is required"
  },
  contrasenaactualizadasatisfactoriamente: {
    es: "Contraseña actualizada satisfactoriamente",
    en : "Password updated successfully"
  },
  lacontrasenaanteriorylanuevacontrasenasoniguales: {
    es: "La contraseña anterior y la nueva contraseña, son iguales",
    en : "The old password and the new password are the same"
  },
  lacontrasenanoescorrecta: {
    es: "La contraseña no es correcta",
    en : "The password is not correct"
  },
  ocurrioalgunerrorintentelomastarde: {
    es: "Ocurrió algún error, intentelo mas tarde",
    en : "An error occurred, please try again later."
  },
  noseencontroalusuario: {
    es: "No se encontro al usuario",
    en : "User not found"
  },
  elusuarionoperteneceaningunacompania: {
    es: "El usuario no pertenece a ninguna compañia",
    en : "The user does not belong to any company"
  },
  usuarioeliminadosatisfactoriamente: {
    es: "Usuario eliminado satisfactoriamente",
    en : "User deleted successfully"
  },
  erroralcrearusuario: {
    es: "Error al crear Usuario",
    en : "Error creating User"
  },
  correoelectronicoenviadosatisfactoriamente: {
    es: "Correo electrónico enviado satisfactoriamente",
    en : "Email sent successfully"
  },
  //EmploymentHistoryService
  elempleadonoestacontratado: {
    es: "El empleado no esta contratado",
    en : "The employee is not hired"
  },
  historiallaboralcreadosatisfactoriamente: {
    es: "Historial Laboral creado satisfactoriamente",
    en : "Employment History created successfully"
  },
  historiallaboralactualizadosatisfactoriamente: {
    es: "Historial Laboral actualizado satisfactoriamente",
    en : "Employment History updated successfully"
  },
  historiallaboralelimiandosatisfactoriamente: {
    es: "Historial Laboral elimiando satisfactoriamente",
    en : "Employment History deleted successfully"
  },
  //ResponsabilityFamilyService
  responsabilidadfamiliarcreadasatisfactoriamente: {
    es: "Responsabilidad Familiar creada satisfactoriamente",
    en : "Family Responsibility created successfully"
  },
  responsabilidadfamiliaractualizadasatisfactoriamente: {
    es: "Responsabilidad Familiar actualizada satisfactoriamente",
    en : "Family Responsibility updates successfully"
  },
  responsabilidadfamiliareliminadasatisfactoriamente: {
    es: "Responsabilidad Familiar eliminada satisfactoriamente",
    en : "Family Responsibility deleted successfully"
  },
  //EmployeeService
  noseencontroalempleado: {
    es: "No se encontro al empleado",
    en : "Employee not found"
  },
  elempleadonoperteneceaningunacompania: {
    es: "El empleado no pertenece a ninguna compañia",
    en : "The user does not belong to any company"
  },
  empleadoeliminadosatisfactoriamente: {
    es: "Empleado eliminado satisfactoriamente",
    en : "User deleted successfully"
  },
  elempleadocuentaconuncontratoporfavorediteelcontratodirectamente: {
    es: "El empleado cuenta con un contrato, por favor edite el contrato directamente",
    en : "The employee has a contract, please edit the contract directly"
  },
  elempleadocuentaconuncontratoporfavorelimineodesactiveelcontratoantesdedesactivaralempleado: {
    es: "El empleado cuenta con un contrato, por favor elimine o desactive el contrato antes de desactivar al empleado",
    en : "The employee has a contract, please delete or deactivate the contract before deactivating the employee"
  },
  empleadodesactivadosatisfactoriamente: {
    es: "Empleado desactivado satisfactoriamente",
    en : "Employee disabled successfully"
  },
  elcorreoesincorrecto: {
    es: "El correo es incorrecto",
    en : "the email is wrong"
  },
  contactocreadosatisfactoriamente: {
    es: "Contacto creado satisfactoriamente",
    en : "Contact created successfully"
  },
  contactoactualizadosatisfactoriamente: {
    es: "Contacto actualizado satisfactoriamente",
    en : "Contact updated successfully"
  },
  contactoeliminadosatisfactoriamente: {
    es: "Contacto eliminado satisfactoriamente",
    en : "Contact deleted successfully"
  },
  //BranchOfficeService
  ladireccióndelasucursalyaseencuentraregistrado: {
    es: "La dirección de la sucursal ya se encuentra registrado",
    en : "The address of the branch is already registered"
  },
  sucursalcreadasatisfactoriamente: {
    es: "Sucursal creada satisfactoriamente",
    en : "Branch created successfully"
  },
  sucursalactualizadasatisfactoriamente: {
    es: "Sucursal actualizada satisfactoriamente",
    en : "Branch updated successfully"
  },
  sucursaleliminadasatisfactoriamente: {
    es: "Sucursal eliminada satisfactoriamente",
    en : "Branch deleted successfully"
  },
  elTipodesucursalyaseencuentraregistrado: {
    es: "El Tipo de Sucursal ya se encuentra registrado",
    en : "The Branch Type is already registered"
  },
  tipodesucursalcreadosatisfactoriamente: {
    es: "Tipo de sucursal creado satisfactoriamente",
    en : "Branch type created successfully"
  },
  noseencontroeltipodesucursal: {
    es: "No se encontro el tipo de sucursal",
    en : "Branch type not found"
  },
  tipodesucursalactualizadosatisfactoriamente: {
    es: "Tipo de sucursal actualizado satisfactoriamente",
    en : "Branch type updated successfully"
  },
  tipodesucursaleliminadosatisfactoriamente: {
    es: "Tipo de sucursal eliminado satisfactoriamente",
    en : "Branch type deleted successfully"
  },
  //Proyecto eliminado satisfactoriamente
  proyectoeliminadosatisfactoriamente: {
    es: "Proyecto eliminado satisfactoriamente",
    en : "Project deleted successfully"
  },
  nosehageneradoremuneracionparaelempleadoenelperiodoseleccionado: {
    es: "No se ha generado remuneración para el empleado en el periodo seleccionado",
    en : "No remuneration has been generated for the employee in the selected period"
  },
  lasumatoriadeparticipacionessuperaelsueldoliquidodelmes: {
    es: "La sumatoria de participaciones supera el sueldo liquido del mes",
    en : "The sum of participations exceeds the net salary of the month"
  },
  lasumatoriasicumple: {
    es: "La sumatoria si cumple",
    en : "The sum if it meets"
  },
  lasumatoriadeporcentajesuperaellimite: {
    es: "La sumatoria de porcentajes supera el 100%",
    en : "The sum of percentages exceeds 100%"
  },
  participaciondelempleadocreadosatisfactoriamente: {
    es: "Participacion del empleado creado satisfactoriamente",
    en : "Employee participation created successfully"
  },
  //costCenterService
  elnombredelcentrodecostoyaseencuentraregistrado: {
    es: "El nombre del centro de costo ya se encuentra registrado",
    en : "The name of the cost center is already registered"
  },
  centrodecostoeliminadosatisfactoriamente: {
    es: "Centro de Costo eliminado satisfactoriamente",
    en : "Cost Center deleted successfully"
  },
  //BusinessAreaService
  areadenegocioeliminadosatisfactoriamente: {
    es: "LArea de negocio eliminado satisfactoriamente",
    en : "Business area successfully removed"
  },
  //ChargeService
  elcargoyaseencuentraregistrado: {
    es: "El cargo ya se encuentra registrado",
    en : "The charge is already registered"
  },
  cargocreadosatisfactoriamente: {
    es: "Cargo creado satisfactoriamente",
    en : "Charge created successfully"
  },
  elnombredelcargoyaseencuentraregistrado: {
    es: "El nombre del cargo ya se encuentra registrado",
    en : "The name of the position is already registered"
  },
  cargoeditadosatisfactoriamente: {
    es: "Cargo editado satisfactoriamente",
    en : "Charge edited successfully"
  },
  cargoeliminadosatisfactoriamente: {
    es: "Cargo eliminado satisfactoriamente",
    en : "Charge deleted successfully"
  },
  //DocumentContractService
  lasclausulaspredeterminadasnosepuedeneditar: {
    es: "Las clausulas predeterminadas no se pueden editar",
    en : "Default clauses cannot be edited"
  },
  clausulaseditadassatisfactoriamente: {
    es: "Clausulas editadas satisfactoriamente",
    en : "clauses updated successfully"
  },
  clausulaeliminadasatisfactoriamente: {
    es: "Clausula eliminada satisfactoriamente",
    en : "Clause deleted successfully"
  },
  documentodecontratoeditadosatisfactoriamente: {
    es: "Documento de Contrato editado satisfactoriamente",
    en : "Contract document edited successfully"
  },
  //WorkingScheduleCompanyService
  horariocreadosatisfactoriamente: {
    es: "Horario creado satisfactoriamente",
    en : "Schedule created successfully"
  },
  horarioeditadosatisfactoriamente: {
    es: "Horario editado satisfactoriamente",
    en : "Schedule updated successfully"
  },
  horarioeliminadosatisfactoriamente: {
    es: "Horario eliminado satisfactoriamente",
    en : "Schedule deleted successfully"
  },
  detalledehorarioeditadosatisfactoriamente: {
    es: "Detalle de Horario editado satisfactoriamente",
    en : "Schedule detail edited successfully"
  },
  eliniciodecolacionnopuedesermayoralfinaldecolacion: {
    es: "El inicio de colación no puede ser mayor al final de colación",
    en : "The start of the collation cannot be greater than the end of the collation"
  },
  aseguresedeseleccionarlosdiaslaborales: {
    es: "Asegurese de seleccionar los dias laborales",
    en : "Be sure to select business days"
  },
  aseguresedeelegirunhorario: {
    es: "Asegurese de elegir un horario",
    en : "Be sure to pick a time"
  },

  //ViewService
  elusuarionoseencuentradisponible: {
    es: "El usuario no se encuentra disponible",
    en : "The user is not available"
  },
  //TypeExpenseService
  eltipodegastoyaseencuentraregistrado: {
    es: "El tipo de gasto ya se encuentra registrado",
    en : "The type of expense is already registered"
  },
  tipodegastocreadosatisfactoriamente: {
    es: "Tipo de gasto creado satisfactoriamente",
    en : "Type of expense created successfullyy"
  },
  tipodegastoactualizadosatisfactoriamente: {
    es: "Tipo de gasto actualizado satisfactoriamente",
    en : "Type of expense updated successfully"
  },
  tipodegastoeliminadosatisfactoriamente: {
    es: "Tipo de gasto eliminado satisfactoriamente",
    en : "Type of expense deleted successfully"
  },

  //VariableGlobalService
  yaexistevariablesglobalesparaesafechaeditalas:{
    es: "Ya existe Variables globales para esa fecha, editalas",
    en : "Global Variables already exist for that date, edit them" 
  },
  variablesglobalescreadasconexito:{
    es: "Variables globales creadas con éxito",
    en : "Global variables created successfully" 
  },
  variablesglobaleseditadaconexito:{
    es: "Variables globales editada con éxito",
    en : "Global variables edited successfully" 
  },
  variablesglobaleseliminadapermanentemente:{
    es: "Variables globales eliminada permanentemente",
    en : "Global variables permanently removed" 
  },

  //rleStrcutureService
  estructuracreadasatisfactoriamente: {
    es: "Estructura creada satisfactoriamente",
    en : "Structure created successfully"
  },
  laestructuranoexiste:{
    es: "La estructura no existe",
    en : "Structure does not exist" 
  },
  estructurasopcionalescreadosconexito:{
    es: "Estructuras opcionales creados con éxito",
    en : "Optional structures created successfully"
  },
  elopcionalyaseencuentrapresenteensuempresaporfavormodifiquesuestado:{
    es: "El opcional ya se encuentra presente en su empresa, por favor modifique su estado",
    en : "The optional is already present in your company, please modify its status" 
  },
  elopcionalnoperteneceasuempresa:{
    es: "El opcional no pertenece a su empresa",
    en : "The optional doesn't belong to your company"
  },
  estructuraopcionalescreadoconexito:{
    es: "Estructura opcionales creado con éxito",
    en : "Optional structure created successfully"
  },
  existeremuneracionparaesteperiododeestetrabajador:{
    es: "Existe Remuneracion para este periodo de este trabajador",
    en : "There is Remuneration for this period of this worker"
  },
  noexisteremuneracionparaesteperiododeestetrabajador:{
    es: "No existe Remuneracion para este periodo de este trabajador",
    en : "There is no remuneration for this period of this worker"
  },
  estructuraeliminadaconexito:{
    es: "Existe Liquidación para este periodo de este trabajador",
    en : "Structure removed successfully"
  },
  nosepuedegenerarlaremuneracionsieltotalapagarsaleceroomenor:{
    es: "No se puede generar la remuneración si el total a pagar sale cero o menor",
    en : "Remuneration cannot be generated if the total to be paid is zero or less"
  },
  elsueldobasenopuedeserceroomenor:{
    es: "El sueldo base no puede ser cero o menor",
    en : "Base salary cannot be zero or less"
  },
  noseencuentraregistrosdelvalvenesteperiodo:{
    es: "No se encuentra registros del valV en este periodo",
    en : "No Vval records found in this period"
  },
  elmesoanonoseencuentrandisponiblesdelafponotieneregistrodeeste:{
    es: "El Mes o Año no se encuentran disponibles del AFP o no tiene registro de este",
    en : "The Month or Year are not available from the AFP or there is no record of this"
  },
  esteempleadonotieneuntipodegratificacion:{
    es: "Este empleado no tiene un tipo de Gratificación",
    en : "This employee doesn't have a type of Gratuity"
  },
  yasegenerosusremuneracionesacadatrabajador:{
    es: "Ya se genero sus remuneraciones a cada trabajador",
    en : "Their remunerations have already been generated for each worker"
  },
  elperiodonoseencuentradisponible:{
    es: "El periodo no se encuentra disponible",
    en : "The period isn't available"
  },
  nosepudocrearlasremuneraciones:{
    es: "No se pudo crear las remuneraciones",
    en : "Unable to create remunerations"
  },

  //planilla remuneraciones
  sindatapormostrar:{
    es: "Sin data por mostrar",
    en : "No data to show"
  },
  sindataderemuneraciones:{
    es: "Sin data de remuneraciones",
    en : "No salary data"
  },
  aseguresequeelempleadotengaliquidaciones:{
    es: "Asegurese que el empleado tenga liquidaciones y que la tabla 'cm' contenga data, para el año seleccionado",
    en : "Make sure the employee has payoffs and that the 'cm' table contains data, for the selected year"
  },
  nocuentasconliquidacionesparaelañoseleccionado:{
    es: "No cuentas con liquidaciones para el año seleccionado. Comuníquese con su administrador.",
    en : "You do not have settlements for the selected year. Contact your administrator."
  },

  //proyecto
  noseencontraronproyectos:{
    es: "No se encontraron proyectos",
    en : "No projects found"
  },
  noseencontraronproyectosiniciadosenelano:{
    es: "No se encontraron proyectos iniciados en el año ",
    en: "No projects started in the year were found "
  },

  //ip
  acceptIP:{
    es: "Solo se aceptan direcciones IP's pertenecientes a la v4. Tienen el formato x.x.x.x, donde a la x se le denomina un octeto y debe ser un valor decimal entre 0 y 255. Los octetos se encuentran separados por puntos.",
    en: "Only IP addresses belonging to v4 are accepted. They have the format x.x.x.x, where x is called an octet and must be a decimal value between 0 and 255. The octets are separated by periods."
  },

  rangoeditadoip:{
    es: "Rangos de ip editados satisfactoriamente",
    en: "Successfully edited ip ranges"
  },

  rangosdeipcreadossatisfactoriamente:{
    es: "Rangos de ip creados satisfactoriamente",
    en: "IP ranges created successfully"
  },

  yaexisteunrangoconlasmismasdireccionesparaelusuario:{
    es: "Ya existe un rango con las mismas direcciones para el usuario",
    en: "A range with the same addresses already exists for the user"
  },

  yaexisteunaipdenegadaconlamismadireccionparaelusuario:{
    es: "Ya existe una ip denegada con la misma dirección para el usuario",
    en: "There is already an ip denied with the same address for the user"
  },

  ipcreadosatisfactoriamente:{
    es: "IP creado satisfactoriamente",
    en: "IP created successfully"
  },

  ipeditadosatisfactoriamente:{
    es: "IP editado satisfactoriamente",
    en: "IP edited successfully"
  },

  rangoipeliminado:{
    es: "Rango de IP eliminado",
    en: "IP range removed"
  },

  //notificacionService
  noexisteeltipodenotificacion:{
    es: "No existe el tipo de notificación",
    en : "Notification type does not exist" 
  },
  editadoconexito:{
    es: "Editado con éxito",
    en : "Edited successfully" 
  },
  noexisteeltipodenotifiacion:{
    es: "No existe el tipo de notificación",
    en : "Notification type does not exist" 
  },
  notificacioneliminada:{
    es: "Notificación eliminada",
    en : "Notification removed" 
  },

  //TimeZoneService
  lazonahorarianoexiste:{
    es: "La Zona horaria no existe",
    en : "Time zone does not exist" 
  },
  lazonahorariaestasiendousada:{
    es: "La Zona horaria esta siendo usada",
    en : "Time zone is being used" 
  },
  zonahorariaeliminada:{
    es: "Zona horaria eliminada",
    en : "Removed time zone" 
  },
  zonahorariaeditadaconexito:{
    es: "Zona horaria editada con éxito",
    en : "Successfully edited time zone" 
  },
  yaseencuentraregistradoestazonahoraria:{
    es: "Ya se encuentra registrado esta zona horaria",
    en : "This time zone is already registered" 
  },
  zonahorariacreadaconexito:{
    es: "Zona horaria creada con éxito",
    en : "Time zone created successfully" 
  },
  
  //politicsService
  documentoactualizadosatisfactoriamente:{
    es: "documento actualizado satisfactoriamente",
    en : "document successfully updated" 
  },

  //cmService
  nocuentaconlospermisossuficientes:{
    es: "No cuenta con los permisos suficientes",
    en : "You do not have sufficient permissions" 
  },
  yahayregistroparaesteaño:{
    es: "Ya hay registro para este año",
    en : "There is already registration for this year" 
  },
  noexisteesteregistro:{
    es: "No existe este registro",
    en : "This record does not exist" 
  },
  
  //AFPService
  detalleafpeliminado:{
    es: "Detalle AFP eliminado",
    en : "AFP detail removed" 
  },
  detalledeafpeditadoconexito:{
    es: "Detalle de AFP editado con éxito",
    en : "AFP detail successfully edited" 
  },
  yaexisteundetallparaestafechayafp:{
    es: "Ya existe un detalle para esta fecha y AFP",
    en : "There is already a detail for this date and AFP" 
  },
  detalledeafpcreadoconexito:{
    es: "Detalle de AFP creado con éxito",
    en : "AFP detail successfully created" 
  },

  //asistencia

 selecioneunafechadeinicioyfinparabuscarlasasistencias:{
    es: "Seleccione una fecha de incio y fin para buscar las asistencias.",
    en : "Select a start and end date to search for attendance." 
  },
  aseguresequelafechainicialseamenorquelafinal:{
    es: "Asegurese que la fecha inicial sea menor que la final.",
    en : "Make sure the start date is less than the end date." 
  },
  lafechafinnopuedesermayoralafechactual:{
    es: "La fecha fin no puede ser mayor a la fecha actual.",
    en : "The end date cannot be greater than the current date." 
  },
  horaextraeliminada:{
    es: "Hora extra eliminada",
    en : "Overtime removed" 
  },
  horaextraecreadoconexito:{
    es: "Hora extra creado con éxito",
    en : "Overtime created successfully" 
  },
  horaextraeditadaconexito:{
    es: "Hora extra editada con éxito",
    en : "Overtime successfully edited" 
  },

  //assistanceWorkerService
  laasistenciaparalafechaseleccionadayaseencuentraregistrada:{
    es: "La asistencia para la fecha seleccionada ya se encuentra registrada.",
    en : "The attendance for the selected date is already registered." 
  },
  asistenciaeditada:{
    es: "asistencia editada",
    en : "edited assistance" 
  },
  lafechafinnopuedesermenoralafechainicio:{
    es: "La fecha fin no puede ser menor a la fecha inicio",
    en : "The end date cannot be less than the start date." 
  },

  //integrationService

  //advanceService
  yaexisteunasolicitudpendienteenelmesactual:{
    es: "Ya existe una solicitud pendiente en el mes actual.",
    en : "There is already a pending request in the current month." 
  },
  anticipocreadosatisfactoriamente:{
    es: "Anticipo creado satisfactoriamente.",
    en : "Advance created successfully." 
  },
  adelantoeliminado:{
    es: "Adelanto eliminado",
    en : "advance removed" 
  },
  eliminarAnticipo:{
    es: "ELIMINAR ANTICIPO",
    en : "DELETE ADVANCE" 
  },
  noesposibleeliminarelanticipolasolicitudyahasidorespondida:{
    es: "No es posible eliminar el anticipo. La solicitud ya ha sido respondida",
    en : "It is not possible to delete the advance. The request has already been answered" 
  },
  estasegurodeeliminaresteanticipo:{
    es: "¿Está seguro de eliminar este Anticipo?",
    en : "Are you sure to delete this Advance?" 
  },

  //REQUEST
  lasolicitudaunnohasidorespondida:{
    es: "La solicitud aún no ha sido respondida.",
    en : "The request has not yet been answered." 
  },
  lasolicitudhasidorechazada:{
    es: "La solicitud ha sido rechazada.",
    en : "The request has been rejected." 
  },
  estadodesolicitudactualizadoconexito:{
    es: "Estado de solicitud actualizado con éxito.",
    en : "Application status updated successfully." 
  },
  nocuentasconunaliquidaciongeneradaenelmesactual:{
    es: "No cuentas con una liquidación generada en el mes actual.",
    en : "You do not have a settlement generated in the current month." 
  },
  lafechainicialtienequeencontrarsedentrodelmesdelaliquidacion:{
    es: "La fecha inicial tiene que encontrarse dentro del mes de la liquidación",
    en : "The initial date must be within the month of the settlement" 
  },
  deseaaceptarorechazarlasolicitud:{
    es: "¿Desea Aceptar o Rechazar la Solicitud?",
    en : "Do you want to Accept or Reject the Request?" 
  },
  agregarSolicitud:{
    es: "Agregar Solicitud",
    en : "Add Request" 
  },
  motivoesuncamporequerido:{
    es: "Motivo es un campo requerido",
    en : "Reason is a required field" 
  },
  fechaInicial:{
    es: "Fecha Inicial",
    en : "Initial date" 
  },
  fechaInicialEsUnCampoRequerido:{
    es: "Fecha Inicial es un campo requerido",
    en : "Start Date is a required field" 
  },
  fechaFinal:{
    es: "Fecha Final",
    en : "Final date" 
  },
  fechaFinalEsUncampoRequerido:{
    es: "Fecha Final es un campo requerido",
    en : "End Date is a required field" 
  },
  observacionEsUncampoRequerido:{
    es: "Observación es un campo requerido",
    en : "Observation is a required field" 
  },

  //movimientos

  debeDeSeleccionarUnTipoDeMovimiento:{
    es: "Debe seleccionar un tipo de movimiento",
    en : "You must select a type of movement" 
  },
  elempleadonoperteneceaestaempresa:{
    es: "El empleado no pertenece a esta empresa",
    en : "The employee does not belong to this company" 
  },
  movimientocreadoconexito:{
    es: "Movimiento creado con éxito",
    en : "movement created successfully" 
  },
  movimientoeditadoconexito:{
    es: "Movimiento editado con éxito",
    en : "Movement edited successfully" 
  },
  elmovimientonopertneceaestaempresa:{
    es: "El movimiento no pertenece a esta empresa",
    en : "The movement does not belong to this company" 
  },
  asignamientoeliminado:{
    es: "Asignamiento eliminado",
    en : "Assignment removed" 
  },
  movimientoeliminado:{
    es: "movimiento eliminado",
    en : "move removed" 
  },

  //validations
  aprobado:{
    es: "Aprobado",
    en : "Approved" 
  },
  rechazado:{
    es: "Rechazado",
    en : "Refused" 
  },
  pendiente:{
    es: "Pendiente",
    en : "Earring" 
  },
  aceptada:{
    es: "aceptada",
    en : "accepted" 
  },
  revisado:{
    es: "Revisado",
    en : "Reviewed" 
  },
  norevisado:{
    es: "No Revisado",
    en : "Not reviewed" 
  },
  actualizarvalidaciondeidentidad:{
    es: "Actualizar Validación de Identidad",
    en : "Update Identity Validation" 
  },
  enviarvalidacion:{
    es: "Enviar Validación",
    en : "Send Validation" 
  },
  laverificaciondeidentidadestaenproceso:{
    es: "La verificación de identidad está en proceso",
    en : "Identity verification is in process" 
  },
  identidadverificada:{
    es: "Identidad verificada",
    en : "verified identity" 
  },
  verificaciondeidentidadeliminada:{
    es: "Verificación de identidad eliminada",
    en : "Identity verification removed" 
  },
  verificaciondeidentidadrechazada:{
    es: "Verificación de identidad rechazada",
    en : "Identity verification rejected" 
  },

  //solicitud de gastos
  editarestadodereporte:{
    es: "Editar estado de reporte",
    en : "Edit report status" 
  },
  agregarreporte:{
    es: "Agregar reporte",
    en : "Add report" 
  },
  estadoreporte:{
    es: "Estado reporte",
    en : "Report status" 
  },


  
}