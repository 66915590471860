import { useEffect, useState } from "react"
import { Protected } from "@/components/layout/Protected"
import { Button, Container, Grid, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { Props } from "./UnauthorizedPage.type"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/constants/role-type"
import { useHistory } from "react-router-dom"
import { ROUTE_COMPANIES, ROUTE_MY_VALIDATIONS, ROUTE_USER } from "@/toolbox/constants/route-map"
import '../PageNoFound/PageNotFound.css'

export const UnauthorizedPageView: React.FC<Props> = (props:any): JSX.Element => {

    const history = useHistory();

    const redirect = () => {
        const userSession = readLocalStorage(KEY_USER_DATA);
        const role = userSession?.user?.role;

        switch (role) {
            case ROLE_SUPER_ADMIN:
                history.push(ROUTE_COMPANIES);
                break;

            case ROLE_ADMIN:
                history.push(ROUTE_USER);
                break;

            case ROLE_TRABAJADOR:
                history.push(ROUTE_MY_VALIDATIONS);
                break;
        }
    }

    return (
        <Container className={'page-not-found-container'}>
            <Grid>
                {/* imagen */}
            </Grid>
            <Grid className={'text-container'}>
                <div className={'text-error'}>PÁGINA NO AUTORIZADA</div>
            </Grid>
            <Grid className={'number-container'}>
                <div className={'number-error'}>401</div>
            </Grid>
            <Grid className={'paragraph-container'}>
                <div className={'paragraph-error'}>Lo sentimos, no estas autorizado para ver esta página.</div>
            </Grid>
            <Grid className={'btn-container'}>
                <Button variant="contained" className={'btn-redirect'} onClick={redirect}>IR A LA PÁGINA DE INICIO</Button>
            </Grid>
        </Container>
    )
}