import { ViewsService } from "@/service/services/Views.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalViewsDelete.type";
import ___PHRASE from "@/service/translate/translateService";

export const ModalViewsDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, view, onListViews, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeleted= async()=>{
        setLoading(true);
        await ViewsService.deleteView(view.id).then((data) => {
            onListViews && onListViews();
            closeModal && closeModal();
        })
        setLoading(false);
    }
  
    return (
        <>
        <Modal
        open={open}
        handleClose={closeModal}
        size="xs"
        >
        <ModalHeader
            text="ELIMINAR VISTA"
            className='positionElements'
            onCancel={closeModal}
            clearState={clearState}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
            ¿Está seguro de eliminar esta vista?
            </Typography>
        </ModalBody>

        <ModalFooter 
            confirmText={___PHRASE("eliminar")}
            cancelText={___PHRASE("cancelar")}
            onCancel={closeModal}
            className="footerRight"
            onConfirm={onDeleted}
            clearState={clearState}
        />
        </Modal>
        </>
    )
}