import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { AfpService } from '@/service/services/Afp.service';
import { Props } from './AFP.type';
import ___PHRASE from '@/service/translate/translateService';
import { ModalCM } from '@/components/Modal/ModalCM';
import { ModalCMDelete } from '@/components/Modal/ModalCMDelete';
import moment from 'moment'
import { ModalAFP } from '@/components/Modal/ModalAFP';
import { ModalAFPDelete } from '@/components/Modal/ModalAFPDelete';


export const AFPView: React.FC<Props> = (props: any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [afp, setAFP] = useState<any>([]);
    const [afpType, setAFPType] = useState<any>([]);
    const [showAFPModal, setShowAFPModal] = useState<boolean>(false);
    const [showAFPDeleteModal, setShowAFPDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [afpSelected, setAFPSelected] = useState<any>('');
    const [year, setYear] = useState<any>([]);
    moment.locale('es')

    const headerAFP = [
        { name: 'afpcodigo', label: "codigo", filter: false, Chip: true },
        { name: 'afpname', label: "AFP", filter: false, Chip: true },
        { name: 'cot', label: "cot", filter: false, Chip: true },
        { name: 'sis', label: "sis", filter: false, Chip: true },
        { name: 'year', label: "año", filter: false, Chip: false },
        { name: 'nameMonth', label: "mes", filter: false, Chip: false },
    ]

    useEffect(() => {
        getDetailAFP();
        getTypeAFP()
    }, []);


    const getDetailAFP = async () => {
        setLoading(true);
        const yearNow = moment().year()
        const monthNow = moment().month()

        // const response = await AfpService.getDetailAfp(yearNow.toString(), monthNow.toString()).then((data) => {
        await AfpService.getDetailAfp(yearNow.toString(), "3").then(async (data) => {
            const oldData = data.data || []
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                const [ano, mes, dia] = item.date.split("-");
                const date = new Date(ano, mes - 1, dia);
                const nameMonth = date.toLocaleString('es-CL', { month: 'long' });

                let obj = {
                    id: item.id,
                    idafp: item.idafp,
                    afpcodigo: item.afpcodigo,
                    afpname: item.afpname,
                    cot: item.cot,
                    sis: item.sis,
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    nameMonth: nameMonth,
                    status: item.status,
                }
                newData.push(obj);
            });
            setAFP(newData || [])

            await AfpService.getYearDetailAFP().then((data) =>{
                setYear(data.data)
            })
        })
        setLoading(false);
    }

    const getTypeAFP = async () => {
        await AfpService.getAfpType().then((data) => {
            setAFPType(data.data)
        })
    }

    function RecuperarData(value){
        setAFPSelected(value);
    }

    const searchData = async (value) => {
        // const response = await companiesService.getCompanies(value);
        // if (response.data) {
        //     setCompanies(response.data || [])
        // } else {
        //     console.log('error')
        // }
    }

    return (
        <Protected>
            {loading && <Loading/>}

            <Container>
                <TableDataV2
                title={"Variables de AFP"}
                data={afp || []}
                header={headerAFP}
                actionSelect={setActionSelected}
                setModalSave={setShowAFPModal}
                setModalDelete={setShowAFPDeleteModal}
                btn_disabled={false}
                btn_delete_disable={false}
                status_action
                // textSearch={"buscar por año"}
                // searchData={searchData}
                RecuperarData={RecuperarData}
                />
            </Container>

            {
                showAFPModal && (
                <ModalAFP
                    open={showAFPModal}
                    closeModal={()=>{setShowAFPModal(false)}}
                    actionSelected={actionSelected}
                    onListAFP={getDetailAFP}
                    afp={afpSelected}
                    afpType={afpType}
                    year={year}
                    clearState={()=>{setAFPSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalAFPDelete
                    open={showAFPDeleteModal}
                    closeModal={()=>{setShowAFPDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListAFP={getDetailAFP}
                    afp={afpSelected}
                    clearState={()=>{setAFPSelected('')}}
                />
                )
            }
        </Protected>
    )
};