import { AssistanceService } from "@/service/services/Assistance.service";
import { WorkerContactService } from "@/service/services/WorkerContact.service";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalWorkerContactDelete.type";
import ___PHRASE from "@/service/translate/translateService";
import { Loading } from "@/components/common/Loading";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalWorkerContactDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, contact, onListContacts, workerSelected, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
    });

    const onDeleted= async()=>{
        setLoading(true);
        await WorkerContactService.deleteEmployeeContact(contact.id).then((data) => {
          let apimessagge = apiResponseTranslate(data?.data?.message || '');
          setSnackBarConfig(prev => ({ ...prev, open: true, 
            severity:'success', 
            message: `${ apimessagge || ___PHRASE("eliminadoCorrectamente")}`  }
          ));
          onListContacts && onListContacts(workerSelected.id);
          closeModal && closeModal();
          clearState && clearState();
        })
        setLoading(false);
    }
  
  return (
    <>
    {loading && <Loading/>}
    <Snackbar
        open={snackBarConfig.open} 
        autoHideDuration={snackBarConfig.autoHideDuration} 
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
        </Alert>
    </Snackbar>
    <Modal
      open={open}
      handleClose={closeModal}
      disableEscapeKeyDown disableBackdropClick
      size="xs"
    >
      <ModalHeader
        text={___PHRASE("eliminar")+' '+___PHRASE("contacto")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {___PHRASE("estaSeguroDeEliminarEsteContacto")}
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}