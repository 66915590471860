import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idCompany = dataUser?.user?.id_company;

export const ProductsRepository = {

    getProducts: async(search = ''): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/product?idcompany=${idCompany}&search=${search}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createProducts: async (dataProduct) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/product/create`, {
            name: dataProduct.name,
            description: dataProduct.description,
            brand: dataProduct.brand,
            idcompany: idCompany,

            observations: dataProduct.observations,
            status_initial: dataProduct.status_initial,
            num_doc: dataProduct.num_doc,
            model: dataProduct.model,
            net_cost: dataProduct.net_cost,
            date_purchase: dataProduct.date_purchase,
            estimated_duration: dataProduct.estimated_duration,

            id_employee: dataProduct.id_employee,
            id_branch_office: dataProduct.id_branch_office,
            date: dataProduct.date,
            agreed_return_date: dataProduct.agreed_return_date,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editProducts: async (dataProduct, idProduct: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/product/update/${idProduct}`, {
            name: dataProduct.name,
            description: dataProduct.description,
            brand: dataProduct.brand,
            status: dataProduct.status,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    inventaryGeneralProduct: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/movementProduct/inventaryGeneralProduct?idcompany=${idCompany}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    purchasesByProdyct: async(idProduct: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/product/purchasesByProdyct/${idProduct}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    movementsByPurchase: async(idPurchase: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/movementProduct/movementsByPurchase/${idPurchase}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    changeBranchOfficeMovement: async (dataProduct) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/movementProduct/changeBranchOffice`, {
            id_products_purchase: dataProduct.id_products_purchase,
            observations: dataProduct.observations,
            id_employee: dataProduct.id_employee,
            id_branch_office: dataProduct.id_branch_office,
            date: dataProduct.date,
            agreed_return_date: dataProduct.agreed_return_date,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    benefitMovemnt: async (dataProduct) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/movementProduct/benefit`, {
            id_products_purchase: dataProduct.id_products_purchase,
            observations: dataProduct.observations,
            id_employee: dataProduct.id_employee,
            id_branch_office: dataProduct.id_branch_office,
            date: dataProduct.date,
            agreed_return_date: dataProduct.agreed_return_date,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    refundMovement: async (dataProduct) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/movementProduct/refund`, {
            id_products_purchase: dataProduct.id_products_purchase,
            observations: dataProduct.observations,
            id_employee: dataProduct.id_employee,
            id_branch_office: dataProduct.id_branch_office,
            date: dataProduct.date,
            agreed_return_date: dataProduct.agreed_return_date,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },
}   