import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './StructureManteiner.type';
import { TimeZoneService } from '@/service/services/TimeZone.service';
import { ModalTimeZone } from '@/components/Modal/ModalTimeZone/ModalTimeZone';
import { ModalTimeZoneDelete } from '@/components/Modal/ModalTimeZoneDelete';
import { RleStructureService } from '@/service/services/RleStructure.service';
import { ModalStructureManteiner } from '@/components/Modal/ModalStructureManteiner';
import { ModalStructureManteinerDelete } from '@/components/Modal/ModalStructureManteinerDelete';
import ___PHRASE from '@/service/translate/translateService';

export const StructureMaintenerView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [structureManteiners, setStructureManteiners] = useState([]);
   const [showStructureManteinerModal, setShowStructureManteinerModal] = useState<boolean>(false);
   const [showStructureManteinerDeleteModal, setShowStructureManteinerDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [structureManteinerSelected, setStructureManteinerSelected] = useState<String>('');

   useEffect(() => {
    getStructureManteiner()
   },[]);

   const getStructureManteiner = async () => {
      setLoading(true);
      const response = await RleStructureService.getRLEStructure();
      if (response.data) {
        setStructureManteiners(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setStructureManteinerSelected(value);
   }

   const searchData = async (value) => {
      const response = await RleStructureService.getRLEStructure(value);
      if (response.data) {
        setStructureManteiners(response.data || [])
      } else {
         console.log('error')
      }
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={___PHRASE("estructurasDeLiquidacion")}
               data={structureManteiners}
               header={[
                  { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: true },
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                  { name: 'typestructure', label: ___PHRASE("estructurasDeLiquidacion"), filter: false, Chip: true },
                  { name: 'required_name', label: ___PHRASE("obligatorio"), filter: false, Chip: true },
                  { name: 'gratification_name', label: ___PHRASE("Gratificacion"), filter: false, Chip: true },
                  { name: 'concept_DT', label: ___PHRASE("conceptoDT"), filter: false, Chip: true },
                  { name: 'country_name', label: ___PHRASE("pais"), filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowStructureManteinerModal}
               setModalDelete={setShowStructureManteinerDeleteModal}
               status_action
               textSearch={___PHRASE("buscarLRE")}
               searchData={searchData}
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showStructureManteinerModal && (
               <ModalStructureManteiner
                  open={showStructureManteinerModal}
                  closeModal={()=>{setShowStructureManteinerModal(false)}}
                  actionSelected={actionSelected}
                  onListStructure={getStructureManteiner}
                  structure={structureManteinerSelected}
                  clearState={()=>{setStructureManteinerSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalStructureManteinerDelete
                  open={showStructureManteinerDeleteModal}
                  closeModal={()=>{setShowStructureManteinerDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListStructure={getStructureManteiner}
                  structure={structureManteinerSelected}
                  clearState={()=>{setStructureManteinerSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};