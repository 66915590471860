import { AssistanceService } from "@/service/services/Assistance.service";
import { ConfigurationIPService } from "@/service/services/ConfigurationIP.service";
import { CostCenterService } from "@/service/services/CostCenter.service";
import { ProfileService } from "@/service/services/Profile.service";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalIpDelete.type";
import { Loading } from "@/components/common/Loading";
import ___PHRASE from "@/service/translate/translateService";

export const ModalIpDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, userIp, onListIps, clearState, type } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
    });

    const onDeletedAccept= async()=>{
        setLoading(true);
        await ConfigurationIPService.deleteAccessIP(userIp.id).then((data) => {
            setSnackBarConfig(prev => ({ ...prev, open: true, 
              severity:'success', 
              message: ___PHRASE('rangoipeliminado') }
            ));
            onListIps && onListIps();
            closeModal && closeModal();
            clearState && clearState();
        })
        setLoading(false);
    }

    const onDeletedDenied= async()=>{
      setLoading(true);
      await ConfigurationIPService.deleteDeniedIP(userIp.id).then((data) => {
          setSnackBarConfig(prev => ({ ...prev, open: true, 
            severity:'success',
            message: ___PHRASE('rangoipeliminado') }
          ));
          onListIps && onListIps();
          closeModal && closeModal();
          clearState && clearState();
      })
      setLoading(false);
  }
  
  return (
    <>
    {loading && <Loading/>}
    <Snackbar
        open={snackBarConfig.open} 
        autoHideDuration={snackBarConfig.autoHideDuration} 
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
        </Alert>
    </Snackbar>
    <Modal
      open={open}
      disableEscapeKeyDown disableBackdropClick 
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text={___PHRASE('eliminarIP')}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {type == 'accept' ? ___PHRASE('estaSeguroDeEliminarElRangoDeIP') : ___PHRASE('estaSeguroDeEliminarLaIP')}
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={type == 'accept' ? onDeletedAccept : onDeletedDenied}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}