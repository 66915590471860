import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./RecluitingTeam.type";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { RecluitingTeamService } from "@/service/services/RecluitingTeam.service";
import { Loading } from "@/components/common/Loading";
import { JobOffertService } from "@/service/services/JobOffert.service";
import { userRepository } from "@/service/repositories/User.repository";
import ___PHRASE from "@/service/translate/translateService";

export const ModalRecluitingTeam: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, actionSelected, recluitingTeam, onListRecluitingTeam, currentJobOffert, jobOffertSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState<any>('');
    
    const [data, setData] = useState({
        id: recluitingTeam ? recluitingTeam.id : '',
        id_user: recluitingTeam ? recluitingTeam.id_user : '',
        id_job_offer:  recluitingTeam ? recluitingTeam.id_job_offer : '',
        role: recluitingTeam ? recluitingTeam.role : ''
    });

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if(recluitingTeam && users){
            setCustomUser(recluitingTeam.id_user, users);
        }

    },[recluitingTeam, users]);

    const getUsers = async () => {
        setLoading(true);
        await userRepository.getUsers().then((data) => {
            setUsers(data.data || [])
           setLoading(false);
        });
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.role) errors.role = "rol requerido";
        // if(!values.description) errors.description = "descripción requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const idJobOffertStorage = currentJobOffert ? currentJobOffert.id : jobOffertSelected?.id;
        const formData = {
            id: values.id,
            id_user: recluitingTeam ? userSelected.iduser : userSelected,
            id_job_offer: idJobOffertStorage,
            role : values.role
        }

        setLoading(true);
        if(recluitingTeam && actionSelected=='edit'){
            await RecluitingTeamService.editRecluitingTeam(formData).then((data) => {
                onListRecluitingTeam && onListRecluitingTeam(recluitingTeam.id_job_offer);
                closeModal && closeModal();
            })
        }else{
            await RecluitingTeamService.createRecluitingTeam(formData).then((data) => {
                onListRecluitingTeam && onListRecluitingTeam(idJobOffertStorage);
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    function setCustomUser(idUser, users): any {
        const data = users && users.find(user => user.iduser === idUser);
        const newData = {
            id: data?.id,
            iduser: data?.iduser,
            email: data?.email,
            user_name: data?.user_name,
            role: data?.role,
            n_document: data?.n_document,
        }
        return setUserSelected(newData);
    }

    return(
        <>

        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((recluitingTeam && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("equipoDeReclutamiento")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    {
                                        recluitingTeam && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("usuario")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        id="disable-portal"
                                                        disablePortal
                                                        value={userSelected}
                                                        options={users || []}
                                                        getOptionLabel={(option) => option.user_name || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                        onChange={(e, newValue) => {  setUserSelected(newValue) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    {
                                        !recluitingTeam && (
                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>{___PHRASE("usuario")}:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        multiple
                                                        id="disable-portal"
                                                        disablePortal
                                                        value={userSelected || []}
                                                        options={users || []}
                                                        getOptionLabel={(option) => option.user_name || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                        onChange={(e, newValue) => { setUserSelected(newValue) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }    

                                    <Grid item xs={12} container>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("rol")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="role"
                                            variant="standard"
                                            type="text" 
                                            name="role"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.role}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("rol")}
                                            error={errors.role && touched.role ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider style={{margin: '30px 0px 10px 0px'}}/>
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(recluitingTeam && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}