import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Button, Divider, Grid, MenuItem, Select, TextField, Typography, Snackbar, Alert } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalAdvance.type";
import { PeriodService } from "@/service/services/Period.service";
import { AdvanceService } from "@/service/services/Advance.service";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";
import { ROLE_TRABAJADOR } from "@/toolbox/constants/role-type";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalAdvance: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, advance, onListAdvances, workerSelected, clearState, setPeriodMonth } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [periods, setPeriods] = useState<any>([]);

    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [data, setData] = useState({
        id: advance ? advance.id : '',
        idperiod: advance ? advance.idperiod : '',
        value: advance ? advance.value : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthJS = currentDate.getMonth() + 1; //getMonth() su indice empieza desde 0
    const currentMonth = currentMonthJS <= 9 ? `0${currentMonthJS}` : `${currentMonthJS}`;

    useEffect(()=> {
        getPeriodsActually()
        if(!advance){
            setData({
                id: '',
                idperiod: '',
                value: '',
            })
        }
    },[open]);

    useEffect(() => {
        if(!advance){
            setYearSelected(`${currentYear}`);
            setMonthSelected(currentMonth);
        }
    }, [open]);

    useEffect(() => {
        if(advance && periods){
            const dataPEriod = setCustomPeriod(advance.periodo, periods);
            if(dataPEriod){
                setYearSelected(dataPEriod.year)
                setMonthSelected(dataPEriod.month)
            }
        }

    },[advance, periods]);

    const getPeriodsActually = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriodsActually();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' +  item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if(!found) { arrayYears.push(item.year); }
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    function setCustomPeriod(periodo, periods): any {
        const periodoFilter = periodo.replace(/\s+/g, '')
        const data = periods && periods.find(period => period.alias_name == periodoFilter);
        return data;
    }

    const acceptAdvance = async(value, valuesData)  => {
        const periodSelect = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelect);

        if(!period && role == ROLE_ADMIN) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: ___PHRASE("elPeriodoActualNoSeEncuentraDisponible") }
            ))
        );

        if(!period && role == ROLE_TRABAJADOR) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: ___PHRASE("elPeriodoActualNoSeEncuentraDisponible") }
            ))
        );

        const formData = {
            request_status: value,
            validity: yearSelected + '-' + monthSelected,
            total_value: parseFloat(valuesData.value),
            idemployee: workerSelected.id,
        }
        await AdvanceService.acceptAdvance(formData, advance.id).then((data) => {
            if(data?.data?.status == true){
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'success', 
                    message: `${___PHRASE("solicitudAceptada")}`}
                ))
                onListAdvances && onListAdvances(workerSelected.id);
                setTimeout(function(){
                    closeModal && closeModal();
                }, 1000);
            }else{
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'error', 
                    message: `${data?.data?.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                ))
            }
        })
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.value) errors.value = ___PHRASE("valorRequerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const periodSelect = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelect);

        if(!period && role == ROLE_ADMIN) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: ___PHRASE("elPeriodoActualNoSeEncuentraDisponible") }
            ))
        );

        if(!period && role == ROLE_TRABAJADOR) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: ___PHRASE("elPeriodoActualNoSeEncuentraDisponible") }
            ))
        );

        const formData = {
            id: values.id,
            idperiod : period.id,
            value : values.value,
        }

        setLoading(true);
        if(advance && actionSelected=='edit'){
            await AdvanceService.editAdvance(formData, advance.id).then((data) => {
                if(data?.data){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${___PHRASE("anticipoeditadosatisfactoriamente")}`}
                    ))
                    onListAdvances && onListAdvances(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                    ))
                }
            })
        }else{
            await AdvanceService.createAdvance(formData, workerSelected.id).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);
                if(data?.data?.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge || 'Anticipo creado satisfactoriamente.'}`}
                    ))
                    onListAdvances && onListAdvances(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                    ))
                }
            })
        }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={(advance && actionSelected=='edit') ? (advance.request_status == 'pendiente' ? ___PHRASE("editar")+' '+___PHRASE("anticipo") : ___PHRASE("laSolicitudYaHaSidoRespondida")) : ___PHRASE("agregar")+' '+___PHRASE("anticipo")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                value={yearSelected}
                                                onChange={(event) => {
                                                setYearSelected(event.target.value);
                                                }}
                                                fullWidth
                                                inputProps={{ readOnly: true }}
                                            >
                                                {
                                                years.map((year, i) => {
                                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("mes")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                value={monthSelected}
                                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                                fullWidth
                                                inputProps={{ readOnly: true }}
                                            >
                                                <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                                <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                                <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                                <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                                <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                                <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                                <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                                <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                                <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                                <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                                <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                                <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("valor")} ($):</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="value"
                                            variant="standard"
                                            type="number" 
                                            name="value"
                                            fullWidth
                                            value={values.value}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("valorDeAnticipo")}
                                            error={errors.value && touched.value ? true : false}
                                            InputProps={{readOnly: (advance && advance.request_status != 'pendiente') && true}}
                                        />
                                        </Grid>
                                    </Grid>
                                    
                                    {
                                        (advance && role == ROLE_ADMIN) && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                
                                                {
                                                    (advance.request_status == 'aceptada' || advance.request_status == 'rechazada') && (
                                                        <>
                                                        <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("estadoDePeticion")}:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                        <TextField
                                                            id="request_status"
                                                            variant="standard"
                                                            type="text" 
                                                            name="request_status"
                                                            fullWidth
                                                            value={advance.request_status}
                                                            InputProps={{readOnly: true}}
                                                        />
                                                        </Grid>
                                                        </>
                                                    )
                                                }
                                                {
                                                    advance.request_status == 'pendiente' && (
                                                        <>
                                                        <Grid item xs={5}>
                                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("peticion")+` "${advance.request_status}": `}</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Button onClick={()=>acceptAdvance(1, values)} size="small" color={"success"}variant="contained" style={{marginRight:'3px'}}>
                                                                {___PHRASE("aceptarPeticion")}
                                                            </Button>
                                                            
                                                            <Button onClick={()=>acceptAdvance(2, values)}size="small" color={"error"} variant="contained">
                                                                {___PHRASE("rechazarPeticion")}
                                                            </Button>
                                                        </Grid>
                                                        </>
                                                    )
                                                }
                                                
                                                
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Divider />
                                {
                                    ((advance && advance.request_status == 'pendiente') || !advance) && (
                                        <ModalFooter 
                                            buttonType="submit"
                                            confirmText={(advance && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                            cancelText={___PHRASE("cancelar")} 
                                            onCancel={closeModal}
                                            onConfirm={handleSubmit}
                                            clearState={clearState}
                                        />
                                    )
                                }
                                
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}