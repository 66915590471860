import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { branchOfficeRepository } from '@/service/repositories/BranchOffice.repository';
import { ModalBranchOffice } from '@/components/Modal/ModalBranchOffice';
import { ModalBranchOfficeDelete } from '@/components/Modal/ModalBranchOfficeDelete';
import { Props } from './LiquidationProject.type';
import ___PHRASE from '@/service/translate/translateService';


export const LiquidationProjectView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [liquidationProjects, setliquidationProjects] = useState<any>([]);
   const [LiquidationProjectSelected, setLiquidationProjectSelected] = useState<any>('');
   const [showLiquidationProjectModal, setShowLiquidationProjectModal] = useState<boolean>(false);
   const [showLiquidationProjectDeleteModal, setShowLiquidationProjectDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<any>('');

//    useEffect(() => {
//       getBranchOffices()
//    },[]);

//    const getBranchOffices = async () => {
//       setLoading(true);
//       const response = await branchOfficeRepository.getBranchOffices();
//       if (response.data) {
//          setBranchOffices(response.data || [])
//       } else {
//       console.log('error')
//       }
//       setLoading(false);
//    }

   function RecuperarData(value){
      console.log(value);
      setLiquidationProjectSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}

         <Container>
            <TableDataV2
               title={___PHRASE("liquidacionesPorProyecto")}
               data={liquidationProjects}
               header={[
                  { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: false },
                  { name: 'address', label: ___PHRASE("direccion"), filter: false, Chip: true },
                  { name: 'phone', label: ___PHRASE("telefono"), filter: false, Chip: false },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowLiquidationProjectModal}
               setModalDelete={setShowLiquidationProjectDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         {/* { 
            showBranchOfficeModal && (
               <ModalBranchOffice
                  open={showBranchOfficeModal}
                  closeModal={()=>{setShowBranchOfficeModal(false)}}
                  actionSelected={actionSelected}
                  onListBranchOffices={getBranchOffices}
                  branchOffice={branchOfficeSelected}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalBranchOfficeDelete
                  open={showBranchDeleteModal}
                  closeModal={()=>{setShowBranchDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListBranchOffices={getBranchOffices}
                  branchOffice={branchOfficeSelected}
               />
            )
         } */}
      
      </Protected>
   )
};