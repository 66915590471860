import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalDelay.type";
import ___PHRASE from "@/service/translate/translateService";
import moment from "moment";

export const ModalDelay: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, delay, onListDelays, workerSelected, clearState, configSnackBar } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [typeAbsences, setTypeAbsences] = useState<any>([]);
    const [typeDiscounts, setTypeDiscounts] = useState<any>([]);

    const [data, setData] = useState({
        id: delay ? delay.id : '',
        idemployee: delay ? delay.idemployee : '',
        date: delay ? delay.date : '',
        total_delay: delay ? delay.total_delay : '',
        observation: delay ? delay.observation : '',
    });
  
    useEffect(()=> {
        if (actionSelected != 'edit') {
          setData({
            id: '',
            idemployee: '',
            date: '',
            total_delay: '',
            observation: '',
          })
        }
    }, [open]);

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.total_delay) errors.total_delay = "total tardanzas requerido";
        if(values.total_delay > 8) errors.total_delay = "cantidad de horas excedidas";
        if(!values.observation) errors.observation = "observación requerido";
        if(!values.date) errors.date = "fecha requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            idemployee: workerSelected.id,
            date : values.date,
            total_delay : values.total_delay,
            observation : values.observation,
        }
        setLoading(true);
        if(delay && actionSelected=='edit'){
            await AssistanceService.editDelay(formData, delay.id).then((data) => {
                onListDelays && onListDelays(workerSelected.id);
                configSnackBar && configSnackBar(___PHRASE("editadoCorrectamente"));
                closeModal && closeModal();
            })
        }else{
            await AssistanceService.createDelay(formData).then((data) => {
                onListDelays && onListDelays(workerSelected.id);
                configSnackBar && configSnackBar(___PHRASE("guardadoCorrectamente"));
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(delay && actionSelected=='edit') ? ___PHRASE("editarAtraso") : ___PHRASE("agregarAtraso")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fecha")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="date"
                                                variant="standard"
                                                fullWidth
                                                name="date"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                  min: moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD"),
                                                  max: moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format("YYYY-MM-DD")
                                                }}
                                                error={errors.date && touched.date ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nHorasDeAtrasos")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="total_delay"
                                            variant="standard"
                                            type="number" 
                                            name="total_delay"
                                            fullWidth
                                            value={values.total_delay}
                                            onChange={handleChange}
                                            InputProps={{ inputProps: { min: 0, max: 8 } }}
                                            placeholder={___PHRASE("totalAtrasos")}
                                            error={errors.total_delay && touched.total_delay ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("observacion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observation"
                                            variant="standard"
                                            type="text" 
                                            name="observation"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.observation}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("observacion")}
                                            error={errors.observation && touched.observation ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(delay && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}