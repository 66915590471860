import React, { Component, useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Props } from './Companies.type';
import { companiesService } from '@/service/services/Companies.service';
import { ModalCompany } from '@/components/Modal/ModalCompany';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA, KEY_USER_PERMISSION, KEY_UPDATE_TRANSLATE } from '@/toolbox/constants/local-storage';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '@/toolbox/constants/role-type';
import { ModalCompanyDelete } from '@/components/Modal/ModalCompanyDelete';
import { ModalCompanySettings } from '@/components/Modal/ModalCompanySettings/ModalCompanySettings';
import ___PHRASE from '@/service/translate/translateService';


export const CompaniesView: React.FC<Props> = (props: any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<any>([]);
    const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false);
    const [showCompanyDeleteModal, setShowCompanyDeleteModal] = useState<boolean>(false);
    const [showModalSettings, setShowModalSettings] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [companySelected, setCompanySelected] = useState<any>('');

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;
    const idUserDetail = dataUser?.user?.id;

    const headerComapanies = [
        { name: 'registry_number', label: ___PHRASE("numeroDeRegistro"), filter: false, Chip: false },
        { name: 'business_name', label: ___PHRASE("nombre"), filter: false, Chip: true },
        { name: 'phone', label: ___PHRASE("telefono"), filter: false, Chip: false },
        { name: 'email', label: ___PHRASE("correo"), filter: false, Chip: false },
        { name: 'phone', label: ___PHRASE("telefono"), filter: false, Chip: false },
    ]
    
    useEffect(() => {
        getCompanies();
    }, []);
  

    const getCompanies = async () => {
        setLoading(true);
        if(role == ROLE_ADMIN){
            const response = await companiesService.getCompanies('',idUserDetail);
            if (response.data) {
                setCompanies(response.data || [])
            } else {
                console.log('error')
            }
        }else if(role == ROLE_SUPER_ADMIN){
            const response = await companiesService.getCompaniesAdmin();
            if (response.data) {
                setCompanies(response.data || [])
            } else {
                console.log('error')
            }
        }
        
        setLoading(false);
    }

    function RecuperarData(value){
        console.log(value);
        setCompanySelected(value);
    }

    const searchData = async (value) => {
        if(role == ROLE_ADMIN){
            const response = await companiesService.getCompanies(value,idUserDetail );
            if (response.data) {
                setCompanies(response.data || [])
            } else {
                console.log('error')
            }
        }else if(role == ROLE_SUPER_ADMIN){
            const response = await companiesService.getCompaniesAdmin(value);
            if (response.data) {
                setCompanies(response.data || [])
            } else {
                console.log('error')
            }
        }
    }

    return (
      
        <Protected>
            {loading && <Loading/>}

            <Container>
                <TableDataV2
                title={role == ROLE_SUPER_ADMIN ? ___PHRASE("empresas") : ___PHRASE('miEmpresa')}
                data={companies || []}
                header={headerComapanies}
                actionSelect={setActionSelected}
                setModalSave={setShowCompanyModal}
                setModalDelete={setShowCompanyDeleteModal}
                setModalSettings={setShowModalSettings}
                btn_disabled={role == ROLE_SUPER_ADMIN ?  false : true}
                btn_delete_disable={role == ROLE_SUPER_ADMIN ?  false : true}
                btn_settings={role == ROLE_SUPER_ADMIN ?  true : false}
                status_action
                textSearch={___PHRASE("buscarEmpresa")}
                searchData={searchData}
                RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showCompanyModal && (
                <ModalCompany
                    open={showCompanyModal}
                    closeModal={()=>{setShowCompanyModal(false)}}
                    actionSelected={actionSelected}
                    onListCompanies={getCompanies}
                    company={companySelected}
                    clearState={()=>{setCompanySelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalCompanyDelete
                    open={showCompanyDeleteModal}
                    closeModal={()=>{setShowCompanyDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListCompanies={getCompanies}
                    company={companySelected}
                    clearState={()=>{setCompanySelected('')}}
                />
                )
            }
            {
                showModalSettings && (
                <ModalCompanySettings
                    open={showModalSettings}
                    closeModal={()=>{setShowModalSettings(false)}}
                    actionSelected={actionSelected}
                    company={companySelected}
                    clearState={()=>{setCompanySelected('')}}
                />
                )
            }
        
        </Protected>
    )
};