import { Protected } from "@/components/layout/Protected"
import { Backdrop, Grid, Typography } from "@mui/material"
import { SpinnerGrow } from '@/components/common/Spinner';
import { useEffect, useState } from "react";
import { Alert, Breadcrumbs, CircularProgress, Container, Divider, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { TableDataV2 } from "@/components/common/Tablev2";
import { WorkerService } from "@/service/services/Worker.service";
import { Loading } from "@components/common/Loading";
import { LoanService } from "@/service/services/Loan.service";
import { ModalMaxLoan } from "@/components/Modal/ModalMaxLoan";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ModalRequestLoan } from "@/components/Modal/ModalRequestLoan";
import ___PHRASE from "@/service/translate/translateService";
import { ModalFeeLoanDetail } from "@/components/Modal/ModalFeeLoanDetail";

export const LoanEmployee = () => {
    const data_user = readLocalStorage(KEY_USER_DATA);
    const idEmployee = data_user?.user?.idemployee;
    const VIEW_LIST_EMPLOYEE = 'Lista de Empleados'
    const VIEW_LOAN = "Prestamos"
    const [loading, setLoading] = useState<boolean>(false);
    const [openView, setOpenView] = useState(false);
    const [view, setView] = useState(VIEW_LIST_EMPLOYEE);
    const [workers, setWorkers] = useState<any>([]);
    const [employeeSelected, setEmployeeSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [loanSelected, setLoanSelected] = useState<any>();
    const [dataLoans, setDataLoans] = useState<any>([]);
    const [validateMaxLoan, setValidateMaxLoan] = useState<any>(false)
    const [modalRequestLoan, setModalRequestLoan] = useState(false);
    const [modalFeeLoanDetail, setModalFeeLoanDetail] = useState(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
  
  const headerLoans = [
    { name: 'year_initial', label: ___PHRASE("periodo") },
    { name: 'month_initial', label: ___PHRASE("mes") },
    { name: 'loan', label: ___PHRASE("monto") },
    { name: 'status_name', label: ___PHRASE("estado") },
  ]
  
    const getWorkers = async () => {
        setLoading(true);
        const resp: any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const RecuperarData = (value) => {
       setLoanSelected(value);
    }


    useEffect(() => {
        getLoans(0, idEmployee)
    }, []);

    const getLoans = async (status, idEmployee) => {
        setLoading(true)
        console.log('api')
        const resp = await LoanService.getLoan(status, idEmployee);
        setDataLoans(resp.data)
        const validate = await LoanService.validatorMaxLoan(idEmployee);
        setValidateMaxLoan(validate.data)
        setLoading(false)
    }

    const validateActive = () => {
        if(dataLoans.find((item)=> item.status_loan == "1")){
            return false
        }else{
            return true
        }

    }


    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openView}
            >
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <SpinnerGrow />
                    <h1 style={{ marginLeft: '10px' }}><strong>{___PHRASE("cargando")}</strong></h1>
                </Grid>
            </Backdrop>


            <>
                    {
                        validateMaxLoan  ?
                        <>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ mt: 1 , fontWeight:600, ml:4}}>{___PHRASE("misprestamos")}</Typography>

                        <Typography sx={{fontWeight: 'bold', ml:5, mb:2}}>{___PHRASE("puedesSolicitarComoMaximoUnPrestamoDe")} $ {validateMaxLoan.limit_max} </Typography>
                        </>:
                         <>
                         <Typography sx={{fontWeight: 'bold', ml:5, mb:2}}>{___PHRASE("noTienesDisponibleSolicitarPrestamo")} </Typography>
                         </>
                    }
                <TableDataV2
                    title={___PHRASE("misprestamos")}
                    disabled_title
                    data={dataLoans}
                    header={headerLoans}
                    currentView={view}
                    RecuperarData={RecuperarData}
                    actionSelect={setActionSelected}
                    btn_disabled={true}
                    btn_validateRequestLoan={validateMaxLoan && validateActive()}
                    setModalRequestLoan={setModalRequestLoan}
                    setModalFeeLoanDetail={setModalFeeLoanDetail}
                    btn_feeLoanDetail
                    status_action
                    btn_edit_disable
                    btn_delete_disable
                />
            </>

            {
                modalRequestLoan &&
                 <ModalRequestLoan
                    open={modalRequestLoan}
                    closeModal = {()=> setModalRequestLoan(false)}
                    onList ={getLoans}
                    workerSelected={idEmployee}
                    validateMaxLoan={validateMaxLoan}
                 />
            }
            {
                modalFeeLoanDetail && 
                <ModalFeeLoanDetail
                    open={modalFeeLoanDetail}
                    closeModal = {()=> setModalFeeLoanDetail(false)}
                    onList ={getLoans}
                    workerSelected={{id: idEmployee}}
                    loanSelected = {loanSelected}
                    setSnackbar={setSnackBarConfig}
                    snackbar={snackBarConfig}
                />
            }
        </Protected>
    )
}