import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalRequest.type";
import { useEffect, useState } from "react";
import { Alert, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { requestTypeService } from "@/service/services/RequestType.service";
import { RequestService } from "@/service/services/Request.service";
import { RemunerationService } from "@/service/services/Remuneration.service";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_TRABAJADOR } from "@/toolbox/defaults/static-roles";
import '../../../assets/styles/modals.scss';
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";

export const ModalRequest: React.FC<Props> = (props: any): JSX.Element => {
    const { open, closeModal, actionSelected, request, requestCreate, onList, clearState, periods} = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [typeRequest, setTypeRequest] = useState<any>([]);
    const [typeRequestSelected, setTypeRequestSelected] = useState<any>(null);
    const [error, setError] = useState(null);

    const [dataForm, setDataForm] = useState({
        observations: '',
        from_date_request: '',
        to_date_request: '',
        detail: '',
    });
    
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     });

    const userData = readLocalStorage(KEY_USER_DATA);
    const userSession = userData?.user;

    const getTypeRequest = async() => {
        const respType = await requestTypeService.getRequestTypes();
        const newData = [];
        respType.data && (respType.data).length > 0 && (respType.data).map((item) => {
            if(item.id != '5') newData.push(item)
        });
        setTypeRequest(newData)
    }

    const clearData = () => {
        setDataForm({
            observations: request ? request.observations : '',
            from_date_request: request ? request.from_date_request : '',
            to_date_request: request ? request.to_date_request : '',
            detail: request ? request.detail : '',
        });
        setTypeRequestSelected(null)
        setError('')
    }

    useEffect(()=>{
        getTypeRequest();
        clearData();
    },[open])

    const onSubmit = async (e, status=null) => {
        // SOLO EL ADMIN PUEDE EDITAR, LA OBSERVACION Y EL NUEVO ESTADO, LOS OTROS PARAMETROS SON PORQUE LA API ME LOS PIDE COMO OBLIGATORIO
        if (request && actionSelected == 'edit') {

            if(!dataForm.observations) return setError('observations');

            const dataRequest = {
                observations: dataForm.observations,
                status_review: status,
                detail: request.detail,
                idrequests_type: request.idrequests_type,
                status: request.status
            }
            setLoading(true);
            const respEditRequest = await RequestService.AceptorDeniedRequest(request?.id, dataRequest);
            if(respEditRequest?.data){
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'success', 
                    message: `${___PHRASE("estadodesolicitudactualizadoconexito")}`}
                ))
                onList && onList();
                setTimeout(function(){
                    closeModal && closeModal();
                }, 1000);
            }else{
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'error', 
                    message: `${___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                ))
            }

        } else {

            const firstDayOfMonth = moment().startOf('month');
            const lastDayOfMonth = moment().endOf('month');
            const isDateValidFromDate = moment(dataForm.from_date_request).isBetween(firstDayOfMonth, lastDayOfMonth, 'day', '[]');
            const isDateValidToDate = moment(dataForm.to_date_request).isBetween(firstDayOfMonth, lastDayOfMonth, 'day', '[]');
            const isFromValid = moment(dataForm.to_date_request).isSameOrAfter(dataForm.from_date_request, 'day');
            let dateStart= '';
            let dateEnd= '';
            if(dataForm.from_date_request) dateStart = moment(dataForm.from_date_request).format("YYYY/MM/DD");
            if(dataForm.to_date_request) dateEnd = moment(dataForm.to_date_request).format("YYYY/MM/DD");

            if(dataForm.from_date_request && dataForm.to_date_request){
                if(dateStart >= dateEnd) return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${___PHRASE("aseguresequelafechainicialseamenorquelafinal")}` }
                    ))
                );
            }

            if(isDateValidFromDate && isDateValidToDate && isFromValid && dataForm.detail && typeRequestSelected){

                let remuneration:any = '';

                if(typeRequestSelected.name == 'Liquidación' && userSession.role == ROLE_TRABAJADOR && userSession.idemployee){
                    let currentMonth = moment().format('MM-YYYY');
                    const period= periods.length > 0 ? periods.find(element => element.alias_name == currentMonth) : '';
                    setLoading(true);
                    const verifyResp:any = await RemunerationService.verfifyRemuneration(userSession.idemployee, period.id);
                    if(!(verifyResp.data.detail)){
                        setLoading(false);
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: `${___PHRASE("nocuentasconunaliquidaciongeneradaenelmesactual")}` }
                            ))
                        );
                    }
                    remuneration = verifyResp.data.detail ? verifyResp.data?.detail[0] : '';
                    setLoading(false);
                }

                if(remuneration && typeRequestSelected.name == 'Liquidación' ){
                    const period= periods.length > 0 ? periods.find(element => element.id == remuneration.idperiod) : '';
                    if(period){
                        let startDate = dataForm.from_date_request ? moment(dataForm.from_date_request).format("MM-YYYY") : '';
                        let endDate = dataForm.to_date_request ? moment(dataForm.to_date_request).format("MM-YYYY") : '';

                        if(startDate && startDate != period.alias_name) return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'warning', 
                                message: `${___PHRASE("lafechainicialtienequeencontrarsedentrodelmesdelaliquidacion")} (${period.alias_name}).` }
                            ))
                        )
                        if(endDate && endDate != period.alias_name) return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'warning', 
                                message: `${___PHRASE("lafechainicialtienequeencontrarsedentrodelmesdelaliquidacion")} (${period.alias_name}).` }
                            ))
                        )
                    }
                }

                const dataRequest = {
                    detail: dataForm.detail,
                    from_date_request: dataForm.from_date_request,
                    to_date_request: dataForm.to_date_request,
                    idrequests_type: typeRequestSelected?.id,
                    idremuneration: remuneration ? remuneration.id : '',
                }
                setLoading(true);
                const respRequest = await requestCreate(dataRequest);
                if(respRequest?.data?.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${respRequest?.data?.message || ___PHRASE("solicitudGeneradaConExito")}`}
                    ))
                    onList && onList();
                    setTimeout(function(){
                        closeModal && closeModal();
                    }, 1000);
                    setLoading(false);
                }else{
                    setLoading(false);
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${respRequest?.data?.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                    ))
                }
            }else{
                if(!typeRequestSelected){ return setError('typeRequest')}
                if(!dataForm.detail){ return setError('detail')}
                if(!dataForm.from_date_request){ return setError('from_date_request')}
                if(!dataForm.to_date_request){ return setError('to_date_request')}
            }
        }
        setLoading(false);
    }

    const handleInput = (e) => {
        setError("")
        if(e.target.name == "detail"){
            if(e.target.value.length <= 250){
                setDataForm({...dataForm,  [e.target.name]: e.target.value})
            }
        }else{
            const changedFormValues = {
                ...dataForm,
                [e.target.name]: e.target.value
            }
            setDataForm(changedFormValues);
        }
    }

    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Modal open={open} disableEscapeKeyDown disableBackdropClick handleClose={closeModal} handle size="sm">
                <ModalHeader
                    text={(request && actionSelected=='edit') ? (request.status_review == '0' ? ___PHRASE("deseaaceptarorechazarlasolicitud") : ___PHRASE("laSolicitudYaHaSidoRespondida")) : ___PHRASE("agregarSolicitud")}
                    className='positionElements'
                    onCancel={closeModal}
                    clearState={clearState}
                >
                </ModalHeader>
                <ModalBody>
                    <Grid container >
                       { actionSelected == 'save' ?
                       <>
                       <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="body1"><b>{___PHRASE("tipoDeSolicitud")} :</b> </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    id="id_education_level"
                                    variant="standard"
                                    value={typeRequestSelected}
                                    onChange={(event) => {
                                        setError("")
                                        setTypeRequestSelected(event.target.value);
                                    }}
                                    error={ error == "typeRequest" ? true : false }
                                    fullWidth
                                >
                                    {
                                        typeRequest.map((row, i) => {
                                            return <MenuItem key={i} value={row}>{row.name}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="body1"><b>{___PHRASE("motivo")}:</b> </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={dataForm?.detail}
                                    type='text'
                                    multiline
                                    maxRows={4}
                                    fullWidth
                                    onChange={handleInput}
                                    name={'detail'}
                                    error={error === "detail" ? true : false}
                                    helperText={error === "detail" ? ___PHRASE("motivoesuncamporequerido") : ""}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="body1"><b>{___PHRASE("fechaInicial")}:</b> </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={dataForm?.from_date_request}
                                    variant="standard"
                                    type="date"
                                    fullWidth
                                    onChange={handleInput}
                                    name={'from_date_request'}
                                    error={error === "from_date_request" ? true : false}
                                    helperText={error === "from_date_request" ? ___PHRASE("fechaInicialEsUnCampoRequerido") : ""}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="body1"><b>{___PHRASE("fechaFinal")}:</b> </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={dataForm?.to_date_request}
                                    type="date"
                                    variant="standard"
                                    fullWidth
                                    onChange={handleInput}
                                    name={'to_date_request'}
                                    error={error === "to_date_request" ? true : false}
                                    helperText={error === "to_date_request" ? ___PHRASE("fechaFinalEsUncampoRequerido") : ""}
                                />
                            </Grid>
                        </Grid>
                       </>:
                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                        <Grid item xs={4}>
                            <Typography variant="body1"><b>{___PHRASE("observacion")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={dataForm?.observations}
                                type='text'
                                multiline
                                maxRows={4}
                                fullWidth
                                onChange={handleInput}
                                name={'observations'}
                                error={error === "observations" ? true : false}
                                helperText={error === "observations" ? ___PHRASE("observacionEsUncampoRequerido") : ""}
                            />
                        </Grid>
                    </Grid>
                       }

                    </Grid>
                </ModalBody>
                {
                    actionSelected == 'save' ? (
                        <ModalFooter
                            buttonType="submit"
                            confirmText={___PHRASE("crearNueva")}
                            cancelText={___PHRASE("cancelar")}
                            onCancel={closeModal}
                            onConfirm={(e) => { onSubmit(e) }}
                            clearState={clearState}
                        />
                    ) :
                    ((request && request.status_review == '0')) && (
                        <ModalFooter
                            buttonType="submit"
                            confirmText={___PHRASE("aceptarSolicitud")}
                            cancelText={___PHRASE("rechazarSolicitud")}
                            onCancel={(e) => { onSubmit(e,2) }}
                            onConfirm={(e) => { onSubmit(e,1) }}
                            clearState={clearState}
                        />
                    )
                }
                
            </Modal>
        </>
    );
}