import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { useEffect, useState } from "react";
import { Alert, Accordion, AccordionSummary, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, AccordionDetails, Snackbar,Typography, IconButton } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModaAmountGeneral.type";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { templateRemunerationMonthlyRepository } from "@/service/repositories/TemplateRemunerationMonthly.repository";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ROUTE_PDF_AMOUNT_GENERAL } from "@/toolbox/constants/route-map";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";
import { ContractService } from "@/service/services/Contract.service";

export const ModalAmountGeneral: React.FC<Props> = (props: any): JSX.Element => {

    const { open, closeModal, actionSelected, user, onListUsers, clearState, idperiod } = props;
    const [expanded, setExpanded] = useState('panel1');

    const [loading, setLoading] = useState<boolean>(false);
    const [afp, setAfp] = useState<any>([]);
    const [isapre, setIsapre] = useState<any>([]);
    const [mutual, setMutual] = useState<any>([]);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState<any>({
        id: '',
        email: '',
        user_name: '',
        password: '',
        confirm_password: '',
        n_document: '',
        role: '1',
        assign_ip: '',
    });

    useEffect(() => {
        getAbstractPrevired();
    }, []);

    const getAbstractPrevired = async () => {
        setLoading(true);
        const resp = await templateRemunerationMonthlyRepository.getAbstractPrevired(idperiod)
        if (resp?.data) {
            setAfp(resp?.data?.afp);
            setIsapre(resp?.data?.isapre);
            setMutual(resp?.data?.mutual);
        }
        setLoading(false);
    }
    const getDetailPrevired = async (e, type) => {
        setLoading(true);
        let pdfResp:any = '';

        switch (type) {
            case 'afp':
                pdfResp = await templateRemunerationMonthlyRepository.getDetailPreviredAFP(idperiod);
                break;
            case 'isapre':
                pdfResp = await templateRemunerationMonthlyRepository.getDetailPreviredISAPRE(idperiod);
                break;
            case 'mutual':
                pdfResp = await templateRemunerationMonthlyRepository.getDetailPreviredMutual(idperiod);
                break;
            default:
                break;
        }
        
        if(pdfResp){
            const blob = new Blob([pdfResp], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            saveLocalStorage('previewAmountGeneral',{pdf: blobURL})
            window.open(ROUTE_PDF_AMOUNT_GENERAL, '_blank');
        }
        
        setLoading(false);
        closeModal && closeModal();
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
                <ModalHeader
                    text={'Resumen Previded'}
                    className='positionElements'
                    onCancel={closeModal}
                    clearState={clearState}
                >
                </ModalHeader>
                <ModalBody>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>AFP</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>{___PHRASE("institucion")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("totalaPagar")}</Typography>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {afp?.detail?.map((row) => {
                                            return (
                                                <TableRow >
                                                    <TableCell size={'small'}>
                                                        {row?.AFP}
                                                    </TableCell>
                                                    <TableCell size={'small'}>
                                                        {row?.total}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {___PHRASE("subTotalAFP")}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {afp?.total} <IconButton onClick={(e)=>{getDetailPrevired(e, 'afp')}} title={___PHRASE("verDetalle")}><VisibilityIcon/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>ISAPRE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>{___PHRASE("institucion")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("codigo")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("totalaPagar")}</Typography>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isapre?.detail?.map((row) => {
                                            return (
                                                <TableRow >
                                                    <TableCell size={'small'}>
                                                        {row?.salud}
                                                    </TableCell>
                                                    <TableCell size={'small'}>
                                                        {row?.codigo}
                                                    </TableCell>
                                                    <TableCell size={'small'}>
                                                        {row?.total}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {___PHRASE("subtotalIsapre")}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {/* {isapre?.total} */}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {isapre?.total} <IconButton onClick={(e)=>{getDetailPrevired(e,'isapre')}} title={___PHRASE("verDetalle")}><VisibilityIcon/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>{___PHRASE("mutual")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>{___PHRASE("institucion")}</Typography>
                                            </TableCell>
                                           
                                            <TableCell >
                                                <Typography>{___PHRASE("totalaPagar")}</Typography>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mutual?.detail?.map((row) => {
                                            return (
                                                <TableRow >
                                                    <TableCell size={'small'}>
                                                        {row?.name}
                                                    </TableCell>                                                
                                                    <TableCell size={'small'}>
                                                        {row?.total}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {___PHRASE("subtotalMutual")}
                                            </TableCell>
                                            <TableCell size={'small'}>

                                                {mutual?.total} <IconButton onClick={(e)=>{getDetailPrevired(e,'mutual')}} title={___PHRASE("verDetalle")}><VisibilityIcon/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </ModalBody>
            </Modal>
        </>
    );
}