import { Loading } from "@/components/common/Loading";
import { ReminderTypeService } from "@/service/services/ReminderTypee.service";
import { RleStructureService } from "@/service/services/RleStructure.service";
import { TimeZoneService } from "@/service/services/TimeZone.service";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalStructureManteinerDelete.type";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalStructureManteinerDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, actionSelected, structure, onListStructure, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
  const onDeleted = async () => {
    setLoading(true);
    await RleStructureService.deleteRLEStructure(structure.id).then((data) => {
      let apimessagge = apiResponseTranslate(data?.data?.message);
      if (data.data.status) {
        setSnackBarConfig({ ...snackBarConfig, open: true, severity: 'success', message: apimessagge })
      } else {
        setSnackBarConfig({ ...snackBarConfig, open: true, severity: 'error', message: apimessagge })
      }
      setTimeout(() => {
        onListStructure && onListStructure();
        closeModal && closeModal();
      }, 2000)

    })
    setLoading(false);
  }
  
  return (
    <>
    {loading && <Loading/>}
    <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text={___PHRASE("eliminar")+' '+___PHRASE("estructura")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {___PHRASE("estaSeguroDeEliminarEstaEstructura")}
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}