import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const notifyRepository = {
   getNotify: async (perPage: number, page: number): Promise<any> => {
      const notify = await http.get<any>(`${API_URL_BASE}/v1/notifications?per_page=${perPage}&page=${page}`)
      return notify;
   },

   getNotifyNotView: async (perPage: number, page: number): Promise<any> => {
      const notifyNotView = await http.get<any>(`${API_URL_BASE}/v1/notifications/notSeen?per_page=${perPage}&page=${page}` )
      return notifyNotView;
   },

   getNotifyView: async (perPage: number, page: number): Promise<any> => {
      const notifyView = await http.get<any>(`${API_URL_BASE}/v1/notifications/seen?per_page=${perPage}&page=${page}` )
      return notifyView;
   },

   getNotifySent: async (perPage: number, page: number): Promise<any> => {
      const notifySent = await http.get<any>(`${API_URL_BASE}/v1/notifications/sent?per_page=${perPage}&page=${page}` )
      return notifySent;
   },

   editVisto: async (id): Promise<any> => {
      const notify = await http.patch<any>(`${API_URL_BASE}/v1/notifications/seen/${id}`,{})
      return notify;
   },

   editVistoMassive: async (): Promise<any> => {
      const notify = await http.patch<any>(`${API_URL_BASE}/v1/notifications/seen`,{})
      return notify;
   },
}
