import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { ModalRequestLiquidationError } from "@/components/Modal/ModalRequestLiquidationError";
import { PeriodService } from "@/service/services/Period.service";
import { RemunerationService } from "@/service/services/Remuneration.service";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Box, Button, Container, Grid, MenuItem, Select, Snackbar, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { useLocation } from 'react-router-dom';
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";

const Div = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'rgba(102, 102, 102, 0.4)',
}));

export const MyLiquidationSalaryView = (props: any): JSX.Element => {
   let location = useLocation();
   const dataprops: any = location?.state;
   const dataEmployees: any = dataprops?.idemployee;
   const periodSend: any = dataprops?.idperiod;

   const [years, setYears] = useState<any>([]);
   const [periods, setPeriods] = useState<any>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
   const [yearSelected, setYearSelected] = useState<any>(moment().format('YYYY'));
   const [monthSelected, setMonthSelected] = useState<any>(moment().format('MM'));
   const [liquidation, setLiquidation] = useState<any>('');
   const [dataPDF, setDataPDF] = useState<any>(null)
   const userData = readLocalStorage(KEY_USER_DATA);
   const idEmployee = userData?.user.idemployee;

   const [openModal, setOpenModal] = useState<boolean>(false)

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: ___PHRASE("eliminar"),
      confirm: false,
      id: null,
      message: ``
   })

   useEffect(() => {
      getPeriods();
   }, []);

   useEffect(()=> {
      if(periods.length > 0) showLiquidation();
   },[periods]);

   useEffect(()=> {
      if(dataprops){
         getPDF()
      }
   },[dataprops]);

   const getPDF = async () => {
      setLoading(true);
      const pdfResp: any = await RemunerationService.generatePDFRemuneration(dataprops);
      if (pdfResp) {
         const blob = new Blob([pdfResp], { type: 'application/pdf' });
         const blobURL = URL.createObjectURL(blob);
         setDataPDF(blobURL)
         setLoading(false);
      }
      setLoading(false);
   }

   const getPeriods = async () => {
      setLoading(true);
      const resp: any = await PeriodService.getPeriods();
      const newData = [];
      const arrayYears = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            ...item,
            alias_name: item.month + '-' + item.year,
         })
         const found = arrayYears.find(element => element == item.year);
         if (!found) { arrayYears.push(item.year); }

      });
      setYears(arrayYears);
      setPeriods(newData || []);
      setLoading(false);
   }

   const showLiquidation = async () => {
      setLoading(true);
      if (!monthSelected || !yearSelected) {
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'warning',
               message: `Debe llenar todos los campos`
            }
            ))
         );
      }

      const periodSelected = monthSelected + '-' + yearSelected;
      const period = periods.find(element => element.alias_name == periodSelected);

      const dataPdf = {
         idperiod: period.id,
         idemployee: idEmployee,
      }

      const verifyResp: any = await RemunerationService.verfifyRemuneration(idEmployee, period.id);

      if ((verifyResp.data.message).includes('Existe')) {
         const detail = verifyResp.data?.detail[0];
         setLiquidation(detail)
         const pdfResp: any = await RemunerationService.generatePDFRemuneration(dataPdf);
         if (pdfResp) {
            const blob = new Blob([pdfResp], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            setDataPDF(blobURL)
            setLoading(false);
         }
         setLoading(false);
      }else{
         setDataPDF(null);
         setSnackBarConfig({
            ...snackBarConfig, 
            open:true, 
            severity:'error', 
            message:`${verifyResp.data.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
         })
      }
      setLoading(false);
   }

   const Delete = async () => {
      const { confirm, id } = Dialog;
      try {
          if (confirm == true) {
            
          }
      } catch (e) {
      }
   }

   const changeEmployeeStatus = async (status_confirmation) => {
      setLoadingConfirm(true)
      const res = await RemunerationService.changeEmployeeStatus(liquidation.id, status_confirmation);
      if(res?.data?.status == true){
         setLoadingConfirm(false);
         setSnackBarConfig(prev => ({ ...prev, open: true, 
             severity:'success', 
             message: `${res?.data?.message || ___PHRASE("liquidacionConfirmada")}`}
         ))
      }else{
         setLoadingConfirm(false);
         setSnackBarConfig(prev => ({ ...prev, open: true, 
            severity:'error', 
            message: `${res?.data?.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
         ))
      }
      setLoadingConfirm(false);
   }

   return (
      <Protected>
         {loading && <Loading />}
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>

         <ConfirmDialog
            open={Dialog.open}
            title={Dialog.title}
            message={Dialog.message}
            onConfirm={() => Delete()}
            onClose={() => setDialog(prev => ({ ...prev, open: false }))}
        />

         <Container>

            <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
               {___PHRASE("misliquidaciones")}
            </Typography>

            <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
               <Grid xs={3} display='flex' alignItems="center"
                  sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                  <Grid item xs={4}>
                        <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                        <Select
                           id="id_education_level"
                           variant="standard"
                           value={yearSelected}
                           onChange={(event) => {
                           setYearSelected(event.target.value);
                           }}
                           fullWidth
                        >
                           {
                           years.map((year, i) => {
                              return <MenuItem key={i} value={year}>{year}</MenuItem>;
                           })
                           }
                        </Select>
                  </Grid>
               </Grid>
               <Grid xs={3} display='flex' alignItems="center" 
                  sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                     <Grid item xs={4}>
                        <Typography variant="body1">{___PHRASE("mes")} </Typography>
                     </Grid>
                     <Grid item xs={8}>
                           <Select
                           id="id_education_level"
                           variant="standard"
                           value={monthSelected}
                           onChange={(event) => { setMonthSelected(event.target.value) }}
                           fullWidth
                           >
                           <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                           <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                           <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                           <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                           <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                           <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                           <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                           <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                           <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                           <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                           <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                           <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                           </Select>
                     </Grid>
                  </Grid>
                  <Grid xs={3} display='flex'>
                     <Button onClick={() => { showLiquidation() }} type={'submit'} size="small" color={"primary"} variant="contained" >
                           {___PHRASE("verLiquidacion")}
                     </Button>
                  </Grid>
            </Grid>

            {
               (!dataPDF && !loading) && (
                  <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '45px' }} >
                        <Div>{___PHRASE("aunNoSeHaGeneradoSuLiquidacion")}</Div>
                  </Box>
               )
            }
            {
               loading && (
                  <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '45px' }}>
                        <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                  </Box>
               )
            }

            { (dataPDF && !loading) &&  <Button sx={{marginTop:'30px'}} onClick={()=>{setOpenModal(true)}}>{___PHRASE("solicitarRevision")}</Button>}
            { (dataPDF && !loading) && <Button sx={{marginTop:'30px'}} onClick={()=>{changeEmployeeStatus(1)}}>
               {loadingConfirm ? ___PHRASE("confirmando") : ___PHRASE("confirmar")}
               </Button>}

           {(dataPDF && !loading) && <iframe src={dataPDF} id="pdfVer" height="1000px" width="100%"></iframe>}
            <ModalRequestLiquidationError
               open={openModal}
               closeModal={()=>setOpenModal(false)}
               liquidation={liquidation}
            />

         </Container>
      </Protected>
   )
}