import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Chip, Divider, Grid, MenuItem, Select, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { RleStructureService } from "@/service/services/RleStructure.service";
import { ProfileService } from "@/service/services/Profile.service";
import { Props } from "./ModalTypeExpense.type";
import { typesExpenseService } from "@/service/services/TypesExpense.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalTypeExpense: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, typeExpense, onListTypesExpense, clearState, getSnackBar } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState({
        id: typeExpense ? typeExpense.id : '',
        name: typeExpense ? typeExpense.name : '',
        idrlestructureoptional: typeExpense ? typeExpense.idrlestructureoptional : '',
        idcompany: typeExpense ? typeExpense.idcompany : '',
    });

    const [structures, setStructures] = useState([]);
    const [structureSelected, setStructureSelected] = useState<any>({});

    useEffect(() => {
        getStructures();
    }, []);

    useEffect(() => {
        if(typeExpense && structures){
            setCustomRleOptional(typeExpense.idrlestructureoptional, structures);
        }

    },[typeExpense, structures]);

    function setCustomRleOptional(idrlestructureoptional, structures): any {
        const data = structures && structures.find(structure => structure.id === idrlestructureoptional);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return setStructureSelected(newData);
    }

    const getStructures = async() => {
        const requireStructure = 0; //values: 1 - 0
        setLoading(true);
        await RleStructureService.getRLEStructureOptional().then((data) => {
            const resp = data.data;
            const newData = [];
            resp.length > 0 && resp.map((item) => {
                newData.push({
                    id: item.id,
                    name: item.code+' - '+item.alias_name
                })
            });
            setStructures(newData || []);
            setLoading(false);
        })
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            name: values.name,
            idrlestructureoptional : structureSelected && structureSelected.id,
        }
        setLoading(true);
        if(typeExpense && actionSelected=='edit'){
            await typesExpenseService.editTypeExpense(typeExpense.id, formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                onListTypesExpense && onListTypesExpense();
                closeModal && closeModal();
                getSnackBar && getSnackBar('success', `${ apimessagge || ___PHRASE("registroActualizadoConExito")}`)
            }).catch((data) => {
                getSnackBar && getSnackBar('error', ___PHRASE("errorAlEditarElTipoDeGasto"))
            })
        }else{
            await typesExpenseService.createTypeExpense(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                closeModal && closeModal();
                onListTypesExpense && onListTypesExpense();
                closeModal && closeModal();
                getSnackBar && getSnackBar('success', `${ apimessagge || ___PHRASE("creadoConExito")}`)
            }).catch((data) => {
                getSnackBar && getSnackBar('error', ___PHRASE("errorAlCrearElTipoDeGasto"))
            })
        }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((typeExpense && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("tipoDeGasto")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombreDelTipoDeGasto")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="body1"><b>{___PHRASE("estructurasOpcionales")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                value={structureSelected}
                                                options={structures || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} margin="dense" />}
                                                onChange={(e, newValue) => {
                                                    setStructureSelected(newValue);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(typeExpense && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    clearState={clearState}
                                    onConfirm={handleSubmit}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}