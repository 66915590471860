import { Props } from "./ModalJobOffertSpecification.type"
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik} from 'formik';
import { useEffect, useState } from "react";
import { Alert, Button, Chip, Divider, Grid, MenuItem, Select, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import '../../../assets/styles/modals.scss';
import { JobOffertService } from "@/service/services/JobOffert.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalJobOffertSpecification: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, specification, onListSpecification, currentJobOffert,setShowTeam, setView, clearState } = props;

    console.log(specification);

    const [loading, setLoading] = useState<boolean>(false);
    const [specifications, setSpecifications] = useState([]);
    const [dataSpecification, setDataSpecification] = useState({
        specification: specification?.specification ? specification.specification : '',
        type: specification?.type ? specification.type : '1',
        required_doc: specification?.required_doc ? specification.required_doc : '1',

    });
    console.log(dataSpecification);
    const [data, setData] = useState({
        id: '',
        id_job_offer: '',
        specifications: ''

    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const validateForm = (values) => {
        let errors: any = {};

        // if(!dataSpecification.specification) errors.name = "nombre requerido";

        return errors;
    }

    const addSpecification = (newSpecification) => {
        if(dataSpecification){
            let exits = false;
            if(specifications.length > 0){
                for (let i = 0; i < specifications.length; i++) {
                    if(specifications[i].specification === newSpecification.specification){
                        exits = true;
                    }
                }
            }
            if(!exits){
                setSpecifications([
                    ...specifications,
                    newSpecification
                ]);
            }else{
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, severity:'warning', message: `"${newSpecification.specification}" ya se encuentra seleccionado.` }))
                )
            }
        }
        setDataSpecification({
            specification: '',
            type: '1',
            required_doc: '1',
        });
    }

    const removeSpecification = (index) => {
        let arraySpecifications: Array<any> = specifications;
        if(arraySpecifications.length > 0){
            arraySpecifications.splice(index, 1);
        }else{
            alert('no ha agregado nada')
        }
        setSpecifications([...arraySpecifications])
    }

    const onSubmit = async(values, {resetForm})  => {
        if(!currentJobOffert) return(setSnackBarConfig(prev => ({ ...prev, open: true, severity:'warning', message: `no se a seleccionado la oferta laboral` })));

        const formData = {
            id: values.id,
            id_job_offer: currentJobOffert.id,
            specifications: specifications,
        }

        setLoading(true);
        if(specification && actionSelected=='edit'){
            console.log('edit')
            // await PhasesService.editPhase(formData).then((data) => {
            //     onListJobOfferts && onListJobOfferts();
            //     closeModal && closeModal();
            // })
        }else{
            await JobOffertService.createSpecificationMultiple(formData).then((data) => {
                // onListSpecification && onListSpecification(currentJobOffert.id);
                closeModal && closeModal();
            })
        }
        setShowTeam && setShowTeam(true);
        setView && setView('recruitingTeam')
        setLoading(false);
    }

    return (
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(specification && actionSelected=='edit') ? "Editar Especificaciones" : "Agregar Especificaciones"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="specification"
                                            variant="standard"
                                            type="text" 
                                            name="specification"
                                            multiline
                                            fullWidth
                                            value={dataSpecification.specification}
                                            onChange={(e) => {setDataSpecification({...dataSpecification, specification: e.target.value})}}
                                            placeholder="nombre de la especificacion"
                                            // error={!dataSpecification.specification && touched ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Tipo:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="type"
                                                variant="standard"
                                                value={dataSpecification.type}
                                                onChange={(e) => {setDataSpecification({...dataSpecification, type: e.target.value})}}
                                                fullWidth
                                                >
                                                    <MenuItem value={1}>{'Certificado'}</MenuItem>
                                                    <MenuItem value={0}>{'Constancia'}</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Documento:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="required_doc"
                                                variant="standard"
                                                value={dataSpecification.required_doc}
                                                onChange={(e) => {setDataSpecification({...dataSpecification, required_doc: e.target.value})}}
                                                fullWidth
                                                >
                                                    <MenuItem value={1}>{'Requerido'}</MenuItem>
                                                    <MenuItem value={0}>{'No requerido'}</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    {
                                        !specification?.specification && dataSpecification.specification != '' && (
                                            <Grid item xs={12} container justifyContent="end" marginBottom='10px'>
                                                <Button onClick={(e) => { addSpecification(dataSpecification) }} size="small" color={"primary"} variant="contained">
                                                    Agregar item
                                                </Button>
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs={12} container justifyContent="start" marginBottom='25px'>
                                        <Stack direction="column" spacing={2}>
                                        { specifications && specifications.map((item,index) =>
                                            <Stack
                                                direction="column" spacing={2}>
                                                <Chip onDelete={()=>removeSpecification(index)} label={item.specification} variant="outlined"/>
                                            </Stack>
                                        )}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(specification && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    )
}