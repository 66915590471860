import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Box, Button, Divider, Grid, IconButton, MenuItem, Select, Snackbar, styled, TextField, Tooltip, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { WorkerValidationService } from "@/service/services/WorkerValidation.service";
import { Props } from "./ModalValidationAdmin.type";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import ___PHRASE from "@/service/translate/translateService";

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const ModalValidationAdmin: React.FC<Props> = (props:any): JSX.Element => {

    const { closeModal, validations, typeFiles } = props;

    const [loading, setLoading] = useState<boolean>(false); 

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const idemployee = dataUser?.user?.idemployee;


    const setNameFile = (event, type) => {
        const files = event.target && event.target.files || [];
        const currentCv = files[0];
        
        if(currentCv){
            console.log(currentCv)

            const nameAsArray = currentCv.name.split(".") || [];
            const extension = nameAsArray.slice(-1);
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
            const nameFile = nameWithoutExtension.join(".") || "";

            if(extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' || 
                extension == 'JPEG' || extension == 'png' || extension == 'PNG'){
        
                return currentCv;
            }else{
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: ___PHRASE("elFormatoImagenNoEsCorrecto") }
                    ))
                );
            }
        }
        return "";
    };

    const onSubmit = async()  => {
        setLoading(true);
        const formData = new FormData();
        formData.append("idemployee", idemployee);

        await WorkerValidationService.confirmValidationIdentityAdmin(formData).then((data) => {
            if(data.data.status = true){
                closeModal && closeModal();
                return (
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'success',
                        message: `${data?.data?.message || ___PHRASE("documentosConfirmados")}`,
                    }))
                )
            }
        })
        setLoading(false);
    }

    const showDocument = (image) => {
        setLoading(true);
        if(image){
            window.open(image, '_blank');
        }
        setLoading(false);
    }


    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="md">
            <ModalHeader
                text={___PHRASE("confirmarDocumentosDeValidacionRegistradosPorSuAdministrador")}
                className='positionElements'
                onCancel={closeModal}
                disabledButton
            >
            </ModalHeader>
            <ModalBody>
                <Grid container >

                    {
                        validations.length > 0 && (
                            <>
                            <Grid item xs={4} marginBottom='20px'>
                                <Typography variant="subtitle1" className="custom-input"><b><u>{___PHRASE("fotosoImagenes")}</u></b></Typography>
                            </Grid>
                            <Grid item xs={12} container alignItems="center" display='flex' justifyContent="start" marginBottom='25px' sx={{border: '1px #b9b1a9 solid', borderRadius: '8px', padding:'12px'}}>
                            {
                                validations.length > 0 && validations.map((item, key)=>{
                                    return <>
                                        {
                                            //Es Imagen (con la "I" mayuscula)
                                            item.typeDocument == 'Imagen' && (
                                                <>                                                
                                                    <Grid item xs={3} marginBottom='15px' textAlign='center' alignItems='center' justifyContent='center'
                                                    >                                             
                                                        <Grid sx={{border: '1px #e1dcdc solid', height:'100px', width:'100px', cursor: 'pointer',textAlign: 'center', padding:'5px', borderRadius:'10px'}}>
                                                            <img
                                                                src={item.doc || ''}
                                                                alt={___PHRASE('imagen')}
                                                                style={{maxHeight:'85px', cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    showDocument(item.doc)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Tooltip title={item.nameDocument}>
                                                            <Typography sx={{ width:'150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} variant="subtitle1" className="custom-input">
                                                                <b>{item.nameDocument}</b>
                                                            </Typography>
                                                        </Tooltip>
                                                    </Grid>
                                                </>
                                            )
                                        }
                                    </>
                                })
                            }
                            {
                                typeFiles?.image == 0 && (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%'}}>
                                        <Div>{___PHRASE("noSeRegistraronImagenes")}</Div>
                                    </Box>
                                )
                            }
                            </Grid>

                            <Grid item xs={4}  marginBottom='20px'>
                                <Typography variant="subtitle1" className="custom-input"><b><u>{___PHRASE("documentosPDF")}</u></b></Typography>
                            </Grid>

                            <Grid item xs={12} container alignItems="center" display='flex' justifyContent="start" marginBottom='25px' sx={{border: '1px #b9b1a9 solid', borderRadius: '8px', padding:'12px'}}>
                                {
                                    validations.length > 0 && validations.map((item, key)=>{
                                        return <>
                                            {
                                                item.typeDocument == 'pdf' && (
                                                    <>                                               
                                                        <Grid item xs={3} marginBottom='15px'>
                                                            <Grid sx={{border: '1px #e1dcdc solid', height:'100px', width:'100px', cursor: 'pointer',
                                                                    textAlign: 'center', paddingTop:'15px', borderRadius:'10px'}}
                                                                onClick={() => {
                                                                    showDocument(item.doc)
                                                                }}
                                                            >
                                                                <PictureAsPdfIcon sx={{fontSize:'60px'}}/>
                                                            </Grid>
                                                            <Tooltip title={item.nameDocument}>
                                                                <Typography sx={{ width:'150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}  variant="subtitle1" className="custom-input">
                                                                    <b>{item.nameDocument}</b>
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    
                                                    </>
                                                )
                                            }
                                        </>
                                    })
                                }
                                {
                                    typeFiles?.pdf == 0  && (
                                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%' }}>
                                            <Div>{___PHRASE("noSeRegistraronDocumentos")}</Div>
                                        </Box>
                                    )
                                }
                            </Grid>
                            </>
                        )
                    }
                    
                    {
                        validations.length === 0 && (<>
                            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%' }}>
                                <Div>{___PHRASE("noSeRegistraronDocumentosDeIdentidad")}</Div>
                            </Box>
                        </>)
                    }

                </Grid>
                <Divider />
                <ModalFooter 
                    buttonType="submit"
                    confirmText={___PHRASE("confirmar")}
                    onConfirm={onSubmit}
                />
            </ModalBody>
        </Modal>
        </>
    );
}