import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Button, Select, Card, Container, Grid, MenuItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, styled } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { PeriodService } from "@/service/services/Period.service";
import { API_URL_BASE } from '@/toolbox/defaults/app';
import moment from 'moment';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import ___PHRASE from '@/service/translate/translateService';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const ReportLiquidations: React.FC<any> = (props: any): JSX.Element => {

    const [data, setData] = useState<any>('')

    //data localstorage
    const data_user = readLocalStorage(KEY_USER_DATA);
    const id_company = data_user?.user?.id_company;

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>(moment().format('YYYY'));
    const [monthSelected, setMonthSelected] = useState<any>(moment().format('MM'));
    //DATA
    const [branch_office_obj, setBranch_office_obj] = React.useState([]);
    const [branchOfficeQuantity, setBranchOfficeQuantity] = React.useState(0);
    const [business_area_obj, setBusiness_area_obj] = React.useState([]);
    const [businessAreaQuantity, setBusinessAreaQuantity] = React.useState(0);
    const [chargue_type_obj, setChargue_typee_obj] = React.useState([]);
    const [chargueTypeQuantity, setChargueTypeQuantity] = React.useState(0);
    const [cost_center_obj, setCost_center_obj] = React.useState([]);
    const [costCenterQuantity, setCostCenterQuantity] = React.useState(0);
    const [countEmployees_total, setCountEmployees_total] = React.useState<any>(0);
    const [employeesWithRemu, SetEmployeesWithRemu] = useState<any>(0);
    const [excelDissable, setExcelDissable]= useState<any>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        getPeriods();
    }, []);

    useEffect(()=> {
        if(periods.length > 0) showLiquidationsReport();
    },[periods]);

    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' + item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if (!found) { arrayYears.push(item.year); }

        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }
    const limpiarData = () => {
        setBranch_office_obj([]);
        setBusiness_area_obj([]);
        setChargue_typee_obj([]);
        setCost_center_obj([]);
    }
    const showLiquidationsReport = async () => {
        limpiarData();
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSeleccionarMesyAno")
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year
        }
        await ReportService.getReportLiquidation(id_company, dataPre.idperiod).then((data) => {

            const branch_office = data?.data?.employee?.branch_office || [];
            const business_area = data?.data?.employee?.business_area || [];
            const chargue_type = data?.data?.employee?.chargue_type || [];
            const cost_center = data?.data?.employee?.cost_center || []
            const countEmployees = data?.data?.employee?.countEmployees || 0;
            const countEmployeesWithRemu = data?.data?.employee?.countEmployeesWithRemu || 0;

            setCountEmployees_total(countEmployees);
            SetEmployeesWithRemu(countEmployeesWithRemu);

            let branch_office_quantity = 0;
            if (branch_office.length > 0) {
                let obj;
                let branch_office_obj_values = [];
                for (let index = 0; index < branch_office.length; index++) {
                    const keys = Object.keys(branch_office[index]);
                    const values = Object.values(branch_office[index]);
                    obj = {
                        ...obj,
                        name: keys[0],
                        name2: keys[1],
                        chargue_type: values[0],
                        full_payment: values[3]
                    }
                    branch_office_obj_values.push(obj || {});
                    branch_office_quantity = branch_office_quantity + branch_office[index].quantity;
                }
                setBranch_office_obj(branch_office_obj_values)
                setBranchOfficeQuantity(branch_office_quantity)
            }
            let business_area_quantity = 0;
            if (business_area.length > 0) {
                let obj;
                let business_area_obj_values = [];
                for (let index = 0; index < business_area.length; index++) {
                    const keys = Object.keys(business_area[index]);
                    const values = Object.values(business_area[index]);
                    console.log(values)
                    obj = {
                        ...obj,
                        name: keys[0],
                        name2: keys[1],
                        business_area: values[0],
                        full_payment: values[3]
                    }
                    business_area_obj_values.push(obj || {});
                    business_area_quantity = business_area_quantity + business_area[index].quantity;
                }
                setBusiness_area_obj(business_area_obj_values)
                setBusinessAreaQuantity(business_area_quantity)
            }
            let chargue_type_quantity = 0;
            if (chargue_type.length > 0) {
                let obj;
                let chargue_type_obj_values = [];
                for (let index = 0; index < chargue_type.length; index++) {
                    const keys = Object.keys(chargue_type[index]);
                    const values = Object.values(chargue_type[index]);
                    obj = {
                        ...obj,
                        name: keys[0],
                        name2: keys[1],
                        chargue_type: values[0],
                        full_payment: values[3]
                    }
                    chargue_type_obj_values.push(obj || {});
                    chargue_type_quantity = chargue_type_quantity + chargue_type[index].quantity;
                }
                setChargue_typee_obj(chargue_type_obj_values)
                setChargueTypeQuantity(chargue_type_quantity)
            }
            let cost_center_quantity = 0;
            if (cost_center.length > 0) {
                let obj;
                let cost_center_obj_values = [];
                for (let index = 0; index < cost_center.length; index++) {
                    const keys = Object.keys(cost_center[index]);
                    const values = Object.values(cost_center[index]);
                    console.log(values)
                    obj = {
                        ...obj,
                        name: keys[0],
                        name2: keys[1],
                        cost_center: values[0],
                        full_payment: values[3]
                    }
                    cost_center_obj_values.push(obj || {});
                    cost_center_quantity = cost_center_quantity + cost_center[index].quantity;
                }
                setCost_center_obj(cost_center_obj_values)
                setCostCenterQuantity(cost_center_quantity)
            }

            if(branch_office_quantity != 0 && business_area_quantity != 0 && chargue_type_quantity != 0 && cost_center_quantity != 0 ) setExcelDissable(true);
            setLoading(false);
        })
    }

    const  getExcelReport = async() =>{
        limpiarData();
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSeleccionarMesyAno")
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year}
        
        const respExcel = await ReportService.getReportLiquidationExcel(id_company, dataPre?.idperiod);
       
        const btn_excel = document.createElement("a")
        btn_excel.href = `${respExcel.data.link}`
        btn_excel.download = 'Liquidation_report.xlsx'

        document.body.appendChild(btn_excel);
        btn_excel.click();
        document.body.removeChild(btn_excel)
        setLoading(false);
    }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    {___PHRASE("reportePorLiquidaciones")}
                </Typography>

                <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={yearSelected}
                                onChange={(event) => {
                                setYearSelected(event.target.value);
                                }}
                                fullWidth
                            >
                                {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1">{___PHRASE("mes")} </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={monthSelected}
                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                fullWidth
                            >
                                <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid xs={2} display='flex'>
                        <Button onClick={showLiquidationsReport} type={'submit'} size="small" color={"primary"} variant="contained" >
                            {___PHRASE("filtrar")}
                        </Button>
                    </Grid>
                </Grid>

                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }

                {
                    (!loading) && (
                        <>

                        <Grid container xs={12} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                            <Grid item xs={9} sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="subtitle2" className="custom-input" 
                                    sx={{ border: `1px #81a4cb solid`, borderRadius:'8px', padding: '5px', color: '#707ac3', width:'610px', textAlign: 'center', marginRight: '5px'}}>{___PHRASE("seEncontro")+' '+ employeesWithRemu+' '+___PHRASE("empleadosConLiquidacionGeneradaEnElPeriodoyMesSeleccionado")}
                                </Typography>

                                <Typography variant="subtitle2" className="custom-input" 
                                    sx={{ border: `1px #81a4cb solid`, borderRadius:'8px', padding: '5px', color: '#707ac3', width:'235px',textAlign: 'center' }}>
                                    Total empleados con contrato: {___PHRASE("totalEmpleadosConContrato")+': '+countEmployees_total}.
                                </Typography>
                            </Grid>

                            {
                                excelDissable && (
                                    <Grid item xs={3} sx={{textAlign: 'right'}}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => getExcelReport()}
                                        >
                                            {___PHRASE("descargarExcel")}
                                        </Button>
                                    </Grid>
                                )
                            }
                        </Grid>

                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {___PHRASE("sucursales")}
                            </Typography>
                            {
                                branchOfficeQuantity > 0 ? (
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>

                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>{___PHRASE("nombre")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("cantidadEmpleados")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("totalPago")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {branch_office_obj?.map(row => {
                                                    return (
                                                        <TableRow >
                                                            <TableCell >
                                                                {row.name}
                                                            </TableCell> 
                                                            <TableCell >
                                                                {row.chargue_type}
                                                            </TableCell>
                                                            <TableCell >
                                                                {moneyFormat(row.full_payment)}
                                                            </TableCell>
                                                        </TableRow >
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', margin:'10px' }} >
                                        <Div>{___PHRASE("noSeEncontraronEmpleadosConLiquidacion")}</Div>
                                    </Box>
                                )
                            }
                        </Card>
                        </Grid>

                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {___PHRASE("departamento")}
                            </Typography>
                            {
                                businessAreaQuantity > 0 ? (
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>{___PHRASE("nombre")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("cantidadEmpleados")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("totalPago")}</Typography>
                                                    </TableCell>
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                {business_area_obj?.map(row => {
                                                    return (
                                                        <TableRow >
                                                            <TableCell >
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.business_area}
                                                            </TableCell>
                                                            <TableCell >
                                                                {moneyFormat(row.full_payment)}
                                                            </TableCell>
                                                        </TableRow >
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', margin:'10px' }} >
                                        <Div>{___PHRASE("noSeEncontraronEmpleadosConLiquidacion")}</Div>
                                    </Box>
                                )
                            }
                        </Card>
                        </Grid>

                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {___PHRASE("tipoDeCargos")}
                            </Typography>
                            {
                                chargueTypeQuantity > 0 ? (
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>{___PHRASE("nombre")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("cantidadEmpleados")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("totalPago")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {chargue_type_obj?.map(row => {
                                                    return (
                                                        <TableRow >
                                                            <TableCell >
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.chargue_type}
                                                            </TableCell>
                                                            <TableCell >
                                                                {moneyFormat(row.full_payment)}
                                                            </TableCell>
                                                        </TableRow >
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', margin:'10px' }} >
                                        <Div>{___PHRASE("noSeEncontraronEmpleadosConLiquidacion")}</Div>
                                    </Box>
                                )
                            }
                        </Card>
                        </Grid>

                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {___PHRASE("centrosdecostos")}
                            </Typography>
                            {
                                costCenterQuantity > 0 ? (
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>{___PHRASE("nombre")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("cantidadEmpleados")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("totalPago")}</Typography>
                                                    </TableCell>
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                {cost_center_obj?.map(row => {
                                                    return (
                                                        <TableRow >
                                                            <TableCell >
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.cost_center}
                                                            </TableCell>
                                                            <TableCell >
                                                                {moneyFormat(row.full_payment)}
                                                            </TableCell>
                                                        </TableRow >
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', margin:'10px' }} >
                                        <Div>{___PHRASE("noSeEncontraronEmpleadosConLiquidacion")}</Div>
                                    </Box>
                                )
                            }
                        </Card>
                        </Grid>

                        </>
                    )
                }

            </Container>
        </Protected>
    )
};