import { http } from '../http/http';
import { UserDetailsDTO,UserDTO,UserPost,UserRequestPost, UsersDTO} from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { User } from '../models/User';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idCompany = dataUser?.user?.id_company;

export const userRepository = {

   getRoles: async(): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/detailUserCompany/role`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   getUsers: async (search = "") : Promise<any>=> {
      const users = await http.get<any>(`${API_URL_BASE}/v1/user?search=${search}`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            iduser: raw?.iduser,
            assign_ip: raw?.assign_ip,
            user_name: raw?.user_name,
            n_document: raw?.n_document,
            email: raw?.email,
            idrole: raw?.idrole,
            role: raw?.role,
            idemployee: raw?.idemployee ? raw.idemployee : null,
            employee: raw?.employee ? raw.employee : null,
            profile: raw?.profile ? raw.profile : null,
         })),
         error,
         message
      }
   },

   getUserByCompany: async (search = '') : Promise<any>=> {
      const users = await http.get<any>(`${API_URL_BASE}/v1/user/getUserByCompany?search=${search}`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            iduser: raw?.iduser,
            assign_ip: raw?.assign_ip,
            user_name: raw?.user_name,
            n_document: raw?.n_document,
            email: raw?.email,
            idrole: raw?.idrole,
            role: raw?.role,
            idemployee: raw?.idemployee ? raw.idemployee : null,
            employee: raw?.employee ? raw.employee : null,
            profile: raw?.profile ? raw.profile : null,
         })),
         error,
         message
      }
   },

   getUserPage: async (perPage, page): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const user = await http.get<any>(`${API_URL_BASE}/v1/user/${rut_empresa}?per_page=${perPage}&page=${page}` )
      const {data,error,message} = user
      return {
         data,
         error,
         message
      };
   },

   getUserSearch: async (search: string): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const user = await http.get<any>(`${API_URL_BASE}/v1/user?search=${search}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const {data,error,message} = user
      return {
         data,
         error,
         message
      };
   },
   
   getUserDetails: async (id: number) : Promise<UserDetails>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const users = await http.get<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);

      let { data, error, message } = users;

      return {
         data,
         error,
         message
      }
   },

   createUser: async (dataUser) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/user/create`, {
         id: dataUser.id,
         email: dataUser.email,
         user_name: dataUser.user_name,
         password: dataUser.password,
         confirm_password: dataUser.confirm_password,
         n_document: dataUser.n_document,
         role: dataUser.role,
         idCompany: idCompany,
         assign_ip: dataUser.assign_ip,
         url: 'https://test.remy.cl/',
      })
      return { status: resp.status, message: resp.message, data: resp.data };
   },

   createOnlyUser: async (dataUser) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/user/createOnlyUser`, {
         id: dataUser.id,
         email: dataUser.email,
         user_name: dataUser.user_name,
         n_document: dataUser.n_document,
         role: dataUser.role,
         idCompany: dataUser.idCompany,
         assign_ip: dataUser.assign_ip,
         url: 'https://test.remy.cl/',
      })
      return { status: resp.status, message: resp.message, data: resp.data };
   },

   // updateUser: async (id: number, dataUser: UserPost) : Promise<any> => {
   //    const userEdited = await http.patch<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}`, {
   //       idtipousuario: dataUser.idtipousuario,
   //       nombre: dataUser.nombre,
   //       email: dataUser.email,
   //       password: dataUser.password,
   //       role: dataUser.role,
   //    });
   //    return userEdited;
   // },
   updateStatusUser: async (id: number, dataUser: UserPost) : Promise<any> => {
      const userEdited = await http.patch<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}`, {
         // is_active: dataUser.is_active
      });

      return userEdited;
   },
   //verificaciones user
   verifyUsernick: async (user_nick:string) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/verify-nick`, {
         user_nick: user_nick
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      }
   },
   verifyEmail: async (email:string) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/verify-email`, {
         email: email
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      }
   },
   getUserData: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/user/data/initial?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return {
         data,
         error,
         message
      }
   },
   updateUser: async (id: number, dataUser) : Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/user/${id}`, {
         idtipousuario: dataUser.idtipousuario,
         nombre: dataUser.nombre,
         user_nick: dataUser.user_nick,
         email: dataUser.email_admin,
         password: dataUser.password,
         telefono: dataUser.telefono
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   updateParticipationUsers: async (participacion) : Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/user/participacion/data`, {
         participacion:participacion
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   deleteUser: async (id: number, roleUserSession) : Promise<any> => {
      const userDeleted = await http.delete(`${API_URL_BASE}/v1/user/${id}?user_session=${roleUserSession}`)
      return userDeleted;
   },

   ResendPassword: async (idusuario) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/${idusuario}`, {})
      return resp;
   },

   RelationUSerEmployee: async (idUser, idEmployee) : Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/detailUserCompany/updateEmployee/${idUser}?idemployee=${idEmployee}`)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   updatePassword: async (dataPassword) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/updatePassword`, dataPassword)
      return resp;
   },

   update: async (data) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/update`, data)
      return resp;
   },

   editDetailUserCompany: async (dataUserCompany) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/detailUserCompany/edit`, dataUserCompany)
      return resp;
   },

   createDetailUser: async (dataDetail) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/detailUserCompany/create`, {
         id: dataDetail.id,
         iduser: dataDetail.iduser,
         idcompany: dataDetail.idcompany,
         idrole: dataDetail.idrole,
         assign_ip: dataDetail.assign_ip,
         idemployee: dataDetail.idemployee,
      })
      return { status: resp.status, message: resp.message, data: resp.data };
   },
}


