import { Props } from './ScheduleWorker.type';
import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Breadcrumbs, Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";
import { ScheduleService } from '@/service/services/Schedule.service';
import { ModalScheduleWorker } from '@/components/Modal/ModalScheduleWorker/ModalScheduleWorker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ___PHRASE from '@/service/translate/translateService';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const ScheduleWorkerView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);

    const [actionSelected, setActionSelected] = useState<any>('');
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');

    const [schedule, setSchedule] = useState<any>([]);
    const [scheduleSelected, setScheduleSelected] = useState<any>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    
    const [scheduleDetail, setScheduleDetail] = useState<any>([])
    const [scheduleDetailSelected, setScheduleDetailSelected] = useState<any>('');
    const [showModalDetail, setShowModalDetail] = useState<boolean>(false)
    
    const [showSchedule, setShowSchedule] = useState<boolean>(false);
    const [showScheduleDetail, setShowSchduleDetail] = useState<boolean>(false)
    const [view, setView] = useState<String>('workers');

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;
    const sessionUser = dataUser?.user;

    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }];

    const cabeceraSchedule = [
    { name: 'check_in_times', label: ___PHRASE("horarioDeEntrada") },
    { name: 'start_collation', label: ___PHRASE("inicioDeColacion") },
    { name: 'end_collation', label: ___PHRASE("finDeColacion") },
    { name: 'closing_hour', label: ___PHRASE("horarioDeSalida") },
    { name: 'weekly_hour', label: ___PHRASE("horasALaSemana") },
    ]

    const cabeceraScheduleDetaiil = [
        { name: 'start_date', label: ___PHRASE("fechaInicio") },
        { name: 'end_date', label: ___PHRASE("fechaFin") },
        { name: 'weekly', label: ___PHRASE("diasLaborales") },
        { name: 'observations', label: ___PHRASE("observacion") },
    ]

    useEffect(()=> {
      getWorkers();
    },[]);

    useEffect(()=> {
        if(workerSelected) getScheduleByEmployee(workerSelected.id);
    },[workerSelected]);

    useEffect(()=>{
        if(view == 'view_detail') getScheduleDetailByEmployee(workerSelected.id, scheduleSelected.id)
    },[scheduleSelected])

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getScheduleByEmployee = async(idEmployee) => {
        setLoading(true)
        const resp = await ScheduleService.getScheduleEmployee(idEmployee);
        setSchedule(resp.data || [])
        setLoading(false)
    }

    const getScheduleDetailByEmployee = async(idEmployee, idworkSchedule) => {
        setLoading(true)
        const resp = await ScheduleService.getScheduleDetailEmployee(JSON.stringify([idEmployee]), idworkSchedule);
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                weekly: item.weekly.length > 0 ? item.weekly.join(" - ") : '',
            }
            newData.push(obj);
        });
        setScheduleDetail(newData || [])
        setLoading(false)
    }

    function RecuperarData(value){

        !workerSelected && setWorkerSelected(value);
        workerSelected && !scheduleSelected && setScheduleSelected(value);
        view == 'view_detail' && scheduleSelected && !scheduleDetailSelected && setScheduleDetailSelected(value);

        if(value.action == "helper"){
            if(view == 'workers'){
                setShowSchedule(true)
                setView('schedule')
            }else if(view == 'schedule'){
                setShowSchduleDetail(true)
                setView('view_detail')
                setScheduleSelected(value)
            }
         }
    }

    const searchData = async (value) => {
        const resp:any = await WorkerService.getWorkers(value);
        setWorkers(resp.data || []);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            {
                role == ROLE_ADMIN && (
                    <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:2}}>
                        <StyledBreadcrumb
                            label={___PHRASE("listaDeEmpleados")}
                            onClick={() => {
                                setView('workers');
                                setWorkerSelected('');
                                setScheduleSelected('');
                                setScheduleDetailSelected('');
                                setShowSchedule(false);
                                setShowSchduleDetail(false);
                            }}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                        {
                            showSchedule && (
                                <StyledBreadcrumb
                                    label={___PHRASE("horario")}
                                    onClick={() => {
                                        setView('schedule');
                                        setScheduleDetailSelected('');
                                        setShowSchduleDetail(false);
                                    }}
                                    icon={<GroupsIcon fontSize="small" />}
                                />
                            )
                        }
                         {
                            showScheduleDetail && (
                                <StyledBreadcrumb
                                    label={___PHRASE("detalleDeHorario")}
                                    onClick={() => {setView('view_detail')}}
                                    icon={<GroupsIcon fontSize="small" />}
                                />
                            )
                        }
                    </Breadcrumbs>
                )
            }
            
            {
                (role == ROLE_ADMIN && view == 'workers') && (
                    <TableDataV2
                        title={___PHRASE("horariosPorEmpleado")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<AccessTimeIcon fontSize="small" ></AccessTimeIcon>}
                        toolTipBtnHelper={___PHRASE("verHorario")}
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'schedule' && (
                    <TableDataV2
                        title={___PHRASE("horarioDe")+` ${workerSelected?.name}, ${workerSelected?.surname && workerSelected?.surname} ${workerSelected?.second_surname && workerSelected?.second_surname}`}
                        data={schedule}
                        header={cabeceraSchedule}
                        actionSelect={setActionSelected}
                        setModalSave={setShowModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={true}
                        currentView={view}
                        btn_helper
                        iconBtnHelper={<EventNoteIcon fontSize="small" ></EventNoteIcon>}
                        toolTipBtnHelper={___PHRASE("verDetalles")}
                        RecuperarData={RecuperarData}
                    />
                )
            }

{
                view == 'view_detail' && (
                    <TableDataV2
                        title={___PHRASE("detalleDeHorariosDe")+` ${workerSelected?.name}, ${workerSelected?.surname && workerSelected?.surname} ${workerSelected?.second_surname && workerSelected?.second_surname}`}
                        data={scheduleDetail}
                        header={cabeceraScheduleDetaiil}
                        actionSelect={setActionSelected}
                        setModalSave={setShowModalDetail}
                        status_action
                        disabled_title={false}
                        btn_disabled={true}
                        btn_edit_disable={false}
                        btn_delete_disable={true}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                )
            }
            </Container>

            { 
                showModal && (
                    <ModalScheduleWorker
                        open={showModal}
                        closeModal={()=>{setShowModal(false)}}
                        actionSelected={actionSelected}
                        onList={getScheduleByEmployee}
                        schedule={scheduleSelected}
                        workerSelected={workerSelected || sessionUser}
                        clearState={()=>{setScheduleSelected('')}}
                    />
                )
            }

            { 
                showModalDetail && (
                    <ModalScheduleWorker
                        open={showModalDetail}
                        closeModal={()=>{setShowModalDetail(false)}}
                        actionSelected={actionSelected}
                        onList={getScheduleDetailByEmployee}
                        scheduleDetailSelected={scheduleDetailSelected}
                        workerSelected={workerSelected || sessionUser}
                        clearState={()=>{setScheduleDetailSelected('')}}
                    />
                )
            }
        
        </Protected>
    )
};