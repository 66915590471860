import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Button, Select, Card, Container, Grid, MenuItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, styled } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { PeriodService } from "@/service/services/Period.service";
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import moment from 'moment';
import ___PHRASE from '@/service/translate/translateService';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const ReportgGroupCompany: React.FC<any> = (props: any): JSX.Element => {

    const [data, setData] = useState<any>([]);

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>(moment().format('YYYY'));
    const [monthSelected, setMonthSelected] = useState<any>(moment().format('MM'));
    const [excelDissable, setExcelDissable]= useState<any>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const userData = readLocalStorage(KEY_USER_DATA);

    useEffect(() => {
        getPeriods();
    }, []);

    useEffect(()=> {
        if(periods.length > 0) getReportGroupCompany();
    },[periods]);

    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                ...item,
                alias_name: item.month + '-' + item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if (!found) { arrayYears.push(item.year); }

        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const getReportGroupCompany = async () => {
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSeleccionarMesyAno")
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year
        }
        await ReportService.getReportGroupCompany(dataPre.idperiod).then((resp) => {
            console.log(resp.data || [])
            setData(resp.data || []);
            if((resp.data).length > 0) setExcelDissable(true);
            setLoading(false);
        })

    }

    const getReportGroupCompanyExcel = async() => {
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSeleccionarMesyAno")
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        await ReportService.getReportGroupCompanyExcel(period.id).then((resp) => {
            const btn_excel = document.createElement("a")
            btn_excel.href = `${resp.data.link}`
            btn_excel.download = 'Company_group_report.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)
            setLoading(false);
        })

    }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    {___PHRASE("reporteDeGrupoDeEmpresas")}
                </Typography>

                <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={yearSelected}
                                onChange={(event) => {
                                setYearSelected(event.target.value);
                                }}
                                fullWidth
                            >
                                {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1">{___PHRASE("mes")} </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={monthSelected}
                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                fullWidth
                            >
                                <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid xs={2} display='flex'>
                        <Button onClick={getReportGroupCompany} type={'submit'} size="small" color={"primary"} variant="contained" >
                            {___PHRASE("filtrar")}
                        </Button>
                    </Grid>
                </Grid>

                {
                    (data.length == 0 && !loading) && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }} >
                            <Div>{___PHRASE("noSeEncontraronDatos")}</Div>
                        </Box>
                    )
                }
                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }

                {(excelDissable && !loading)  &&
                    <Grid container spacing={3} sx={{ marginTop: '10px', marginBottom: '20px' }}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='flex-end'>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={getReportGroupCompanyExcel}
                            >
                                {___PHRASE("descargarExcel")}
                            </Button>
                        </Grid>
                    </Grid>
                }

                {
                    (data.length > 0 && !loading) && (
                        <Card sx={{ width: "100%", marginTop: '20px' }}>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>

                                        <TableRow >
                                            <TableCell >
                                                <Typography>RUT</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("empresa")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("totalEmpleados")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("empleadosPorLiquidacion")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("pagoAfp")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("pagoSalud")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{___PHRASE("pagototal")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.map(row => {
                                            return (
                                                <TableRow >
                                                    <TableCell >
                                                        {row.company.registry_number}
                                                    </TableCell>
                                                    <TableCell >
                                                        {row.company.business_name}
                                                    </TableCell>
                                                    <TableCell >
                                                        {row.countEmployees}
                                                    </TableCell>
                                                    <TableCell >
                                                        {row.countEmployeesWithRemu}
                                                    </TableCell>
                                                    <TableCell >
                                                        {moneyFormat(row.totalAFP)}
                                                    </TableCell>
                                                    <TableCell >
                                                        {moneyFormat(row.totalHealt)}
                                                    </TableCell>
                                                    <TableCell >
                                                        {moneyFormat(row.totalPayment)}
                                                    </TableCell>
                                                </TableRow >
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    )
                }

            </Container>
        </Protected>
    )
};