import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './TemplateMonthly.type'
import { Alert, Autocomplete, Button, Card, Container, Grid, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Loading } from '@/components/common/Loading';
import { PeriodService } from '@/service/services/Period.service';
import { templateRemunerationMonthlyService } from '@/service/services/TemplateRemunerationMonthly.service';
import { WorkerService } from '@/service/services/Worker.service';
import { saveLocalStorage, readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { ROUTE_CERTIFICATE_EMPLOYEE_PDF } from '@/toolbox/constants/route-map';
import { PreviewPrevired } from './PreviewPrevired';
import { PreviewEbook } from './PreviewEbook';
import { costCentersRepository } from '@/service/repositories/CostCenters.repository';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import ___PHRASE from '@/service/translate/translateService';
import { ROLE_ADMIN } from '@/toolbox/constants/role-type';
import moment from 'moment';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const TemplateMonthlyView: React.FC<Props> = (props: any): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [available, setAvailable] = useState<boolean>(false);

    const data_user = readLocalStorage(KEY_USER_DATA);
    //DATOS DE USUARIO LOGEADO
    const typeEmployee= data_user?.user?.idrole;
    const idEmployee= data_user?.user?.idemployee;
    const role = data_user?.user?.role;
    
    
    const [costCenters, setCostCenters] = useState([]);
    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>(null)
    const [linkExcel, setLinkExcel] = useState<any>(null);
    const [linkCSV, setLinkCSV] = useState<any>(null)
    const [showMain, setShowMain] = useState<boolean>(true);
    const [showPrevired, setShowPrevired] = useState<boolean>(false);
    const [dataPrevired, setDataPrevired] = useState<any>([]);

    const [yearPrevSelected, setYearPrevSelected] = useState<any>(moment().format('YYYY'))
    const [monthPrevSelected, setMonthPrevSelected] = useState<any>(moment().format('MM'));
    const [periodPrevSelected, setPeriodPrevSelected] = useState<any>('');
    const [costCenterPrevSelected, setCostCenterPrevSelected] = useState<any>('');
    
    const [yearLibrSelected, setYearLibrSelected] = useState<any>(moment().format('YYYY'));
    const [monthLibrSelected, setMonthLibrSelected] = useState<any>(moment().format('MM'));
    const [periodLibrSelected, setPeriodLibrSelected] = useState<any>('');
    const [costCenterLibrSelected, setCostCenterLibrSelected] = useState<any>('');
    
    const [yearDJSelected, setYearDJSelected] = useState<any>(moment().format('YYYY'));
    const [yearCertSelected, setYearCertSelected] = useState<any>(moment().format('YYYY'));

    const [years, setYears] = useState<any>([]);
    const [periods, setPeriods] = useState<any>([]);

    const [showEbook, setShowEbook] = useState<boolean>(false);
    const [dataEbook, setDataEbook] = useState<any>([]);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const componentsAvailable=()=>{
        switch (typeEmployee) {
            case 5:
                setAvailable(false)
                break;
            default:
                setAvailable(true)
                break;
        }
    }
    
    useEffect(() => {
        getPeriods();
        getTrabajadores();
        getCenterCostos();
        componentsAvailable();
    }, []);

    const getTrabajadores = async  () => {
            const resp = await WorkerService.getWorkers();
            setWorkerOptions(resp?.data)
            if(typeEmployee == 5){
                setWorkerSelected(resp?.data.filter((item) => item.id == idEmployee )[0])
            }
    }

    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' + item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if (!found) { arrayYears.push(item.year); }

        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const getCenterCostos = async () => {
        setLoading(true);
        const response = await costCentersRepository.getCostCenters();
        if (response.data) {
           setCostCenters(response.data || [])
        } else {
           console.log('error')
        }
        setLoading(false);
     }

    const months = [
        { number: '01', name: ___PHRASE("enero") },
        { number: '02', name: ___PHRASE("febrero") },
        { number: '03', name: ___PHRASE("marzo") },
        { number: '04', name: ___PHRASE("abril") },
        { number: '05', name: ___PHRASE("mayo") },
        { number: '06', name: ___PHRASE("junio") },
        { number: '07', name: ___PHRASE("julio") },
        { number: '08', name: ___PHRASE("agosto") },
        { number: '09', name: ___PHRASE("septiembre") },
        { number: '10', name: ___PHRASE("octubre") },
        { number: '11', name: ___PHRASE("noviembre") },
        { number: '12', name: ___PHRASE("diciembre") },
    ]

    const getPrevired = async() => {
        setLoading(true);
        if(!monthPrevSelected || !yearPrevSelected){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeLlenarTodosLosCampos") }
               ))
            );
         }

        const periodSelected = monthPrevSelected + '-' + yearPrevSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        setPeriodPrevSelected(period);
   
        const resp = await templateRemunerationMonthlyService.getPrevired(period.id, costCenterPrevSelected?.id || "");
        let apimessage = apiResponseTranslate(resp.data.message)
        if(resp.data.status == false){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
               ))
            );
        }else{
            setDataPrevired(resp.data.detail)
            setShowMain(false);
            setShowPrevired(true);
        }
        setYearPrevSelected(null);
        setMonthPrevSelected(null);
        setCostCenterPrevSelected('');
        setLoading(false);
    }

    const getElectronicBook = async() => {
        setLoading(true);
        if(!monthLibrSelected || !yearLibrSelected){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeLlenarTodosLosCampos") }
               ))
            );
         }
         const periodSelected = monthLibrSelected + '-' + yearLibrSelected;
         const period = periods.find(element => element.alias_name == periodSelected);
         setPeriodLibrSelected(period);
   
        const resp = await templateRemunerationMonthlyService.getElectronicBook(period.id, costCenterLibrSelected?.id || "");
        let apimessage = apiResponseTranslate(resp.data.message)
        if(resp.data.status == false){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
               ))
            );
        }else{
            setDataEbook(resp.data)
            setShowMain(false);
            setShowEbook(true);
        }
        setYearLibrSelected('');
        setMonthLibrSelected('');
        setCostCenterLibrSelected('');
        setLoading(false);
    }

    const getDJ = async(typeDownload) => {
        if( !yearDJSelected){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeLlenarTodosLosCampos") }
               ))
            );
         }
         if( typeDownload == 'excel'){
            const respExcel = await templateRemunerationMonthlyService.createDJExcel(yearDJSelected);
            setLinkExcel(respExcel.data.link)
            const btn_excel = document.createElement("a")
            btn_excel.href = `${respExcel.data.link}`
            btn_excel.download = 'DJ.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)

         }else{
            const respCSV = await templateRemunerationMonthlyService.createDJCsv(yearDJSelected);
            setLinkCSV(respCSV.data.link)
            const btn_csv = document.createElement("a")
            btn_csv.href = `${respCSV.data.link}`
            btn_csv.download = 'DJ.csv'

            document.body.appendChild(btn_csv);
            btn_csv.click();
            document.body.removeChild(btn_csv)
    
         }
    }

    const certificateRemuneration = async() => {

        setLoading(true);
        if(!yearCertSelected || !workerSelected){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeLlenarTodosLosCampos") }
               ))
            );
        }

        const pdfResp: any = await templateRemunerationMonthlyService.createPDFCertificateRemuneration(yearCertSelected, workerSelected.id);

        if(pdfResp.size < 200){
            setLoading(false);
            setSnackBarConfig(prev => ({
                ...prev, open: true,
                severity: 'warning',
                message: role == ROLE_ADMIN ? 
                         `${___PHRASE('aseguresequeelempleadotengaliquidaciones')}` : `${___PHRASE('nocuentasconliquidacionesparaelañoseleccionado')}`
                }
            ));
        }else{
           const blob = new Blob([pdfResp], { type: 'application/pdf' });
           const blobURL = URL.createObjectURL(blob);
           const dataPDF = {
              pdf: blobURL,
           }
           saveLocalStorage('CertificateEmployee', dataPDF);
           setLoading(false);
            window.open(`${ROUTE_CERTIFICATE_EMPLOYEE_PDF}`, '_target');
        }
        setLoading(false);
    }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            {
                showMain && (
                    <Container>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle"
                            sx={{ m: 1, fontWeight: 600 }}>{___PHRASE("planillasDeRemuneraciones")}</Typography>
                        {available &&
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>{___PHRASE("previredMesyPeriodo")}</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    {___PHRASE("periodo")}
                                                </TableCell>
                                                <TableCell >
                                                    {/* <InputLabel id="demo-simple-select-label">{___PHRASE("periodo")}</InputLabel> */}
                                                    <Select
                                                        id="id_education_level"
                                                        variant="standard"
                                                        value={yearPrevSelected}
                                                        onChange={(event) => {
                                                            setYearPrevSelected(event.target.value);
                                                        }}
                                                        sx={{ width: '300px' }}
                                                    >
                                                        {years.map((year, i) => {
                                                            return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                        })}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    {___PHRASE("mes")}
                                                </TableCell>
                                                <TableCell >
                                                    {/* <InputLabel id="demo-simple-select-label">Mes</InputLabel> */}
                                                    <Select
                                                        id="id_education_level"
                                                        variant="standard"
                                                        value={monthPrevSelected}
                                                        onChange={(event) => { setMonthPrevSelected(event.target.value) }}
                                                        sx={{ width: '300px' }}
                                                    >
                                                        {months.map((value) => {
                                                            return (
                                                                <MenuItem value={value.number}>{value.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>

                                        <TableRow >
                                            <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                {___PHRASE("centroDeCosto")}
                                            </TableCell>
                                            <TableCell >
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={costCenterPrevSelected}
                                                    options={costCenters || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    sx={{ width: '300px' }}
                                                    renderInput={(params) => <TextField {...params} variant="standard"/>}
                                                    onChange={(e, newValue) => {
                                                        if (newValue) {
                                                            setCostCenterPrevSelected(newValue)
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                <Button color="primary" variant="contained" onClick={() => {
                                getPrevired()
                                }}>
                                    {___PHRASE("ir")}
                                </Button>
                            </Grid>
                        </Card>
                        }
                        {available && 
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>{___PHRASE("libroElectronicoDeRemuneraciones")}</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    {___PHRASE("periodo")}
                                                </TableCell>
                                                <TableCell >
                                                    {/* <InputLabel id="demo-simple-select-label">{___PHRASE("periodo")}</InputLabel> */}
                                                    <Select
                                                        id="id_education_level"
                                                        variant="standard"
                                                        value={yearLibrSelected}
                                                        onChange={(event) => {
                                                            setYearLibrSelected(event.target.value);
                                                        }}
                                                        sx={{ width: '300px' }}
                                                    >
                                                        {years.map((year, i) => {
                                                            return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                        })}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    {___PHRASE("mes")}
                                                </TableCell>
                                                <TableCell >
                                                    {/* <InputLabel id="demo-simple-select-label">Mes</InputLabel> */}
                                                    <Select
                                                        id="id_education_level"
                                                        variant="standard"
                                                        value={monthLibrSelected}
                                                        onChange={(event) => { setMonthLibrSelected(event.target.value) }}
                                                        sx={{ width: '300px' }}
                                                    >
                                                        {months.map((value) => {
                                                            return (
                                                                <MenuItem value={value.number}>{value.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    {___PHRASE("centroDeCosto")}
                                                </TableCell>
                                                <TableCell >
                                                    <Autocomplete
                                                        id="disable-portal"
                                                        disablePortal
                                                        value={costCenterLibrSelected}
                                                        options={costCenters || []}
                                                        getOptionLabel={(option) => option.name || ""}
                                                        sx={{ width: '300px' }}
                                                        renderInput={(params) => <TextField {...params} variant="standard" />}
                                                        onChange={(e, newValue) => {
                                                            if (newValue) {
                                                                setCostCenterLibrSelected(newValue)
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                    <Button color="primary" variant="contained" onClick={() => {
                                        getElectronicBook()
                                    }}>
                                        {___PHRASE("ir")}
                                    </Button>
                                </Grid>
                            </Card>
                        }
                        <Card sx={{ width: "100%", marginTop: '20px' }}>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>{___PHRASE("certificadoDelTrabajador")}</Typography>
                                            </TableCell>
                                            <TableCell >
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow >
                                            <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                {___PHRASE("periodo")}
                                            </TableCell>
                                            <TableCell >
                                                {/* <InputLabel id="demo-simple-select-label">{___PHRASE("periodo")}</InputLabel> */}
                                                <Select
                                                    id="id_education_level"
                                                    variant="standard"
                                                    value={yearCertSelected}
                                                    onChange={(event) => {
                                                        setYearCertSelected(event.target.value);
                                                    }}
                                                    sx={{ width: '300px' }}
                                                >
                                                    {years.map((year, i) => {
                                                        return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                    })}
                                                </Select>
                                            </TableCell>
                                        </TableRow>


                                        <TableRow >
                                            <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                {___PHRASE("trabajador")}
                                            </TableCell>
                                            <TableCell >
                                                {/* <InputLabel id="demo-simple-select-label">Mes</InputLabel> */}
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={workerSelected}
                                                    options={typeEmployee == 5 ? (workerOptions.filter((value) => { return value.id == idEmployee }) || []) : (workerOptions?workerOptions:[])}
                                                    getOptionLabel={(option) => option.name+' '+option.surname || ""}
                                                    sx={{ width: '300px' }}
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("seleccioneUnTrabajador")} />}
                                                    onChange={(e, newValue) => { setWorkerSelected(newValue) }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                <Button color="primary" variant="contained" onClick={() => {
                                    certificateRemuneration()
                                }}>
                                    {___PHRASE("ir")}
                                </Button>
                            </Grid>
                        </Card>

                        {available && 
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>{___PHRASE("generaDj")}</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    {___PHRASE("periodo")}
                                                </TableCell>
                                                <TableCell >
                                                    {/* <InputLabel id="demo-simple-select-label">{___PHRASE("periodo")}</InputLabel> */}
                                                    <Select
                                                        id="id_education_level"
                                                        variant="standard"
                                                        value={yearDJSelected}
                                                        onChange={(event) => {
                                                            setYearDJSelected(event.target.value);
                                                        }}
                                                        sx={{ width: '300px' }}
                                                    >
                                                        {years.map((year, i) => {
                                                            return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                        })}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>

                                    
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid sx={{ textAlign: 'center', margin: '10px' }} >
                                <Button color="primary" variant="contained" sx={{mr:2}} onClick={() => {
                                getDJ('excel')
                                }}>
                                    EXCEL
                                </Button>
                                <Button color="primary" variant="contained" onClick={() => {
                                    getDJ('csv')
                                }}>
                                    CSV
                                </Button>
                            </Grid>
                        </Card>}
                    </Container>
                )
            }
            {
                showPrevired && (
                    <PreviewPrevired
                        data={dataPrevired}
                        setShowMain={setShowMain}
                        setShowPrevired={setShowPrevired}
                        period={periodPrevSelected}
                        costCenter={costCenterPrevSelected}
                    />
                )
            }
            {
                showEbook && (
                    <PreviewEbook
                        data={dataEbook}
                        setShowMain={setShowMain}
                        setShowEbook={setShowEbook}
                        period={periodLibrSelected}
                        costCenter={costCenterLibrSelected}
                    />
                )
            }


        </Protected>
    )
};
