import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { useEffect, useState } from "react";
import { Alert, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { RemunerationService } from "@/service/services/Remuneration.service";
import { PeriodService } from "@/service/services/Period.service";
import moment from "moment";
import { RequestService } from "@/service/services/Request.service";
import ___PHRASE from "@/service/translate/translateService";

//aqui

export const ModalRequestLiquidationError = (props:any): JSX.Element => {

   const { open, closeModal, liquidation } = props;

   const [loading, setLoading] = useState<boolean>(false);
   const [error,setError] = useState<any>(null);
   const [periodOfRemu , setPeriodOfRemu ] = useState<any>('');
   const [requestLiquidation , setRequestLiquidation ] = useState<any>('');

   const [dataForm, setDataForm] = useState<any>({
      detail: '',
      from_date_request: '',
      to_date_request:''
   });

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   useEffect(()=> {
      if(open && liquidation){ verifyRequestLiquidation(); getPeriodOfRemu();  };
   },[open]);

   const getPeriodOfRemu = async() => {
      setLoading(true);
      const resp:any = await PeriodService.getPeriodsActually();
      const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
          newData.push({
              ...item,
              alias_name: item.month + '-' +  item.year,
          })
      });

      const found = newData.length > 0 ? newData.find(element => element.id == liquidation.idperiod) : '';
      setPeriodOfRemu (found || '');
      setLoading(false);
   }

   const handleInput = (e) => {
      setError("");
      const changedFormValues = {
         ...dataForm,
         [e.target.name]: e.target.value
      }
      changedFormValues.detail.trim() !== "" && setError("");
      changedFormValues.from_date_request.trim() !== "" && setError("");
      changedFormValues.to_date_request.trim() !== "" && setError("");
      setDataForm(changedFormValues);
   }

   const limpiarData = () => {
      setDataForm({
         detail: '',
         from_date_request: '',
         to_date_request:''
      })
   }

   const verifyRequestLiquidation =  async() => {
      const formData = {
         idremuneration: liquidation.id
      }
      await RequestService.verifyRequestLiquidation(formData).then((data)=>{
         if(data?.data?.status == true){
            setRequestLiquidation('');
         }else{
            setRequestLiquidation(data?.data?.request);
         }
      })
   }


   const onSubmit = async (e) =>{
      e.preventDefault();

      if(!dataForm.detail) return setError('detail');
      if(!dataForm.from_date_request) return setError('from_date_request');
      if(!dataForm.to_date_request) return setError('to_date_request');

      let startDate = dataForm.from_date_request ? moment(dataForm.from_date_request).format("MM-YYYY") : '';
      let endDate = dataForm.to_date_request ? moment(dataForm.to_date_request).format("MM-YYYY") : '';

      if(startDate && startDate != periodOfRemu.alias_name) return (
         setSnackBarConfig(prev => ({ ...prev, open: true, 
             severity:'warning', 
             message: ___PHRASE("laFechaInicialTieneQueEncontrarseDentroDelMesDeLaLiquidación")+` (${periodOfRemu.alias_name}).` }
         ))
      )
      if(endDate && endDate != periodOfRemu.alias_name) return (
         setSnackBarConfig(prev => ({ ...prev, open: true, 
             severity:'warning', 
             message: ___PHRASE("laFechaFinalTieneQueEncontrarseDentroDelMesDeLaLiquidación")+` (${periodOfRemu.alias_name}).` }
         ))
      )

      const dataRequest = {
         detail: dataForm.detail,
         from_date_request: dataForm.from_date_request,
         to_date_request: dataForm.to_date_request,
         idremuneration: liquidation.id
      }

      setLoading(true);

      const res = await RemunerationService.requestRemunerationError(dataRequest);
      if(res?.data?.status == true){
         setSnackBarConfig(prev => ({ ...prev, open: true, 
             severity:'success', 
             message: `${res?.data?.message || ___PHRASE("solicitudGeneradaConExito")}`}
         ))
         setTimeout(function(){
             closeModal && closeModal();
             limpiarData();
         }, 1000);
         setLoading(false);
      }else{
            setLoading(false);
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'error', 
               message: `${res?.data?.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
            ))
      }
      setLoading(false);
      
   }

   return(
      <>
      {loading && <Loading/>}
      <Snackbar
         open={snackBarConfig.open} 
         autoHideDuration={snackBarConfig.autoHideDuration} 
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
         <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
         </Alert>
      </Snackbar>

      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
         <ModalHeader
            text={___PHRASE("generarSolicitudDeRevision")}
            className='positionElements'
            onCancel={closeModal}
            clearState={limpiarData}
         >
         </ModalHeader>
         <ModalBody>
            <Grid container >
               {
                  (!requestLiquidation && !loading) && (
                     <>
                     <Grid container item xs={12} alignItems="flex-start" justifyContent="center" marginBottom='25px'>
                        <Grid item xs={4}>
                           <Typography variant="body1"><b>{___PHRASE("motivo")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                        <TextField
                        value={dataForm?.detail}
                        type='text'
                        multiline
                        maxRows={10}
                        placeholder={___PHRASE("especifiqueDetallamenteElIncovenienteConSuLiquidacion")}
                        fullWidth
                        onChange={handleInput}
                        name={'detail'}
                        error={ error == "detail" ? true : false }
                        />
                        </Grid>
                     </Grid>
                     <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                        <Grid item xs={4}>
                           <Typography variant="body1"><b>{___PHRASE("fechaInicio")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                        <TextField
                        value={dataForm?.from_date_request}
                        variant="standard"
                        type="date"
                        fullWidth
                        onChange={handleInput}
                        name={'from_date_request'}
                        error={ error == "from_date_request" ? true : false }
                        />
                        </Grid>
                     </Grid>
                     <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                        <Grid item xs={4}>
                           <Typography variant="body1"><b>{___PHRASE("fechaFin")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                        <TextField
                        value={dataForm?.to_date_request}
                        type="date"
                        variant="standard"
                        fullWidth
                        onChange={handleInput}
                        name={'to_date_request'}
                        error={ error == "to_date_request" ? true : false }
                        />
                        </Grid>
                     </Grid>
                     </>
                  )
               }
               {
                  (requestLiquidation && !loading) && (
                     <Grid container item xs={12} display='block' justifyContent="center">
                        <Typography variant="subtitle1" className="textColor">
                           {___PHRASE("yaHaRealizadoUnaSolicitudDeRevisionParaLaLiquidacionActual")}
                        </Typography>
                        <Typography variant="subtitle1" className="textColor">
                           { ___PHRASE("motivo")+`: ${requestLiquidation?.detail || '---'}` }
                        </Typography>
                     </Grid>
                  )
               }
               {
                  loading && (
                     <Grid container item xs={12} alignItems="flex-start" justifyContent="center">
                        <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', borderRadius: '10px', height: '120px', width:'100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        </Grid>
                     </Grid>
                  )
               }

            </Grid>
         </ModalBody>
         {
            !requestLiquidation ? (
               <ModalFooter 
                  buttonType="submit"
                  confirmText={___PHRASE("crearNueva")}
                  cancelText={___PHRASE("cancelar")}
                  onCancel={closeModal}
                  onConfirm={(e)=>{onSubmit(e)}}
                  clearState={limpiarData}
               />
            )  : (
               <ModalFooter 
                  buttonType="submit"
                  cancelText={___PHRASE("cancelar")}
                  onCancel={closeModal}
                  clearState={limpiarData}
               />
            )
         }
      </Modal>
      </>
   );
}