import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Alert, Autocomplete, Box, Button, Card, Checkbox, Container, Grid, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, styled } from "@mui/material"
import { userService } from "@/service/services/User.service"
import { Loading } from "@/components/common/Loading"
import { Props } from "./ViewsControl.type"
import { ViewsService } from "@/service/services/Views.service"
import { PermissionsService } from "@/service/services/Permissions.service"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import { ProfileService } from "@/service/services/Profile.service"
import { ProfileSessionService } from "@/service/services/ProfileSession.service"
import { ModalNewViewPermission } from "@/components/Modal/ModalNewViewPermission/ModalNewViewPermission"
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/constants/role-type'
import ___PHRASE from "@/service/translate/translateService"
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper"

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const ViewsControlView: React.FC<Props> = (props: any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState<any>('');
    const [views, setViews] = useState([]);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [showViwes, setShowViews] = useState<boolean>(false);
    const [viewsProfile, setViewsProfile] = useState<any>([])
    const [showModalAddView, setShowModalAddView] = useState<boolean>(false);
    
    const cabeceraView = [
      { id: 1, name: 'code', label: ___PHRASE("codigo") },
      { id: 2, name: 'name', label: ___PHRASE("nombre") },
      { id: 3, name: 'module', label: ___PHRASE("modulo") },
      { id: 4, name: 'route', label: ___PHRASE("ruta") },
    ];

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const userSession = dataUser?.user;
    const role = dataUser?.user?.role;

    const cabecera = [
        { id: 1, name: 'code', label: ___PHRASE("codigo") },
        { id: 2, name: 'name', label: ___PHRASE("nombre") },
        { id: 3, name: 'module', label: ___PHRASE("modulo") },
        { id: 4, name: 'route', label: ___PHRASE("ruta") },
    ];

    useEffect(() => {
        getUsers();
    }, []);

    const filterUSers = (users) => {
        let newArray = [];
        let arrayTemporal = [];
  
        for(let i=0; i<users.length; i++){
           arrayTemporal = newArray.filter(resp => resp["iduser"] == users[i]["iduser"]);
  
           if(arrayTemporal.length > 0){
              newArray[newArray.indexOf(arrayTemporal[0])]["roles"].push(users[i]["role"])
              newArray[newArray.indexOf(arrayTemporal[0])]["idroles"].push(users[i]["idrole"])
           }else{
              newArray.push(
              {
                 'id' : i+1,
                 'email' : users[i]["email"] , 
                 'employee' : users[i]["employee"] , 
                 'idemployee' : users[i]["idemployee"] , 
                 'idprofile' : users[i]["idprofile"] , 
                 'iduser' : users[i]["iduser"] , 
                 'n_document' : users[i]["n_document"] , 
                 'profile' : users[i]["profile"] , 
                 'user_name' : users[i]["user_name"] , 
                 'roles' : [users[i]["role"]],
                 'idroles' : [users[i]["idrole"]],
                 'assign_ip': users[i]['assign_ip'],
              }
              )
           }
        }
  
        return newArray || [];
    }

    const getUsers = async () => {
        setLoading(true);
        
        if(role == ROLE_SUPER_ADMIN){
            const response = await userService.getUsers();
  
            if (response.data) {
                let newUsers = [];
    
                const users = response.data;
                const newArray = filterUSers(users)
    
                newArray.length > 0 && newArray.map((item)=> {
                    const validate = (item.roles).find(role => role == ROLE_ADMIN)
                    if(validate) newUsers.push({...item, 'roles': (item.roles).toString()});
                })
    
                setUsers(newUsers || [])
    
            } else {
                console.log('error')
            }
        }else{
            const response = await userService.getUserByCompany();
    
            if (response.data) {
                let newUsers = [];
    
                const users = response.data;
                const newArray = filterUSers(users)
                
                newArray.length > 0 && newArray.map((item)=> {
                    const validate = (item.roles).find(role => role == ROLE_ADMIN || role == ROLE_TRABAJADOR)
                    if(validate) newUsers.push({...item, 'roles': (item.roles).toString()});
                })

                if (newUsers.length > 0) {
                    newUsers = newUsers.filter(user => user.iduser != userSession.iduser);
                }
    
                setUsers(newUsers || [])
            } else {
                console.log('error')
            }
        }
        
        setLoading(false);
     }

    const structureDataAction = (arrayData) => {
        return arrayData.map((item) => {return {...item, active: item.status_permission == '1'? true:false}})
    }

    const getViews = async () => {
        setShowViews(false);
        setViews([]);

        if (!userSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSeleccionarUnUsuario")
                }
                ))
            );
        }
        setLoading(true);

        const idrole = userSelected.idroles;
        const iduser = userSelected.iduser;

        await ProfileSessionService.getProfileByUser(iduser).then(async (data) => {
            if (data.data) {
                const resp = await ViewsService.getViewsByProfile(data.data.idprofile);
                if (resp.data) {
                    setViewsProfile(resp.data)
                }
            }
        });

        await ViewsService.getAdditionalViews(iduser).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            if (data.data.status == false) {
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'error',
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                    }
                    ))
                );
            } else {
                if(data.data){
                    setViews(structureDataAction(data.data) )
                    setShowViews(true);
                }
            }
            setLoading(false);
        });
    }

    const onsubmit = async () => {
        setLoading(true);
        const viewsfilter = views.filter(view => (view.active == true));
        const filter = viewsfilter.map((view) => {
            return view.id
        })

        const formData = {
            idviews: JSON.stringify(filter),
            iduser: userSelected.iduser,
        }

        await PermissionsService.createPermission(formData).then((data) => {
            if (data.data.status == true) {
                getViews();
            }
            setLoading(false);
        });
    }



    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };



    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Grid container spacing={3} sx={{ marginTop: '10px', marginBottom: '20px', alignItems: 'center'}}>
                    <Grid item spacing={3} xs={12} md={12} display='flex' sx={{ alignItems: 'center' }}>
                        <Autocomplete
                            id="disable-portal"
                            disablePortal
                            value={userSelected || []}
                            options={users || []}
                            getOptionLabel={(option) => (option.employee ? option.employee +' - '+ option.user_name : option.user_name) || ""}
                            renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribaParaBuscarUnUsuario")} />}
                            onChange={(e, newValue) => { setUserSelected(newValue) }}
                            sx={{ width: '450px', marginRight: '20px' }}
                        />

                        <Button
                            sx={{ marginTop: '20px' }}
                            onClick={getViews}
                        >
                            {___PHRASE("verVistas")}
                        </Button>
                    </Grid>
                </Grid>

                {
                    showViwes && !loading && (
                        <>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={___PHRASE("vistasPorPerfil")} {...a11yProps(0)} />
                                    <Tab label={___PHRASE("vistasAdicionales")} {...a11yProps(1)} />
                                </Tabs>
                            </Box>

                            <TabPanel value={value} index={0}>
                                <TableDataV2
                                    data={viewsProfile}
                                    header={cabeceraView}
                                    disabled_title
                                    btn_disabled
                                    />
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', m: -1, py: 3, px: 3 }}>
                                    <Box sx={{ m: 1 }}>
                                        {
                                            !props?.btn_disabled && (
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => { setShowModalAddView(true) }}
                                                >
                                                    {___PHRASE("agregarVistaAdicional")}
                                                </Button>
                                            )
                                        }
                                    </Box>
                                </Box>
                                <TableDataV2
                                    data={views || []}
                                    setData={setViews}
                                    header={cabecera}
                                    actionSelect={setActionSelected}
                                    status_action={false}
                                    checkbox
                                    btn_disabled={true}
                                    disabled_title={true}
                                />
                                <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', m: -1, py: 3, px: 3 }}>
                                    <Button variant='contained' onClick={() => {onsubmit()}}>{___PHRASE("guardarCambios")}</Button>
                                </Box>
                            </TabPanel>
                        </>
                    )
                }
                {
                    !showViwes && (
                        <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                        sx={{marginTop:'50px'}} >
                            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%'}}>
                                <Div>{loading ? ___PHRASE("cargandoDatosEspereUnMomento") : ___PHRASE("seleccioneUnUsuarioParaVerSusVistasDisponibles")}</Div>
                            </Box>
                        </Grid>
                    )
                }
                {
                    showModalAddView &&
                    <ModalNewViewPermission
                       closeModal={()=>setShowModalAddView(false)}
                       open={showModalAddView}
                       onListViewAdiccional={getViews}
                       dataValidProfile={viewsProfile}
                       dataValidView={views}
                       user={userSelected}
                       idUser={userSelected.iduser}
                    />
                }
            </Container>
        </Protected>
    )
}