import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalCompanySettings.type";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { companiesService } from "@/service/services/Companies.service";
import { userService } from "@/service/services/User.service";
import { ROLE_ADMIN } from "@/toolbox/constants/role-type";
import { IntegrationDiviceService } from "@/service/services/IntegrationDiviceService";
import { branchOfficeService } from "@/service/services/BranchOffice.service";
import { TableDataV2 } from "@/components/common/Tablev2";
import { ModalDivice } from "../ModalDivice/ModalDivice";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalCompanySettings: React.FC<Props> = (props: Props): JSX.Element => {

    const { open, actionSelected, closeModal, company, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [checkedAssistences, setCheckedAssistences] = useState<boolean>(false);
    const [showDivices, setShowDivices] = useState<boolean>(false);
    const [showAddDivice, setShowAddDivice] = useState<boolean>(false);
    const [integrations, setIntegrations] = useState<any>([]);
    const [divices, setDivices] = useState<any>([]);
    const [users, setUsers]= useState([]);
    const [branchOffices, setBranchOffices] = useState<any>([])
    const [branchOfficesSelected, setBranchOfficesSelected] = useState<any>(null);

    const [showModalDivice, setShowModalDivice] = useState<boolean>(false);
    const [DiviceSelected, setdiviceSelected] = useState<any>('');
    
    const [dataDivice, setDataDivice] = useState({
        userBD: '',
        password: '',
        serie: '',
        model: '',
        nameBD: '',
        area: ''
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        idtypesignature: null,
        img: ''
    });

    useEffect(() => {
        getIntegrations();
    }, []);

    useEffect(() => {
        if(checkedAssistences) getUsers();
    }, [checkedAssistences]);

    useEffect(()=>{
        if(showAddDivice) getBranchOffice();
    },[showAddDivice])

    const getBranchOffice = async () => {
        const resp = await branchOfficeService.getBranchOfficesByCompany(company.id);
        setBranchOffices(resp.data);
    }

    const getIntegrations = async () => {
        setLoading(true);
        await companiesService.getIntegrations(company.id).then((data) => {
            (data.data).length > 0 && (data.data).map((item)=>{
                if(item.id == 2) {
                    setCheckedAssistences(item.statusDetail == '1' ? true : false)
                }
            })
            setIntegrations(data.data || [])
           setLoading(false);
        });
    }

    const filterUSers = (users) => {
        let newArray = [];
        let arrayTemporal = [];
  
        for(let i=0; i<users.length; i++){
           arrayTemporal = newArray.filter(resp => resp["iduser"] == users[i]["iduser"]);
  
           if(arrayTemporal.length > 0){
              newArray[newArray.indexOf(arrayTemporal[0])]["roles"].push(users[i]["role"])
           }else{
              newArray.push(
              {
                 'id' : i+1,
                 'email' : users[i]["email"] , 
                 'employee' : users[i]["employee"] , 
                 'idemployee' : users[i]["idemployee"] , 
                 'idprofile' : users[i]["idprofile"] , 
                 'iduser' : users[i]["iduser"] , 
                 'n_document' : users[i]["n_document"] , 
                 'profile' : users[i]["profile"] , 
                 'user_name' : users[i]["user_name"] , 
                 'roles' : [users[i]["role"]]
              }
              )
           }
        }
  
        return newArray || [];
    }

    const getDivicesByCompany = async() => {
        setLoading(true);
        await IntegrationDiviceService.getDivicesByCompany(company.id).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            if(data.data.status == false){
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'error',
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                    }))
                );
            }else{
                setDivices(data.data || []);
                setLoading(false);
            }
            
        })
    }

    const getUsers = async () => {
        setLoading(true);
        const response = await userService.getUserByCompany(company.id || '');

        if (response.data) {
            let newUsers = [];

            const users = response.data;
            const newArray = filterUSers(users)

            newArray.length > 0 && newArray.map((item)=> {
                const validate = (item.roles).find(role => role == ROLE_ADMIN)
                if(validate) newUsers.push({...item, 'roles': (item.roles).toString()});
            })

            setUsers(newUsers || [])

        } else {
            console.log('error')
        }

        setLoading(false);
     }

    const createDivice = async () => {

        if(dataDivice.serie == '' || dataDivice.model == '' || dataDivice.userBD == '' || dataDivice.nameBD == '' || dataDivice.password == ''){
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeLlenarTodosLosCamposParaLaIntegracionDelControlAsistencia")
                }))
            );
        }
            
        setLoading(true);

        const formData = {
            ...dataDivice, idbranch_office : branchOfficesSelected.id
        }

        await IntegrationDiviceService.createDivice(formData).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            if(data?.data?.status == true){
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'success', 
                    message: `${apimessagge}`}
                ))
                setShowAddDivice(false);
                setShowDivices(true);
                getDivicesByCompany();

                setTimeout(function(){
                    closeModal && closeModal();
                }, 1000);
            }else{
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'error', 
                    message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                ))
            }
            
        });
        setLoading(false);
    }

    const handleInput = (e) => {
        const changedFormValues = {
            ...dataDivice,
            [e.target.name]: e.target.value
        }
        setDataDivice(changedFormValues);
    }

    const assignIntegration = async (idintegration) => {
        setLoading(true);
        let formData:any = {
            'idintegration': idintegration,
        }

        await companiesService.assignIntegration(company.id, formData).then((data) => {
            getIntegrations();
            setShowAddDivice(true);
        });

        setLoading(false);
    }

    function RecuperarData(value){
        setdiviceSelected(value)
    }

    return (
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="md">
            <ModalHeader
                text={___PHRASE("configuracionDeEmpresa")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                    <Grid container >
                        <Grid>
                            <Typography variant='h6' className="tittle" sx={{marginBottom: '20px'}}>
                                {___PHRASE("estadoDeLasIntegraciones")}
                            </Typography>
                        </Grid>
                        {
                            integrations.length > 0 && integrations.map((item, key)=>(
                                <>
                                <Grid key={key} item xs={12} container alignItems="center" justifyContent="start" marginBottom='20px'>
                                    <Grid item xs={5}>
                                    <Typography variant="subtitle1" className="custom-input"><b>{item.name || '---' }</b></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControlLabel control={
                                            <Switch 
                                                checked={item.id != '2' ? (item.statusDetail == 1 ? true : false) : (checkedAssistences)} 
                                                onChange={()=>{assignIntegration(item.id) }}
                                            />} 
                                        label={item.statusDetail == 1 ? ___PHRASE('activo') : ___PHRASE('desactivado')} />
                                    </Grid>
                                    {
                                        item.id == '2' && checkedAssistences && (
                                            <Grid item xs={5} alignItems="center" justifyContent="end" >
                                                <Button onClick={()=>{getDivicesByCompany(); setShowDivices(true); setShowAddDivice(false)}} size="small" sx={{marginRight: '10px', marginLeft: '10px'}} >
                                                    {___PHRASE("verDispositivos")}
                                                </Button>
                                                <Button onClick={()=>{setShowAddDivice(true); setShowDivices(false)}} size="small" >
                                                    {___PHRASE("agregarDispositivo")}
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                {
                                    item.id == '2' && item.statusDetail == '1' && showAddDivice && (
                                        <Grid container sx={{display: 'flex', justifyContent: 'space-between', border: '1px solid #c7c7c7', borderRadius: '20px', padding: '20px', marginBottom: '20px'}}>
                                           
                                           <Grid item xs={5} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Autocomplete
                                                    disablePortal
                                                    fullWidth
                                                    id="combo-box-demo"
                                                    options={branchOffices}
                                                    onChange={(e,value:any)=> {setBranchOfficesSelected(value) }}
                                                    getOptionLabel={(option: any) => option.address ? option.address : ''}
                                                    // sx={{ bgcolor: '#F3F3F3'}}
                                                    value={branchOfficesSelected}
                                                    renderInput={(params) => <TextField {...params}
                                                    label={___PHRASE("sucursal")}
                                                    variant="standard"
                                                    // error={error=='idbusinessarea'}
                                                    // helperText={error=='idbusinessarea' && 'Campo Obligatorio'}
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={5} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <TextField
                                                    id="area"
                                                    variant="standard"
                                                    type="text" 
                                                    name="area"
                                                    fullWidth
                                                    value={dataDivice.area}
                                                    onChange={handleInput}
                                                    label={___PHRASE("areaDelDispositivo")}
                                                />
                                            </Grid>
                                           <Grid item xs={5} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <TextField
                                                    id="userBD"
                                                    variant="standard"
                                                    type="text" 
                                                    name="userBD"
                                                    fullWidth
                                                    value={dataDivice.userBD}
                                                    onChange={handleInput}
                                                    label={___PHRASE("usuarioDeBaseDeDatos")}
                                                />
                                            </Grid>
                                            <Grid item xs={5} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <TextField
                                                    id="password"
                                                    variant="standard"  
                                                    type="text" 
                                                    name="password"
                                                    fullWidth
                                                    value={dataDivice.password}
                                                    onChange={handleInput}
                                                    label={___PHRASE("contrasena")}
                                                />
                                            </Grid>
                                            <Grid item xs={5} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <TextField
                                                    id="nameBD"
                                                    variant="standard"
                                                    type="text" 
                                                    name="nameBD"
                                                    fullWidth
                                                    value={dataDivice.nameBD}
                                                    onChange={handleInput}
                                                    label={___PHRASE("nombreDeLaBaseDeDatos")}
                                                />
                                            </Grid>
                                            <Grid item xs={5} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <TextField
                                                    id="serie"
                                                    variant="standard"
                                                    type="text" 
                                                    name="serie"
                                                    fullWidth
                                                    value={dataDivice.serie}
                                                    onChange={handleInput}
                                                    label={___PHRASE("serieDelDispositivo")}
                                                />
                                            </Grid>
                                            <Grid item xs={5} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <TextField
                                                    id="model"
                                                    variant="standard"
                                                    type="text" 
                                                    name="model"
                                                    fullWidth
                                                    value={dataDivice.model}
                                                    onChange={handleInput}
                                                    label={___PHRASE("modeloDelDispositivo")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Button onClick={()=>{createDivice()}}>{___PHRASE("agregar")}</Button>
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                {
                                    item.id == '2' && showDivices && divices.length > 0 && (
                                        <Grid style={{marginBottom: '20px'}}>
                                        <TableDataV2 
                                            data={divices || []}
                                            header={[
                                                { name: 'address', label: ___PHRASE("sucursal"), filter: false, Chip: false },
                                                { name: 'serie', label: ___PHRASE("serie"), filter: false, Chip: false },
                                                { name: 'model', label: ___PHRASE("modelo"), filter: false, Chip: false },
                                                { name: 'userBD', label: ___PHRASE("usuario"), filter: false, Chip: true },
                                                { name: 'nameBD', label: ___PHRASE("nombre"), filter: false, Chip: true },
                                            ]}
                                            status_action
                                            btn_delete_disable={true}
                                            btn_disabled={true}
                                            disabled_title={true}
                                            setModalSave={setShowModalDivice}
                                            RecuperarData={RecuperarData}
                                        />
                                        </Grid>
                                    )
                                }
                                
                                {/* {
                                    item.id == '2' && showDivices && divices.length > 0 && divices.map((divice, key)=>(
                                        <Grid key={key} container sx={{display: 'flex', justifyContent: 'space-between', border: '1px solid #c7c7c7', borderRadius: '20px', padding: '20px', marginBottom: '20px'}}>

                                            <Grid item xs={6} container alignItems="center" justifyContent="start" marginBottom='25px'>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle1" className="custom-input" ><b>Sucursal:</b></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{divice.address}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6} container alignItems="center" justifyContent="start" marginBottom='25px'>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Serie del Dispositivo:</b></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{divice.serie}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Modelo del Dispositivo:</b></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{divice.model}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='5px'>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Usuario de Base de Datos:</b></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{divice.userBD}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='5px'>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Nombre de Base de Datos:</b></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{divice.nameBD || '---'}</Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    ))
                                } */}
                                </>
                            ))
                        }
                        
                    </Grid>
            </ModalBody>
        </Modal>

        {
            showModalDivice && (
                <ModalDivice
                    open={showModalDivice}
                    closeModal={()=>{setShowModalDivice(false)}}
                    actionSelected={actionSelected}
                    divice={DiviceSelected}
                    company={company}
                    getDivicesByCompany={getDivicesByCompany}
                    clearState={()=>{setdiviceSelected('')}}
                />
            )
        }
        </>
    );
}