import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Button, Divider, Grid, MenuItem, Select, TextField, Typography, Snackbar, Alert } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { Props } from "./ModalProfileAssignment.type";
import { userService } from "@/service/services/User.service"
import { ProfilePermissionsService } from "@/service/services/ProfilePermissions.service"
import ___PHRASE from "@/service/translate/translateService";

export const ModalProfileAssignment: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, profile, onListPermissions, clearState} = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState<any>('');
    
    const [data, setData] = useState<any>({
        idprofile: profile ? profile.id : '',
        profile: profile ? profile.name : '',
        idusers: '',
    });

    console.log(data)
    console.log(profile)

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const userSession = dataUser?.user;

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    useEffect(() => {
        getUsers();
    },[]);

    const getUsers = async () => {
        setLoading(true);
        await userService.getUsers().then((data) => {
            const users = data.data || [];
            if (users.length > 0) {
                if (userSession?.idrole == 1) {
                    const respFilter = users.filter(user => user.idrole == 2 && user.iduser != userSession.iduser);
                    setUsers(respFilter || []);
                }
                if (userSession?.idrole == 2) {
                    const respFilter = users.filter(user => user.idrole != 1 && user.iduser != userSession.iduser);
                    setUsers(respFilter || []);
                }
            }
            setLoading(false);
        });
    }

    const validateForm = (values) => {
        let errors: any = {};
        // if(!values.value) errors.value = "valor requerido";
        return errors;
    }
    

    const onSubmit = async(values, {resetForm})  => {

        let arrayIdUsers= [];
        values.idusers && values.idusers.map((item) => {
            arrayIdUsers.push(item.id);
        });

        const formData = {
            idprofile: values.idprofile,
            idusers: JSON.stringify(arrayIdUsers),
        }
        
        await ProfilePermissionsService.createPermissionProfile(formData).then((data) => {
            onListPermissions && onListPermissions(profile.id);
            closeModal && closeModal();
        })
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={___PHRASE("asignarUsuariosAlPerfil")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                    <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("perfil")}:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                    <TextField
                                        id="name"
                                        variant="standard"
                                        type="text" 
                                        name="name"
                                        fullWidth
                                        value={data.profile}
                                        disabled={true}
                                    />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("usuarios")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Autocomplete
                                            id="disable-portal"
                                            disablePortal
                                            multiple
                                            value={values.idusers || []}
                                            options={users || []}
                                            fullWidth
                                            getOptionLabel={(option) => option.user_name || ""}
                                            renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                            onChange={(e, newValue) => { setFieldValue("idusers", newValue);}}
                                        />

                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={___PHRASE("guardar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}