import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const ProfilePermissionsRepository = {

    getPermissionsProfile: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/permissionProfile`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getPermissionsByIdProfile: async(idProfile): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/permissionProfile/getByIdProfile/${idProfile}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createPermissionProfile: async (dataPermission) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/permissionProfile`, {
            idprofile: dataPermission.idprofile,
            idusers: dataPermission.idusers,
            idrole: dataPermission.idrole,
            idCompany: dataPermission?.idCompany ? dataPermission?.idCompany : null
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editPermissionProfile: async (id, dataPermission) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/permissionProfile/${id}`, {
            idprofile: dataPermission.idprofile,
            idusers: dataPermission.idusers,
            idCompany: dataPermission?.idCompany ? dataPermission?.idCompany : null
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editPermissionProfileForAdmin: async (id, dataPermission) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/permissionProfile/ForAdmin/${id}`, {
            idprofile: dataPermission.idprofile,
            iduser: dataPermission.iduser,
            idEmployee: dataPermission?.idEmployee ,
            role: dataPermission.role
         });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deletePermissionProfile: async(id): Promise<any> => {
        const res = await http.delete<any>(`${API_URL_BASE}/v1/permissionProfile/${id}`)
        const {data, error, message} = res;
        return { data, error, message };
    }
}   