import React, { useState, useMemo, useRef } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, TextField, InputAdornment, Typography } from '@mui/material';
import { Props } from './FormBankInformation.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import "./FormBankInformation_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ___PHRASE from '@/service/translate/translateService';
import './style.sass'

export const FormBankInformation: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueBankInformation, setValueBankInformation] = useState<any>(props.valueBankInformation)
   const [error, setError] = useState<any>('');

   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'account_number':
            props.setError('');
            setValueBankInformation(prev => ({ ...prev, account_number: value }));
            break;
         case 'last_imponible':
               props.setError('');
               setValueBankInformation(prev => ({ ...prev, last_imponible: value }));
               break;
         default:
            break;
      }
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      const resp =  props.createWorkers(valueBankInformation);
      
      try{
         if(resp){
            console.log('sin errores')
         }
      }catch(exeption){
         console.log("Error de formulario: ",exeption)
      }
      
   }

   return (
      <Container maxWidth="xl">
         <Grid container >

            <Typography>INFORMACIÓN BANCARIA</Typography>
            <Grid item container xs={12} alignItems="center" justifyContent="space-between"
                  sx={{border: '1px #b9b1a9 solid', marginTop: '10px', marginBottom: '15px', padding:'15px'}} > 
               <Grid item xs={12} sm={3} paddingRight={2}>
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.Bank}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idbank: value?value.id:null}); props.setError('')}}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idbank?(
                                 props.dataInitial.Bank.filter((value)=>{return value.id == valueBankInformation?.idbank})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label={___PHRASE("banco")}
                        error={props.error=='idbank'} 
                        helperText={props.error=='idbank' && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>
               <Grid item xs={12} sm={3} paddingRight={2}>
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.WayPay}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idwaypay: value?value.id: null}); props.setError('')}}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idwaypay?(
                                 props.dataInitial.WayPay.filter((value)=>{return value.id == valueBankInformation?.idwaypay})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label={___PHRASE("formaDePago")}
                        error={props.error=='idwaypay'} 
                        helperText={props.error=='idwaypay' && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>
               <Grid item xs={12} sm={3} paddingRight={2}>
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.AccountType}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idaccounttype:value? value.id:null}); props.setError('')}}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idaccounttype?(
                                 props.dataInitial.AccountType.filter((value)=>{return value.id == valueBankInformation?.idaccounttype})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label={___PHRASE("tipoDeCuenta")}
                        error={props.error=='idaccounttype'} 
                        helperText={props.error=='idaccounttype' && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>                 
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="account_number"
                     type='text'
                     placeholder={___PHRASE("numero")}
                     value={valueBankInformation.account_number}
                     onChange={handleInput}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     error={props.error=='account_number'}
                     errorMessages={props.error=='account_number' && ___PHRASE("campoObligatorio")}
                     height='38px'
                  />
               </Grid>
            </Grid>

            <Typography>CAJA DE COMPENSACIÓN</Typography>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                  sx={{border: '1px #b9b1a9 solid', marginTop: '10px', padding:'15px' , marginBottom: '15px'}} >
               <Grid item xs={12} sm={3} >
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.Compensationbox}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idcompensationbox: value?value.id:null}); props.setError('')}}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idcompensationbox?(
                                 props.dataInitial.Compensationbox.filter((value)=>{return value.id == valueBankInformation?.idcompensationbox})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label={___PHRASE("cajaDeCompensacion")}
                        error={props.error=='idcompensationbox'} 
                        helperText={props.error=='idcompensationbox' && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>
            </Grid>

            <Typography>ÚLTIMO IMPONIBLE</Typography>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                  sx={{border: '1px #b9b1a9 solid', marginTop: '10px', padding:'15px', marginBottom: '15px'}} >
               <Grid item xs={12} sm={3} >
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="last_imponible"
                     type='number'
                     placeholder={"Último Imponible"}
                     value={valueBankInformation.last_imponible}
                     onChange={handleInput}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     error={props.error=='last_imponible'}
                     errorMessages={props.error=='last_imponible' && ___PHRASE("campoObligatorio")}
                     height='38px'
                  />
               </Grid>
            </Grid>

            <Grid item container justifyContent='space-between' >
                  <Grid item xs={6}>
                     <Button
                        variant='contained'
                        onClick={() => { props.setValueBankInformation(valueBankInformation); props.handleBack(); }}
                        disabled={loading}
                        disableElevation
                        className='btn_back'
                        startIcon={<ArrowBackIosIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("atras")}</span>
                        }
                     </Button>
                  </Grid>
               <Grid item xs={6} container justifyContent='right'>
               <Button
                     type='submit'
                     variant='contained'
                     onClick={handleSubmit}
                     disabled={loading}
                     disableElevation
                  >
                     {
                        loading ?
                           <CircularProgress className="CircularProgress" size={24} /> :
                           props.isEdit? <span>{___PHRASE("actualizar")}</span> : <span>{___PHRASE("guardar")}</span>
                     }
               </Button>
               </Grid>
            </Grid>
         </Grid>
      </Container>
   );
}
