import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalCountry.type";
import { CountryService } from "@/service/services/Country.service";
import ___PHRASE from "@/service/translate/translateService";

const cleanPhase = {
    id: "",
    name: "",
    description: "",
    idCompany: "",
};

export const ModalCountry: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, country, onListCountries, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [timeZone, setTimeZone] = useState<any>([]);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: country ? country.id : '',
        code: country ? country.code : '',
        abbreviation: country ? country.abbreviation : '',
        name: country ? country.name : '',
        nationality: country ? country.nationality : '',
        language: country ? country.language : '',
        coin: country ? country.coin : '',
        coin_code: country ? country.coin_code : '',
        idtimezone: country ? country.idtimezone : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.code) errors.code = "codigo requerido";
        if(!values.abbreviation) errors.abbreviation = "abbreviation requerido";
        if(!values.name) errors.name = "nombre requerido";
        if(!values.nationality) errors.nationality = "nationality requerido";
        if(!values.language) errors.language = "language requerido";
        if(!values.coin) errors.coin = "coin requerido";
        if(!values.coin_code) errors.coin_code = "coin_code requerido";
        if(!values.idtimezone) errors.idtimezone = "zona horaria requerido";
        return errors;
    }

    useEffect(() => {
        getTimeZone();
    }, []);

    const getTimeZone = async () => {
        setLoading(true);
        await CountryService.getTimeZone().then((data) => {
            setTimeZone(data.data || []);
           setLoading(false);
        });
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            code: values.code,
            abbreviation: values.abbreviation,
            name: values.name,
            nationality : values.nationality,
            language : values.language,
            coin : values.coin,
            coin_code : values.coin_code,
            idtimezone : values.idtimezone,
        }
        setLoading(true);
        if(country && actionSelected=='edit'){
            await CountryService.editCountry(formData, country.id).then((data) => {
                onListCountries && onListCountries();
                closeModal && closeModal();
            })
        }else{
            await CountryService.createCountry(formData).then((data) => {
                if(data.data.status == false){
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({
                            ...prev, open: true,
                            severity: 'warning',
                            message: `${data.data.message || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                        }))
                    );
                }
                onListCountries && onListCountries();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((country && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("pais")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("codigo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="code"
                                            variant="standard"
                                            type="text" 
                                            name="code"
                                            fullWidth
                                            value={values.code}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("codigo")}
                                            error={errors.code && touched.code ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("abreviacion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="abbreviation"
                                            variant="standard"
                                            type="text" 
                                            name="abbreviation"
                                            fullWidth
                                            value={values.abbreviation}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("abreviacion")}
                                            error={errors.abbreviation && touched.abbreviation ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombre")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nacionalidad")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="nationality"
                                            variant="standard"
                                            type="text" 
                                            name="nationality"
                                            fullWidth
                                            value={values.nationality}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nacionalidad")}
                                            error={errors.nationality && touched.nationality ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("idioma")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="language"
                                            variant="standard"
                                            type="text" 
                                            name="language"
                                            fullWidth
                                            value={values.language}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("idioma")}
                                            error={errors.language && touched.language ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("moneda")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="coin"
                                            variant="standard"
                                            type="text" 
                                            name="coin"
                                            fullWidth
                                            value={values.coin}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("moneda")}
                                            error={errors.coin && touched.coin ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("codigoDeMoneda")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="coin_code"
                                            variant="standard"
                                            type="text" 
                                            name="coin_code"
                                            fullWidth
                                            value={values.coin_code}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("codigoDeMoneda")}
                                            error={errors.coin_code && touched.coin_code ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("zonaHoraria")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idtimezone"
                                                variant="standard"
                                                value={values.idtimezone}
                                                onChange={(event) => { 
                                                setFieldValue("idtimezone", event.target.value);
                                                }}
                                                error={ errors.idtimezone && touched.idtimezone ? true : false }
                                                fullWidth
                                            >
                                                {
                                                    timeZone && timeZone.map((item, i) => {
                                                        return <MenuItem key={i} value={item.id}>{item.time_zone}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(country && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    clearState={clearState}
                                    onConfirm={handleSubmit}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}