import { Props } from "./ModalFeeLoan.type"
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Loading } from "@/components/common/Loading";
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ModalFooter } from "../ModalFooter";
import { LoanService } from "@/service/services/Loan.service";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { PeriodService } from "@/service/services/Period.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalFeeLoan: React.FC<Props> = (props: any): JSX.Element => {

    const { open, closeModal, onList, workerSelected, loanSelected, setSnackbar, snackbar } = props;
    const [yearSelected, setYearSelected] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [messageMax, setMessageMax] = useState<any>('');
    const [totalMax, setTotalMax] = useState<any>(0)
    const [data, setData] = useState({
        year_initial: '',
        month_initial: '',
    })
    const [monthSelected, setMonthSelected] = useState<any>('');
    const [periods, setPeriods] = useState<any>([]);

    const [years, setYears] = useState<any>([]);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

     const validateForm = (values) => {
        let errors: any = {};
        if(!values.year_initial) errors.year_initial = "requerido";
        if(!values.month_initial) errors.month_initial = "requerido";
        return errors;
    }

    const onSubmit = async () => {
        console.log(data)
        if(!yearSelected){return setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: ___PHRASE("debeSeleccionarUnPerido") })}
        if(!monthSelected){return setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: ___PHRASE("debeSeleccionarUnMes") })}
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // los meses en JS empiezan en 0 (enero) y van hasta 11 (diciembre), por lo que debes sumar 1 al mes actual
        if (parseInt(yearSelected) < currentYear || (parseInt(yearSelected) === currentYear && parseInt(monthSelected) < currentMonth)) {
            return setSnackBarConfig({ ...snackBarConfig, open: true, severity: 'error', message: ___PHRASE("debeSeleccionarUnAnoyMesMayoresoIgualesAlActual") });
        }
        let formData:any = rows.map((row)=>{return {...row,idLoan: loanSelected?.id,  year_initial: yearSelected, month_initial: monthSelected}})
        console.log(formData)
        let validationMax = formData.find((item)=> item.total > totalMax);
        let validationNotZero =  formData.find((item)=> item.total ==  0);
        let validationNotZeroQuota = formData.find((item)=> item.n_fee ==  0);
        if(validationMax){
            setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message: ___PHRASE("revisaTusCuotasHasSuperadoElMontoMaximoPorLey") })
        }else if(validationNotZero || validationNotZeroQuota){
            setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message: ___PHRASE("revisaTusCuotasHayMontosIgualaCero") })
        }
        else{
            setLoading(true)
            // let formDataJson = JSON.stringify(formData)
            await LoanService.createLoanOptional(formData).then((data) => {
                onList && onList(0, workerSelected.id)
                closeModal && closeModal();
            });
    
            setLoading(false);
        }
       
    }

    const [rows, setRows] = useState([ { n_fee: 0, total: 0 }]);

    const handleChangeFechas = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }
    const handleAddRow = () => {
        setRows([...rows, { n_fee: 0, total: 0 }]);
    };

    const handleUpdateRow = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    const getPeriodsActually = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriodsActually();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' +  item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if(!found) { arrayYears.push(item.year); }
        });
        console.log(arrayYears)
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    useEffect(()=>{
        if(open){
            getDetailFeeLoan()
            getPeriodsActually();
        }
    },[open])

    useEffect(()=>{
        getMaxForLey();
    },[years])

    const getDetailFeeLoan = async() => {
        setLoading(true)
        await LoanService.getOptionalFeeLoan(loanSelected?.id).then((data) => {
            if(data.data.length > 0 ){
                setLoading(false)
                setSnackbar({...snackbar, open:true, severity: 'info', message: ___PHRASE("yaSeDisponibilizoLasPosiblesCuotas")})
                closeModal();
            }else{
                setLoading(false)
            }       
        });
    }

    const getMaxForLey = async() => {
        console.log('hola')
        setLoading(true)
        await LoanService.limitForLey(workerSelected.id).then((data)=>{
            if(data?.data?.status == false){
                setMessageMax(data.data.message)
                    setLoading(false)             
            }else{
                setTotalMax(data.data.data)
                setMessageMax(`${data.data.message} $${data.data.data} `+___PHRASE("porCuotaSegunLeyDeProteccionAlTrabajador"))
                
                 setLoading(false)
            }
        })

    }
    console.log(years)
    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Modal open={open} handleClose={closeModal} handle size="sm">
                <ModalHeader
                    text={___PHRASE("disponibilizarPosiblesFormasDePago")}
                    className='positionElements'
                    onCancel={closeModal}
                >
                </ModalHeader>
                <ModalBody>
                    <div>

                        <Grid container >

                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                <Grid item xs={10} sx={{background:'gray', paddingX:5, borderRadius:5, mb:2}}>
                                    <Typography variant="subtitle1" className="custom-input"><b>{messageMax}</b></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("periodoDeInicioDePago")}:</b></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <TextField
                                        id="year_initial"
                                        variant="standard"
                                        fullWidth
                                        name="year_initial"
                                        type="text"
                                        onChange={handleChangeFechas}
                                        value={data.year_initial}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // error={errors.year_initial && touched.year_initial ? true : false}
                                    /> */}
                                    <Select
                                        id="year_initial"
                                        variant="standard"
                                        value={yearSelected}
                                        onChange={(event) => {
                                            setYearSelected(event.target.value);
                                        }}
                                        fullWidth
                                        // inputProps={{ readOnly: true }}
                                    >
                                        {
                                            years.map((year, i) => {
                                                console.log(year)
                                                return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("mesDeInicioDePago")}:</b></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        id="id_education_level"
                                        variant="standard"
                                        value={monthSelected}
                                        onChange={(event) => { setMonthSelected(event.target.value) }}
                                        fullWidth
                                        // inputProps={{ readOnly: true }}
                                    >
                                        <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                        <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                        <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                        <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                        <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                        <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                        <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                        <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                        <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                        <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                        <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                        <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Button onClick={handleAddRow} variant="outlined" color="primary">
                            {___PHRASE("agregarFila")}
                        </Button>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"><Typography sx={{fontSize:18}}>{___PHRASE("nCuotas")}</Typography></TableCell>
                                    <TableCell align="center"><Typography sx={{fontSize:18}}>{___PHRASE("monto")}</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                size='small'
                                                placeholder={___PHRASE("cuotas")}
                                                onChange={(e) =>
                                                    handleUpdateRow(index, 'n_fee', parseInt(e.target.value))
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                size='small'
                                                placeholder={___PHRASE("monto")}
                                                value={row.total}
                                                onChange={(e) =>
                                                    handleUpdateRow(index, 'total', parseInt(e.target.value))
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </ModalBody>
                <Grid justifyContent={'center'} alignItems={'flex-end'} sx={{p:3}}>
                <Button variant='contained' sx={{width: 100, ml:50}} onClick={()=>onSubmit()}>
                    {___PHRASE("guardar")}
                </Button>
                </Grid>
               
            </Modal>
        </>
    )
}