import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { ProjectService } from "@/service/services/Project.service";
import { Props } from "./ModalProjectParticipation.type";
import { PeriodService } from "@/service/services/Period.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalProjectParticipation: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, participation, onListParticipations, detailSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');
    const [typeParticipation, setTypeParticipation] = useState<any>('');
    const [valuePercentage, setvaluePercentage] = useState<any>('');
    const [valueTotal, setvalueTotal] = useState<any>('');
    const [valuePercentageOrTotal, setvaluePercentageOrTotal] = useState<any>('');

    const [data, setData] = useState({
        id: participation ? participation.id : '',
        idperiod: participation ? participation.idperiod : '',
        idemployee: participation ? participation.idemployee : '',
        participation_percentage: participation ? participation.participation_percentage : '',
        total: participation ? participation.total : '',
        iddetailproject: participation ? participation.iddetailproject : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    useEffect(()=> {
        getPeriodsActually()
    },[]);

    const getPeriodsActually = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' +  item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if(!found) { arrayYears.push(item.year); }
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        // if(!values.participation_percentage) errors.participation_percentage = "porcentaje requerido";
        // if(!values.total) errors.total = "total requerido";
        return errors;
    }

    const changeTypeParticipation = (event) => {
        setTypeParticipation(event.target.value);
        setvaluePercentage('');
        setvalueTotal('');
        setvaluePercentageOrTotal('');
    }

    const seePercentageOrTotal = async() => {
        setvaluePercentageOrTotal('');
        if(!monthSelected || !yearSelected){
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeSeleccionarMesyAno") }
               ))
            );
        }

        if((typeParticipation == '%' && valuePercentage == '') || (typeParticipation == '$' && valueTotal == '')){
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeLlenarTodosLosCampos") }
               ))
            );
        }

        const periodSelect = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelect);

        let startDay = (detailSelected.start_date).split('-');
        startDay = startDay[2];
        let endDay = (detailSelected.end_date).split('-');
        endDay = endDay[2];
        
        const startProyect = new Date(detailSelected.start_date);
        const endProyect = new Date(detailSelected.end_date);
        const dateSelected01 = new Date(yearSelected + '-' + monthSelected + '-' + `${startDay}`);
        const dateSelected02 = new Date(yearSelected + '-' + monthSelected + '-' + `${endDay}`);

        if(dateSelected02 > endProyect || dateSelected01 < startProyect){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                   severity:'warning', 
                   message: ___PHRASE("ElPeriodoSeleccionadoNoSeEncuentraEnElRangoDeInicioyFinDElPoryecto") }
                ))
             );
        }

        setLoading(true);

        let percentage:any = '';
        if(typeParticipation == '%') percentage = (valuePercentage / 100);

        const formData = new FormData();
        formData.append("idperiod", period.id);
        formData.append("idemployee", detailSelected.idemployee);
        if(typeParticipation == '%') formData.append("participation_percentage", percentage);
        if(typeParticipation == '$') formData.append("total", valueTotal);

        await ProjectService.seePercentageOrTotal(formData).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            if(data.data.status == true) {
                setvaluePercentageOrTotal(data?.data.value || '');
                setLoading(false);
            }else{
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                    ))
                )
            }
            
        })
        setLoading(false);
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!monthSelected || !yearSelected){
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeSeleccionarMesyAno") }
               ))
            );
        }

        const periodSelect = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelect);

        let startDay = (detailSelected.start_date).split('-');
        startDay = startDay[2];
        let endDay = (detailSelected.end_date).split('-');
        endDay = endDay[2];

        const startProyect = new Date(detailSelected.start_date);
        const endProyect = new Date(detailSelected.end_date);
        const dateSelected01 = new Date(yearSelected + '-' + monthSelected + '-' + `${startDay}`);
        const dateSelected02 = new Date(yearSelected + '-' + monthSelected + '-' + `${endDay}`);

        if(dateSelected02 > endProyect || dateSelected01 < startProyect){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                   severity:'warning', 
                   message: ___PHRASE("ElPeriodoSeleccionadoNoSeEncuentraEnElRangoDeInicioyFinDElPoryecto") }
                ))
             );
        }

        const formData = {
            id: values.id,
            idperiod : period.id,
            idemployee : detailSelected.idemployee,
            participation_percentage : valuePercentage && (valuePercentage / 100),
            total : valueTotal,
            iddetailproject : detailSelected.iddetailproject,
        }

        setLoading(true);
        let verifyQuantity;
        let verifyPorcentage;

        if(valueTotal != '' && typeParticipation == '$'){
            await ProjectService.verifyTotal(detailSelected.idemployee, period.id, valueTotal).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true) {
                    verifyQuantity = true;
                }else{
                    verifyQuantity = false;
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'warning', 
                            message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                        ))
                    )
                }
            })
        }

        if(valuePercentage != '' && typeParticipation == '%'){
            const particpation = (valuePercentage / 100)
            await ProjectService.verifyParticipation(detailSelected.idemployee, period.id, particpation).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true) {
                    verifyPorcentage = true;
                }else{
                    verifyPorcentage = false;
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'warning', 
                            message: `${apimessagge ||  ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                        ))
                    )
                }
            })
        }

        if(verifyPorcentage == true || verifyQuantity == true){
            await ProjectService.createDetailParticipation(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == false) {
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'warning', 
                            message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                        ))
                    )
                }
                onListParticipations && onListParticipations(detailSelected.iddetailproject);
                closeModal && closeModal();
            })
        }
        
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} disableEscapeKeyDown disableBackdropClick handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={___PHRASE("agregarParticipacion")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                value={yearSelected}
                                                onChange={(event) => {
                                                setYearSelected(event.target.value);
                                                }}
                                                fullWidth
                                            >
                                                {
                                                years.map((year, i) => {
                                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("mes")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                value={monthSelected}
                                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                                fullWidth
                                            >
                                                <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                                <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                                <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                                <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                                <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                                <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                                <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                                <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                                <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                                <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                                <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                                <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px' marginTop='10px'>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"><b>{___PHRASE("elegirLaParticipacionDelEmpleado")}:</b> </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={typeParticipation}
                                            onChange={changeTypeParticipation}                                         
                                        >
                                            <FormControlLabel value={'%'} control={<Radio />} label={___PHRASE("participacion")+' (%)'}/>
                                            <FormControlLabel value={'$'} control={<Radio />} label={___PHRASE("participacion")+" ($)"} />
                                        </RadioGroup>
                                    </Grid>

                                    {
                                        typeParticipation && typeParticipation == '%' && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("participacion")} (%):</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="participation_percentage"
                                                        variant="standard"
                                                        type="number" 
                                                        fullWidth
                                                        name="participation_percentage"
                                                        value={valuePercentage}
                                                        onChange={(e)=>{setvaluePercentage(e.target.value); setvalueTotal('')}}
                                                        placeholder={___PHRASE("porcentajeParticipacion")}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.participation_percentage && touched.participation_percentage ? true : false}
                                                        disabled={values.total!='' ?  true : false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    {
                                        typeParticipation && typeParticipation == '$' && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("participacion")} ($):</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="total"
                                                    variant="standard"
                                                    type="number" 
                                                    name="total"
                                                    fullWidth
                                                    value={valueTotal}
                                                    onChange={(e)=>{setvalueTotal(e.target.value); setvaluePercentage('')}}
                                                    placeholder={___PHRASE("totalParticipacion")}
                                                    error={errors.total && touched.total ? true : false}
                                                    disabled={values.participation_percentage!='' ?  true : false}
                                                />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    {
                                        typeParticipation && (typeParticipation == '$' || typeParticipation == '%') && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Button onClick={seePercentageOrTotal} sx={{ mr: 2, borderRadius: '5px', }} variant="contained" size="small">
                                                        {typeParticipation == '$' ? ___PHRASE("verPorcentaje")+' (%)' : ___PHRASE("verTotal")+' ($)'}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    type="number" 
                                                    fullWidth
                                                    value={valuePercentageOrTotal}
                                                    placeholder="----"
                                                    disabled
                                                    sx={{fontSize:'20px'}}
                                                />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    // clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}