import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { WorkerValidationService } from "@/service/services/WorkerValidation.service";
import { Props } from "./ModalAdminValidation.type";
import { AdminValidationService } from "@/service/services/AdminValidation.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalAdminValidation: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, validation, onListValidations, clearState } = props;

    console.log(validation)

    const [loading, setLoading] = useState<boolean>(false);
    const [reviewStatus, setReviewStatus] = useState<any>(''); 

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: validation ? validation.VIid : '',
        face_matching: validation ? validation.VIface_matching : '',
        observations: validation ? validation.VIobservations : '',
        status: validation ? validation.VIstatus : '',
    });

    console.log(data);

    const validateForm = (values) => {
        let errors: any = {};
        // if(!values.observations) errors.observations = "observación requerido";
        if(reviewStatus == 'rechazar' && !values.observations) errors.observations = "observación requerido";
        return errors;
    }


    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            face_matching: reviewStatus == 'aceptar' ? '1' : '2',
            observations: values.observations,
            status: reviewStatus == 'aceptar' ? '1' : '3',
        }

        console.log(formData);

        setLoading(true);
        if(validation){
            await AdminValidationService.editAdminValidation(validation.VIid
                , formData).then((data) => {
                console.log(data);
                onListValidations && onListValidations();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    const showDocument = (image) => {
        setLoading(true);

        if(image){
            window.open(image, '_blank');
        }

        setLoading(false);
    }


    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(validation) ? ___PHRASE("actualizarvalidaciondeidentidad") : ___PHRASE("enviarvalidacion")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    {
                                        validation && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("empleado")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="employee"
                                                    variant="standard"
                                                    type="text" 
                                                    name="employee"
                                                    fullWidth
                                                    value={validation.employeeName}
                                                    disabled
                                                    placeholder="empleado"
                                                />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("imagenRostro")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8} sx={{display: 'flex'}}>
                                                    <>
                                                    
                                                    <img
                                                        src={validation.VIface_img}
                                                        style={{maxHeight:'100px', cursor: 'pointer'}}
                                                        onClick={() => {
                                                            showDocument(validation.VIface_img)
                                                        }}
                                                    />

                                                    </>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("imagenDocumento")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8} sx={{display: 'flex'}}>
                                                    <>
                                                    
                                                    <img
                                                        src={validation.VIdoc_img}
                                                        style={{maxHeight:'100px', cursor: 'pointer'}}
                                                        onClick={() => {
                                                            showDocument(validation.VIdoc_img)
                                                        }}
                                                    />

                                                    </>
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    }

                                    {/* <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Comparacion de Rostro:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="face_matching"
                                                variant="standard"
                                                value={values.face_matching}
                                                onChange={(event) => { 
                                                    setFieldValue("face_matching", event.target.value)
                                                }}
                                                fullWidth
                                                error={errors.face_matching && touched.face_matching ? true : false}
                                            >
                                                <MenuItem value="1">Iguales</MenuItem>
                                                <MenuItem value="2">Diferentes</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid> */}

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("estado")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="status"
                                            // variant="standard"
                                            type="text" 
                                            name="status"
                                            multiline
                                            fullWidth
                                            value={
                                                validation.VIstatus == 0 ? 
                                                    ___PHRASE("laverificaciondeidentidadestaenproceso") : 
                                                validation.VIstatus == 1 ? 
                                                    ___PHRASE("identidadverificada") : 
                                                validation.VIstatus == 2 ? 
                                                ___PHRASE("verificaciondeidentidadeliminada") : 
                                                ___PHRASE("verificaciondeidentidadrechazada") }
                                            disabled
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("observacion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observations"
                                            variant="standard"
                                            type="text" 
                                            name="observations"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.observations}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("observacion")}
                                            error={errors.observations && touched.observations ? true : false}
                                            helperText={errors.observations ? errors.observations : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    {/* <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Estado:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="status"
                                                variant="standard"
                                                value={values.status}
                                                onChange={(event) => { 
                                                    setFieldValue("status", event.target.value)
                                                }}
                                                fullWidth
                                                error={errors.status && touched.status ? true : false}
                                            >
                                                <MenuItem value="0">Pendiente</MenuItem>
                                                <MenuItem value="1">Correcto</MenuItem>
                                                <MenuItem value="2">Eliminado</MenuItem>
                                                <MenuItem value="3">Rechazado</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid> */}

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={___PHRASE("aceptarSolicitud")}
                                    secondaryText={___PHRASE("rechazarSolicitud")}
                                    onConfirm={()=> {setReviewStatus('aceptar'); handleSubmit}}
                                    onSecondaryConfirm={()=> {setReviewStatus('rechazar'); handleSubmit}}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}