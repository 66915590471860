import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Breadcrumbs, Container, Snackbar, Alert } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AssistanceService } from '@/service/services/Assistance.service';
import { Props } from './Delay.type';
import { ModalDelay } from '@/components/Modal/ModalDelay';
import { ModalDelayDelete } from '@/components/Modal/ModalDelayDelete';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ___PHRASE from '@/service/translate/translateService';
import { useLocation } from 'react-router-dom';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const DelayView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [delays, setDelays] = useState<any>([]);
    const [showDelayModal, setShowDelayModal] = useState<boolean>(false);
    const [showDelayDeleteModal, setShowDelayDeleteModal] = useState<boolean>(false);
    const [delaySelected, setDelaySelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showDelays, setShowDelays] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 5000,
    })

    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }];

    const cabeceraDelay = [
        { name: 'date', label: ___PHRASE("fecha") },
        { name: 'total_delay', label: ___PHRASE("totalDeHoras") },
        { name: 'observation', label: ___PHRASE("observacion") },
    ];

    //Redireccion: props enviados de la vista solicitud (novedades del mes)
    let location: any = useLocation();
    let dataprops: any = location?.state;
    
    useEffect(()=> {
        if(dataprops){
            setWorkerSelected(dataprops);
            setShowDelays(true);
            setView('delays');
        }
    },[dataprops]);

    useEffect(()=> {
        getWorkers()
    }, []);

    useEffect(()=> {
        if(workerSelected){
            getDelaysByEmployee(workerSelected.id);
        }
    }, [workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();

        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });

        setWorkers(newData || []);
        setLoading(false);
    }

    const getDelaysByEmployee = async(idEmployee) => {
        setLoading(true);
        await AssistanceService.getDelaysByEmployee(idEmployee).then((data) => {
            setDelays(data.data || []);
            setLoading(false);
        })
    }
    
    function ConfigSnackBar(value){
      setSnackBarConfig(prev => ({ ...prev, open: true, severity:'success', message: value }));
    }

    function RecuperarData(value){
        !workerSelected && setWorkerSelected(value);
        workerSelected && setDelaySelected(value);
        setShowDelays(true);
        setView('delays');
    }

    const searchData = async (value) => {
        const resp:any = await WorkerService.getWorkers(value);
        setWorkers(resp.data || []);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:2}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeEmpleados")}
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowDelays(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                    disabled={dataprops ? true : false}
                />
                {
                    showDelays && (
                        <StyledBreadcrumb
                            label={___PHRASE("atrasos")}
                            onClick={() => {setView('delays')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={___PHRASE("atrasosPorEmpleado")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<PendingActionsIcon/>}
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'delays' && (
                    <TableDataV2
                        title={___PHRASE("atrasosDe")+(` ${workerSelected?.name_complete ? workerSelected?.name_complete : workerSelected?.name}`).toUpperCase()}
                        data={delays}
                        header={cabeceraDelay}
                        actionSelect={setActionSelected}
                        setModalSave={setShowDelayModal}
                        setModalDelete={setShowDelayDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                        note_severity={dataprops && 'warning'}
                        note={dataprops && 
                            `Asegurese de agregar la(s) tardanza(s) de acuerdo a la solicitud de "${dataprops.requestTypeName}"; la cual corresponde de la fecha ${dataprops.from_date_request} hasta ${dataprops.to_date_request}.`}
                    />
                )
            }
            </Container>

            { 
                showDelayModal && (
                    <ModalDelay
                        open={showDelayModal}
                        closeModal={()=>{setShowDelayModal(false)}}
                        actionSelected={actionSelected}
                        onListDelays={getDelaysByEmployee}
                        delay={delaySelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setDelaySelected('')}}
                        configSnackBar={(value)=>{ConfigSnackBar(value)}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalDelayDelete
                        open={showDelayDeleteModal}
                        closeModal={()=>{setShowDelayDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListDelays={getDelaysByEmployee}
                        delay={delaySelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setDelaySelected('')}}
                        configSnackBar={(value)=>{ConfigSnackBar(value)}}
                    />
                )
            }
        
        </Protected>
    )
};