import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalCM.type";
import { cmService } from "@/service/services/CM.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
export const ModalCM: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, cm, onListCM, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: cm ? cm?.id : '',
        year: cm ? cm.year : 0,
        C1: cm ? cm.C1 : 0,
        C2: cm ? cm.C2 : 0,
        C3: cm ? cm.C3 : 0,
        C4: cm ? cm.C4 : 0,
        C5: cm ? cm.C5 : 0,
        C6: cm ? cm.C6 : 0,
        C7: cm ? cm.C7 : 0,
        C8: cm ? cm.C8 : 0,
        C9: cm ? cm.C9 : 0,
        C10: cm ? cm.C10 : 0,
        C11: cm ? cm.C11 : 0,
        C12: cm ? cm.C12 : 0,
        additional: cm ? cm.additional : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        var regDecimal = /^\d{1,3}(\.\d{1,3})?$/;
        var regYear = /^\d{4}$/;

        if(!values.year) errors.year = "año requerido";
        if(!(regYear.test(values.year))) errors.year = "año no válido";
        if(!values.C1) errors.C1 = "C1 requerido";
        if(!(regDecimal.test(values.C1))) errors.C1 = "C1 no válido";
        if(!values.C2) errors.C2 = "C2 requerido";
        if(!(regDecimal.test(values.C2))) errors.C2 =  "C2 no válido";
        if(!values.C3) errors.C3 = "C3 requerido";
        if(!(regDecimal.test(values.C3))) errors.C3 = "C3 no válido";
        if(!values.C4) errors.C4 = "C4 requerido";
        if(!(regDecimal.test(values.C4))) errors.C4 = "C4 no válido";
        if(!values.C5) errors.C5 = "C5 requerido";
        if(!(regDecimal.test(values.C5))) errors.C5 = "C5 no válido";
        if(!values.C6) errors.C6 = "C6 requerido";
        if(!(regDecimal.test(values.C6))) errors.C6 = "C6 no válido";
        if(!values.C7) errors.C7 = "C7 requerido";
        if(!(regDecimal.test(values.C7))) errors.C7 = "C7 no válido";
        if(!values.C8) errors.C8 = "C8 requerido";
        if(!(regDecimal.test(values.C8))) errors.C8 = "C8 no válido";
        if(!values.C9) errors.C9 = "C9 requerido";
        if(!(regDecimal.test(values.C9))) errors.C9 = "C9 no válido";
        if(!values.C10) errors.C10 = "C10 requerido";
        if(!(regDecimal.test(values.C10))) errors.C10 = "C10 no válido";
        if(!values.C11) errors.C11 = "C11 requerido";
        if(!(regDecimal.test(values.C11))) errors.C11 = "C11 no válido";
        if(!values.C12) errors.C12 = "C12 requerido";
        if(!(regDecimal.test(values.C12))) errors.C12 = "C12 no válido";
        return errors;
    }

    const onSubmit = async(values, {})  => {
        const formData = {
            year: values.year.toString(),
            C1: values.C1,
            C2: values.C2,
            C3: values.C3,
            C4: values.C4,
            C5: values.C5,
            C6: values.C6,
            C7: values.C7,
            C8: values.C8,
            C9: values.C9,
            C10: values.C10,
            C11: values.C11,
            C12: values.C12,
            additional: values.additional,
        }

        console.log(formData)

        setLoading(true);
        if(actionSelected=='edit'){
            await cmService.editCM(cm.id, formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: apimessagge})
                    onListCM && onListCM()
                    closeModal && closeModal();
                } else {
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: apimessagge})
                }
            })
            setLoading(false);
        }else{
            await cmService.createCM(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: apimessagge})
                    onListCM && onListCM()
                    closeModal && closeModal();
                } else {
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: apimessagge})
                }
            })
            setLoading(false);
        }
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={(actionSelected=='edit') ? ___PHRASE("editar") + ' variables CM' : ___PHRASE("agregar") + ' variables CM'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{overflowY:'scroll', maxHeight:'400px', paddingX:4}}>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Año:</b></Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                        <TextField
                                            id="year-number"
                                            variant="standard"
                                            type="number"
                                            name="year"
                                            fullWidth
                                            value={values.year}
                                            onChange={handleChange}
                                            placeholder="Ingrese año"
                                            error={errors.year && touched.year ? true : false}
                                            helperText={errors.year && touched.year ? errors.year : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C1:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C1"
                                            variant="standard"
                                            type="number"
                                            name="C1"
                                            fullWidth
                                            value={values.C1}
                                            onChange={handleChange}
                                            placeholder="Ingrese C1"
                                            error={errors.C1 && touched.C1 ? true : false}
                                            helperText={errors.C1 && touched.C1 ? errors.C1 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C2:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C2"
                                            variant="standard"
                                            type="number"
                                            name="C2"
                                            fullWidth
                                            value={values.C2}
                                            onChange={handleChange}
                                            placeholder="Ingrese C2"
                                            error={errors.C2 && touched.C2 ? true : false}
                                            helperText={errors.C2 && touched.C2 ? errors.C2 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C3:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C3"
                                            variant="standard"
                                            type="number"
                                            name="C3"
                                            fullWidth
                                            value={values.C3}
                                            onChange={handleChange}
                                            placeholder="Ingrese C3"
                                            error={errors.C3 && touched.C3 ? true : false}
                                            helperText={errors.C3 && touched.C3 ? errors.C3 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C4:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C4"
                                            variant="standard"
                                            type="number"
                                            name="C4"
                                            fullWidth
                                            value={values.C4}
                                            onChange={handleChange}
                                            placeholder="Ingrese C4"
                                            error={errors.C4 && touched.C4 ? true : false}
                                            helperText={errors.C4 && touched.C4 ? errors.C4 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C5:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C5"
                                            variant="standard"
                                            type="number"
                                            name="C5"
                                            fullWidth
                                            value={values.C5}
                                            onChange={handleChange}
                                            placeholder="Ingrese C5"
                                            error={errors.C5 && touched.C5 ? true : false}
                                            helperText={errors.C5 && touched.C5 ? errors.C5 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C6:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C6"
                                            variant="standard"
                                            type="number"
                                            name="C6"
                                            fullWidth
                                            value={values.C6}
                                            onChange={handleChange}
                                            placeholder="Ingrese C6"
                                            error={errors.C6 && touched.C6 ? true : false}
                                            helperText={errors.C6 && touched.C6 ? errors.C6 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C7:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C7"
                                            variant="standard"
                                            type="number"
                                            name="C7"
                                            fullWidth
                                            value={values.C7}
                                            onChange={handleChange}
                                            placeholder="Ingrese C7"
                                            error={errors.C7 && touched.C7 ? true : false}
                                            helperText={errors.C7 && touched.C7 ? errors.C7 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C8:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C8"
                                            variant="standard"
                                            type="number"
                                            name="C8"
                                            fullWidth
                                            value={values.C8}
                                            onChange={handleChange}
                                            placeholder="Ingrese C8"
                                            error={errors.C8 && touched.C8 ? true : false}
                                            helperText={errors.C8 && touched.C8 ? errors.C8 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C9:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C9"
                                            variant="standard"
                                            type="number"
                                            name="C9"
                                            fullWidth
                                            value={values.C9}
                                            onChange={handleChange}
                                            placeholder="Ingrese C9"
                                            error={errors.C9 && touched.C9 ? true : false}
                                            helperText={errors.C9 && touched.C9 ? errors.C9 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C10:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C10"
                                            variant="standard"
                                            type="number"
                                            name="C10"
                                            fullWidth
                                            value={values.C10}
                                            onChange={handleChange}
                                            placeholder="Ingrese C10"
                                            error={errors.C10 && touched.C10 ? true : false}
                                            helperText={errors.C10 && touched.C10 ? errors.C10 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C11:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C11"
                                            variant="standard"
                                            type="number"
                                            name="C11"
                                            fullWidth
                                            value={values.C11}
                                            onChange={handleChange}
                                            placeholder="Ingrese C11"
                                            error={errors.C11 && touched.C11 ? true : false}
                                            helperText={errors.C11 && touched.C11 ? errors.C11 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>C12:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="C12"
                                            variant="standard"
                                            type="number"
                                            name="C12"
                                            fullWidth
                                            value={values.C12}
                                            onChange={handleChange}
                                            placeholder="Ingrese C12"
                                            error={errors.C12 && touched.C12 ? true : false}
                                            helperText={errors.C12 && touched.C12 ? errors.C12 : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                            <Typography variant="body1"><b>Adicional:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                        <TextField
                                            id="additional"
                                            variant="standard"
                                            type="text"
                                            name="additional"
                                            fullWidth
                                            value={values.additional}
                                            onChange={handleChange}
                                            placeholder="Ingrese Adicional"
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}