import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, Snackbar, Alert, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormPersonalData.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass'
import "./FormPersonalData_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import HeightIcon from '@mui/icons-material/Height';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { dateFormat } from '@/toolbox/helpers/date.helper';
import ___PHRASE from '@/service/translate/translateService';


export const FormPersonalData: React.FC<Props> = (
   props: Props
): JSX.Element => {
   
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [valuePersonalData, setvaluePersonalData] = useState<any>(props?.valuePersonalData);

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
  });

   useEffect(() => {
      if(props?.valuePersonalData){
         setvaluePersonalData(props.valuePersonalData)
      }
   }, [props?.valuePersonalData])

   const sexoValue = [
      { id:'1', label: ___PHRASE("masculino") },
      { id:'2', label: ___PHRASE("femenino") },
   ]
   
   useEffect(() => {
      if(props?.valuePersonalData){
         setvaluePersonalData(props.valuePersonalData)
      }
   }, [props?.valuePersonalData])
   
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'n_document':
            props.setError('')
            setvaluePersonalData(prev => ({ ...prev, n_document: value }));
            break;
         case 'name':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, name: value }))
            break;
         case 'surname':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, surname: value }))
            break;
         case 'second_surname':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, second_surname: value }));
            break;
         case 'direccion':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, direccion: value }));
            break;
         case 'telefono':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, telefono: value }));
            break;
         // case 'ciudad':
         //    props.setError('')  
         //    setvaluePersonalData(prev => ({ ...prev, ciudad: value }));
         //    break;
         case 'celular':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, celular: value }));
            break;
         case 'emergency_phone':
         props.setError('')  
         setvaluePersonalData(prev => ({ ...prev, emergency_phone: value }));
         break;
         case 'email':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, email: value }));
            break;
         default:
            break;
      }  
   };

   const handleChangeBirthDate = (newValue) => {
      props.setError('')
      setvaluePersonalData({...valuePersonalData, birth_date: newValue.target.value})
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;

      if(valuePersonalData.n_document && !(regRut.test(valuePersonalData.n_document))){
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: 'Rut incorrecto' 
               // message: ___PHRASE("debeSubirDocumentosDeIdentidadDelEmpleado") 
            }))
         );
      }

      props.setvaluePersonalData && props.setvaluePersonalData(valuePersonalData); 
      props.handleNext && props.handleNext();
   }

   return (
      <>
      <Snackbar
         open={snackBarConfig.open} 
         autoHideDuration={snackBarConfig.autoHideDuration} 
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
         <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
         </Alert>
      </Snackbar>
      
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3}>

               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="n_document"
                     type='text'
                     placeholder="Rut"
                     value={valuePersonalData.n_document}
                     onChange={handleInput}
                     error={props.error=='n_document'}
                     errorMessages={props.error=='n_document' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="name"
                     type='text'
                     placeholder={___PHRASE("nombre")}
                     value={valuePersonalData.name}
                     onChange={handleInput}
                     error={props.error=='name'}
                     errorMessages={props.error=='name' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="surname"
                     type='text'
                     placeholder={___PHRASE("apellidoPaterno")}
                     value={valuePersonalData.surname}
                     onChange={handleInput}
                     error={props.error=='surname'}
                     errorMessages={props.error=='surname' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="second_surname"
                     type='text'
                     placeholder={___PHRASE("apellidoMaterno")}
                     value={valuePersonalData.second_surname}
                     onChange={handleInput}
                     error={props.error=='second_surname'}
                     errorMessages={props.error=='second_surname' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.CivilStatus}
                     onChange={(e,value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, idcivilstatus: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valuePersonalData?.idcivilstatus?(
                        //    props.dataInitial.CivilStatus.filter((value)=>{return value.id == props.valuePersonalData?.idcivilstatus})[0]
                        // ):(
                           valuePersonalData?.idcivilstatus?(
                              props.dataInitial.CivilStatus.filter((value)=>{return value.id == valuePersonalData?.idcivilstatus})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("estadoCivil")}
                     error={props.error=='idcivilstatus'} 
                     helperText={props.error=='idcivilstatus' && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.EmployeeType}
                     onChange={(e, value:any)=>{
                        const validpensioner = (value.code == 0 || value.code == 3 ) ? 0 : 1;
                        props.setError('');
                        setvaluePersonalData(prev => ({ ...valuePersonalData, idemployeetype: value?value.id:null, pensioner: validpensioner}));
                     }}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valuePersonalData?.idemployeetype?(
                        //    props.dataInitial.EmployeeType.filter((value)=>{return value.id == props.valuePersonalData?.idemployeetype})[0]
                        // ):(
                           valuePersonalData?.idemployeetype?(
                              props.dataInitial.EmployeeType.filter((value)=>{return value.id == valuePersonalData?.idemployeetype})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("tipoDeTrabajador")}
                     error={props.error=='idemployeetype'}
                     helperText={props.error== 'idemployeetype' && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={sexoValue}
                     onChange={(e,value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, sexo:value?value.id:null })}}
                     value={
                        // props.valuePersonalData?.sexo?(
                        //   sexoValue.filter((value)=>{return value.id == props.valuePersonalData?.sexo})[0]
                        // ):(
                           valuePersonalData?.sexo?(
                             sexoValue.filter((value)=>{return value.id == valuePersonalData?.sexo})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("sexo")}
                     error={props.error=='sexo'}
                     helperText={props.error=='sexo' && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                            inputFormat="MM/dd/yyyy"
                           // views={['year', 'month', 'day']}
                           label="Fecha de Nacimiento"
                           value={valuePersonalData.birth_date}
                           onChange={handleChangeBirthDate}
                           renderInput={(params) => <TextField {...params} 
                           error={props.error=='birth_date'}
                           helperText={props.error=='birth_date' && 'Campo obligatorio'} />}
                        />
                     </Stack>                                               
                  </LocalizationProvider> */}
                  <TextField
                           variant="standard"
                           fullWidth
                           name="birth_date"
                           type="date"
                           onChange={handleChangeBirthDate}
                           value={valuePersonalData.birth_date}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={props.error=='birth_date'}
                           helperText={props.error=='birth_date' && ___PHRASE("campoObligatorio")}
                        />
               </Grid>

               {/* <Grid item xs={12} sm={3}>
                  <FormControl >
                     <FormControlLabel control={<Checkbox />} label="Pensionado" />                     
                  </FormControl>
               </Grid>  */}

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.State}
                     onChange={(e, value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, state: value?value.valueStatus:null})}}
                     getOptionLabel={(option: any) => option.status ? option.status : ''}
                     value={
                        // props.valuePersonalData?.state?(
                        //    props.dataInitial.State.filter((value)=>{return value.valueStatus == props.valuePersonalData?.state})[0]
                        // ):(
                           valuePersonalData?.state?(
                              props.dataInitial.State.filter((value)=>{return value.valueStatus == valuePersonalData?.state})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("estadoDelTrabajador")}
                     error={props.error=='state'}
                     helperText={props.error=='state' && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.Education}
                     onChange={(e, value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, ideducation: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        // props.valuePersonalData?.ideducation?(
                        //    props.dataInitial.Education.filter((value)=>{return value.id == props.valuePersonalData?.ideducation})[0]
                        // ):(
                           valuePersonalData?.ideducation?(
                              props.dataInitial.Education.filter((value)=>{return value.id == valuePersonalData?.ideducation})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("ultimoNivelDeEstudios")} 
                     error={props.error =='ideducation'} 
                     helperText={props.error=='ideducation' && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <HeightIcon />

                        </InputAdornment>
                     }
                     name="direccion"
                     placeholder={___PHRASE("direccion")}
                     value={valuePersonalData.direccion}
                     onChange={handleInput}
                     error={props.error=='direccion'}
                     errorMessages={props.error=='direccion' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.District}
                     onChange={(e,value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, iddistrict: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        // props.valuePersonalData?.iddistrict?(
                        //    props.dataInitial.District.filter((value)=>{return value.id == props.valuePersonalData?.iddistrict})[0]
                        // ):(
                           valuePersonalData?.iddistrict?(
                              props.dataInitial.District.filter((value)=>{return value.id == valuePersonalData?.iddistrict})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("comuna")}
                     error={props.error=='iddistrict'}
                     helperText={props.error=='iddistrict' && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneIcon />

                        </InputAdornment>
                     }
                     name="telefono"
                     placeholder={___PHRASE("telefonoFijo")}
                     value={valuePersonalData.telefono}
                     onChange={handleInput}
                     error={props.error=='telefono'}
                     errorMessages={props.error=='telefono' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneAndroidIcon />                           
                        </InputAdornment>
                     }
                     name="celular"
                     placeholder={___PHRASE("celular")}
                     value={valuePersonalData.celular}
                     onChange={handleInput}
                     error={props.error=='celular'}
                     errorMessages={props.error=='celular' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneAndroidIcon />                           
                        </InputAdornment>
                     }
                     name="emergency_phone"
                     placeholder={___PHRASE("celularDeEmergencia")}
                     value={valuePersonalData.emergency_phone}
                     onChange={handleInput}
                     error={props.error=='emergency_phone'}
                     errorMessages={props.error=='emergency_phone' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense    
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <AlternateEmailIcon />                          
                        </InputAdornment>
                     }
                     name="email"
                     placeholder={___PHRASE("correo")}
                     value={valuePersonalData.email}
                     onChange={handleInput}
                     error={props.error=='email'}
                     errorMessages={props.error=='email' && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>

               <Grid item container
                  xs={12}
                  justifyContent='right'
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={(e)=>{handleSubmit(e)}}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        sx={{ml:2}}
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("siguiente")}</span>
                        }
                     </Button>
                     

                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
      </>
   );
}
