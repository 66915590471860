import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const AfpRepository = {

    getAfpType: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/detailAFP/type/afp`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getDetailAfp: async(year: string, month: string): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/detailAFP?year=${year}&month=${month}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getYearDetailAFP: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/detailAFP/yearDetailAFP`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createDetailAfp: async (dataAFP: any) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/detailAFP`, dataAFP);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editDetailAfp: async (idDetail: number, dataAFP: any) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/detailAFP/${idDetail}`, dataAFP);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteDetailAfp: async(idDetail: number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/detailAFP/${idDetail}`)
        return res;
    },
}