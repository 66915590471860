import { http } from '../http/http';
import { UserDetailsDTO,UserDTO,UserPost,UserRequestPost, UsersDTO} from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { User } from '../models/User';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { WorkersDTO } from '../http/dto/WorkerDTO';
import { Worker } from '../models/Worker';
import { BranchOffice } from '../models/BranchOffice';


export const branchOfficeRepository = {

   getBranchOffices: async (search = '') : Promise<BranchOffice>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<BranchOffice>(`${API_URL_BASE}/v1/branchOffice?search=${search}`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            code: raw?.code,
            address: raw?.address,
            phone: raw?.phone,
            idcompany: raw?.idcompany,
            rutCompany: raw?.rutCompany,
            nameCompany: raw?.nameCompany,
            idofficetype: raw?.idofficetype,
            nameOfficeType: raw?.nameOfficeType,
            iddistrict: raw?.iddistrict,
            codeDistrict: raw?.codeDistrict,
            nameDistrict: raw?.nameDistrict,
            idprovince: raw?.idprovince,
            codeprovince: raw?.codeprovince,
            nameProvince: raw?.nameProvince,
            iddepartament: raw?.iddepartament,
            codeDepartament: raw?.codeDepartament,
            nameDepartament: raw?.nameDepartament,
            idcountry: raw?.idcountry,
            codecountry: raw?.codecountry,
            nameCountry: raw?.nameCountry,
            time_zone: raw?.time_zone,
            status: raw?.status,
         })),
         error,
         message
      }
   },

   getBranchOfficesByCompany: async (idCompany) : Promise<BranchOffice>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<BranchOffice>(`${API_URL_BASE}/v1/branchOffice/${idCompany}`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            code: raw?.code,
            address: raw?.address,
            phone: raw?.phone,
            idcompany: raw?.idcompany,
            rutCompany: raw?.rutCompany,
            nameCompany: raw?.nameCompany,
            idofficetype: raw?.idofficetype,
            nameOfficeType: raw?.nameOfficeType,
            iddistrict: raw?.iddistrict,
            codeDistrict: raw?.codeDistrict,
            nameDistrict: raw?.nameDistrict,
            idprovince: raw?.idprovince,
            codeprovince: raw?.codeprovince,
            nameProvince: raw?.nameProvince,
            iddepartament: raw?.iddepartament,
            codeDepartament: raw?.codeDepartament,
            nameDepartament: raw?.nameDepartament,
            idcountry: raw?.idcountry,
            codecountry: raw?.codecountry,
            nameCountry: raw?.nameCountry,
            time_zone: raw?.time_zone,
            status: raw?.status,
         })),
         error,
         message
      }
   },

   createBranch: async (dataBranch) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/branchOffice`, {
         code: dataBranch.code,
         address: dataBranch.address,
         phone: dataBranch.phone,
         idofficetype: dataBranch.idofficetype,
         iddistrict: dataBranch.iddistrict,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   editBranch: async (dataBranch, idBranch) : Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/branchOffice/${idBranch}`, {
         code: dataBranch.code,
         address: dataBranch.address,
         phone: dataBranch.phone,
         idofficetype: dataBranch.idofficetype,
         iddistrict: dataBranch.iddistrict,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   deleteBranch: async(idBranch:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/branchOffice/${idBranch}`)
      return res;
   },

   getBranchTypes: async(): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/branchOffice/typeBranchOffice`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   createTypeBranchOffice: async (dataTypeBranch) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/branchOffice/create-type`, {
         name: dataTypeBranch.name,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   updateTypeBranchOffice: async (dataTypeBranch, idTypeBranch) : Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/branchOffice/update-type/${idTypeBranch}`, {
         name: dataTypeBranch.name,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   deleteTypeBranchOffice: async(idTypeBranch:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/branchOffice/delete-type/${idTypeBranch}`)
      return res;
   },
}


