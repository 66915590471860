import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { branchOfficeRepository } from '@/service/repositories/BranchOffice.repository';
import { ModalBranchOffice } from '@/components/Modal/ModalBranchOffice';
import { ModalBranchOfficeDelete } from '@/components/Modal/ModalBranchOfficeDelete';
import { Props } from './BranchOfficeTypes.type';
import { ModalBranchOfficeType } from '@/components/Modal/ModalBranchOfficeType/ModalBranchOfficeType';
import { ModalBranchOfficeTypeDelete } from '@/components/Modal/ModalBranchOfficeTypeDelete/ModalBranchOfficeTypeDelete';
import ___PHRASE from '@/service/translate/translateService';


export const BranchOfficeTypesView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [branchOfficeTypes, setBranchOfficeTypes] = useState<any>([]);
    const [showBranchOfficeTypeModal, setShowBranchOfficeTypeModal] = useState<boolean>(false);
    const [showBranchTypeDeleteModal, setShowBranchTypeDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [branchOfficeTypeSelected, setBranchOfficeTypeSelected] = useState<any>('');

    useEffect(() => {
        getBranchOfficeTypes()
    },[]);

    const getBranchOfficeTypes = async () => {
        setLoading(true);
        const response = await branchOfficeRepository.getBranchTypes();
        if (response.data) {
            setBranchOfficeTypes(response.data || [])
        } else {
            console.log('error')
        }
        setLoading(false);
    }

    function RecuperarData(value){
        console.log(value);
        setBranchOfficeTypeSelected(value);
    }

    return (
        <Protected>
            {loading && <Loading/>}

            <Container>
                <TableDataV2
                title={___PHRASE("tiposdesucursales")}
                data={branchOfficeTypes}
                header={[
                    { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                ]}
                actionSelect={setActionSelected}
                setModalSave={setShowBranchOfficeTypeModal}
                setModalDelete={setShowBranchTypeDeleteModal}
                status_action
                RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showBranchOfficeTypeModal && (
                <ModalBranchOfficeType
                    open={showBranchOfficeTypeModal}
                    closeModal={()=>{setShowBranchOfficeTypeModal(false)}}
                    actionSelected={actionSelected}
                    onListTypes={getBranchOfficeTypes}
                    branchOfficeType={branchOfficeTypeSelected}
                    clearState={()=>{setBranchOfficeTypeSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalBranchOfficeTypeDelete
                    open={showBranchTypeDeleteModal}
                    closeModal={()=>{setShowBranchTypeDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListTypes={getBranchOfficeTypes}
                    branchOfficeType={branchOfficeTypeSelected}
                    clearState={()=>{setBranchOfficeTypeSelected('')}}
                />
                )
            }
        
        </Protected>
    )
};