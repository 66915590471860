import { http } from '../http/http';
import { UserDetailsDTO,UserDTO,UserPost,UserRequestPost, UsersDTO} from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { User } from '../models/User';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { WorkersDTO } from '../http/dto/WorkerDTO';
import { Worker } from '../models/Worker';


export const workerRepository = {

   // getUser: async (idToken: string) => {
   //    const user = await http.post('http://localhost/login22', JSON.stringify({'token': idToken }) )
   //    return user
   // },
   // getUser: async (): Promise<any> => {
   //    const dataUser = readLocalStorage(KEY_USER_DATA);
   //    const rut_empresa = dataUser?.user?.rut_empresa;
   //    const user = await http.get<any>(`${API_URL_BASE}/v1/user/${rut_empresa}` )
   //    const {data,error,message} = user
   //    return {
   //       data,
   //       error,
   //       message
   //    };
   // },
   // getUserPage: async (perPage, page): Promise<any> => {
   //    const dataUser = readLocalStorage(KEY_USER_DATA);
   //    const rut_empresa = dataUser?.user?.rut_empresa;
   //    const user = await http.get<any>(`${API_URL_BASE}/v1/user/${rut_empresa}?per_page=${perPage}&page=${page}` )
   //    const {data,error,message} = user
   //    return {
   //       data,
   //       error,
   //       message
   //    };
   // },
   // getUserSearch: async (search: string): Promise<any> => {
   //    const dataUser = readLocalStorage(KEY_USER_DATA);
   //    const rut_empresa = dataUser?.user?.rut_empresa;
   //    const id_empresa = dataUser?.user?.idempresacliente;
   //    const id_usuario = dataUser?.user?.idusuario;
   //    const user = await http.get<any>(`${API_URL_BASE}/v1/user?search=${search}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
   //    const {data,error,message} = user
   //    return {
   //       data,
   //       error,
   //       message
   //    };
   // },
   getWorkers: async (search = "") : Promise<Worker>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<WorkersDTO>(`${API_URL_BASE}/v1/employee/list?search=${search}`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            name: raw?.name,
            surname: raw?.surname,
            second_surname: raw?.second_surname,
            profession: raw?.profession,
            n_document:raw?.n_document,
            civilstatus:raw?.civilstatus,
         })),
         error,
         message
      }
   },
   getWorkersUnique: async () : Promise<Worker>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<WorkersDTO>(`${API_URL_BASE}/v1/employee/unique`);
      const { data, error, message } = users;
      return {
         data: data,
         error,
         message
      }
   },
   getWorkerSingle: async (idemployee) : Promise<Worker>=> {
      const users = await http.get<WorkersDTO>(`${API_URL_BASE}/v1/employee/${idemployee}`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            name: raw?.name,
            surname: raw?.surname,
            second_surname: raw?.second_surname,
            profession: raw?.profession,
            n_document:raw?.n_document,
            civilstatus:raw?.civilstatus,
         })),
         error,
         message
      }
   },
   getContracts: async () : Promise<any>=> {
      const users = await http.get<any>(`${API_URL_BASE}/v1/employeeContract`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            idemployee: raw?.idemployee,
            n_document: raw?.n_document,
            surname: raw?.surname,
            second_surname: raw?.second_surname,
            name:raw?.name,
            status:raw?.status,
            value_status:raw?.value_status,
         })),
         error,
         message
      }
   },
   getEmployeeContract: async (idContract): Promise<any> => {
      const workers = await http.get<any>(`${API_URL_BASE}/v1/employeeContract/allData/${idContract}`);
      const { data, error, message } = workers;
      return {
         data: (data || []).map((raw) => ({
            n_document: raw?.n_documentname,
            name: raw?.namename,
            surname: raw?.surnamename,
            second_surname: raw?.second_surnamename,
            idcivilstatus: raw?.idcivilstatus,
            idcategoryemployee: raw?.idcategoryemployee,
            // idprofession: 1,
            idprofession: raw?.idprofession,
            // idposition: 1,
            idposition: raw?.idposition,
            sexo: raw?.sexoname,
            birth_date: raw?.birth_datename,
            // pensioner: 1,
            pensioner: raw?.pensionername,
            state: raw?.state,
            idmilitary: raw?.idmilitary,
            ideducation: raw?.ideducation,
            direccion: raw?.direccionname,
            nationality: raw?.nationality,
            // idcountry:raw?.idcountry,
            idprovince: raw?.idprovince,
            iddepartment: raw?.iddepartment,
            iddistrict: raw?.iddistrict,
            // idbranch_office: raw?.idbranch_office,
            idworkmode: raw?.idworkmode,
            idworkingtime: raw?.idworkingtime,
            idtype_duration_contract: raw?.idtype_duration_contract,
            iddistrictbranchoffice: raw?.iddistrictbranchoffice,
            idbranchoffice:raw?.idbranchoffice,
            celular: raw?.personal_phonename,
            telefono: raw?.landlinename,
            emergency_phone: raw?.emergency_phonename,
            email: raw?.emailname,
            // idtypemoney: 1,
            idtypemoney: raw?.idtypemoney,
            idcharge: raw?.idcharge + '',
            idbusinessarea: raw?.idbusinessarea,
            idtype_contract: raw?.idtype_contract,
            workerstate: raw?.status,
            workersubsidy: raw?.workersubsidyname,
            idcommuneprovisionservices: raw?.idcommuneprovisionservices,
            money: raw?.salary,
            idgratificationtype: raw?.idgratificationtype + '',
            idcostcenter: raw?.idcostcenter,
            private_role: "1",
            // n_control_card: 1,
            n_control_card: raw?.n_control_cardname,
            //dt.n_control_card,
            years_other_employers: raw?.years_other_employersname,
            apv_libro_rem: raw?.apv_libro_rem,
            unemployment_insurance: raw?.unemployment_insurancename,
            idemployeetype: raw?.idemployeetype,
            iddisableperson: raw?.iddisableperson,
            time_control: raw?.time_controlname,
            idAFP: raw?.idAFP,
            idsalud: raw?.idsalud,
            idAPV: raw?.idAPV + '',
            reduced_tax: raw?.reduced_taxname,
            apv_afp: raw?.apv_afpname,
            // book_rem_electr: 1,
            book_rem_electr: raw?.book_rem_electrname,
            n_contrato: raw?.n_contratoname,
            idctosalud: raw?.idctosalud,
            cot_salud_por: raw?.cot_salud_porname,
            cot_salud_$: raw?.cot_salud_Sname,
            cto_salud_uf: raw?.cot_salud_ufname,
            payment_method: raw?.payment_methodname,
            load_number: raw?.quantity_burden + '',
            idfamilystretch: raw?.idfamilystretch,
            idbank: raw?.idbank,
            idwaypay: raw?.idwaypay,
            idaccounttype: raw?.idaccounttype,
            account_number: raw?.account_numbername,
            iddocumentcontract: raw?.iddocumentcontract,
            idcompensationbox: raw?.idcompensationbox,
            last_imponible: raw?.last_imponible,
         })),
         error,
         message
      }
   },
   createWorker: async (dataPhase) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/employee`, {
          name: dataPhase.name,
          surname: dataPhase.surname,
          second_surname: dataPhase.second_surname,
          n_document: dataPhase.n_document,
          email:dataPhase.email,
          birth_date:dataPhase.birth_date,
          idcivilstatus:dataPhase.idcivilstatus,
          iddistrict:dataPhase.iddistrict,
          ideducation: dataPhase.ideducation,
          idnationality: dataPhase.idnationality,
          idprofession: dataPhase.idprofession,
          sexo: dataPhase.sexo, 
          pensioner:dataPhase.pensioner,
          idmilitary: dataPhase.idmilitary,         
          state: dataPhase.state,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },
   createValidationIdentityAdmin: async (dt) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/employeeContract/createValidationIdentityAdmin`, dt);
      const {data, error, message} = res;
      return { data, error, message };
   },
   postCreateWorkersContract: async (dt): Promise<any> => {
      const worker = await http.post<any>(`${API_URL_BASE}/v1/employeeContract/create`, {
         ...dt,
         idprofession: 1,
         idposition: 1,
         idmilitary: 1,
         idtypemoney: 1,
         private_role: "1",
         n_control_card: 1,
         time_control: "1",
         idAPV: dt.idAPV + '',
         reduced_tax: 1,
         apv_afp: "0",
         book_rem_electr: 1,
         cot_salud_por: dt.cot_salud_por || '0',
         cot_salud_$: dt.cot_salud_$ || '0',
         cot_salud_uf: dt.cto_salud_uf || '0',
         load_number: dt.load_number+'',
         //valores estáticos
         idworkmode: dt.idworkmode, //1: presencial, 2: remoto
         idworkingtime: dt.idworkingtime, //1: full time, 2: part time
         idtype_duration_contract: dt.idtype_duration_contract, //1: anual, 2: mensual
         contract_status: "1",
         idnationality: 1, //1: chile, 2: extranjero
         start_collation: "13:00",
         end_collation: "14:00",
         weekly_hour: dt.idworkingtime && (dt.idworkingtime == '1' ? '40' : (dt.idworkingtime == '2' ? '20' : '40')),
         idworkschedulecompany: 2,
         id_document: 1,
         signature_date: '2022-09-03',
         start_date: '2022-09-04',
         end_date: '2022-12-30',
         email_contact: 'contrato@gmail.com',
         signature: '1',
         salary:dt.money,
         quantity_burden: dt.load_number+'',
         status_contract: 1,
         idcodhealthinstitucion: dt.idsalud,
         cot_salud_S: '0',


         // n_document: dt.n_document,
         // name: dt.name,
         // surname: dt.surname,
         // second_surname: dt.second_surname,
         // idcivilstatus: dt.idcivilstatus,
         // idcategoryemployee: dt.idcategoryemployee,
         // idprofession: 1,
         // idposition: 1,
         // sexo: dt.sexo,
         // birth_date: dt.birth_date,
         // pensioner: dt.pensioner,
         // state: dt.state,
         // idmilitary: 1,
         // ideducation: dt.ideducation,
         // direccion: dt.direccion,
         // iddistrict: dt.iddistrict,
         // telefono: dt.telefono,
         // celular: dt.celular,
         // emergency_phone: dt.emergency_phone,
         // email: dt.email,
         // idtypemoney: 1,
         // idcharge: dt.idcharge + '',
         // idbusinessarea: dt.idbusinessarea,
         // idtype_contract: dt.idtype_contract,
         // workerstate: dt.workerstate,
         // workersubsidy: dt.workersubsidy,
         // idcommuneprovisionservices: dt.idcommuneprovisionservices,
         // money: dt.money,
         // idgratificationtype: dt.idgratificationtype + '',
         // idcostcenter: dt.idcostcenter,
         // private_role: "1",
         // n_control_card: 1,
         // years_other_employers: dt.years_other_employers,
         // apv_libro_rem: dt.apv_libro_rem,
         // unemployment_insurance: dt.unemployment_insurance,
         // idemployeetype: dt.idemployeetype,
         // iddisableperson: dt.iddisableperson,
         // time_control: "1",
         // idAFP: dt.idAFP,
         // idsalud: dt.idsalud,
         // idAPV: dt.idAPV + '',
         // reduced_tax: 1,
         // apv_afp: "0",
         // book_rem_electr: 1,
         // n_contrato: dt.n_contrato,
         // idctosalud: dt.idctosalud,
         // cot_salud_por: dt.cot_salud_por || '0',
         // cot_salud_$: dt.cot_salud_$ || '0',
         // cot_salud_uf: dt.cto_salud_uf || '0',
         // payment_method: dt.payment_method,
         // load_number: dt.load_number+'',
         // idfamilystretch: dt.idfamilystretch,
         // idbank: dt.idbank,
         // idwaypay: dt.idwaypay,
         // idaccounttype: dt.idaccounttype,
         // account_number: dt.account_number,

         // //valores estáticos
         // idbranch_office:dt.idbranch_office,
         // idbranchoffice:dt.idbranchoffice,
         // idworkmode: dt.idworkmode, //1: presencial, 2: remoto
         // idworkingtime: dt.idworkingtime, //1: full time, 2: part time
         // idtype_duration_contract: dt.idtype_duration_contract, //1: anual, 2: mensual
         // contract_status: "1",
         // idnationality: 1, //1: chile, 2: extranjero
         // start_collation: "13:00",
         // end_collation: "14:00",
         // weekly_hour: dt.account_number,
         // idworkschedulecompany: 2,
         // id_document: 1,
         // signature_date: '2022-09-03',
         // start_date: '2022-09-04',
         // end_date: '2022-12-30',
         // email_contact: 'contrato@gmail.com',
         // signature: '1',
         // iddocumentcontract: dt.iddocumentcontract,
         // salary:dt.money,
         // quantity_burden: dt.load_number+'',
         // // idbranch_office: 4,
         // status_contract: 1,
         // idcodhealthinstitucion: dt.idsalud,
         // cot_salud_S: '0'

      })
      const { data, error, message } = worker
      return { data, error, message }
   },
   patchEditWorkersContract: async (dt,id): Promise<any> => {
      const worker = await http.patch<any>(`${API_URL_BASE}/v1/employeeContract/${id}`, dt)
      const { data, error, message } = worker
      return { data, error, message }
   },
  getDataInitialEmployee: async (): Promise<any> => {
   const resp = await http.get<any>(`${API_URL_BASE}/v1/employee/dataInitial` )
   const {data,error,message} = resp
   return {
      data: {
      Bank: (data?.Bank||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
      })),
      Country: (data?.Country||[]).map((dt) => ({
         id: dt.id,
         code: dt.code,
         abbreviation: dt.abbreviation,
         name: dt.name,
         nationality: dt.nationality,
         language: dt.language,
         coin: dt.coin,
         coin_code: dt.coin_code,
         idtimezone: dt.idtimezone,
         state: dt.state,
         status: dt.status,
      })),
      Military: (data?.Military||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
      })),
      Education: (data?.Education||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
      })),
      State: (data?.State||[]).map((dt) => ({
         status: dt.status,
         valueStatus: dt.valueStatus,
      })),
      Category: (data?.Category||[]).map((dt) => ({
         id: dt.id,
         description: dt.description,
      })),
      AFP: (data?.AFP||[]).map((dt) => ({
         id: dt.id,
         cod_afp_previred: dt.cod_afp_previred,
         cod_apv_previred: dt.cod_apv_previred,
         cod_rem: dt.cod_rem,
         codigo: dt.codigo,
         name: dt.name,
         status: dt.status
      })),
      APV: (data?.APV||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         rut: dt.rut,
         cod_rem: dt.cod_rem,
         codigo: dt.codigo
      })),
      AccountType: (data?.AccountType||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
      })),
      BusinessArea: (data?.BusinessArea||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
      })),
      CivilStatus: (data?.CivilStatus||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
      })),
      ContractType: (data?.ContractType||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
      })),
      DisabledPerson: (data?.DisabledPerson||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
      })),
      District: (data?.District||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
         idprovince: dt.idprovince,
         code : dt.code
      })),
      EmployeeType: (data?.EmployeeType||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
         idprovince: dt.idprovince,
         code : dt.code
      })),
      FamilyStretch: (data?.FamilyStretch||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         code :dt.code,
         status: dt.status,
      })),
      Position: (data?.Position||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
      })),
      Profession: (data?.Profession||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
      })),
      Salud: (data?.Salud||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         rut: dt.rut,
         status: dt.status
      })),
      TypeCtoSalud: (data?.TypeCtoSalud||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status
      })),
      GratificationType: (data?.GratificationType||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status
      })),
      TypeMoney: (data?.TypeMoney||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status
      })),
      WayPay: (data?.WayPay||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status
      })),
      CostCenter: (data?.CostCenter||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status,
         description: dt.description,
         // state_proration: dt.state_proration
      })),
      TypeDurationContract: (data?.TypeDurationContract||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status
      })),
      BranchOffice: (data?.BranchOffice||[]).map((dt) => ({
         id: dt.id,
         code: dt.code,
         address: dt.address,
         phone: dt.phone,
         idcompany: dt.idcompany,
         idofficetype: dt.idofficetype,
         iddistrict:dt.iddistrict,
         status:dt.status
      })),
      WorkMode: (data?.WorkMode||[]).map((dt) => ({
         id: dt.id,
         mode: dt.mode,
         status: dt.status
      })),
      WorkingTime: (data?.WorkingTime||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status
      })),
      Charge: (data?.Charge||[]).map((dt) => ({
         id: dt.id,
         name: dt.name,
         status: dt.status
      })),
      DocumentContract: (data?.DocumentContract||[]).map((dt) => ({
         id: dt.id,
         name: dt.description,
         status: dt.status
      })),
      Compensationbox: (data?.compensationbox||[]).map((dt) => ({
         id: dt.id,
         code: dt.code,
         name: dt.name,
         status: dt.status
      })),
      },
      error,
      message
   };
},
   editWorker: async (dataPhase, id): Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/employee/update/${id}`, {
         name: dataPhase.name,
         surname: dataPhase.surname,
         email: dataPhase.email,
         second_surname: dataPhase.second_surname,
         n_document: dataPhase.n_document,         
         birth_date:dataPhase.birth_date,
         idcivilstatus:dataPhase.idcivilstatus,
         iddistrict:dataPhase.iddistrict,
         ideducation: dataPhase.ideducation,
         idnationality: dataPhase.idnationality,
         idprofession: dataPhase.idprofession,
         pensioner:dataPhase.pensioner,
         sexo: dataPhase.sexo,
         idmilitary: dataPhase.idmilitary,
         state: dataPhase.state,
      });
      const { data, error, message } = res;
      return { data, error, message };
   },
   // getUserDetails: async (id: number) : Promise<UserDetails>=> {
   //    const dataUser = readLocalStorage(KEY_USER_DATA);
   //    const rut_empresa = dataUser?.user?.rut_empresa;
   //    const id_empresa = dataUser?.user?.idempresacliente;
   //    const id_usuario = dataUser?.user?.idusuario;
   //    const users = await http.get<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);

   //    let { data, error, message } = users;

   //    return {
   //       data,
   //       error,
   //       message
   //    }
   // },
   // createUser: async (dataUser) : Promise<any> => {

   //    const resp= await http.post<any>(`${API_URL_BASE}/v1/user`, {
   //       idtipousuario: dataUser.idtipousuario,
   //       nombre: dataUser.nombre,
   //       user_nick: dataUser.user_nick,
   //       email: dataUser.email_admin,
   //       password: dataUser.password,
   //       telefono: dataUser.telefono,
   //       idarea: dataUser.idarea,
   //       idempresacliente: dataUser.idempresacliente,
   //       participacion: dataUser.participacion
   //    })
   //    return {
   //       status: resp.status,
   //       message: resp.message,
   //       data: resp.data
   //    };
   // },
   // updateUser: async (id: number, dataUser: UserPost) : Promise<any> => {
   //    const userEdited = await http.patch<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}`, {
   //       idtipousuario: dataUser.idtipousuario,
   //       nombre: dataUser.nombre,
   //       email: dataUser.email,
   //       password: dataUser.password,
   //       role: dataUser.role,
   //    });
   //    return userEdited;
   // },
   updateStatusUser: async (id: number, dataUser: UserPost) : Promise<any> => {
      const userEdited = await http.patch<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}`, {
         // is_active: dataUser.is_active
      });

      return userEdited;
   },
   // deleteUser: async (id: number) : Promise<any> => {
   //    const userDeleted = await http.delete(`${API_URL_BASE}/v1/manage-users/${id}`)
   //    return userDeleted;
   // },
   //verificaciones user
   verifyUsernick: async (user_nick:string) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/verify-nick`, {
         user_nick: user_nick
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      }
   },
   verifyEmail: async (email:string) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/verify-email`, {
         email: email
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      }
   },
   getUserData: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/user/data/initial?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return {
         data,
         error,
         message
      }
   },
   updateUser: async (id: number, dataUser) : Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/user/${id}`, {
         idtipousuario: dataUser.idtipousuario,
         nombre: dataUser.nombre,
         user_nick: dataUser.user_nick,
         email: dataUser.email_admin,
         password: dataUser.password,
         telefono: dataUser.telefono
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   updateParticipationUsers: async (participacion) : Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/user/participacion/data`, {
         participacion:participacion
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   ResendPassword: async (idusuario) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/${idusuario}`, {})
      return resp;
   },
   deleteEmployee: async (id: number) : Promise<any> => {
      const resp = await http.delete(`${API_URL_BASE}/v1/employee/${id}`)
      return resp;
   },
}


