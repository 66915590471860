import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalTypeReminder.type";
import { ReminderTypeService } from "@/service/services/ReminderTypee.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalReminderType: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, reminderType, onListReminderType, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: reminderType ? reminderType.id : '',
        name: reminderType ? reminderType.name : ''
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            name : values.name
        }
        setLoading(true);
        if(reminderType && actionSelected=='edit'){
            await ReminderTypeService.editReminderType(formData, reminderType.id).then((data) => {
                onListReminderType && onListReminderType();
                closeModal && closeModal();
            })
        }else{
            await ReminderTypeService.createReminderType(formData).then((data) => {
                onListReminderType && onListReminderType();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(reminderType ? "Editar Tipo de Recordatorio" : "Agregar tipo de Recordatorio")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="nombre"
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(reminderType && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}