import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { dateFormatFromMonthYear, dateFormatToMonthYear } from '@/toolbox/helpers/date.helper';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const RleStructureRepository = {

   getRLEStructure: async(search = ''): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/rlestructure?search=${search}`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   getRLEStructureType: async(): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/rlestructure/type`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   getRLEStructureFilter: async(value: number, search = ""): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/rlestructure/filter?required=${value}&search=${search}`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   getRLEStructureOptional: async(search= ""): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/rleoptional?search=${search}`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   createStructureOptional: async (dataStructure) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/rleoptional`, {
         idtblrlestructure: dataStructure.idtblrlestructure,
         alias_name: dataStructure.alias_name,
         indefinite_validity: dataStructure.indefinite_validity,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   downloadStructureOptional: async () : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/rleoptional/downloadExcel`, {});
      const {data, error, message} = res;
      return { data, error, message };
   },

   createStructure: async (dataStructure) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/rlestructure`, {
         code: dataStructure.code,
         name: dataStructure.name,
         idtypestructure: dataStructure.idtypestructure,
         required: dataStructure.required,
         percentage: dataStructure.percentage,
         gratification: dataStructure.gratification,
         concept_DT: dataStructure.concept_DT,
         idcountry: dataStructure.idcountry,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   editRLEOptional: async(id:number, change_value:any): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/rleoptional/${id}`,change_value)
      const {data,error,message} = resp
      return {
         data: data,
         error: error,
         message: message
      }
   },
   editRLEStructure: async(id:number, dataStructure:any): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/rlestructure/${id}`,{
         code: dataStructure.code,
         name: dataStructure.name,
         idtypestructure: dataStructure.idtypestructure,
         required: dataStructure.required,
         percentage: dataStructure.percentage,
         gratification: dataStructure.gratification,
         concept_DT: dataStructure.concept_DT,
         idcountry: dataStructure.idcountry,
      })
      const {data,error,message} = resp
      return {
         data: data,
         error: error,
         message: message
      }
   },

   deleteRLEStructure: async(id:number): Promise<any> => {
      const resp = await http.delete<any>(`${API_URL_BASE}/v1/rlestructure/${id}`)
      const {data,error,message} = resp
      return {
         data: data,
         error: error,
         message: message
      }
   },

   getDetailEmployeeSO: async (id): Promise<any> => {
      const resp = await http.get<any>(`${API_URL_BASE}/v1/detailrleoptional/${id}`)
      const { data, error, message } = resp
      return {
         data: (data || []).map((dt) => ({
            id: dt.id,
            idcompany: dt.idcompany,
            idemployee: dt.idemployee,
            n_document: dt.n_document,
            surname: dt.surname,
            second_surname: dt.second_surname,
            name: dt.name,
            idrlestructureoptional: dt.idrlestructureoptional,
            alias_name: dt.alias_name,
            indefinite_validity: dt.indefinite_validity,
            idrlestructure: dt.idrlestructure,
            code: dt.code,
            required: dt.required,
            percentage: dt.percentage,
            gratification: dt.gratification,
            concept_DT: dt.concept_DT,
            idtypestructure: dt.idtypestructure,
            typestructure: dt.typestructure,
            creation_date: dt.creation_date,
            validity: dateFormatToMonthYear(dt.validity),
            type_value: dt.type_value,
            type_value_name: dt.type_value_name,
            value: dt.value,
            status: dt.status,
         })),
         error,
         message
      };
   },

   editDetailEmployeeSO: async (id: number, change_value: any): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/detailrleoptional/${id}`, change_value)
      const { data, error, message } = resp
      return {
         data: data,
         error: error,
         message: message
      }
   },

   editDetailEmployeeSOMultiple: async (value: any): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/detailrleoptional/updateMultiple`, {
         data: (value || []).map((dt) => ({
            id: dt.id,
            idcompany: dt.idcompany,
            idemployee: dt.idemployee,
            validity: dateFormatFromMonthYear(dt.validity),
            type_value: dt.type_value,
            type_value_name: dt.type_value_name,
            value: dt.value,
            status: dt.status,
         }))
      })
      const { data, error, message } = resp
      return {
         data: data,
         error: error,
         message: message
      }
   },
  
}   