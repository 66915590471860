import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const requestTypeRepository = {

    getRequestTypes: async(search = ''): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/typeRequest?search=${search}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createRequestType: async (dataRequestType) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/typeRequest`, {
            name: dataRequestType.name,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editRequestType: async (dataRequestType, idRequestType: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/typeRequest/${idRequestType}`, {
            name: dataRequestType.name,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteRequestType: async(idRequestType:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/typeRequest/${idRequestType}`)
        return res;
    },
}


