import { http } from '../http/http';
import { UserDetailsDTO,UserDTO,UserPost,UserRequestPost, UsersDTO} from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { CostCenter } from '../models/CostCenter';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idCompany = dataUser?.user?.id_company;


export const costCentersRepository = {

   getCostCenters: async (search = '') : Promise<CostCenter>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<CostCenter>(`${API_URL_BASE}/v1/costCenter?search=${search}`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            name: raw?.name,
            description: raw?.description,
         })),
         error,
         message
      }
   },

   createCostCenter: async (dataCostCenter) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/costCenter/create`, {
         idcompany: idCompany,
         name: dataCostCenter.name,
         description: dataCostCenter.description,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   updateCostCenter: async (idCostCenter: number, dataCostCenter) : Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/costCenter/update/${idCostCenter}`, {
         name: dataCostCenter.name,
         description: dataCostCenter.description,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   deleteCostCenter: async(idCostCenter:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/costCenter/delete/${idCostCenter}`)
      return res;
   },
}


