import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography, Autocomplete, InputAdornment, Snackbar, Alert } from '@mui/material';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import NumbersIcon from '@mui/icons-material/Numbers';
import TextField from '@mui/material/TextField';
import { responsabilityFamilyService } from "@/service/services/ResponsabilityFamily.service";
import { dateFormat } from "@/toolbox/helpers/date.helper";
import { TableDataV2 } from "@/components/common/Tablev2";
import ___PHRASE from "@/service/translate/translateService";
import { Loading } from "@/components/common/Loading";
import { ModalFamilyEmployee } from "@/components/Modal/ModalFamilyEmployee/ModalFamilyEmployee";
import { Modal } from "@/components/Modal/Modal";
import { ModalHeader } from "@/components/Modal/ModalHeader";
import { ModalBody } from "@/components/Modal/ModalBody";
import { ModalFamilyEmployeeDelete } from "@/components/Modal/ModalFamilyEmployeeDelete/ModalFamilyEmployeeDelete";
import '../Workers.css'
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

const header = [
   { name: 'name', label: ___PHRASE("nombre"), width: '' },
   { name: 'birth_date', label: ___PHRASE("fechaDeNacimiento"), width: '' },
   { name: 'valid_since', label: ___PHRASE("vigenciaInicio"), width: '' },
   { name: 'valid_until', label: ___PHRASE("vigenciaTermino"), width: '' },
   { name: 'RelationShip', label: ___PHRASE("parentesco"), width: '' }
]

const sexoValue = [
   { id:1, label: ___PHRASE("masculino")},
   { id:2, label: ___PHRASE("femenino") }
]

export const DataModalFamily: React.FC<any> = (props): JSX.Element => {

   const [loading, setLoading] = useState(false);

   const [formValues, setFormValues] = useState<any>({
      name: '',
      birth_date: null,
      valid_since: null,
      valid_until: null,
      idrelationship: 0,
      sexo: 0,
      idtyperesponsabilityfamily : 0,
      idfamilyburden: props.employee.idfamilyburden,
      idemployee: props.employee.idemployee,
   });
   
   const [dataInitial, setDataInitial] = useState<any>({
      typeResponsabilityFamily:[],
      relationShip:[]
   });

   const [dataAutocomplete, setDataAutocomplete] = useState<any>({
      parentesco: null,
      sexo: null,
      tipo_carga_familiar: null
   });

   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [error, setError] = useState<any>('');
   const [dataRespoFamily,setDataRespoFamily] = useState<any>([]);
   const [actionSelected, setActionSelected] = useState<any>('');
   const [showFamilyModal, setShowFamilyModal] = useState<boolean>(false);
   const [showFamilyDeleteModal, setShowFamilyDeleteModal] = useState<boolean>(false);
   const [familySelected, setFamilySelected] = useState<any>('');
  
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   });

   useEffect(()=>{
      getDataInitial();
      getResponsabilityFamily(props.employee.id);
   },[])

   const getResponsabilityFamily = async(id_employee) => {
      setLoading(true);
      const resp:any = await responsabilityFamilyService.getResponsabilityFamily(id_employee);
      console.log(resp.data);
      setDataRespoFamily(resp.data);
      setLoading(false);
   }

   const getDataInitial = async() =>{
      const resp:any = await responsabilityFamilyService.getDataInitial();
      if(resp){setDataInitial(prev => ({...prev , typeResponsabilityFamily:resp.data.type_responsability_family}))}
      if(resp){setDataInitial(prev => ({...prev , relationShip:resp.data.relation_ship}))}
   }

   const handleChangeBirthDate = (newValue) => {
      setError('')
      setFormValues({...formValues, birth_date: newValue.target.value})
   };

   const handleChangeValidSince = (newValue) => {
   setError('')
   setFormValues({...formValues, valid_since: newValue.target.value})
   };

   const handleChangeValidUntil = (newValue) => {
   setError('')
   setFormValues({...formValues, valid_until: newValue.target.value})
   };

   const handleInput = (e) => {
      const name = e.name? e.name : e.target.name;
      setError('');
      switch(name){
         case 'name':
            setFormValues({...formValues, name: e.target.value});
            break;
         case 'birth_date':
               setFormValues({ ...formValues, birth_date: e});
            break;
         case 'valid_since':
            setFormValues({ ...formValues, valid_since: e });
            break;
         case 'valid_until':
            setFormValues({ ...formValues, valid_until: e});
            break;
         case 'sexo':
            setFormValues({ ...formValues, sexo: e.target.value});
            break;
      }
   }

   const limpiarDatosFamiliar = () => {
      setFormValues({
         ...formValues,
         name:'',
         birth_date: '',
         valid_since: '',
         valid_until: ''
      })
   }

   const CreateResponsabilityFamily = async() => {
      console.log(formValues)
      if(!formValues.name){
         return setError('name');
      }
      if(!formValues.birth_date){
         return setError('birth_date');
      }
      if(!formValues.valid_since){
         return setError('valid_since');
      }
      if(!formValues.valid_until){
         return setError('valid_until');
      }
      if(!dataAutocomplete.sexo){
         return setError('sexo');
      }
      if(!dataAutocomplete.parentesco){
         return setError('relationShip');
      }
      if(!dataAutocomplete.tipo_carga_familiar){
         return setError('tipo_carga_familiar');
      }
     
      const dataCreate = {
         ...formValues,
         idrelationship: dataAutocomplete.parentesco?.id,
         sexo: dataAutocomplete.sexo?.toString(),
         idtyperesponsabilityfamily : dataAutocomplete.tipo_carga_familiar?.id,
         birth_date: dateFormat(formValues.birth_date),
         valid_until: dateFormat(formValues.valid_until),
         valid_since: dateFormat(formValues.valid_since),
         idemployee: props.employee.id
      }
      
      setLoading(true);

      const resp:any = await responsabilityFamilyService.postCreateResponsabilityFamily(dataCreate);
      if(resp?.data?.status){
         let apimessagge = apiResponseTranslate(resp?.data?.message || '');
         getResponsabilityFamily(props.employee.id);
         limpiarDatosFamiliar();
         setLoading(false);
         
         return setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: `${apimessagge || ___PHRASE("registroExitoso")}`})
      }
      setLoading(false);
      return snackBarConfig({...snackBarConfig, open:true, severity:'error', message: ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")})
   }

   const recuperarData = (value) => {
      setFamilySelected(value);
   }

   return (
      <>
      {loading && <Loading/>}
         <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                  {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>
         <Modal open={props.open} handleClose={props.closeModal} disableEscapeKeyDown disableBackdropClick handle size="lg" >
            <ModalHeader
               text={___PHRASE("mantenedorDeCargasFamiliaresDelTrabajador")}
               className='positionElements'
               onCancel={props.closeModal}
            >
            </ModalHeader>
            <ModalBody>
               <Grid container >
                  <Grid container spacing={3} className={`e-mb-lg`}>

                     <Grid item xs={12} sm={4}>
                        <Input
                           prependInnerAdornment={
                              <InputAdornment position="end">
                                 <NumbersIcon />
                              </InputAdornment>
                           }
                           ref={ref => inputRefs.current[0] = ref}
                           name="name"
                           type='text'
                           placeholder={___PHRASE("nombre")}
                           onChange={(e) => {handleInput(e);}}
                           value={formValues.name}
                           error={error == 'name' ? true: false}
                           disableElevation
                           validateOnBlur
                           dense
                           className='borderInput'
                           height='38px'
                        />
                     </Grid>

                     <Grid item xs={12} sm={4}>
                        <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={dataInitial.relationShip}
                           getOptionLabel={(option: any) => option.name ? option.name : ''}
                           onChange={(e, value) => {
                              if(value){setDataAutocomplete(prev=>({...prev,parentesco:value}))
                              setError('')}
                           }}
                           renderInput={(params) => <TextField {...params} 
                           error={error == 'relationShip'}
                           helperText={error == 'relationShip' && ___PHRASE("campoObligatorio")}
                           label={___PHRASE("parentesco")} />}
                        />
                     </Grid>    

                     <Grid item xs={12} sm={4}>
                        <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={sexoValue}
                           onChange={(e, value:any) => {
                              if(value){setDataAutocomplete(prev=>({...prev,sexo:value.id}))
                           setError('')}
                           }}
                           renderInput={(params) => <TextField {...params}
                           error={error == 'sexo' }
                           helperText={error == 'sexo' && ___PHRASE("campoObligatorio")}
                           label={___PHRASE("sexo")} />}
                        />
                     </Grid>

                     <Grid item xs={12} sm={4}>               
                        <TextField
                           variant="standard"
                           fullWidth
                           name="birth_date"
                           label={___PHRASE("fechaDeNacimiento")}
                           type="date"
                           onChange={handleChangeBirthDate}
                           value={formValues.birth_date}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={props.error=='birth_date'}
                           helperText={props.error=='birth_date' && ___PHRASE("campoObligatorio")}
                        />
                     </Grid>

                     <Grid item xs={12} sm={4}>
                        <TextField
                           variant="standard"
                           fullWidth
                           name="valid_since"
                           label={___PHRASE("vigenciaDesde")}
                           type="date"
                           onChange={handleChangeValidSince}
                           value={formValues.valid_since}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={props.error=='valid_since'}
                           helperText={props.error=='valid_since' && ___PHRASE("campoObligatorio")}
                        />
                     </Grid>

                     <Grid item xs={12} sm={4}>               
                        <TextField
                           variant="standard"
                           fullWidth
                           name="valid_until"
                           label={___PHRASE("hasta")}
                           type="date"
                           onChange={handleChangeValidUntil}
                           value={formValues.valid_until}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={props.error=='valid_until'}
                           helperText={props.error=='valid_until' && ___PHRASE("campoObligatorio")}
                        />
                     </Grid>

                     <Grid item xs={12} sm={4}>
                        <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={dataInitial.typeResponsabilityFamily}
                           getOptionLabel={(option: any) => option.name ? option.name : ''}
                           onChange={(e, value) => {
                              if(value){setDataAutocomplete(prev=>({...prev,tipo_carga_familiar:value}))
                           setError('')}
                           }}
                           renderInput={(params) => <TextField {...params} 
                           error={error == 'tipo_carga_familiar' ? true: false}
                           helperText={error == 'tipo_carga_familiar' && ___PHRASE("campoObligatorio")}
                           label={___PHRASE("tipoCargaFamiliar")} />}
                        />
                     </Grid>

                  </Grid>

                  <Grid container mt={2}>
                     <Grid item xs={12} textAlign="center">
                        <button className='btn_add' onClick={()=>{CreateResponsabilityFamily()}} style={{cursor: 'pointer'}}>
                           {___PHRASE("agregar")}
                        </button>
                     </Grid>

                  </Grid>

                  <Grid xs={12}>
                     <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                        {___PHRASE("listadoDeCargasFamiliares")}
                     </Typography>
                  </Grid>

                  <TableDataV2
                     header={header}
                     data={dataRespoFamily}
                     actionSelect={setActionSelected}
                     setModalSave={setShowFamilyModal}
                     setModalDelete={setShowFamilyDeleteModal}
                     status_action
                     disabled_title={true}
                     btn_disabled={true}
                     btn_edit_disable={false}
                     btn_delete_disable={false}
                     RecuperarData={recuperarData}
                     bg_header={'#3f9bcc'}
                     color_text_header={'#FFFF'}
                     rows_per_page={2}
                  />
               </Grid>
            </ModalBody>
         </Modal>

         { 
            showFamilyModal && (
               <ModalFamilyEmployee
                  open={showFamilyModal}
                  closeModal={()=>{setShowFamilyModal(false)}}
                  actionSelected={actionSelected}
                  onListFamilies={getResponsabilityFamily}
                  family={familySelected}
                  workerSelected={props.employee}
                  dataInitial={dataInitial}
                  clearState={()=>{setFamilySelected('')}}
               />
            )
         }
         {
            showFamilyDeleteModal && (
               <ModalFamilyEmployeeDelete
                  open={showFamilyDeleteModal}
                  closeModal={()=>{setShowFamilyDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListFamilies={getResponsabilityFamily}
                  family={familySelected}
                  workerSelected={props.employee}
                  clearState={()=>{setFamilySelected('')}}
               />
            )
         }
      </>
   );
};

