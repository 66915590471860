import { userRepository } from "../repositories/User.repository";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import {ProfilePermissionsService} from './ProfilePermissions.service';

export const userService = {
   getRoles,
   getUsers,
   getUserByCompany,
   getUserPage,
   getUserSearch,
   updateParticipationUsers,
   ResendPassword,
   createUser,
   createOnlyUser,
   RelationUSerEmployee,
   updatePassword,
   editDetailUserCompany,
   createDetailUser,
   update,
   updateForAdmin,
   deleteUser,
}

async function getRoles(){
   const res = await userRepository.getRoles();
   return res;
}

async function getUsers (search = "") {
   const user= await userRepository.getUsers(search);
   return user
}

async function getUserByCompany (search = '') {
   const user= await userRepository.getUserByCompany(search);
   return user
}

async function getUserPage (perPage, page) {
   const user= await userRepository.getUserPage(perPage, page);
   return user
}

async function getUserSearch (search) {
   const user= await userRepository.getUserSearch(search);
   return user
}

async function updateParticipationUsers (participacion) {
   const user= await userRepository.updateParticipationUsers(participacion);
   return user
}

async function ResendPassword(idusuario){
   const user= await userRepository.ResendPassword(idusuario);
   return user
}

async function createUser(data){
   try {
      if(data.role.length > 1){
         const res = await userRepository.createUser({...data, role: 2});
         if(res.data.detail){
            const dataPermission = {
               'idusers': JSON.stringify([res.data.detail[0].iduser]),
               'idprofile': data.idprofile,
               'idrole': 2
            }
            await ProfilePermissionsService.createPermissionProfile(dataPermission);
            await createDetailUser({
               iduser: res.data.detail[0].iduser,
               idrole: 5,
               assign_ip: data.assign_ip,
               idemployee: data.idemployee,
            });
            await ProfilePermissionsService.createPermissionProfile({...dataPermission, idrole: 5});
         }
      }else{
         const res = await userRepository.createUser({...data, role: data.role[0].id});
         if(res.data.detail){
            const dataPermission = {
               'idusers': JSON.stringify([res.data.detail[0].iduser]),
               'idprofile': data.idprofile,
               'idrole': data.role[0].id
            }
            await ProfilePermissionsService.createPermissionProfile(dataPermission);
         }
      }
      return true;
   } catch (error) {
      return false;
   }
   
}

async function createOnlyUser(data){
   const res = await userRepository.createOnlyUser(data);

   if(res.data.detail){
      const dataPermission = {
         'idusers': JSON.stringify([res.data.detail[0].iduser]),
         'idprofile': data.idprofile,
         'idrole': data.role,
         'idCompany': data.idCompany
      }
      const assignment = await ProfilePermissionsService.createPermissionProfile(dataPermission);
   }
   return res;
}

async function RelationUSerEmployee (idUser, idEmployee) {
   const user= await userRepository.RelationUSerEmployee(idUser, idEmployee);
   return user
}

async function updatePassword (dataPassword) {
   const user= await userRepository.updatePassword(dataPassword);
   return user
}

async function update (data) {
      const dataEdit = {
         'iduser': data.id,
         'email': data.email,
         'user_name': data.user_name,
         'n_document': data.n_document
      }
   const user= await userRepository.update(dataEdit);
   if(user.data.status){
      const dataPermission = {
         'idusers': JSON.stringify([data.iduser]),
         'idprofile': data.idprofile,
         'idCompany': data.idcompanies
      }
      await ProfilePermissionsService.editPermissionProfile(dataPermission.idprofile, dataPermission);
      return true;
   }else{
      return false;
   }

}

async function deleteUser (idUser, roleUserSession) {
   const user= await userRepository.deleteUser(idUser, roleUserSession);
   return user
}

async function updateForAdmin (data) {
      const dataEdit = {
         'iduser': data.id,
         'email': data.email,
         'user_name': data.user_name,
         'n_document': data.n_document
      }
   const user= await userRepository.update(dataEdit);
   if(user.data.status){
      const dataPermission = {
         'iduser': JSON.stringify([data.iduser]),
         'idprofile': data.idprofile,
         'idEmployee': data?.idEmployee ? data?.idEmployee : null,
         'role': data.role
      }
      await ProfilePermissionsService.editPermissionProfileForAdmin(dataPermission.idprofile, dataPermission);
      return true;
   }else{
      return false;
   }

}

async function editDetailUserCompany (dataUserCompany) {
   const user= await userRepository.editDetailUserCompany(dataUserCompany);
   return user
}

async function createDetailUser (dataDetail) {
   const user= await userRepository.createDetailUser(dataDetail);
   return user
}
