import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Container } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { Props } from "./Country,type"
import { CountryService } from "@/service/services/Country.service"
import { ModalCountry } from "@/components/Modal/ModalCountry/ModalCountry"
import { ModalCountryDelete } from "@/components/Modal/ModalCountryDelete/ModalCountryDelete"
import ___PHRASE from "@/service/translate/translateService"

export const CountryView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState<any>([]);
    const [showCountryModal, setShowCountryModal] = useState<boolean>(false);
    const [showCountryDeleteModal, setShowCountryDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [countrySelected, setCountrySelected] = useState<any>('');
    

    useEffect(() => {
        getCountries();
    }, []);

    const getCountries = async () => {
        setLoading(true);
        await CountryService.getCountries().then((data) => {
            setCountries(data.data || [])
           setLoading(false);
        });
    }

    function RecuperarData(value){
        setCountrySelected(value)
        console.log(value)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <TableDataV2
                    title={___PHRASE("paises")}
                    data={countries || []}
                    header={[
                        { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: false },
                        { name: 'abbreviation', label: ___PHRASE("abreviacion"), filter: false, Chip: false },
                        { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: false },
                        { name: 'language', label: ___PHRASE("lenguaje"), filter: false, Chip: true },
                        { name: 'coin', label: ___PHRASE("moneda"), filter: false, Chip: true },
                    ]}
                    actionSelect={setActionSelected}
                    setModalSave={setShowCountryModal}
                    setModalDelete={setShowCountryDeleteModal}
                    status_action
                    RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showCountryModal && (
                    <ModalCountry
                        open={showCountryModal}
                        closeModal={()=>{setShowCountryModal(false); setCountrySelected('')}}
                        actionSelected={actionSelected}
                        onListCountries={getCountries}
                        country={countrySelected}
                        clearState={()=>{setCountrySelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalCountryDelete
                        open={showCountryDeleteModal}
                        closeModal={()=>{setShowCountryDeleteModal(false);setCountrySelected('')}}
                        actionSelected={actionSelected}
                        onListCountries={getCountries}
                        country={countrySelected}
                        clearState={()=>{setCountrySelected('')}}
                    />
                )
            }
        </Protected>
    )
}