import { http } from '../http/http';
import { LoginDTO, LogoutDTO, UserDTO } from '../http/dto/AuthenticationDTO';
import { Login, Logout, Authentication } from '../models/Authentication';
import { API_URL_BASE, API_URL_SOFTNET } from '@/toolbox/defaults/app';

export const authenticationRepository = {
   login: async (user_nick: string, rut_empresa: string, password: string, from: string): Promise<Login> => {
      const resp = await http.post<LoginDTO>(`${API_URL_BASE}/v1/auth/login`, {
         user_name: user_nick,
         rut: rut_empresa,
         password: password,
         from: from
      })
      return {
         error: resp.error,
         status: resp.status,
         message: resp.message,
         data: !!resp.data ? {
            token: resp.data?.access_token || '',
            expires_in: resp.data?.expires_in || 0,
            token_type: resp.data?.token_type || '',
            message: resp.data?.message || '',
            user: resp.data?.user || {},
         } : undefined
      }
   },
   changePassword: async (password): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/user?`, {
         password: password
      })
      return resp
   },
   logout: async (): Promise<Logout> => {
      const resp = await http.post<LogoutDTO>(`${API_URL_BASE}/v1/auth/sign-out`)
      // console.log(resp)
      return {
         error: resp?.error,
         status: resp?.status,
         message: resp?.message
      }
   },
   profile: async (access_token: string, resp: any) => {
      // console.log(resp)
      return {
         user: {
            id: resp[0]?.id,
            id_company: resp[0]?.id_company,
            idrole: resp[0]?.idrole,
            iduser: resp[0]?.iduser,
            idemployee: resp[0]?.idemployee,
            n_document: resp[0]?.n_document,
            role: resp[0]?.roleName,
            user_name: resp[0]?.user_name,
            email: resp[0]?.email,
            date_assignment: resp[0]?.date_assignment,
            status: resp[0]?.status,
            status_confirm: resp[0]?.status_confirm,
            validation_confirm: resp[0]?.validation_confirm,
            main_redirect: '/',
         },
         error: null,
         token: access_token
      }
   },
}

