import { Props } from "./ModalFeeLoanDetail.type"
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Loading } from "@/components/common/Loading";
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ModalFooter } from "../ModalFooter";
import { LoanService } from "@/service/services/Loan.service";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ROLE_TRABAJADOR, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import ___PHRASE from "@/service/translate/translateService";

export const ModalFeeLoanDetail: React.FC<Props> = (props: any): JSX.Element => {

    const { open, closeModal, onList, workerSelected, loanSelected, setSnackbar, snackbar } = props;
    console.log(workerSelected)
    const [loading, setLoading] = useState<boolean>(false);
    const userData = readLocalStorage(KEY_USER_DATA)
    const role = userData.user.role
    const onSubmit = async () => {
        console.log(rows)
        setLoading(true)
        if(!(rows.find((item)=>item.selected == true))){
            setSnackbar({...snackbar, open:true, severity:'error', message: ___PHRASE("debesDeSeleccionarUnaCuota")})
            setLoading(false)
            return
        }
         let formData = {
            idLoan: loanSelected?.id,
            idOptional: rows.find((item)=>item.selected == true).id,
            idEmployee: workerSelected.id
         }
        await LoanService.accept(formData).then((data) => {
            onList && onList(0, workerSelected.id)
            closeModal && closeModal();
        });

        setLoading(false);
    }

    const [rows, setRows] = useState([]);
    const getDetailFeeLoan = async() => {
        setLoading(true)
        await LoanService.getOptionalFeeLoan(loanSelected?.id).then((data) => {
            if(data.data.length > 0 ){
                setRows(data.data)
                setLoading(false)
            }else{
                setLoading(false)
                setSnackbar({...snackbar, open:true, severity: 'info', message: ___PHRASE("aunNoSeDisponibilizaLasCuotas")})
                closeModal();
            }

           
        });
    }

    useEffect(()=>{
        getDetailFeeLoan()
    },[])

    const handleRowClick = (index) => {
        const updatedRows = [...rows];
        updatedRows[index].selected = !updatedRows[index].selected;
        if (updatedRows[index].selected) {
          updatedRows.forEach((row, i) => {
            if (i !== index) {
              row.selected = false;
            }
          });
        }
        setRows(updatedRows);
      };
      

    return (
        <>
            {loading && <Loading />}
            <Modal open={open} handleClose={closeModal} handle size="sm">
                <ModalHeader
                    text={role== ROLE_TRABAJADOR ? ___PHRASE("eligeLaCuotaDePago") : ___PHRASE("detallesDeLaCuotas")}
                    className='positionElements'
                    onCancel={closeModal}
                >
                </ModalHeader>
                <ModalBody>
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell ><Typography sx={{fontSize:18}}>{___PHRASE("nCuotas")}</Typography></TableCell>
                                    <TableCell ><Typography sx={{fontSize:18}}>{___PHRASE("monto")}</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {row.n_fee}
                                        </TableCell>
                                        <TableCell>
                                           $ {row.total}
                                        </TableCell>
                                        { role== ROLE_TRABAJADOR && <TableCell>
                                            <Button variant={row.selected ? 'contained' : 'outlined'} onClick={() => handleRowClick(index)}>
                                                {row.selected ? ___PHRASE("seleccionado") : ___PHRASE("seleccionar")}
                                            </Button>
                                        </TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </ModalBody>
                { role== ROLE_TRABAJADOR &&
                <Grid justifyContent={'center'} alignItems={'flex-end'} sx={{p:3}}>
                <Button variant='contained' 
                // sx={{width: 100, ml:50}} 
                sx={{background:'#007ea7',color:'#fff', ml:50, '&:hover':{
                    bgcolor:'#007ea7'
                   }}}
                onClick={()=>onSubmit()}>
                    {___PHRASE("aceptar")}
                </Button>
                </Grid>}
               
            </Modal>
        </>
    )
}