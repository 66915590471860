import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalProfileSession.type";
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { TableDataV2 } from "@/components/common/Tablev2";
import { ProfileSessionService } from "@/service/services/ProfileSession.service";
import { ViewsService } from "@/service/services/Views.service";
import { ModuleService } from "@/service/services/Module.service";
import { RawOff } from "@mui/icons-material";
import ___PHRASE from "@/service/translate/translateService";

export const ModalProfileSession: React.FC<Props> = (props: any): JSX.Element => {

    const { open, closeModal, actionSelected, profile, onListProfiles, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [roles, setRoles] = useState<any>([]);
    const [rolesSelected, setRolesSelected] = useState<any>([])
    const [modules, setModules] = useState<any>([]);
    const [modulesSelected, setModuleSelected] = useState<any>([]);
    const [data, setData] = useState<any>({
        description: profile ? profile.name : ''
    });

    const [views, setViews] = useState([]);
    const [numIteration, setNumIteration] = useState<any>(1)

    const getViewByRol = async () => {
        setLoading(true);

    }

    const cabecera = [
        { id: 1, name: 'code', label: ___PHRASE("codigo") },
        { id: 2, name: 'name', label: ___PHRASE("nombre") },
        { id: 3, name: 'module', label: ___PHRASE("modulo") },
        { id: 4, name: 'route', label: ___PHRASE("ruta") },
        { id: 4, name: 'role', label: ___PHRASE("rol") },
    ];

    const getIdsViews = (arrayData) => {
        let filterData = arrayData.filter(item => item.active == true)
        const ids = filterData.map((i) => {
            return i.id;
        })
        return JSON.stringify(ids)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (actionSelected == 'edit') {
            await ProfileSessionService.editProfile(profile.id, { name: data.description })
            await ProfileSessionService.editViews(profile.id, {idviews: getIdsViews(views)})
            onListProfiles && onListProfiles();
            closeModal && closeModal();
            setLoading(false);
        } else {
            //console.log(getIdsViews(views))
            await ProfileSessionService.createProfiles({name: data.description, idviews: getIdsViews(views)})
            onListProfiles && onListProfiles();
            closeModal && closeModal();
            setLoading(false);
        }
    }

    const handleChange = (e) => {
        setData({ ...data, description: e.target.value })
    }

    const getRoles = async () => {
        // const resp = await ProfileSessionService.getRoles();
        // if (resp.data) { setRoles(resp.data) }
        setRoles([
            { id: 2, description: ___PHRASE("administrador") },
            { id: 5, description: ___PHRASE("trabajador") }
        ])

        if (actionSelected == 'edit') {
            // propsRoles = profile.roles
            setRolesSelected(profile.roles)
        }
    }

    const getModulos = async () => {
        if (rolesSelected.length > 0) {
            let arraysIds = rolesSelected.map((item) => { return item.id })
            const resp = await ModuleService.getByRoles(JSON.stringify(arraysIds));
            setModules(resp.data || [])
            if (actionSelected == 'edit') {
                setModuleSelected(profile.modules)
            }
        } else {
            setModules([])
        }

    }

    useEffect(() => {
        getRoles();
    }, []);

    const getViewsByRoles = async () => {
        if (rolesSelected.length > 0) {
            const ids = rolesSelected.map((item) => { return item.id })
            if (views.length > 0) {
                const resp = await ViewsService.getViewsByRoles(JSON.stringify(ids));
                const filterViews = views.filter(item => item.active == true)
                if (resp.data) {
                    let dataAction = resp.data.map(raw => {

                        if (filterViews.find((item) => item.id == raw.id)) {
                            return { ...raw, active: true }
                        } else {
                            return { ...raw, active: false }
                        }
                    })
                    setViews(dataAction)

                }
            } else {
                const resp = await ViewsService.getViewsByRoles(JSON.stringify(ids));
                if (resp.data) {
                    let dataAction = resp.data.map(raw => { return { ...raw, active: false } })
                    setViews(dataAction)
                }
            }
        } else {
            setViews([])
        }

    }

    const markedViewsbyModules = () => {
        if (modulesSelected.length > 0) {
            const filteredArray = views.filter(item => {
                return modulesSelected.some(route => route.id === item.idmodule);
            });
            console.log(filteredArray)

            let dataAction = views.map((raw) => {
                if (filteredArray.find(item => item.id == raw.id)) {
                    return { ...raw, active: true }
                } else {
                    return { ...raw, active: false }
                }
            })

            setViews(dataAction)
        } else {
            let dataAction = views.map((item) => {
                return { ...item, active: false }
            })
            setViews(dataAction)
        }
    }

    useEffect(() => {
        getViewsByRoles(); getModulos();
    }, [rolesSelected])

    const markedViewsByProfile = async () => {
        const resp = await ViewsService.getViewsByProfile(profile.id);
        if (resp.data) {
            let dataActive = views.map((item) => {
                if (resp.data.find((raw) => { raw.id == item.id })) {
                    return { ...item, active: true }
                } else {
                    return { ...item, active: false }
                }
            })
            setViews(dataActive);
        }
        setNumIteration(numIteration + 1)
    }

    useEffect(() => {
        numIteration == 1 && actionSelected== 'edit' ? markedViewsByProfile() : markedViewsbyModules();
    }, [modulesSelected])

    return (
        <>
            {loading && <Loading />}

            <Modal open={open} handleClose={closeModal} handle size={"lg"}>
                <ModalHeader
                    text={((profile && actionSelected == 'edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("perfil")}
                    className='positionElements'
                    onCancel={closeModal}
                    clearState={clearState}
                >
                </ModalHeader>
                <ModalBody>
                    <Grid container flexDirection={'row'} alignItems='flex-start'>
                        <Grid item xs={4} container marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                            </Grid>
                            <Grid item xs={8} mb={3}>
                                <TextField
                                    id="name"
                                    variant="standard"
                                    type="text"
                                    name="name"
                                    fullWidth
                                    value={data.description}
                                    onChange={handleChange}
                                    placeholder={___PHRASE("nombre")}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("filtrarPorRoles")}:</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Autocomplete
                                    id="disable-portal"
                                    disablePortal
                                    value={rolesSelected}
                                    options={roles || []}
                                    getOptionLabel={(option) => option.description || ""}
                                    fullWidth
                                    multiple
                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                    onChange={(e, newValue) => { setRolesSelected(newValue) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("seleccionarPorModulos")}:</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Autocomplete
                                    id="disable-portal"
                                    // disablePortal
                                    value={modulesSelected}
                                    options={modules || []}
                                    getOptionLabel={(option) => option.name || ""}
                                    fullWidth
                                    multiple
				    limitTags={5}
                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                    onChange={(e, newValue) => { setModuleSelected(newValue) }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={8} container alignItems="center" justifyContent="center" marginBottom='25px'>
                            <TableDataV2
                                data={views}
                                setData={setViews}
                                header={cabecera}
                                checkbox
                                disabled_title
                                btn_disabled
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <ModalFooter
                        buttonType="submit"
                        confirmText={(profile && actionSelected == 'edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                        cancelText={___PHRASE("cancelar")}
                        onCancel={closeModal}
                        onConfirm={handleSubmit}
                        clearState={clearState}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}
